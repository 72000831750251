import React from 'react';
import { Svg, G, Path } from 'react-native-svg';

export function Logo({ color = '#000000' }) {
  return (
    <Svg
      width="30"
      height="15"
      viewBox="0 0 1024.000000 458.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <G
        transform="translate(0.000000,458.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <Path d="M0 2290 l0 -2290 1645 0 1645 0 0 430 0 430 -1125 0 -1125 0 0 1860 0 1860 -520 0 -520 0 0 -2290z" />
        <Path d="M4210 2290 l0 -2290 545 0 545 0 0 2290 0 2290 -545 0 -545 0 0 -2290z" />
        <Path d="M6270 4150 l0 -430 1231 0 c1067 0 1230 -2 1226 -14 -3 -8 -569 -690 -1256 -1515 l-1251 -1501 0 -345 0 -345 2010 0 2010 0 0 430 0 430 -1306 0 c-803 0 -1304 4 -1302 9 2 5 305 373 675 818 369 444 920 1107 1224 1473 303 366 565 680 580 698 l29 33 0 344 0 345 -1935 0 -1935 0 0 -430z" />
      </G>
    </Svg>
  );
}
