import { useCallback, useEffect, useState } from 'react';
import { update } from 'ramda';

import { useErrors, useSessionErrors } from '@providers/Errors';
import { ContextFinderError } from '@providers/Errors/interfaces';
import { useLoggedIn, useLogout } from '@views/Login';

import { mapErrorForFinderUI } from './common';
import { UIFinderError } from './interfaces';
import uiErrorActions from './actions';

const errorsFromStateToUI = (errors: ContextFinderError[]): UIFinderError[] =>
  errors.map((error, index) => {
    const newUIError = mapErrorForFinderUI({
      error: {
        ...error,
      },
      actions: uiErrorActions,
    });

    return { ...newUIError, index };
  });

export function useErrorsView() {
  const logout = useLogout();
  const loggedIn = useLoggedIn();
  const { forceClearAllErrors } = useErrors();
  const errors = useSessionErrors();

  const [uiErrors, setUiErrors] = useState<UIFinderError[]>(
    errorsFromStateToUI(errors),
  );

  const fatalPresent = uiErrors.some((error) => !error.dismissible);
  const visible =
    uiErrors.filter((error) => !error.showed).length > 0 && loggedIn;

  const dismiss = useCallback((index: number) => {
    setUiErrors((prevState) => {
      const currentErr = { ...prevState[index], showed: true };

      return update(index, currentErr, prevState);
    });
  }, []);

  useEffect(() => {
    setUiErrors(errorsFromStateToUI(errors));
  }, [errors]);

  return {
    dismiss,
    errors: uiErrors.filter((error) => !error.showed),
    fatalPresent,
    visible,
    forceClearErrors: forceClearAllErrors,
    logout,
  };
}
