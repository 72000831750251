import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';

import { useCurrentDate, useOpenCalendar } from '@views/Calendar/hooks';
import { useMyOrganization } from '@providers/Organization';

export function useTopBar() {
  const {
    formats: { dayFullFormat },
  } = useIntl() as any;
  const { backgroundColor, name, logo, iconColor } = useMyOrganization();
  const currentDate = useCurrentDate();
  const date = universalDateFormatter({
    date: currentDate,
    format: dayFullFormat,
  });
  const { open: openCalendar } = useOpenCalendar();

  return {
    backgroundColor,
    date,
    logo,
    name,
    openCalendar,
    iconColor,
  };
}
