import { useState, useEffect, useLayoutEffect } from 'react';

import { ScaleInput } from './interfaces';

const NO_SCALE_LIMIT = -1;

interface limitProps {
  maxLimit: number;
  minLimit: number;
  value: number;
}

const hasLimit = (limit) => limit > -1;
const getValue = ({ maxLimit, minLimit, value }: limitProps) => {
  if (maxLimit > -1 && value > maxLimit) {
    return maxLimit;
  }

  if (minLimit > -1 && value < minLimit) {
    return minLimit;
  }

  return value;
};

const getLimitOrValue = ({ maxLimit, minLimit, value }: limitProps) => hasLimit(maxLimit) || hasLimit(minLimit) ? getValue({ maxLimit, minLimit, value }) : value;

export function useScale({
  imageHeight = 1,
  imageWidth = 1,
  viewHeight = 1,
  viewWidth = 1,
  onChange: listener,
  maxScale = NO_SCALE_LIMIT,
  minScale = NO_SCALE_LIMIT,
}: ScaleInput) {
  const [scale, setScale] = useState(1);
  const [pinch, setPinch] = useState(1);

  // Determine which dimension of image is less compatible width view
  const widthDiff = Math.abs(1 - imageWidth / viewWidth);
  const heightDiff = Math.abs(1 - imageHeight / viewHeight);
  const useWidth = widthDiff > heightDiff;

  const initialScale = useWidth ? viewWidth / imageWidth : viewHeight / imageHeight;
  const pinchedScale = getLimitOrValue({ maxLimit: maxScale, minLimit: minScale, value: scale * pinch });

  // Update scale only after initial scale changed
  useEffect(() => {
    setScale(initialScale);
  }, [initialScale, setScale]);

  const areaWidth = viewHeight / pinchedScale;
  const areaHeight = viewHeight / pinchedScale;

  useLayoutEffect(() => {
    listener?.({
      scale: pinchedScale,
      areaWidth,
      areaHeight,
    });
  }, [areaWidth, areaHeight, pinchedScale]);

  return {
    areaWidth,
    areaHeight,
    scale,
    pinch,
    pinchedScale,
    setPinch,
    setScale,
  };
}
