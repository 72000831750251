import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { pathOr, pluck, uniq } from 'ramda';
import { useErrorQuery } from '@providers/Errors';

import { useIntl } from '@utils/intl';
import { universalDateFormatter, dateFormatShort } from '@utils/DateAndTime';

import { useCurrentDate } from '@views/Calendar/hooks';
import { getDeskSlots } from '@views/Home/Bookings/Desks/hooks';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_ROOM,
} from '@views/shared/consts';

import {
  FavouriteArea,
  FavouriteAreasData,
  FavouriteDesksData,
  FavouriteItem,
} from '@views/shared/interfaces/favourites';

const queryDesks = gql`
  query getDesks($ids: [ID]!, $date: DateTime!) {
    desksByIds(desksIds: $ids, date: $date) {
      id
      label
      description
      areaId
      isFree
      blocked
      numberOnFloor
      equipment {
        category {
          id
          name
        }
        id
        name
      }
    }
  }
`;

const queryAreas = gql`
  query getAreas($ids: [ID]!, $date: Date!) {
    areasByIds(areasIds: $ids, date: $date) {
      id
      name
      areaType
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        freeSlots
      }
      meetingRoomTimeStats {
        booked
        total
      }
      floor {
        floorLabel
        floorNumber
        floorType
        building {
          name
          address
        }
      }
      equipment {
        category {
          id
          name
        }
        id
        name
      }
    }
  }
`;

const checkAvailability = (area: FavouriteArea): boolean => {
  const areasTypes = {
    [AREA_TYPE_FREE_SPACES_ROOM]: () => {
      const free = getDeskSlots(
        area,
        'freeSlots',
        0,
        AREA_TYPE_FREE_SPACES_ROOM,
      );

      return free > 0;
    },
    [AREA_TYPE_MEETING_ROOM]: () => {
      const booked = pathOr(0, ['meetingRoomTimeStats', 'booked'], area);
      const total = pathOr(1, ['meetingRoomTimeStats', 'total'], area);
      return total - booked > 0;
    },
  };

  return areasTypes[area.areaType]();
};

const transformDataForAreas = (areas: FavouriteAreasData) =>
  areas.areasByIds.map((area) => {
    const { id, name: areaName = '', floor, equipment, areaType } = area;

    return {
      areaId: id,
      areaName,
      available: checkAvailability(area),
      floor,
      equipment,
      id,
      label: areaName,
      numberOnFloor: 0,
      type: areaType,
    };
  });

const transformDataForDesks = (
  areas: FavouriteAreasData,
  desks: FavouriteDesksData,
) =>
  desks.desksByIds.map((desk) => {
    const {
      areaId,
      blocked,
      equipment = [],
      id,
      isFree,
      label,
      numberOnFloor,
    } = desk;
    const { name: areaName = '', floor } = areas.areasByIds.find(
      (area) => area.id === areaId,
    ) as FavouriteArea;

    return {
      areaId,
      areaName,
      available: isFree && !blocked,
      floor,
      equipment,
      id,
      label,
      numberOnFloor,
      type: AREA_TYPE_ROOM,
    };
  });

export function useAreaList(ids) {
  const { t } = useIntl();
  const date = useCurrentDate();
  const { data, error, loading } = useErrorQuery<FavouriteAreasData>(
    queryAreas,
    {
      fetchPolicy: 'no-cache',
      variables: {
        ids,
        date: universalDateFormatter({ date, format: dateFormatShort }),
      },
      finderError: {
        type: 'fatal',
        message: t('Home.Favourites.hooks.area.fetchFail'),
      },
    },
  );

  return {
    items: data?.areasByIds ? transformDataForAreas(data) : [],
    error,
    loading,
  };
}

export function useDeskList(ids) {
  const { t } = useIntl();
  const date = useCurrentDate();

  const [items, setItems] = useState<FavouriteItem[]>([]);
  const [areaIds, setAreaIds] = useState<string[]>([]);

  const { data, loading, error } = useErrorQuery<FavouriteDesksData>(
    queryDesks,
    {
      fetchPolicy: 'no-cache',
      variables: {
        ids,
        date: universalDateFormatter({ date }),
      },
      finderError: {
        type: 'fatal',
        message: t('Home.Favourites.hooks.desks.desksFetchFail'),
      },
    },
  );

  const {
    data: areasData,
    error: areasError,
    loading: areasLoading,
  } = useErrorQuery<FavouriteAreasData>(queryAreas, {
    fetchPolicy: 'no-cache',
    variables: {
      ids: areaIds,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !areaIds.length,
    finderError: {
      type: 'fatal',
      message: t('Home.Favourites.hooks.area.fetchFail'),
    },
  });

  useEffect(() => {
    if (data?.desksByIds) {
      const pluckedIds = uniq(pluck('areaId', data.desksByIds));
      setAreaIds(pluckedIds);
    }
  }, [data]);

  // unfortunately we don't have the deep area data (floor, building) for any
  // desk and need to fetch this from areas query and merge together
  useEffect(() => {
    if (data?.desksByIds && areasData?.areasByIds) {
      const combinedData = transformDataForDesks(areasData, data);
      setItems(combinedData);
    }
  }, [areasData, data]);

  return {
    items,
    error: error || areasError,
    loading: loading || areasLoading,
  };
}
