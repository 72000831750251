import React from 'react';
import { View, Text } from 'react-native';

import { universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { getBookingType } from '@views/shared/utils';
import { CustomIcon } from '@views/shared/CustomIcon';
import { styles } from '@views/Bookings/Day/Ticket/Booking';
import { getLocation } from '@views/shared/hooks/getLocation';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { globalStyles } from '@views/shared/styles';
import { useMyOrganization } from '@providers/Organization';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getBookingIcon } from '@views/shared/consts';

const height = 'auto';

interface Props {
  booking: BookingType;
}

export function Booking({ booking }: Props) {
  const {
    formats: { timeFormatShort },
    t,
  } = useIntl() as any;

  const type = getBookingType(booking);
  const start = universalDateFormatter({
    date: booking.start,
    format: timeFormatShort,
  });
  const end = universalDateFormatter({
    date: booking.end,
    format: timeFormatShort,
  });
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();
  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });
  const location = getLocation({
    booking,
    t,
    showDeskNumber,
  });

  return (
    <View style={[styles.card, globalStyles.shadow]}>
      <View style={[styles.row, { height }]}>
        <View style={[styles.left, styles.between]}>
          <View style={styles.icon}>
            <CustomIcon name={`${getBookingIcon(type)}-no`} size={30} />
          </View>
        </View>
        <View style={[styles.center, styles.between]}>
          <View>
            <Text style={globalStyles.title}>{location}</Text>
            <Text style={globalStyles.description}>{time}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
