import { useCallback, useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { gql } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { useNavigation } from '@react-navigation/native';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useIntl } from '@utils/intl';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';
import { universalDateFormatter } from '@utils/DateAndTime';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useErrorQuery } from '@providers/Errors';

import { STATUS } from '@views/shared/TimeSlots/helper';
import {
  AREA_TYPE_PARKING,
  AREA_TYPE_ROOM,
  FLOOR_TYPE_PARKING,
} from '@views/shared/consts';
import {
  scaleSelector,
  rotatedSelector,
  imageHeightSelector,
} from '@views/Floorplan/ZoomImage/state';

import {
  AreaType,
  FloorTypes,
} from '@views/shared/interfaces/buildingStructure';
import { Booking } from '@views/shared/interfaces/booking';
import { Status } from '@views/shared/TimeSlots/interfaces';
import {
  AreaPoint,
  AvailabilityInfo,
  Point,
  PointsData,
} from '@views/shared/interfaces/floorplan';

export interface ComponentProps {
  ids: string[];
  floorType: FloorTypes;
  showAvailability: (info?: AvailabilityInfo) => any;
}

export interface HookProps {
  ids: string[];
  floorType: FloorTypes;
}

const getDesks = gql`
  query getPoints($ids: [ID]!, $date: DateTime) {
    areasPoints: areasDesks(areaIds: $ids, date: $date) {
      areaId
      areaName
      areaIsBookable
      areaType
      points: desks {
        id
        coordinateX
        coordinateY
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        blocked
        numberOnFloor
        label
        type
      }
    }
  }
`;

const getParkingSpots = gql`
  query getPoints($ids: [ID]!, $date: DateTime) {
    areasPoints: areasParkings(areaIds: $ids, date: $date) {
      areaId
      areaName
      areaIsBookable
      areaType
      points: parkings {
        id
        coordinateX
        coordinateY
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        blocked
        numberOnFloor
        type: combinedType
        label
      }
    }
  }
`;

const getPointStatus = (point: AreaPoint): Status => {
  const { isFree, isOccupied, blocked, isVipForbidden, isBooked } = point;

  if (isBooked) {
    return STATUS.BOOKED;
  }

  if (blocked || isVipForbidden) {
    return STATUS.BLOCKED;
  }

  /**
   * If is occupied we show as blocked ( after discussion https://lizsmartoffice.slack.com/archives/C02TFV6BW6S/p1641980092016900 )
   * Note: if it is also booked the booked status will not showed.
   * */
  if (isOccupied) {
    return STATUS.BLOCKED;
  }

  if (isFree) {
    return STATUS.FREE;
  }

  return STATUS.BOOKED_BY_COLLEAGUE;
};

const checkDeskBooking = ({ desk }: Booking, id: string) => desk?.id === id;
const checkParkingBooking = ({ parking }: Booking, id: string) =>
  parking?.id === id;

export const checkActiveBooking = (areaType: AreaType, id: string) => (
  booking: Booking,
) => {
  const areaOverlap = {
    [AREA_TYPE_PARKING]: () => checkParkingBooking(booking, id),
    [AREA_TYPE_ROOM]: () => checkDeskBooking(booking, id),
  };
  return areaOverlap[areaType]();
};

const isOwnDailyBookDesks = ({
  status,
  enabledHourlyBooking,
}: {
  status: Status;
  enabledHourlyBooking: boolean;
}) => !enabledHourlyBooking && status === STATUS.BOOKED;

export function usePoints({ ids, floorType }: HookProps) {
  const [points, setPoints] = useState<Point[]>([]);
  const { t } = useIntl();
  const navigation = useNavigation();
  const scale = useRecoilValue(scaleSelector);
  const rotated = useRecoilValue(rotatedSelector);
  const height = useRecoilValue(imageHeightSelector) ?? 1;
  const makeABooking = useGoToBookingPage();
  const { bookings } = useMyActiveBookings();
  const showNumber = useHasFeature('desk_number_on_floor');
  const enabledHourlyBooking = useHasFeature('hourly_booking');

  const query = floorType === FLOOR_TYPE_PARKING ? getParkingSpots : getDesks;

  const date = useCurrentDate();
  const { data, refetch } = useErrorQuery<PointsData>(query, {
    variables: {
      ids,
      date: universalDateFormatter({ date }),
    },
    fetchPolicy: 'no-cache',
    skip: !ids,
    finderError: {
      type: 'fatal',
      message: t('Floorplan.ZoomImage.Points.fetch'),
    },
  });

  const checkIsAvailableToBook = useCallback(
    (status: Status) => {
      return (
        status === STATUS.FREE ||
        (status !== STATUS.BLOCKED &&
          status !== STATUS.BOOKED_BY_COLLEAGUE &&
          !isOwnDailyBookDesks({ enabledHourlyBooking, status }))
      );
    },
    [enabledHourlyBooking],
  );

  const findActiveBooking = useCallback(
    (id: string, areaType: AreaType): boolean =>
      !isNil(bookings.find(checkActiveBooking(areaType, id))),
    [bookings],
  );

  useEffect(() => {
    navigation.addListener('focus', () => {
      refetch();
    });
  }, [navigation, refetch]);

  useEffect(() => {
    if (data?.areasPoints) {
      const points: Point[] = data.areasPoints.reduce(
        (points: Point[], area): Point[] =>
          points.concat(
            area.points
              .filter((point: AreaPoint) => {
                // isForbidden -> https://liz-smart-office.atlassian.net/browse/NW-1833
                // without the check all possible desks are bookable
                const { isForbidden, coordinateX, coordinateY } = point;
                return (
                  coordinateX !== null && coordinateY !== null && !isForbidden
                );
              })
              .map((point: AreaPoint) => {
                const { areaId, areaIsBookable, areaType, areaName } = area;
                const {
                  blocked,
                  id,
                  coordinateX,
                  coordinateY,
                  numberOnFloor,
                  label,
                  type,
                } = point;

                const pointIsBlocked = !areaIsBookable
                  ? !areaIsBookable
                  : blocked;

                // special case for blocked booked desk
                const isBooked =
                  !pointIsBlocked && findActiveBooking(id, areaType);

                const status = getPointStatus({
                  ...point,
                  blocked: pointIsBlocked,
                  isBooked,
                });

                const platformScale = 1;
                const x = coordinateX * platformScale;
                const y = coordinateY * platformScale;

                return {
                  areaId,
                  areaName,
                  areaType,
                  deskCaption: numberOnFloor,
                  id,
                  isAvailableToBook: checkIsAvailableToBook(status),
                  label,
                  status,
                  x: rotated ? y : x,
                  y: rotated ? height - x : y,
                  type,
                };
              }),
          ),
        [],
      );
      setPoints(points);
    }
  }, [checkIsAvailableToBook, data, findActiveBooking, height, rotated]);

  const book = useCallback(
    ({ areaId, areaType, id }: Point) => {
      const isParkingArea = areaType === AREA_TYPE_PARKING;

      const props = {
        areaId,
        type: areaType,
        ...(isParkingArea ? { parkingId: id } : { deskId: id }),
      };

      makeABooking(props);
    },
    [makeABooking],
  );

  return {
    points,
    scale,
    book,
    showNumber,
  };
}
