import React from 'react';
import { View, Text, TouchableWithoutFeedback, StyleSheet } from 'react-native';

import { ItemComponentProps } from '@views/shared/Select/interfaces';
import { globalStyles } from '@views/shared/styles';
import { fonts } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeDefault,
  },
});

export function SelectedItem({ show, item, placeholder }: ItemComponentProps) {
  return (
    <View>
      <Text style={globalStyles.label}>{placeholder}</Text>
      <TouchableWithoutFeedback onPress={show}>
        <View style={[globalStyles.input, styles.container]}>
          <Text style={styles.item}>{item?.label ?? placeholder}</Text>
          <CustomIcon name="arrow-down" size={13} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}
