import React, { cloneElement, useCallback, useState } from 'react';
import { View } from 'react-native';

import QuestionIcon from '@views/shared/Booking/QuestionIcon';
import { useIntl } from '@utils/intl';
import { BookingConfirmation } from '@views/shared/BookingConfirmation';
import ActiveBookingNotice from '@views/Home/Book/ActiveBookingNotice';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { getLocation } from '@views/shared/hooks/getLocation';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import Infos from './Infos';
import { TimeSlot } from '@views/shared/TimeSlots/interfaces';
import {
  BookingType,
  MinimalBookingInfo,
} from '@views/shared/interfaces/booking';

interface Props {
  enabledHalfDayBooking: boolean;
  enabledHourlyBooking: boolean;
  type: BookingType;
  time?: string;
  booking: MinimalBookingInfo;
  date: string;
  children: JSX.Element;
}

export default function BasicBook({
  enabledHalfDayBooking,
  enabledHourlyBooking,
  type,
  time,
  booking,
  date,
  children,
}: Props) {
  const { t } = useIntl();
  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const { checkFullDayActiveBookingByType } = useMyActiveBookings();
  const [availabilityInfos, setAvailabilityInfos] = useState<TimeSlot>();

  const showAvailability = useCallback(
    (slot?: TimeSlot) => setAvailabilityInfos(slot),
    [],
  );

  const location = getLocation({ booking, showDeskNumber, t });

  if (
    // Not Needed: `type !== BOOKING_TYPE_MEETING_ROOM &&`
    // For meeting room `enabledHourlyBooking` will be always true!
    // reference: @views/Home/Book/MeetingRoom/hooks.ts
    !enabledHourlyBooking &&
    !enabledHalfDayBooking &&
    checkFullDayActiveBookingByType(type)
  ) {
    return (
      <ActiveBookingNotice
        location={location}
        type={type}
        date={date}
        time={time}
      />
    );
  }

  const title = t('Home.ActiveBooking.Verify.Booking.Title');

  const childrenWithProps = React.Children.map(children, (child) => {
    return cloneElement(child, {
      showAvailability: showAvailability,
    });
  });

  return (
    <>
      <BookingConfirmation
        location={location}
        type={type}
        date={date}
        Icon={QuestionIcon}
        title={title}
        time={time}
      >
        <View>{childrenWithProps}</View>
      </BookingConfirmation>
      {availabilityInfos && (
        <Infos
          slot={availabilityInfos}
          dismiss={showAvailability}
          location={location}
          type={type}
        />
      )}
    </>
  );
}
