import React from 'react';
import { Text, View } from 'react-native';
import { path } from 'ramda';

import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Home/Favourites/styles';
import { Accordion } from '@views/shared/Accordion';

import {
  FavouriteItem,
  Building as BuildingType,
} from '@views/shared/interfaces/favourites';

import Item from './Item';

interface Props {
  items: FavouriteItem[];
}

export default function Building({ items }: Props) {
  const building = path([0, 'floor', 'building'], items);
  const { address, name } = building as BuildingType;

  const title = (
    <View style={styles.header}>
      <View style={styles.left}>
        <View style={styles.flexContainer}>
          {name && <Text style={globalStyles.title}>{name}</Text>}
          {address && <Text style={globalStyles.description}>{address}</Text>}
        </View>
      </View>
    </View>
  );
  return (
    <View style={styles.building}>
      <Accordion
        childrenStyle={styles.accordionChildrenBuilding}
        open={true}
        title={title}
        openStyle={styles.openStyle}
      >
        {items.map((item, index) => (
          <Item item={item} key={index} last={index === items.length - 1} />
        ))}
      </Accordion>
    </View>
  );
}
