import { gql } from '@apollo/client';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';

import { useCurrentDate } from '../hooks';

import { Data } from './interfaces';

const getEventsQuery = gql`
  query getCalendarEvents($date: Date!) {
    myCalendarEvents(dateFrom: $date, dateTo: $date) {
      subject
      start
      end
    }
  }
`;

export function useEvents() {
  const { t } = useIntl();
  const date = useCurrentDate();
  const { data, loading, error } = useErrorQuery<Data>(getEventsQuery, {
    variables: {
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'network-only',
    finderError: {
      type: 'minor',
      message: t('Calendar.Events.error'),
    },
  });
  const events = data?.myCalendarEvents ?? [];

  return {
    events,
    loading,
    error,
  };
}
