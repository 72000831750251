import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useGoToStructurePage } from '@views/shared/utils/useGoTo';
import { useMyBuildings } from '@providers/Buildings';
import { useFavourites } from '@views/Favourites/hooks';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import { BookCategory } from '@views/shared/interfaces/booking';

const getMeetingRooms = gql`
  query getBuildingMeetingRooms($id: ID!, $date: Date!) {
    building: buildingCountMeetingRooms(buildingId: $id, date: $date) {
      numberOfMeetingRooms
      meetingRoomTimeStats {
        booked
        total
      }
    }
  }
`;

interface Data {
  building: {
    numberOfMeetingRooms: number;
    meetingRoomTimeStats: {
      booked: number;
      total: number;
    };
  };
}

export function useMeetingRooms(type: BookCategory, errorMessage) {
  const date = useCurrentDate();
  const { favCount, goToFavourites } = useFavourites(type);
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const goToStructure = useGoToStructurePage();

  const { data, loading, error } = useErrorQuery<Data>(getMeetingRooms, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: errorMessage,
    },
  });
  const numberOfMeetingRooms = data?.building.numberOfMeetingRooms;

  const booked = pathOr(
    0,
    ['building', 'meetingRoomTimeStats', 'booked'],
    data,
  );
  const total = pathOr(1, ['building', 'meetingRoomTimeStats', 'total'], data);
  const free = total - booked;

  return {
    numberOfMeetingRooms,
    loading: !buildingId || loading,
    error,
    favCount,
    goToFavourites,
    goToStructure: () => goToStructure(type),
    worklight: getWorklightStatus(free, total),
  };
}
