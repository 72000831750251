import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useIntl } from '@utils/intl';

import { Overview } from './Overview';
import { Edit } from './Edit';

const Stack = createStackNavigator();

export function ProfileTab() {
  const { t } = useIntl();

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, title: t('Profile.title') }}
    >
      <Stack.Screen component={Overview} name="overview" />
      <Stack.Screen component={Edit} name="edit" />
    </Stack.Navigator>
  );
}
