import React from 'react';

import { Favourite } from '@views/shared/Favourite';

import { BadgeType } from './interface';
import { useFavouriteBadge } from './hooks';

export interface FavouriteBadge {
  id: string;
  type: BadgeType;
}

export function FavouriteBadge({ id, type }: FavouriteBadge) {
  const { isSelected, toggle } = useFavouriteBadge(id, type);
  return <Favourite onPress={toggle} selected={isSelected} size={30} />;
}
