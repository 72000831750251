import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';
import nl from './nl.json';
import ru from './ru.json';

export const supportedLanguages = ['de', 'en', 'fr', 'it', 'nl', 'ru'];

export default {
  de,
  en,
  fr,
  it,
  nl,
  ru,
};
