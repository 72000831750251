import { useEffect, useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { compose, pathOr } from 'ramda';

import { useIntl } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';
import { useCurrentDate } from '@views/Calendar/hooks';
import { MAX_COUNT_DESKS } from '@views/shared/consts';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import {
  sortDesksByTypeAndLabel,
  getAvailableDesks,
} from '@views/shared/Booking';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import {
  DeskStructure,
  AreaStructureForDesks,
} from '@views/shared/interfaces/buildingStructure';

import { Data } from './interfaces';

const getDesksHourlyQuery = gql`
  query getDesks($areaId: ID!, $dateTime: DateTime) {
    areasDesks(areaIds: [$areaId], date: $dateTime) {
      areaId
      desks {
        blocked
        id
        isForbidden
        isVipForbidden
        isFree
        isOccupied
        label
        numberOnFloor
        equipment {
          category {
            id
            name
          }
          id
          name
        }
      }
    }
  }
`;

const getDesksQuery = gql`
  query getDesks($areaId: ID!, $date: Date!) {
    areasDesks: areasAvailableDesks(areaIds: [$areaId], date: $date) {
      desks {
        id
        label
        numberOnFloor
        equipment {
          category {
            id
            name
          }
          id
          name
        }
      }
    }
  }
`;

export function useDesks(area: AreaStructureForDesks) {
  const { t } = useIntl();
  const route = useRoute<any>();
  const [showAll, setShowAll] = useState(false);
  const [desks, setDesks] = useState<DeskStructure[]>([]);

  const { id: areaId } = area;
  const date = useCurrentDate();
  const enabledHourlyBooking = useHasFeature('hourly_booking');

  const queryDesks = enabledHourlyBooking ? getDesksHourlyQuery : getDesksQuery;
  const { data, loading, error } = useErrorQuery<Data>(queryDesks, {
    variables: {
      areaId,
      dateTime: universalDateFormatter({ date }),
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: t('Home.Structure.Floor.Area.Desks.error'),
    },
  });

  // In case of hourly booking we need to check if there is any condition that
  // made the desk not available anyway (e.g. occupied, blocked, etc. ).
  // For the daily booking, the check is done by backend
  const getDesks = useCallback(
    (data) => (enabledHourlyBooking ? getAvailableDesks(data) : data),
    [enabledHourlyBooking],
  );

  useEffect(() => {
    if (data?.areasDesks) {
      const transformedData = compose(
        sortDesksByTypeAndLabel,
        getDesks,
        pathOr([], ['areasDesks', 0, 'desks']),
      )(data);

      setDesks(transformedData);
    }
  }, [data, getDesks]);

  const makeABooking = useGoToBookingPage();
  const book = useCallback(
    (deskId: string) => {
      makeABooking({ areaId, deskId });
    },
    [areaId, makeABooking],
  );
  const toggleMore = useCallback(() => setShowAll((showAll) => !showAll), [
    setShowAll,
  ]);

  return {
    book,
    desks: showAll ? desks : desks.slice(0, MAX_COUNT_DESKS),
    error,
    isMore: desks.length > MAX_COUNT_DESKS,
    open: route.params?.areaId === areaId,
    loading,
    showAll,
    toggleMore,
  };
}
