import React from 'react';
import { View, StyleSheet } from 'react-native';

import { colors } from '@views/shared/styles';

import { BookingIcon } from '@views/shared/BookingIcon';
import { CustomIcon } from '@views/shared/CustomIcon';
import { getBookingIcon } from '@views/shared/consts';

import { CheckProps } from '../../interfaces';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: 30,
    height: 30,
  },
  active: {
    top: 4,
    left: 6,
  },
});

const { aquaMarine, blackAlpha2 } = colors;

export default function Checkable({
  disabled,
  onToggle,
  selected,
  style,
  type,
}: CheckProps) {
  return (
    <View style={[styles.container, style]}>
      {selected === type ? (
        <View style={styles.active}>
          <CustomIcon
            name="checkmark"
            onPress={() => onToggle(type)}
            color={aquaMarine}
          />
        </View>
      ) : (
        <BookingIcon
          name={getBookingIcon(type)}
          onPress={disabled ? undefined : () => onToggle(type)}
          style={disabled ? { color: blackAlpha2 } : {}}
        />
      )}
    </View>
  );
}
