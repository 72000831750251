import React from 'react';

import { CustomIcon } from '@views/shared/CustomIcon';
import { colors } from '@views/shared/styles';

interface Props {
  onPress?: (() => void) | undefined;
  selected?: boolean;
  size?: number;
}

export function Favourite({ onPress, selected, size = 20 }: Props) {
  const iconName = `heart${selected ? '-inv' : ''}`;
  return (
    <CustomIcon
      name={iconName}
      color={colors.warmPink}
      onPress={onPress}
      size={size}
    />
  );
}
