import React from 'react';
import { Text, ScrollView, StyleSheet, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';
import { BookingIcon } from '@views/shared/BookingIcon';
import { colors, globalStyles, spacings } from '@views/shared/styles';

import {
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_ROOM,
  getBookingIcon,
} from '@views/shared/consts';
import { useFavourites } from '@views/Favourites/hooks';
import { FavouriteCount } from '@views/Home/Bookings/FavouriteCount';

import { BookCategory } from '@views/shared/interfaces/booking';

import { useStructure } from './hooks';
import Floor, {
  withDesks,
  withFreeSeats,
  withMeetingRooms,
  withParkingSpots,
} from './Floor';

// TODO incr2
// enable favoritize of parking spots

const { medium, oversize, small, extraSmall } = spacings;

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.grey1,
    flexDirection: 'row',
    marginBottom: oversize,
    borderRadius: extraSmall,
    paddingHorizontal: medium,
    paddingVertical: small,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleWrapper: {
    alignItems: 'center',
    flex: 1,
  },
});

const getCountDesc = (type: string, count: number) => {
  const translation = {
    [BOOK_CATEGORY_MEETING_ROOM]: 'views.Home.Bookings.MeetingRooms.total',
    [BOOK_CATEGORY_ROOM]: 'views.Home.Bookings.Desks.total',
    [BOOK_CATEGORY_FREE_SPACES_ROOM]: 'views.Home.Bookings.Areas.total',
    [BOOK_CATEGORY_PARKING]: 'Home.Structure.Parkings.Total',
  };

  const templateVars =
    type === BOOK_CATEGORY_ROOM ? { totalDesks: count } : { total: count };

  return {
    translation: translation[type],
    templateVars,
  };
};

function getFloorByType(type: BookCategory) {
  const floorTypes = {
    [BOOK_CATEGORY_FREE_SPACES_ROOM]: withFreeSeats()(Floor),
    [BOOK_CATEGORY_MEETING_ROOM]: withMeetingRooms()(Floor),
    [BOOK_CATEGORY_PARKING]: withParkingSpots()(Floor),
    [BOOK_CATEGORY_ROOM]: withDesks()(Floor),
  };

  return floorTypes[type];
}

export default function Structure() {
  const { type, floors, error, loading, count } = useStructure();
  const { favCount, goToFavourites } = useFavourites(type);

  const { t } = useIntl();

  const { translation, templateVars } = getCountDesc(type, count);

  if (loading) {
    return <Loader />;
  }

  if (error || !floors) {
    return <Text>GraphQL Error: Structure</Text>;
  }

  const FloorForBookCategory = getFloorByType(type);

  return (
    <ScrollView style={globalStyles.container}>
      <View style={styles.header}>
        <BookingIcon name={getBookingIcon(type)} />

        <View style={styles.titleWrapper}>
          <Text style={globalStyles.title}>
            {t(`Home.Structure.Title.${type}`)}
          </Text>
          {translation && (
            <Text style={globalStyles.description}>
              {t(translation, templateVars)}
            </Text>
          )}
        </View>
        {type !== BOOK_CATEGORY_PARKING && ( //incr2
          <FavouriteCount count={favCount} onPress={goToFavourites} />
        )}
      </View>

      {floors.map((floor) => (
        <FloorForBookCategory key={floor.id} floor={floor} />
      ))}
    </ScrollView>
  );
}
