import { pathOr, path } from 'ramda';

import { AREA_TYPE_PARKING, AREA_TYPE_ROOM } from '@views/shared/consts';
import { Booking } from '@views/shared/interfaces/booking';
import {
  getPointFullLabel,
  getBuildingInfo,
  getFloorInfo,
  FloorMinimalInfos,
  BuildingMinimalInfos,
} from '@utils/Building';

interface LocationProps {
  booking: Booking | any;
  t: any;
  showDeskNumber?: boolean;
  showDeskTranslation?: boolean;
}

export function getLocation({
  booking,
  showDeskNumber = false,
  showDeskTranslation = false,
  t,
}: LocationProps) {
  if (booking?.homeOffice) {
    return t('Home.Bookings.HomeOffice.title');
  }

  if (booking?.travelDay) {
    return t('Home.ActiveBookings.Booking.title.travelDay');
  }

  const areaName = pathOr('', ['area', 'name'], booking);
  const areaType = pathOr(AREA_TYPE_ROOM, ['area', 'areaType'], booking);
  const floor = path<FloorMinimalInfos>(['area', 'floor'], booking);
  const building = path<BuildingMinimalInfos>(
    ['area', 'floor', 'building'],
    booking,
  );

  const isParking = areaType === AREA_TYPE_PARKING;
  const point = isParking ? booking?.parking : booking?.desk || {};

  if (building && floor) {
    return getPointFullLabel({
      areaType,
      areaName,
      ...getBuildingInfo(building),
      ...getFloorInfo(floor),
      point,
      showDeskNumber,
      showDeskTranslation,
      t,
    });
  }

  //TODO: Should we return some error label in this case
  // or it will never happen that building and floor are undefined??
  return '';
}
