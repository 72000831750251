import React from 'react';
import { Dimensions, View } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useIntl, defineMessages } from 'react-intl';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { topBarNavigatorStyles } from '@views/shared/styles';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { Loader } from '@views/shared/Loader';
import { DepartmentsView } from '@views/Colleagues/List/Department';
import { AlphabeticalView } from '@views/Colleagues/List/Alphabetical';

import { useColleagues } from './hooks';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  labelStyle: topBarNavigatorStyles.tabLabel,
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
};

const i18nMessages = defineMessages({
  'Colleagues.List.Screen.departments': {
    id: 'Colleagues.List.Screen.departments',
    defaultMessage: 'Departments',
  },
  'Colleagues.List.Screen.alphabetical': {
    id: 'Colleagues.List.Screen.alphabetical',
    defaultMessage: 'Alphabetical',
  },
});

export function List() {
  const { formatMessage } = useIntl();
  const findMyColleagueEnabled = useHasFeature('find_my_colleague');
  const { data, error, loading } = useColleagues();

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    return <UnexpectedNoDataOrError />;
  }

  const { colleagues = [] } = data;

  const renderDepartmentsView = () => (
    <DepartmentsView
      colleagues={colleagues}
      findMyColleagueEnabled={findMyColleagueEnabled}
    />
  );

  const renderAlphabeticalView = () => (
    <AlphabeticalView
      colleagues={colleagues}
      findMyColleagueEnabled={findMyColleagueEnabled}
    />
  );

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        initialRouteName="departments"
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy={true}
        initialLayout={{ width: Dimensions.get('window').width }}
      >
        <Tab.Screen
          name={formatMessage(
            i18nMessages['Colleagues.List.Screen.departments'],
          )}
          component={renderDepartmentsView}
        />
        <Tab.Screen
          name={formatMessage(
            i18nMessages['Colleagues.List.Screen.alphabetical'],
          )}
          component={renderAlphabeticalView}
        />
      </Tab.Navigator>
    </View>
  );
}
