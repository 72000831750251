import React from 'react';
import { Text, View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { compose, groupBy, values } from 'ramda';

import { useIntl } from '@utils/intl';

import { FavouriteCount } from '@views/Home/Bookings/FavouriteCount';
import { BookingIcon } from '@views/shared/BookingIcon';
import {
  AREA_TYPE_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_FREE_SPACES_ROOM,
  getBookingIcon,
} from '@views/shared/consts';
import { globalStyles } from '@views/shared/styles';
import { Accordion } from '@views/shared/Accordion';
import { styles } from '@views/Home/Favourites/styles';

import { AreaType } from '@views/shared/interfaces/buildingStructure';
import { FavouriteItem } from '@views/shared/interfaces/favourites';

import Building from '@views/Home/Favourites/List/Collapsable/Building';

const MAP_TYPE_TITLE = {
  [AREA_TYPE_ROOM]: 'views.Home.Bookings.Desks.shared',
  [AREA_TYPE_MEETING_ROOM]: 'views.Home.Bookings.MeetingRooms.title',
  [AREA_TYPE_FREE_SPACES_ROOM]: 'views.Home.Bookings.Desks.free',
};

interface Props {
  items: FavouriteItem[];
  type: AreaType;
}

const groupByBuilding = (items: any) =>
  compose(
    values,
    groupBy(({ floor: { building } }) => building.name),
  )(items) as [FavouriteItem[]];

export default function Collapsable({ items, type }: Props) {
  const { t } = useIntl();
  const {
    params: { type: routeType },
  } = useRoute<any>();

  const isOpen = routeType === type;

  const title = (
    <View style={styles.header}>
      <View style={styles.left}>
        <BookingIcon name={getBookingIcon(type)} />
        <Text style={[styles.title, globalStyles.title]}>
          {t(MAP_TYPE_TITLE[type])}
        </Text>
      </View>
      <FavouriteCount count={items.length} />
    </View>
  );
  return (
    <View style={styles.container}>
      <Accordion
        childrenStyle={styles.accordionChildren}
        open={isOpen}
        title={title}
        headerStyle={styles.accordionHeader}
      >
        {groupByBuilding(items).map((items: FavouriteItem[], index: number) => (
          <Building items={items} key={index} />
        ))}
      </Accordion>
    </View>
  );
}
