import { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { ReactNativeFile } from 'apollo-upload-client';

import { useIntl } from '@utils/intl';
import { useContent } from '@views/Profile/Overview/UserData/hooks';
import { User } from '@views/Profile/Overview/UserData/interfaces';
import { SelectItem } from '@views/shared/Select/interfaces';
import { useErrorMutation, useErrorQuery } from '@providers/Errors';
import { useMyUser } from '@providers/User';

import { Department, DepsData } from './interfaces';
import { UploadClient } from '@app/client';

const getDepartments = gql`
  query getDepartments {
    myOrganization {
      departments {
        id
        name
      }
    }
  }
`;

function getItems(departments: Department[]): SelectItem[] {
  return departments.map((dep) => {
    return {
      label: dep.name,
      value: dep.id,
    };
  });
}

function useDepartments(me: User | undefined) {
  const { t } = useIntl();

  const { data } = useErrorQuery<DepsData>(getDepartments, {
    finderError: {
      type: 'minor',
      message: t('Profile.Edit.hooks.depsError'),
    },
  });

  const departments = data?.myOrganization?.departments ?? [];
  const initialDepartment = departments.find(
    (dep) => me?.userprofile?.department?.id === dep.id,
  );
  const [selectedDepartment, setSelectedDepartment] = useState(
    initialDepartment,
  );
  const items = getItems(departments);
  const chooseDepartment = useCallback(
    (id: string) => {
      const dep = departments.find((dep) => dep.id === id);

      setSelectedDepartment(dep);
    },
    [departments],
  );

  useEffect(() => {
    setSelectedDepartment(initialDepartment);
  }, [initialDepartment]);

  return {
    items,
    selectedDepartment,
    chooseDepartment,
  };
}

interface PartialProfile {
  name: string;
  email: string;
  avatar?: string;
  phoneNumber: string;
  homeofficeInfo: string;
}

export function useUserFields(me: User) {
  const [name, setName] = useState(me.name);
  const [phoneNumber, setPhoneNumber] = useState(me.userprofile.phoneNumber);
  const [homeofficeInfo, setHomeofficeInfo] = useState(
    me.userprofile.homeofficeInfo,
  );

  return {
    editState: {
      avatar: me.userprofile.avatar,
      email: me.email,
      name,
      phoneNumber,
      homeofficeInfo,
    },
    setName,
    setPhoneNumber,
    setHomeofficeInfo,
  };
}

const updateUserMutation = gql`
  mutation updateUser(
    $name: String!
    $email: String!
    $departmentId: ID
    $phoneNumber: String!
    $avatar: FileType
    $homeofficeInfo: String
  ) {
    updateMe(
      appName: FINDER
      user: {
        name: $name
        email: $email
        userprofile: {
          avatar: $avatar
          departmentId: $departmentId
          phoneNumber: $phoneNumber
          homeofficeInfo: $homeofficeInfo
        }
      }
    ) {
      email
      name
      userprofile {
        phoneNumber
        avatar
      }
    }
  }
`;

function useUpdate(
  editState: PartialProfile | undefined,
  selectedDepartment: Department | undefined,
  avatar: ReactNativeFile | undefined,
  hasDepartmentDisabled: boolean,
) {
  const { t } = useIntl();
  const navigation = useNavigation();
  const AvatarClient = UploadClient();
  const { refetch } = useMyUser();

  const [
    updateAvatar,
    { loading: updateAvatarLoading, error: updateAvatarError },
  ] = useErrorMutation(updateUserMutation, {
    client: AvatarClient,
    variables: {
      name: editState?.name,
      email: editState?.email,
      phoneNumber: editState?.phoneNumber,
      avatar,
    },
    finderError: {
      type: 'fatal',
      message: t('Profile.Edit.hooks.updateError'),
    },
  });

  const [
    updateUser,
    { loading: updateLoading, error: updateError },
  ] = useErrorMutation(updateUserMutation, {
    onCompleted: () => refetch(),
    variables: {
      name: editState?.name,
      email: editState?.email,
      phoneNumber: editState?.phoneNumber,
      departmentId: !hasDepartmentDisabled ? selectedDepartment?.id : undefined,
      homeofficeInfo: editState?.homeofficeInfo,
    },
    finderError: {
      type: 'fatal',
      message: t('Profile.Edit.hooks.updateError'),
    },
  });

  const update = useCallback(async () => {
    await updateAvatar();
    await updateUser();
    navigation.navigate('overview');
  }, [updateAvatar, updateUser, navigation]);

  return {
    update,
    updateLoading: updateLoading && updateAvatarLoading,
    updateError: updateError && updateAvatarError,
  };
}
// TODO cleanup homeoffice and department selection
export function useEdit() {
  const navigation = useNavigation();
  const [avatar, setAvatar] = useState<ReactNativeFile>();
  const { me, loading: meLoading, error: meError } = useContent();
  const { items, selectedDepartment, chooseDepartment } = useDepartments(me);

  const hasDepartmentDisabled = true;
  /*items.length === 0 ||
    (me?.permissions?.includes('users.change_userprofile_organization') ??
      false);*/

  const {
    editState,
    setName,
    setPhoneNumber,
    setHomeofficeInfo,
  } = useUserFields(me);

  const { update, updateLoading, updateError } = useUpdate(
    editState,
    selectedDepartment,
    avatar,
    hasDepartmentDisabled,
  );

  const cancel = useCallback(() => {
    navigation.navigate('overview');
  }, [navigation]);

  return {
    initials: me?.initials,
    me: editState,
    loading: meLoading || updateLoading,
    error: meError || updateError,
    selectedDepartment,
    items,
    hasDepartmentDisabled,
    chooseDepartment,
    setName,
    setPhoneNumber,
    update,
    setAvatar,
    setHomeofficeInfo,
    cancel,
  };
}
