import React from 'react';
import { isEmpty, pluck } from 'ramda';
import { StyleSheet, Text, View, Platform } from 'react-native';

import { MixDate, universalDateFormatter } from '@utils/DateAndTime';
import { Loader } from '@views/shared/Loader';
import { useIntl } from '@utils/intl';
import { getBookingIcon } from '@views/shared/consts';
import { STATUS } from '@views/shared/TimeSlots/helper';
import { globalStyles, spacings, colors, fonts } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';
import TitleByType from '@views/shared/TitleByType';
import { Booking, BookingType } from '@views/shared/interfaces/booking';
import { Status, Colleague } from '@views/shared/TimeSlots/interfaces';

const { white } = colors;
const { sizeDefault, sizeTitle } = fonts;
const { extraSmall, medium, small, oversize, verySmall, huge } = spacings;

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  view: {
    bottom: small,
    backgroundColor: white,
    borderRadius: extraSmall,
    padding: medium,
    minHeight: '30%', // min-height isn't working for native
    paddingBottom: isWeb ? medium : huge, // fix for native
    position: 'absolute',
    zIndex: 20,
    marginHorizontal: small,
    width: isWeb ? '97%' : '95%',
  },
  container: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: oversize,
  },
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  card: {
    flexDirection: 'row',
    position: 'relative',
    marginTop: verySmall,
    alignItems: 'center',
  },
  header: {
    textAlign: 'left',
    fontSize: sizeTitle,
    fontFamily: 'mrt-regular',
    marginTop: small,
  },
  icon: {
    paddingRight: oversize,
  },
  right: {
    justifyContent: 'space-between',
    flex: 1,
  },
  date: {
    fontSize: sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  checkmark: {
    position: 'absolute',
    right: -15,
    bottom: -35,
  },
  book: {
    paddingVertical: oversize,
    textAlign: 'center',
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: oversize,
  },
  notice: {
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
});

function MyBookingInfo({
  findActiveBooking,
  format,
}: {
  findActiveBooking: () => Booking | undefined;
  format: string;
}) {
  const booking = findActiveBooking();
  if (!booking) {
    return null;
  }

  const { end, start } = booking;

  return (
    <Text>
      {universalDateFormatter({ date: start, format })} -{' '}
      {universalDateFormatter({ date: end, format })}
    </Text>
  );
}

interface TimeWithColleague {
  end: MixDate;
  start: MixDate;
  colleagues: Colleague[];
}

export default function AvailabilityInfos({
  error,
  findActiveBooking,
  fullCaption,
  loading,
  status,
  unavailableTimeWithColleague,
  date,
  dismiss,
  type,
}: {
  date: MixDate;
  loading: boolean;
  error: any;
  fullCaption: string;
  status: Status;
  findActiveBooking?: () => Booking | undefined;
  dismiss: (info?) => any;
  unavailableTimeWithColleague: TimeWithColleague[];
  type: BookingType;
}) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl();

  let message;

  if (error) {
    message = `[Error]: ${error}`;
  }

  if (status === STATUS.BLOCKED) {
    message = t('AvailabilityInfos.Status.Blocked');
  }

  if (status === STATUS.BOOKED) {
    message = t('AvailabilityInfos.Status.Booked');
  }

  if (status === STATUS.BOOKED_BY_COLLEAGUE) {
    if (isEmpty(unavailableTimeWithColleague)) {
      /**
       * Note: If the status is "booked by colleagues" but no booked times slots are detected,
       * most probably the desk is occupied by a colleague without making a booking before!
       * */
      message = t('AvailabilityInfos.Status.OccupiedByColleague');
    } else {
      message = t('AvailabilityInfos.Status.BookedByColleague');
    }
  }

  const statusIconColors = {
    [STATUS.BLOCKED]: colors.warmPink,
    [STATUS.BOOKED_BY_COLLEAGUE]: colors.paleOrange,
    [STATUS.BOOKED]: colors.darkBlue,
  };

  const iconColor = statusIconColors[status];

  return (
    <View style={[styles.view, globalStyles.shadow]}>
      <CustomIcon
        name="close"
        onPress={() => dismiss()}
        size={20}
        style={styles.close}
      />
      {loading && <Loader />}
      {!loading && (
        <>
          <Text style={styles.header}>{message}</Text>
          <View style={[styles.card, styles.container]}>
            <View style={styles.icon}>
              <CustomIcon name={getBookingIcon(type)} size={75} />
            </View>
            <View style={styles.right}>
              <View>
                <TitleByType type={type} />
                <Text style={globalStyles.description}>{fullCaption}</Text>
              </View>
              <View>
                <Text style={[styles.date, { paddingTop: spacings.small }]}>
                  {date}
                </Text>
              </View>
              {status === STATUS.BOOKED_BY_COLLEAGUE &&
                unavailableTimeWithColleague.map((timeWithColleague, idx) => {
                  const start = universalDateFormatter({
                    date: timeWithColleague.start,
                    format: timeFormatShort,
                  });
                  const end = universalDateFormatter({
                    date: timeWithColleague.end,
                    format: timeFormatShort,
                  });

                  let byUser = '';

                  if (!isEmpty(timeWithColleague.colleagues)) {
                    byUser = pluck('name', timeWithColleague.colleagues).join(
                      '\n',
                    );
                  }

                  return (
                    <Text key={idx}>
                      {start} - {end}
                      {'\n'}
                      {byUser}
                    </Text>
                  );
                })}
              {status === STATUS.BOOKED && findActiveBooking && (
                <MyBookingInfo
                  findActiveBooking={findActiveBooking}
                  format={timeFormatShort}
                />
              )}
            </View>
            <CustomIcon
              color={iconColor}
              name={status === STATUS.BOOKED ? 'warning' : 'close'}
              size={status === STATUS.BOOKED ? 75 : 95}
              style={[
                styles.checkmark,
                { right: status === STATUS.BOOKED ? -10 : -25 },
                { bottom: status === STATUS.BOOKED ? -17 : -35 },
              ]}
            />
          </View>
        </>
      )}
    </View>
  );
}
