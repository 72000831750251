import React from 'react';
import { Text, View, StyleSheet, ImageBackground } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';

import { colors, fonts, spacings } from '@views/shared/styles';
import { ICON_COLORS } from '@providers/Organization';
import { CustomIcon } from '@views/shared/CustomIcon';
import { useLoggedIn } from '@views/Login/hooks';

import { useTopBar } from './hooks';
import { Logo } from './Logo';

const { extraSmall, huge, medium, verySmall } = spacings;
const { black, blackAlpha, white } = colors;
const { sizeDefault } = fonts;

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: huge,
    paddingTop: medium,
    paddingBottom: verySmall,
    borderBottomColor: blackAlpha,
    borderBottomWidth: 1,
  },
  titleBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontWeight: 'bold',
    fontSize: sizeDefault,
    textAlign: 'center',
    paddingTop: medium,
  },
  date: {
    textAlign: 'center',
    paddingTop: extraSmall,
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  imageContainer: {
    height: 40,
    flex: 1,
    flexDirection: 'column',
  },
  logo: {
    flex: 1,
  },
  icon: {
    top: -7,
    position: 'relative',
  },
});

export function TopBar() {
  const loggedIn = useLoggedIn();
  const {
    backgroundColor,
    date,
    logo,
    name,
    openCalendar,
    iconColor,
  } = useTopBar();

  if (!loggedIn) {
    return null;
  }

  const contrastColor = iconColor === ICON_COLORS.DARK ? black : white;

  return (
    <SafeAreaView forceInset={{ top: 'always' }}>
      <View style={[styles.header, { backgroundColor }]}>
        <View style={styles.titleBar}>
          <Logo color={contrastColor} />
          <View style={styles.imageContainer}>
            {logo ? (
              <ImageBackground
                source={{ uri: logo }}
                style={styles.logo}
                resizeMode="contain"
              />
            ) : (
              <Text style={[styles.name, { color: contrastColor }]}>
                {name}
              </Text>
            )}
          </View>
          <CustomIcon
            color={contrastColor}
            name="calendar-no"
            size={28}
            onPress={openCalendar}
            style={[styles.icon]}
          />
        </View>
        <View>
          <Text style={[styles.date, { color: contrastColor }]}>{date}</Text>
        </View>
      </View>
    </SafeAreaView>
  );
}
