import React from 'react';
import { View, StyleSheet } from 'react-native';

import { spacings } from '@views/shared/styles';

import { Google } from './Google';
import { Microsoft } from './Microsoft';

export { nativeToggle } from './utils';

const { huge, small } = spacings;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: huge,
    flexWrap: 'wrap',
  },
  content: {
    flex: 1,
  },
});

export function CalendarAuth() {
  return (
    <View style={styles.container}>
      <View style={[styles.content, { marginRight: small }]}>
        <Google />
      </View>
      <View style={[styles.content, { marginLeft: small }]}>
        <Microsoft />
      </View>
    </View>
  );
}
