import { StyleSheet } from 'react-native';
import { colors, spacings } from '@views/shared/styles';

const { grey1, grey2Alpha } = colors;
const { big, extraSmall, medium, oversize, small } = spacings;

export const styles = StyleSheet.create({
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: medium,
    flex: 1,
  },
  pageHeader: {
    backgroundColor: grey1,
    marginBottom: oversize,
    borderRadius: extraSmall,
    padding: big,
    alignItems: 'center',
  },
  container: {
    marginBottom: medium,
  },
  title: {
    paddingLeft: small,
  },
  itemContainer: {
    paddingVertical: small,
    paddingLeft: medium,
    borderColor: grey2Alpha,
    borderBottomWidth: 1,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  building: {
    borderColor: grey2Alpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    marginTop: small,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
    paddingRight: small,
  },
  badge: {
    justifyContent: 'center',
    paddingRight: medium,
  },
  openStyle: {
    borderBottomColor: grey2Alpha,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  accordionChildren: {
    marginBottom: medium,
    marginTop: small,
  },
  accordionHeader: {
    backgroundColor: grey1,
  },
  accordionChildrenBuilding: {
    margin: 0,
    paddingHorizontal: medium,
  },
  flexContainer: {
    flex: 1,
  },
});
