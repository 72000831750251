import { gql, useMutation } from '@apollo/client';

import { useBookForTravel } from '@views/Home/Book/BasicBook/Booking/hooks';

import {
  BasicBooking,
  BookingHooksBasicProps,
  CreateBookingResponse,
} from '@views/shared/interfaces/booking';

export const bookTravelDayMutation = gql`
  mutation bookTravelDay($start: DateTime!, $end: DateTime!) {
    createBooking: createTravelDayBooking(
      booking: { start: $start, end: $end }
    ) {
      travelDay
      start
      end
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  t,
  type,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingResponse },
    BasicBooking
  >(bookTravelDayMutation);

  return useBookForTravel({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {},
    formats,
    bookingMutation: mutation,
  });
}
