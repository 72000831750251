import React from 'react';
import { StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';
import { MainButton } from '@views/shared/Button';
import { colors } from '@views/shared/styles';
import { styles } from '@views/Profile/Overview/CalendarAuth/Google';

import { useMicrosoft } from './hooks';

const { white, msBlue } = colors;

const msColors = StyleSheet.create({
  button: {
    backgroundColor: white,
    borderColor: msBlue,
  },
  icon: {
    color: msBlue,
  },
});

export function Microsoft() {
  const { t } = useIntl();
  const { toggle, checkedIn } = useMicrosoft();

  return (
    <MainButton
      buttonStyle={[styles.button, msColors.button]}
      titleStyle={styles.title}
      title={t(
        checkedIn
          ? 'Profile.Content.Microsoft.out'
          : 'Profile.Content.Microsoft.button',
      )}
      onPress={toggle}
      icon={
        <CustomIcon
          name="microsoft-office"
          style={[styles.icon, msColors.icon]}
        />
      }
    />
  );
}
