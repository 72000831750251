import { useCallback, useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { findIndex, isNil, propEq } from 'ramda';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useMyUser } from '@providers/User';

import {
  CateringData,
  CateringQuantityItem,
  OrderCateringResponse,
} from './types';
import { useNavigation } from '@react-navigation/native';

const cateringItemsQuery = gql`
  query cateringItems($language: LanguageEnumType) {
    cateringItems(language: $language) {
      itemId: id
      title
      description
    }
  }
`;

const orderCateringMutation = gql`
  mutation addCatering(
    $catering: AddCateringInput!
    $language: LanguageEnumType
  ) {
    addCatering(catering: $catering, language: $language) {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
  }
`;

export default function useCatering(bookingId: string) {
  const navigation = useNavigation();
  const { language } = useMyUser();
  const { refreshActiveBookings } = useMyActiveBookings();
  const [quantityItems, setQuantityItems] = useState<CateringQuantityItem[]>(
    [],
  );
  const { data, loading } = useQuery<CateringData>(cateringItemsQuery, {
    variables: {
      language,
    },
  });

  const [
    save,
    {
      data: orderCateringData,
      error: orderCateringError,
      loading: orderCateringLoading,
    },
  ] = useMutation<OrderCateringResponse>(orderCateringMutation);

  const bookCatering = useCallback(async () => {
    const items = quantityItems.filter(
      (item: CateringQuantityItem) => item.quantity,
    );

    if (!items.length) {
      return;
    }

    await save({
      variables: {
        catering: { bookingId, items },
        language,
      },
    });
    refreshActiveBookings();
  }, [bookingId, quantityItems, language, save, refreshActiveBookings]);

  // update quantity for changed item
  const onItemUpdate = useCallback(
    (itemId: string, quantity: number) => {
      const items = quantityItems;
      const index = findIndex(propEq('itemId', itemId))(items);
      items[index].quantity = quantity;

      setQuantityItems(items);
    },
    [quantityItems],
  );

  const goHome = useCallback(() => {
    navigation.navigate('index');
  }, [navigation]);

  useEffect(() => {
    if (data?.cateringItems) {
      const items = data.cateringItems.map(({ itemId }) => ({
        itemId,
        quantity: 0,
      }));

      setQuantityItems(items);
    }
  }, [data]);

  return {
    bookCatering,
    data: data?.cateringItems || [],
    isError: !isNil(orderCateringError),
    isSaving: orderCateringLoading,
    goHome,
    loading,
    onItemUpdate,
    orderCateringData: orderCateringData?.addCatering || {},
    orderCateringLoading,
  };
}
