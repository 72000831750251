import { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { gql } from '@apollo/client';
import { isNil, pathOr } from 'ramda';

import { useErrorQuery } from '@providers/Errors';
import { universalDateFormatter } from '@utils/DateAndTime';
import {
  useBaseValues,
  AREAS_BY_IDS_QUERY,
} from '@views/Home/Book/BasicBook/hooks';
import { Formats } from '@utils/intl';
import { PARKING_SPOT_TYPE_GENERAL } from '@views/shared/consts';
import {
  Area,
  Parking,
  ParkingSpotType,
} from '@views/shared/interfaces/buildingStructure';
import { QueryParkingData } from '@views/Home/Book/BasicBook/interfaces';

const getParkingQuery = gql`
  ${AREAS_BY_IDS_QUERY}
  query getParking($areaId: ID!, $parkingId: ID, $date: DateTime!) {
    parkingsByIds(ids: [$parkingId], date: $date) {
      label
      numberOnFloor
      type: combinedType
    }
    areasByIds(areasIds: [$areaId]) {
      ...BookingArea
    }
  }
`;

interface State {
  area?: Area;
  parking?: Parking;
}

export default function useValues(formats: Formats, message: string) {
  const {
    params: { areaId, parkingId },
  } = useRoute<any>();

  const { currentDate, ...rest } = useBaseValues(formats);
  const [parkingData, setParkingData] = useState<State>({});
  const [parkingType, setParkingType] = useState<ParkingSpotType>(
    PARKING_SPOT_TYPE_GENERAL,
  );

  const { data, error, loading } = useErrorQuery<QueryParkingData>(
    getParkingQuery,
    {
      fetchPolicy: 'no-cache',
      variables: {
        areaId,
        parkingId,
        date: universalDateFormatter({ date: currentDate }),
      },
      finderError: {
        type: 'fatal',
        message,
      },
    },
  );

  useEffect(() => {
    if (!isNil(data)) {
      const parking = pathOr(null, ['parkingsByIds', 0], data);
      const area = pathOr(null, ['areasByIds', 0], data);
      const type = pathOr(PARKING_SPOT_TYPE_GENERAL, ['type'], parking);
      const newState = {
        ...(area ? { area } : {}),
        ...(parking ? { parking } : {}),
      };

      setParkingType(type);
      setParkingData(newState);
    }
  }, [data]);

  return {
    ...rest,
    areaId,
    currentDate,
    data: parkingData,
    error,
    loading,
    parkingId,
    type: parkingType,
    hasNextDaysBooking: true,
  };
}
