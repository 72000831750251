import 'dotenv/config';

const env = process.env.APP_ENV ? process.env.APP_ENV : 'develop';

export default {
  name: 'LIZ Booker',
  slug: 'ltx-finder',
  owner: 'liz-smart-office',
  version: '1.0.18',
  description: 'Book your desk.',
  orientation: 'portrait',
  icon: './assets/images/icon-' + env + '.png',
  splash: {
    image: './assets/images/splash-' + env + '.png', // TODO: change image with the png with transparency like the one created for error-handling ( and maybe remove that one as well )
    resizeMode: 'contain',
    backgroundColor: '#ffffff', // TODO: this should be aquaMarineMedium => '#5ae0b940'
  },
  updates: {
    enabled: false,
  },
  assetBundlePatterns: ['**/*'],
  //TODO: this is for the native binary optimization
  //assetBundlePatterns: ['assets/images/*', 'assets/fonts/*'],
  ios: {
    supportsTablet: true,
    bundleIdentifier: process.env.APP_PACKAGE,
    buildNumber: Math.floor(Date.now() / 1000).toString(),
    config: {
      usesNonExemptEncryption: false,
    },
    infoPlist: {
      NSCameraUsageDescription:
        'LIZ Booker uses the camera to enable the user to personalize its avatar.',
      NSPhotoLibraryUsageDescription:
        'LIZ Booker uses the photo library to upload a personalized avatar for the account of the user.',
    },
    googleServicesFile: './GoogleService-Info.plist',
  },
  web: {
    favicon: './assets/images/icon-' + env + '.png',
    config: {
      firebase: {
        apiKey: 'AIzaSyCS-QWsyIjWTt92eIV7oY7i-fqpkNqqP04',
        authDomain: 'liz-smart-office-booker.firebaseapp.com',
        projectId: 'liz-smart-office-booker',
        storageBucket: 'liz-smart-office-booker.appspot.com',
        messagingSenderId: '1056952237956',
        appId: '1:1056952237956:web:4fbfa7093dd670e69a27d1',
        measurementId: 'G-XZVYBJPGCD',
      },
    },
  },
  scheme: 'booker',
  android: {
    package: process.env.APP_PACKAGE,
    versionCode: Math.floor(Date.now() / 1000),
    permissions: ['READ_EXTERNAL_STORAGE', 'WRITE_EXTERNAL_STORAGE'],
    googleServicesFile: './google-services.json',
  },
  packagerOpts: {
    sourceExts: [
      'expo.ts',
      'expo.tsx',
      'expo.js',
      'expo.jsx',
      'ts',
      'tsx',
      'js',
      'jsx',
      'json',
      'wasm',
      'svg',
      'ttf',
      'png',
    ],
  },
  extra: {
    APP_ENV: env,
    GRAPHQL_URI: process.env.GRAPHQL_URI,
    GRAPHQL_UPLOAD_URI: process.env.GRAPHQL_UPLOAD_URI,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    AUTH0_SCOPE: process.env.AUTH0_SCOPE,
    SENTRY_DSN: process.env.SENTRY_DSN,
    DEBUG_MOBILE_DEVICE_FROM_PROXY_MODE:
      process.env.DEBUG_MOBILE_DEVICE_FROM_PROXY_MODE,
    DEBUG_WITHOUT_OAUTH_LOGIN: process.env.DEBUG_WITHOUT_OAUTH_LOGIN,
  },
};
