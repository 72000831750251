import { useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { useRoute, useNavigation } from '@react-navigation/native';
import { useIntl, defineMessages } from 'react-intl';

import { useErrorMutation } from '@providers/Errors';

const i18nMessages = defineMessages({
  'Home.HowWasYoyrDay.hook.voteError': {
    id: 'Home.HowWasYoyrDay.hook.voteError',
    defaultMessage: 'Could not vote for how was your day',
  },
});

const voteMutation = gql`
  mutation vote($id: ID!, $value: Int!) {
    createHappinessVote(happinessVote: { bookingId: $id, value: $value })
  }
`;

export function useHowWasYourDay() {
  const intl = useIntl();
  const {
    params: { bookingId },
  } = useRoute<any>();
  const navigation = useNavigation();
  const [value, setValue] = useState<number | null>(null);
  const goBack = useCallback(() => {
    navigation.navigate('index');
  }, [navigation]);
  const [triggerVote] = useErrorMutation(voteMutation, {
    variables: {
      id: bookingId,
      value,
    },
    finderError: {
      type: 'minor',
      message: intl.formatMessage(
        i18nMessages['Home.HowWasYoyrDay.hook.voteError'],
      ),
    },
  });
  const vote = useCallback(async () => {
    try {
      await triggerVote();
    } finally {
      navigation.navigate('index');
    }
  }, [navigation, triggerVote]);

  return {
    value,
    setValue,
    vote,
    goBack,
  };
}
