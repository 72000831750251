import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Text } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import {
  fonts,
  topBarNavigatorStyles,
  spacings,
  colors,
} from '@views/shared/styles';

const { black } = colors;
const { sizeTitle } = fonts;
const { huge, medium } = spacings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: medium,
  },
  icon: {
    marginVertical: huge,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'pts-regular',
    fontSize: sizeTitle,
    textAlign: 'center',
    color: black,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const i18nMessages = defineMessages({
  'Errors.error_title': {
    id: 'Errors.error_title',
    defaultMessage: 'Something went wrong',
  },
  'Errors.no-data.error_title': {
    id: 'Errors.no-data.error_title',
    defaultMessage: 'No data available.',
  },
});

interface Prop {
  message?: string;
  level?: 'warning' | 'notify';
}

const defaultLevel = 'warning';

const levelConfig = {
  warning: { defaultTitle: 'Errors.error_title', icon: 'smiley-2' },
  notify: { defaultTitle: 'Errors.no-data.error_title', icon: 'smiley-3' },
};

export default function UnexpectedNoDataOrError({ message, level }: Prop) {
  const { formatMessage } = useIntl();
  const { defaultTitle, icon } = levelConfig[level || defaultLevel];
  const title = message || formatMessage(i18nMessages[defaultTitle]);

  return (
    <View style={[topBarNavigatorStyles.container, styles.container]}>
      <CustomIcon style={styles.icon} name={icon} size={45} color={black} />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}
