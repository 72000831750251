import { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  ascend,
  head,
  flatten,
  prop,
  sortWith,
  isEmpty,
  isNil,
  pick,
} from 'ramda';
import {
  useNavigation,
  useRoute,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';

import { useIntl, Translator } from '@utils/intl';
import { useMyBuildings } from '@providers/Buildings';
import { SelectItem } from '@views/shared/Select';
import { useErrorQuery } from '@providers/Errors';
import { preloadImages } from '@assets/index';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { FLOOR_TYPE_PARKING } from '@views/shared/consts';
import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { getFloorInfo, getFloorName } from '@utils/Building';

import {
  Floor,
  FloorData,
  FloorPlan,
} from '@views/shared/interfaces/floorplan';

const getFloorsQuery = gql`
  query getBuilding($buildingId: ID!) {
    floorplanFloors(buildingId: $buildingId) {
      floors {
        id
        floorLabel
        floorNumber
        floorType
        plan
        areas {
          id
          areaType
          isBookable
        }
      }
    }
  }
`;

const sortByLabelAndType = sortWith<FloorPlan>([
  ascend(prop('floorType')),
  ascend(prop('label')),
]);

const toFloorToFloorPlan = (t: Translator) => (floor: Floor): FloorPlan => {
  const label = getFloorName({
    t,
    ...getFloorInfo(floor),
  });

  return {
    ...floor,
    label,
  };
};

const hasPlan = ({ plan }: Floor) => !isNil(plan) && !isEmpty(plan);
const hideParking = (hasParkingFeature: boolean) => ({ floorType }: Floor) =>
  !hasParkingFeature ? floorType !== FLOOR_TYPE_PARKING : true;

const getFloorsPlanImagesWithRotationIfEnabled = (
  rotationEnabled: boolean,
) => ({ plan }: FloorPlan) =>
  rotationEnabled
    ? [plan, plan.replace('floor_plan', 'floor_plan_rotated')]
    : plan;

const emptyBuilding = { name: '', address: '' };

export function useFloorPlan() {
  // Dependencies
  const { t } = useIntl();
  const date = useCurrentDate();
  const { selectedBuildingId, selectedBuilding } = useMyBuildings();
  const rotationEnabled = useHasFeature('floor_plan_rotation');
  const hasParkingFeature = useHasFeature('parkings_enabled');
  const navigation = useNavigation();
  const route = useRoute<any>();

  // State
  const [currentFloor, setCurrentFloor] = useState<string | undefined>();
  const [buildingId, setBuildingId] = useState<string | undefined>(
    selectedBuildingId,
  );
  const [floors, setFloors] = useState<FloorPlan[]>([]);
  const [initialization, setInitialization] = useState(true);
  const [forceRender, setForceRender] = useState(false);

  const { data, loading, error } = useErrorQuery<FloorData>(getFloorsQuery, {
    variables: {
      buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: t('Floorplan.fetchError'),
    },
    skip: !buildingId,
  });

  useEffect(() => {
    /**
     When the current select building changes and the floor plan was used during the session, even if is not visible,
     the app update the view anyway and this produce the condition where our Tab.Navigator return null and when the
     user will use again the floormap, nothing will be returned so we need to enforce a re-render
     Note: this is only a temporary fix this needs a refactor and move the hook for the floor separate from the main hooks
     * */
    if (selectedBuildingId !== buildingId) {
      setBuildingId(selectedBuildingId);
      setForceRender(true);
    }
  }, [buildingId, selectedBuildingId]);

  useEffect(() => {
    const name = getFocusedRouteNameFromRoute(route);

    if (name !== currentFloor) {
      setCurrentFloor(name);
    }
  }, [currentFloor, navigation, route]);

  useEffect(
    () => {
      if (data?.floorplanFloors?.floors.length) {
        const floors = data.floorplanFloors.floors
          .filter(hasPlan)
          .filter(hideParking(hasParkingFeature))
          .map(toFloorToFloorPlan(t));

        const floorsData = sortByLabelAndType(floors);

        const floorsImages = flatten(
          floorsData.map(
            getFloorsPlanImagesWithRotationIfEnabled(rotationEnabled),
          ),
        );

        preloadImages({ resources: floorsImages }).then(() => {
          setFloors(floorsData);
          setCurrentFloor(head(floorsData)?.id);
          setInitialization(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, rotationEnabled, hasParkingFeature],
  );

  const items = floors.map(
    ({ id, label }: FloorPlan): SelectItem => ({
      value: id,
      label,
    }),
  );

  useEffect(() => {
    navigation.addListener('focus', () => {
      setForceRender(false);
    });
  }, [navigation]);

  return {
    building: selectedBuilding
      ? pick(['name', 'address'])(selectedBuilding)
      : emptyBuilding,
    items,
    loading: loading || initialization,
    error,
    floors,
    currentFloor,
    forceRender,
  };
}
