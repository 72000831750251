import React from 'react';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';

export const NoFloorData = () => {
  const { t } = useIntl();

  return (
    <UnexpectedNoDataOrError
      level={'notify'}
      message={t('Floorplan.no.data.for.floor')}
    />
  );
};

export default function NoFloors() {
  const { t } = useIntl();

  return (
    <UnexpectedNoDataOrError
      level={'warning'}
      message={t('Floorplan.no.active.floor')}
    />
  );
}
