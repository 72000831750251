import React, {
  useContext,
  useEffect,
  useState,
  memo,
  useCallback,
} from 'react';
import { gql } from '@apollo/client';
import { useRecoilValue } from 'recoil';
import { isEmpty, isNil } from 'ramda';

import { useErrorQuery } from '@providers/Errors';
import { useMyUser } from '@providers/User';
import { useIntl } from '@utils/intl';
import {
  universalDateFormatter,
  getAllDay,
  isSameDay,
  sortByDate,
} from '@utils/DateAndTime';
import { loggedInAtom } from '@views/Login';
import { useCurrentDate } from '@views/Calendar/hooks';
import { fromTimeSlotsDataToBlockedTimeSlots } from '@views/shared/TimeSlots/helper';
import {
  Booking as MyBooking,
  BookingType,
} from '@views/shared/interfaces/booking';

import {
  BlockedTimeSlot,
  UnavailableTimeSlot,
} from '@views/shared/TimeSlots/interfaces';

import {
  checkFullDayActiveBookings,
  getActiveBookingsTimePeriods,
} from './helper';

import {
  MyActiveBookingsContextType,
  MyActiveBookingsProps,
  MyActiveBookingsProviderProps,
  MyBookingsData,
  MyBookingsVars,
} from './interfaces';

export { MyBookingsData, MyBookingsVars } from './interfaces';

const getMyBookings = gql`
  query getMyActiveBookings(
    $start: DateTime
    $end: DateTime
    $language: LanguageEnumType
  ) {
    actives: bookings(
      start: $start
      end: $end
      status: ACTIVE
      language: $language
    ) {
      ...bookingData
    }

    checkedIn: bookings(
      start: $start
      end: $end
      status: CHECKED_IN
      homeOffice: false
      language: $language
    ) {
      ...bookingData
    }
  }

  fragment bookingData on BookingType {
    id
    status
    checkedIn
    checkedOut
    homeOffice
    travelDay
    start
    end
    desk {
      id
      type
      label
      numberOnFloor
    }
    area {
      id
      name
      areaType
      floor {
        floorLabel
        floorNumber
        floorType
        building {
          name
          address
        }
      }
    }
    parking {
      id
      type: combinedType
      label
      numberOnFloor
    }
    catering {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
  }
`;

const sortByDateAdapter = ({ start: a }, { start: b }) => sortByDate(a, b);

const DEFAULT_CONTEXT_STATE = {
  bookings: [],
  count: 0,
  loading: true,
  refreshActiveBookings: () => null,
  checkFullDayActiveBookingByType: () => false,
  getTimeSlotsActiveBookingByType: () => [],
};

export const MyActiveBookingsContext = React.createContext<MyActiveBookingsContextType>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyActiveBookings = () => useContext(MyActiveBookingsContext);

// eslint-disable-next-line react/display-name
const MyActiveBookings = memo(
  ({ children, loggedIn, start, end, t, userId }: MyActiveBookingsProps) => {
    const [bookings, setBookings] = useState<MyBooking[]>([]);
    const { language } = useMyUser();

    const { loading, data, refetch: refreshActiveBookings } = useErrorQuery<
      MyBookingsData,
      MyBookingsVars
    >(getMyBookings, {
      variables: {
        start: universalDateFormatter({ date: start }),
        end: universalDateFormatter({ date: end }),
        language,
      },
      skip: !loggedIn || isEmpty(userId),
      fetchPolicy: 'no-cache',
      finderError: {
        type: 'fatal',
        message: t('Home.ActiveBookings.hooks.fetchError'),
      },
    });

    useEffect(() => {
      if (data) {
        const { actives = [], checkedIn = [] } = data;
        const bookings = checkedIn.concat(actives).sort(sortByDateAdapter);

        setBookings(bookings);
      }
    }, [data]);

    const checkFullDayActiveBookingByType = useCallback(
      (type: BookingType): boolean =>
        !isNil(bookings.find(checkFullDayActiveBookings(type))),
      [bookings],
    );

    const getTimeSlotsActiveBookingByType = useCallback(
      (
        // This are the unavailableTimePeriods coming from the specific query implemented into the hooks for each type
        unavailableTimePeriods: UnavailableTimeSlot[],
        type: BookingType,
      ): BlockedTimeSlot[] => {
        const getActiveBookingsTimePeriodsByType = getActiveBookingsTimePeriods(
          type,
        );

        return bookings.reduce(
          getActiveBookingsTimePeriodsByType,
          fromTimeSlotsDataToBlockedTimeSlots(
            userId,
            unavailableTimePeriods,
            type,
          ),
        );
      },
      [bookings, userId],
    );

    return (
      <MyActiveBookingsContext.Provider
        value={{
          loading,
          bookings,
          count: bookings.length,
          refreshActiveBookings,
          checkFullDayActiveBookingByType,
          getTimeSlotsActiveBookingByType,
        }}
      >
        {children}
      </MyActiveBookingsContext.Provider>
    );
  },
  (prevProps, nextProps) =>
    prevProps.loggedIn === nextProps.loggedIn &&
    isSameDay(prevProps.start, nextProps.start) &&
    isSameDay(prevProps.end, nextProps.end) &&
    prevProps.userId === nextProps.userId,
);

export const MyActiveBookingsProvider = ({
  children,
}: MyActiveBookingsProviderProps) => {
  const { t } = useIntl();
  const { id } = useMyUser();
  const date = useCurrentDate();
  const { start, end } = getAllDay(date);
  const loggedIn = useRecoilValue(loggedInAtom);

  return (
    <MyActiveBookings
      start={start}
      end={end}
      loggedIn={loggedIn}
      t={t}
      userId={id}
    >
      {children}
    </MyActiveBookings>
  );
};
