import { useCallback } from 'react';

import { useNavigation } from '@react-navigation/native';

import { ColleagueApiResponse, ColleagueLight } from './interfaces';

interface PropsColleagueLight {
  colleague: ColleagueApiResponse;
}

export enum STATUS {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

const DEFAULT_ICON = 'warning';

const presenceStatusIcons = {
  OFFICE: 'shared-no',
  HOMEOFFICE: 'home-office-no',
  ABSENT: 'exit',
  DISALLOWED: 'disallowed',
  TRAVELDAY: 'travel',
};

const badgeStatus = {
  UNKNOWN: STATUS.WARNING,
  AVAILABLE: STATUS.SUCCESS,
  OCCUPIED: STATUS.ERROR,
};

export function useLightColleague({
  colleague,
}: PropsColleagueLight): ColleagueLight {
  const {
    name,
    id,
    presenceStatus,
    avatar,
    department,
    email,
    phoneNumber,
    homeofficeInfo,
    visibleHomeoffice,
    visibleForColleagues,
    visibleCalendar,
    initials,
    status,
    currentEvent,
  } = colleague;

  const icon = presenceStatusIcons[presenceStatus] || DEFAULT_ICON;
  const badgeColor = badgeStatus[status];

  const navigation = useNavigation();
  const goToProfile = useCallback(() => {
    navigation.navigate('colleagues', {
      screen: 'profile',
      params: {
        id: colleague.id,
        colleague: { ...colleague, icon, badgeColor },
      },
      initial: false,
    });
  }, [navigation, colleague, icon, badgeColor]);

  return {
    id,
    name,
    avatar,
    department,
    email,
    phoneNumber,
    homeofficeInfo,
    visibleHomeoffice,
    visibleForColleagues,
    visibleCalendar,
    presenceStatus,
    goToProfile,
    icon,
    initials,
    status,
    badgeColor,
    currentEvent,
  };
}
