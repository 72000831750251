import { Position } from './interfaces';

export function getDistance(posA: Position, posB: Position): number {
  return Math.sqrt(Math.pow(posA.x - posB.x, 2) + Math.pow(posA.y - posB.y, 2));
}

export function getPositionDiff(posA: Position, posB: Position): Position {
  return {
    x: posA.x - posB.x,
    y: posA.y - posB.y,
  };
}

export function getScaledPos({ x, y }: Position, scale: number): Position {
  return {
    x: x * scale,
    y: y * scale,
  };
}
