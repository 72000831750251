import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { useLogout } from '@views/Login/hooks';
import { SecondaryButton } from '@views/shared/Button';
import { spacings, fonts } from '@views/shared/styles';
import { globalStyles } from '@views/shared/styles';

const { huge } = spacings;
const { sizeTitle } = fonts;

const styles = StyleSheet.create({
  container: {
    marginBottom: huge,
  },
  button: {
    alignSelf: 'flex-start',
    height: 35,
  },
  title: {
    fontSize: sizeTitle,
    fontFamily: 'pts-regular',
  },
});

export function Logout() {
  const { t } = useIntl();
  const logout = useLogout();
  const handleLogout = async () => await logout();

  return (
    <View style={styles.container}>
      <SecondaryButton
        buttonStyle={[styles.button, globalStyles.cancelButton]}
        titleStyle={globalStyles.cancelButtonText}
        title={t('Profile.Content.Logout.text')}
        onPress={handleLogout}
      />
    </View>
  );
}
