import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Text } from 'react-native-elements';

import { Loader } from '@views/shared/Loader';
import { Auth0Redirect } from '@views/Auth0Redirect';
import { fonts, spacings, colors } from '@views/shared/styles';

const { black, aquaMarineLight } = colors;
const { sizeTitle } = fonts;
const { medium } = spacings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: medium,
    backgroundColor: aquaMarineLight,
    width: '100%',
    height: '100%',
  },
  card: {
    bottom: '20%',
  },
  button: {
    width: '30%',
    alignSelf: 'center',
    paddingVertical: '3%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'pts-regular',
    fontSize: sizeTitle,
    textAlign: 'center',
    color: black,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const splashCoverImage = require('@assets/images/LIZ-splash.png');

export function Redirect() {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={splashCoverImage}
        resizeMode="contain"
      />
      <View style={[styles.card]}>
        <Loader />
        <Text style={styles.title}>Loading...</Text>
      </View>
      <Auth0Redirect />
    </View>
  );
}
