import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { List } from './List';
import { Profile } from './Profile';

const Stack = createStackNavigator();

export function Colleagues() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        component={List}
        name="list"
        options={() => ({
          title: 'Colleagues',
        })}
      />
      <Stack.Screen
        component={Profile}
        name="profile"
        options={() => ({
          title: 'Profile',
        })}
      />
    </Stack.Navigator>
  );
}
