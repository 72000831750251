import { useEffect, useState, useCallback } from 'react';
import { Platform } from 'react-native';
import { ReactNativeFile } from 'apollo-upload-client';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

import { universalDateFormatter } from '@utils/DateAndTime';
import { ImageUploadProps } from './interfaces';

function generateRNFile(uri: string, name: string) {
  return new ReactNativeFile({
    uri,
    type: 'image/jpeg',
    name,
  });
}

export function useImageUpload({
  onFileChange,
  onError,
  disabled,
}: ImageUploadProps) {
  const [image, setImage] = useState<string>();
  const pickImage = useCallback(async () => {
    if (disabled) {
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
      exif: false,
    });

    if (!result.cancelled) {
      const format = ImageManipulator.SaveFormat.JPEG;
      const manipulatedImage = await ImageManipulator.manipulateAsync(
        result.uri,
        [{ rotate: 0 }],
        { compress: 0.5, format },
      );

      const date = universalDateFormatter({
        format: 'YYYYMMDDHHmm',
      });

      const file = generateRNFile(
        manipulatedImage.uri,
        `avatar-${date}.${format}`,
      );

      setImage(manipulatedImage.uri); // This is the base64 picture that the Image Component will consume
      onFileChange(file); // This is the RNFile to send to the backend for saving
    }
  }, [disabled, onFileChange]);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const {
          status,
        } = await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (status !== 'granted') {
          onError('error.permissions');
          setImage(undefined);
        }
      }
    })();
  }, [onError]);

  return {
    image,
    pickImage,
  };
}
