import React from 'react';
import { isNil, isEmpty } from 'ramda';
import { Dimensions, View } from 'react-native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { topBarNavigatorStyles } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import NoFloors, { NoFloorData } from '@views/Floorplan/NoFloors';
import { ZoomImage } from '@views/Floorplan/ZoomImage';

import { useFloorPlan } from './hooks';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  labelStyle: topBarNavigatorStyles.tabLabel,
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: { flex: 1 },
  scrollEnabled: true,
};

export function FloorPlan() {
  const {
    error,
    loading,
    items,
    floors,
    currentFloor,
    building,
    forceRender,
  } = useFloorPlan();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  if (loading) {
    return <Loader />;
  }

  if (isNil(items) || isEmpty(items)) {
    return <NoFloors />;
  }

  const FloorPlanScreen = ({ name, selected }) => {
    if (!selected && !forceRender) {
      return null;
    }

    const floor = floors.find((floor) => floor.id === name);

    return floor ? (
      <ZoomImage
        key={name}
        floor={floor}
        reset={currentFloor !== name}
        building={building}
      />
    ) : (
      <NoFloorData />
    );
  };

  const renderFloorPlanScreen = ({ navigation, route }) => {
    return (
      <FloorPlanScreen
        key={route.name}
        name={route.name}
        selected={navigation.isFocused()}
      />
    );
  };

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        initialRouteName={currentFloor}
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        swipeEnabled={false}
        lazy={true}
        initialLayout={{ width: Dimensions.get('window').width }}
      >
        {items.map(({ value, label }) => (
          <Tab.Screen
            key={value}
            name={value}
            options={() => ({
              title: label,
            })}
          >
            {renderFloorPlanScreen}
          </Tab.Screen>
        ))}
      </Tab.Navigator>
    </View>
  );
}
