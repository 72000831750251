import React, { useCallback, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { isNil, isEmpty } from 'ramda';

import {
  getBookableWorkingTime,
  getTodayAsDayJs,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { MainButton, SecondaryButton } from '@views/shared/Button';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { CustomIcon } from '@views/shared/CustomIcon';
import TitleByType from '@views/shared/TitleByType';
import { ICON_COLORS, useMyOrganization } from '@providers/Organization';
import { getLocation } from '@views/shared/hooks/getLocation';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getBookingIcon } from '@views/shared/consts';
import { default as CateringOrder } from '@views/Home/Book/MeetingRoom/Catering/Order';

import { useBooking } from './hooks';
import { Problem } from './Problem';

const { medium, small, smallMedium, smaller, verySmall } = spacings;
const { black, blackAlpha, white } = colors;

const width = 105;
const rightCornerWidth = 20;

const styles = StyleSheet.create({
  card: {
    marginBottom: medium,
    borderColor: blackAlpha,
    borderWidth: 1,
    borderBottomLeftRadius: smallMedium,
    borderBottomRightRadius: smaller,
    borderTopLeftRadius: smallMedium,
    borderTopRightRadius: smaller,
  },
  location: {
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-regular',
  },
  row: {
    flexDirection: 'row',
  },
  date: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
    textAlign: 'left',
  },
  time: {
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-regular',
  },
  right: {
    padding: medium,
    flex: 1,
    backgroundColor: white,
    borderBottomRightRadius: smaller,
    borderTopRightRadius: smaller,
  },
  between: {
    justifyContent: 'space-between',
  },
  left: {
    padding: medium,
    borderColor: blackAlpha,
    borderBottomLeftRadius: smallMedium,
    borderTopLeftRadius: smallMedium,
    width,
  },
  icon: {
    marginBottom: medium,
  },
  dateTime: {
    zIndex: 2,
    flex: 1,
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: verySmall,
    paddingHorizontal: 0,
    paddingVertical: 0,
    padding: 0,
  },
  cateringIcon: {
    marginTop: -1,
    marginLeft: medium,
  },
  cateringContainer: {
    marginTop: small,
    paddingTop: small,
    borderColor: blackAlpha,
    borderTopWidth: 1,
  },
});

const getTriangleStyles = (height, color) => {
  const isNotWhite = color.toLowerCase() !== '#ffffff';
  const rightCornerOffset = -rightCornerWidth + 1;
  const rightCornerOverflowOffset = rightCornerWidth - 1;
  return StyleSheet.create({
    triangle: {
      width,
      height,
      left: 0,
      top: 0,
      position: 'absolute',
      zIndex: 1,
    },
    left: {
      width,
      height,
      borderBottomLeftRadius: smallMedium,
      borderTopLeftRadius: smallMedium,
      backgroundColor: color,
    },
    right: {
      height,
      position: 'absolute',
      right: rightCornerOffset + (isNotWhite ? -1 : 0),
      borderStyle: 'solid',
      borderLeftWidth: rightCornerWidth,
      borderTopWidth: height,
      borderLeftColor: 'transparent',
      borderTopColor: blackAlpha,
    },
    rightOverflow: {
      top: -height,
      right: rightCornerOverflowOffset + (isNotWhite ? 1 : 0),
      height: '100%',
      borderStyle: 'solid',
      borderLeftWidth: rightCornerWidth,
      borderTopWidth: height,
      borderLeftColor: 'transparent',
      borderTopColor: white,
    },
  });
};

interface Props {
  booking: BookingType;
}

interface TimeProps {
  end?: Date | string;
  start?: Date | string;
  timeFormatShort: string;
  workingHoursStart: Date | string;
  workingHoursEnd: Date | string;
}

const calculateWorkingTime = (
  timeFormatShort: string,
  workingHoursStart: string,
  workingHoursEnd: string,
): string => {
  const date = getTodayAsDayJs();
  const { start, end } = getBookableWorkingTime(
    date,
    workingHoursStart,
    workingHoursEnd,
  );

  const startOfWorkingDay = universalDateFormatter({
    date: start,
    format: timeFormatShort,
  });

  const endOfWorkingDay = universalDateFormatter({
    date: end,
    format: timeFormatShort,
  });

  return `${startOfWorkingDay} - ${endOfWorkingDay}`;
};

export function getTimeByType({
  end,
  start,
  timeFormatShort,
  workingHoursStart,
  workingHoursEnd,
}: TimeProps) {
  /*if (type === BOOKING_TYPE_MEETING_ROOM) {*/
  if (start && end) {
    return `${start} - ${end}`;
  }

  return calculateWorkingTime(
    timeFormatShort,
    workingHoursStart as string,
    workingHoursEnd as string,
  );
}

export function Booking({ booking }: Props) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const [height, setHeight] = useState(0);
  const [showCatering, setShowCatering] = useState(false);

  const onPageLayout = useCallback(
    (event) => {
      const { height } = event.nativeEvent.layout;
      setHeight(height);
    },
    [setHeight],
  );

  const toggleCatering = useCallback(
    () => setShowCatering((prevState) => !prevState),
    [setShowCatering],
  );

  const {
    cancel,
    changeCheckStatus,
    checkinEnabled,
    date,
    isCancelable,
    isReportEnabled,
    end,
    start,
    type,
  } = useBooking(booking);

  const {
    backgroundColor,
    workingHoursStart,
    workingHoursEnd,
    iconColor,
  } = useMyOrganization();

  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const location = getLocation({
    booking,
    t,
    showDeskNumber,
  });

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  const { catering, checkedIn, travelDay } = booking;
  const hasCatering = !isNil(catering) && !isEmpty(catering);

  const triangleStyles = getTriangleStyles(height, backgroundColor);
  const contrastColor = iconColor === ICON_COLORS.DARK ? black : white;

  return (
    <View style={[styles.card, globalStyles.shadow]}>
      <View style={styles.row}>
        <View style={[styles.left, styles.between]} onLayout={onPageLayout}>
          <View style={styles.dateTime}>
            <View style={styles.icon}>
              {/* @ts-ignore */}
              <CustomIcon
                name={getBookingIcon(type)}
                size={30}
                color={contrastColor}
              />
            </View>
            <View>
              <Text style={[styles.date, { color: contrastColor }]}>
                {date}
              </Text>
              <Text style={[styles.time, { color: contrastColor }]}>
                {time}
              </Text>
            </View>
          </View>
          <View style={triangleStyles.triangle}>
            <View style={triangleStyles.left} />
            <View style={triangleStyles.right}>
              <View style={triangleStyles.rightOverflow} />
            </View>
          </View>
        </View>

        <View style={[styles.right, styles.between]}>
          <View>
            <TitleByType type={type} />
            {!travelDay ? (
              <Text
                style={[globalStyles.description, { paddingBottom: smaller }]}
              >
                {location}
              </Text>
            ) : null}
          </View>
          <View style={[styles.row, styles.between, { alignItems: 'center' }]}>
            <View style={styles.row}>
              {isReportEnabled ? (
                <Problem booking={booking} location={location} />
              ) : null}
              {hasCatering ? (
                <CustomIcon
                  style={styles.cateringIcon}
                  name="coffee"
                  onPress={toggleCatering}
                  size={22}
                />
              ) : null}
            </View>
            <View style={styles.row}>
              {checkinEnabled ? (
                <MainButton
                  onPress={changeCheckStatus}
                  buttonStyle={styles.button}
                  title={t(
                    checkedIn
                      ? 'Home.ActiveBookings.Booking.check-out'
                      : 'Home.ActiveBookings.Booking.check-in',
                  )}
                />
              ) : null}
              {isCancelable ? (
                <SecondaryButton
                  buttonStyle={[styles.button, globalStyles.cancelButton]}
                  titleStyle={globalStyles.cancelButtonText}
                  title={t('Home.ActiveBookings.Booking.cancel')}
                  onPress={cancel}
                />
              ) : null}
            </View>
          </View>
          {showCatering && hasCatering
            ? catering.map((data, index) => (
                <View style={styles.cateringContainer} key={index}>
                  <CateringOrder data={data} />
                </View>
              ))
            : null}
        </View>
      </View>
    </View>
  );
}
