import '@app/intl-polyfill';
import React from 'react';
import { Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';

import ApolloWrapper from '@app/client';
import LIZTheme from '@views/Theme';
import { NavigationContainer } from '@react-navigation/native';
import { Auth0Provider } from '@app/auth0';
import { LanguageContext } from '@app/i18n';
import { Layout } from '@views/Layout';
import { OAuth } from '@app/oauth';
import { Loader } from '@views/shared/Loader';
import { MyOrganizationProvider } from '@providers/Organization';
import { MyUserProvider } from '@providers/User';
import { MyActiveBookingsProvider } from '@providers/ActiveBookings';
import { MyBuildingsProvider } from '@providers/Buildings';
import { ErrorsProvider } from '@providers/Errors';

const {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
} = Constants.manifest.extra;

if (Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession();
}

const prefix = Linking.makeUrl('/');

const linking = {
  prefixes: [prefix],
};

export default function AppWrapper() {
  // Navigation context outside the main <Tab.Navigator> consumed by Errors actions
  const navigationRef = React.useRef(null);

  return (
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      domain={AUTH0_DOMAIN}
      onLogin={() => {}}
      onTokenRequestFailure={() => {}}
    >
      <ApolloWrapper>
        <>
          <ErrorsProvider>
            <MyUserProvider>
              <MyOrganizationProvider>
                <MyBuildingsProvider>
                  <LanguageContext>
                    <MyActiveBookingsProvider>
                      <SafeAreaProvider>
                        <LIZTheme>
                          <NavigationContainer
                            ref={navigationRef}
                            linking={linking}
                            fallback={<Loader />}
                          >
                            <Layout navigationRef={navigationRef} />
                          </NavigationContainer>
                        </LIZTheme>
                      </SafeAreaProvider>
                    </MyActiveBookingsProvider>
                  </LanguageContext>
                </MyBuildingsProvider>
              </MyOrganizationProvider>
            </MyUserProvider>
          </ErrorsProvider>
          {/*
            OAuth Component is here because is the surrogate module for the web version of the app, ant it provides
            support for the calendar integrations Google and Microsoft
            */}
          <OAuth />
        </>
      </ApolloWrapper>
    </Auth0Provider>
  );
}
