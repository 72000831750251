import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import { useIntl, defineMessages } from 'react-intl';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { Loader } from '@views/shared/Loader';
import { Swipeable } from '@views/shared/Swipeable';
import { ColleagueApiResponse } from '@views/Colleagues/Colleague/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';
import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Bookings';

import { Day } from './Day';
import { useBookings } from './hooks';

const i18nMessages = defineMessages({
  'views.Colleagues.Bookings.title': {
    id: 'views.Colleagues.Bookings.title',
    defaultMessage: 'My Colleagues Bookings',
  },
  'views.Colleagues.featureDisabled': {
    id: 'views.Colleagues.featureDisabled',
    defaultMessage: 'Find my colleague is disabled for your organisation.',
  },
});

interface Props {
  id: string;
  colleague: ColleagueApiResponse;
}
export function Bookings({ id }: Props) {
  const {
    weekDays,
    mapping,
    loading,
    error,
    onSwipe,
    moveToNextWeek,
    moveToPreviousWeek,
  } = useBookings({
    id: id,
  });

  const intl = useIntl();

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Swipeable onSwipe={onSwipe}>
      <ScrollView style={globalStyles.container}>
        <View style={styles.header}>
          <CustomIcon name="arrow-left" onPress={moveToPreviousWeek} />
          <Text style={styles.title}>
            {intl.formatMessage(
              i18nMessages['views.Colleagues.Bookings.title'],
            )}
          </Text>
          <CustomIcon name="arrow-right" onPress={moveToNextWeek} />
        </View>
        {weekDays.map((day) => {
          const key = universalDateFormatter({
            date: day,
            format: dateFormatShort,
          });
          const bookings = mapping.get(key) ?? [];

          return <Day key={key} day={day} bookings={bookings} />;
        })}
      </ScrollView>
    </Swipeable>
  );
}
