import React from 'react';

import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import AvailabilityInfos from '@views/shared/AvailabilityInfos';
import { TimeSlot } from '@views/shared/TimeSlots/interfaces';

import { useInfos } from './hooks';
import { BookingType } from '@views/shared/interfaces/booking';

export default function Infos({
  slot,
  location,
  dismiss,
  type,
}: {
  location: string;
  slot: TimeSlot;
  dismiss: (slot?: TimeSlot) => any;
  type: BookingType;
}) {
  const {
    formats: { dayFormat },
  } = useIntl();

  const { error, loading, unavailableTimePeriods } = useInfos({
    slot,
  });

  const currentDate = useCurrentDate();
  const date = universalDateFormatter({
    date: currentDate,
    format: dayFormat,
  });

  return (
    <AvailabilityInfos
      dismiss={dismiss}
      error={error}
      loading={loading}
      fullCaption={location}
      date={date}
      status={slot.status}
      unavailableTimeWithColleague={unavailableTimePeriods}
      type={type}
    />
  );
}
