import React, { CSSProperties } from 'react';
import { StyleSheet, View } from 'react-native';
import { BookingType } from '@views/shared/interfaces/booking';
import { WorklightStatus } from '@views/shared/interfaces/buildingStructure';
import { colors, globalStyles } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';

interface Props {
  name: string | BookingType;
  style?: CSSProperties;
  onPress?: () => void;
  worklight?: WorklightStatus;
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
});

const badgeColors = (status: WorklightStatus) => {
  const { aquaMarine, paleOrange, warmPink } = colors;
  const colorMap = {
    high: warmPink,
    medium: paleOrange,
    low: aquaMarine,
  };
  return colorMap[status];
};

export function BookingIcon({ name, onPress, style, worklight }: Props) {
  return (
    <View style={styles.container}>
      <CustomIcon name={name} onPress={onPress} size={30} style={style} />
      {worklight && (
        <View
          style={[
            globalStyles.badge,
            { backgroundColor: badgeColors(worklight) },
          ]}
        />
      )}
    </View>
  );
}
