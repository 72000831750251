import React from 'react';
import { ScrollView } from 'react-native';

import { globalStyles } from '@views/shared/styles';

import { UserData } from './UserData';
import { Logout } from './Logout';
import { Language } from './Language';
import { CalendarAuth } from './CalendarAuth';
import { Toggles } from './Toggles';
import { PrivacyPolicy } from './PrivacyPolicy';

export function Overview() {
  return (
    <ScrollView style={globalStyles.container}>
      <Language />
      <UserData />
      <Toggles />
      <CalendarAuth />
      <Logout />
      <PrivacyPolicy />
    </ScrollView>
  );
}
