import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { fonts, globalStyles, spacings } from '@views/shared/styles';
import { styles as bookingStyles } from '@views/shared/Booking';

import WeekDays from './WeekDays';
import useValues from './hooks';

import { BookingProps } from './interfaces';

export const styles = StyleSheet.create({
  book: {
    paddingVertical: spacings.oversize,
    textAlign: 'center',
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
  },
});

export default function FullDayBooking({
  currentBookedType,
  params = {},
}: BookingProps) {
  const { t } = useIntl() as any;

  const { book, errorBooking, onSelect, selectedSlots } = useValues(params);

  return (
    <View>
      <Text style={styles.book}>{t('Home.Book.Again')}</Text>
      <WeekDays
        currentBookedType={currentBookedType}
        params={params}
        onSelect={onSelect}
      />
      {errorBooking ? (
        <Text style={[globalStyles.title, bookingStyles.error]}>
          {t(errorBooking)}
        </Text>
      ) : null}
      <View style={bookingStyles.buttonContainer}>
        <MainButton
          disabled={!selectedSlots.length}
          onPress={book}
          title={t('Home.Book.book_again')}
        />
      </View>
    </View>
  );
}
