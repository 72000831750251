import React from 'react';
import { ButtonProps } from 'react-native-elements';

import { colors } from '@views/shared/styles';
import { MainButton } from './Main';

export function SecondaryButton({
  buttonStyle,
  titleStyle,
  ...props
}: ButtonProps) {
  return (
    <MainButton
      buttonStyle={[{ backgroundColor: 'transparent' }, buttonStyle]}
      titleStyle={[{ color: colors.aquaMarine }, titleStyle]}
      type="outline"
      {...props}
    />
  );
}
