import { gql } from '@apollo/client';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIntl, defineMessages } from 'react-intl';
import { atom, useRecoilState } from 'recoil';
import { useCallback } from 'react';

import { useErrorQuery } from '@providers/Errors';
import { useCurrentDate } from '@views/Calendar/hooks';

import { Data } from './interfaces';

const getEventsQuery = gql`
  query myColleagueCalendarEvents($userID: ID!, $date: Date!) {
    myColleagueCalendarEvents(userId: $userID, dateFrom: $date, dateTo: $date) {
      subject
      description
      start
      end
    }
  }
`;

export const featurKey = 'colleague-events';

const isOpenAtom = atom({
  key: `${featurKey}/open`,
  default: false,
});

const i18nMessages = defineMessages({
  'Colleagues.Events.error': {
    id: 'Colleagues.Events.error',
    defaultMessage: 'Could not fetch events for colleague',
  },
});

interface Props {
  id: string;
}

export function useColleagueEvents({ id }: Props) {
  const intl = useIntl();
  const date = useCurrentDate();

  const { data, loading, error } = useErrorQuery<Data>(getEventsQuery, {
    variables: {
      userID: id,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'network-only',
    finderError: {
      type: 'minor',
      message: intl.formatMessage(i18nMessages['Colleagues.Events.error']),
    },
  });

  return {
    events: data?.myColleagueCalendarEvents || [],
    loading,
    error,
  };
}

export function useOpenColleagueEvents() {
  const [isOpen, setOpen] = useRecoilState(isOpenAtom);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const open = useCallback(() => setOpen(true), [setOpen]);

  return {
    isOpen,
    close,
    openColleagueEvents: open,
  };
}
