import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/shared/BookingConfirmation';
import CloseConfirmBooking from '@views/shared/Booking/CloseConfirm';

export function Confirm() {
  const { t } = useIntl();
  return (
    <View style={globalStyles.container}>
      <View style={styles.container}>
        <Text style={styles.header}>
          {t('Home.ActiveBooking.Booking.Confirm.title')}
        </Text>
        <CloseConfirmBooking />
      </View>
    </View>
  );
}
