import { useEffect } from 'react';
import { gql, useMutation, useApolloClient, MutationFunction, ApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

const $location = window.location;

const authorizeGoogleMutation = gql`
  mutation authGoogle($code: String!, $state: String!) {
    googleAuthorizationCallback(code: $code, state: $state)
  }
`;

const authorizeMicrosoftMutation = gql`
  mutation authMicrosoft($code: String!, $state: String!) {
    microsoftAuthorizationCallback(code: $code, state: $state)
  }
`;

const googlePath = '/google-oauth2-callback';
const microsoftPath = '/microsoft-oauth2-callback';
type AuthPaths = '/google-oauth2-callback' | '/microsoft-oauth2-callback';

function isAuthPath(path: string): path is AuthPaths {
  return [googlePath, microsoftPath].includes(path);
}

const codeKey = 'oauth-code';
const stateKey = 'oauth-state';
const pathKey = 'oauth-path';

export const clearLocalStorage = () => clearLocal();

function clearLocal() {
  localStorage.removeItem(codeKey);
  localStorage.removeItem(stateKey);
  localStorage.removeItem(pathKey);
}

function saveLocal(code: string, state: string, path: AuthPaths) {
  localStorage.setItem(codeKey, code);
  localStorage.setItem(stateKey, state);
  localStorage.setItem(pathKey, path);
}

async function tryAuth(
  code: string,
  state: string,
  path: AuthPaths,
  authMethods: { [T in AuthPaths]: MutationFunction },
  client: ApolloClient<any>,
) {
  await authMethods[path]({
    variables: {
      code: code,
      state: state,
    },
  });
  await client.clearStore();

  clearLocal();
}

export function useAuth() {
  const [authorizeGoogle] = useMutation(authorizeGoogleMutation);
  const [authorizeMicrosoft] = useMutation(authorizeMicrosoftMutation);
  const { isAuthenticated } = useAuth0();
  const client = useApolloClient();
  const authMethods = {
    [googlePath]: authorizeGoogle,
    [microsoftPath]: authorizeMicrosoft,
  };

  useEffect(() => {
    let path = $location.pathname;

    if (isAuthPath(path)) {
      const params = $location.search
        .slice(1)
        .split('&')
        .reduce((map: { [key: string]: string }, part: string) => {
          const [key, value] = part.split('=');

          return {
            ...map,
            [key]: value,
          };
        }, {});

      const code = params.code;
      const state = params.state;

      saveLocal(code, state, path);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const code = localStorage.getItem(codeKey);
      const state = localStorage.getItem(stateKey);
      const path = localStorage.getItem(pathKey) as AuthPaths | null;

      if (isAuthenticated && code && state && path) {
        tryAuth(code, state, path, authMethods, client);
      }
    })();
  }, [authorizeGoogle, authorizeMicrosoft, client, isAuthenticated]);
}
