import React from 'react';
import Constants from 'expo-constants';

import { Auth0Provider as OrginalProvider } from '@auth0/auth0-react';

export { useAuth0 } from '@auth0/auth0-react';

const { AUTH0_SCOPE } = Constants.manifest.extra;

interface Auth0ProviderOptions {
  /**
   * The child nodes your provider has wrapped.
   */
  children: React.ReactElement;
  /**
   * The client ID found on your application settings page.
   */
  clientId: string;
  /**
   * The default audience to be used for requesting API access.
   */
  audience: string;
  /**
   * Your Auth0 account domain such as `'example.auth0.com'`,
   * `'example.eu.auth0.com'` or , `'example.mycompany.com'`
   * (when using [custom domains](https://auth0.com/docs/custom-domains))
   */
  domain: string;
  /**
   * Callback for auth0 login.
   */
  onLogin: () => void | undefined;
  /**
   * Callback for if the access token request fails.
   */
  onTokenRequestFailure: () => void | undefined;
}

export function Auth0Provider({
  domain,
  clientId,
  audience,
  children,
}: Auth0ProviderOptions) {
  return (
    <OrginalProvider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      scope={AUTH0_SCOPE}
      audience={audience}
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
    >
      {children}
    </OrginalProvider>
  );
}
