import React, { forwardRef } from 'react';

import { PinchAreaProps } from './interfaces';
import { useWebScale } from './hooks/web';

export const PinchArea = forwardRef<any, PinchAreaProps>(({ children, ...rest }: PinchAreaProps, ref) => {
  const { onWheel, onTouchStart, onTouchMove, onTouchEnd } = useWebScale(rest);

  return (
    <div ref={ref} onWheel={onWheel} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      {children}
    </div>
  );
});
