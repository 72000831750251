import { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { compose, head, last } from 'ramda';

import { useErrorQuery } from '@providers/Errors';
import { useMyOrganization } from '@providers/Organization';
import { getWorkingDaysOfWeek } from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import {
  dateFormatShort,
  getNextDays,
  getNextDayFromThis,
  isAfter,
  universalDateFormatter,
  getTodayAsDayJs,
} from '@utils/DateAndTime';

import { SelectProps } from '../interfaces';

const availableBookingDays = gql`
  query availableBookingDays(
    $start: Date!
    $end: Date!
    $areaId: ID
    $deskId: ID
  ) {
    availableBookingDays(
      start: $start
      end: $end
      areaId: $areaId
      deskId: $deskId
    ) {
      date
      desk
      area
      parking
      homeOffice
      travelDay
    }
  }
`;

export default function useValues({ onSelect, params }: SelectProps) {
  const [selectedSlots, setSelectedSlots] = useState([]);

  const date = useCurrentDate();

  const {
    workingDaysStart,
    workingDaysEnd,
    bookingRange,
  } = useMyOrganization();

  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const maxAvailableDate = getNextDayFromThis(getTodayAsDayJs(), bookingRange);
  const isDisabledToBook = useCallback(
    (date) => bookingRange > 0 && isAfter(date, maxAvailableDate),
    [bookingRange, maxAvailableDate],
  );

  const bookableDays = getNextDays({
    start: date,
    enabledWorkingDays,
  });

  const start = compose(
    (date: Date) => universalDateFormatter({ date, format: dateFormatShort }),
    head,
  )(bookableDays);

  const end = compose(
    (date: Date) => universalDateFormatter({ date, format: dateFormatShort }),
    last,
  )(bookableDays);

  const { data } = useErrorQuery(availableBookingDays, {
    variables: {
      start,
      end,
      ...params,
    },
    fetchPolicy: 'no-cache',
  });

  const setSlot = useCallback(
    (slot, update) => {
      let data = selectedSlots.filter(({ idx }) => slot.idx !== idx);

      if (update) {
        data = data.concat(slot);
      }
      setSelectedSlots(data);
    },
    [selectedSlots, setSelectedSlots],
  );

  useEffect(() => onSelect(selectedSlots), [onSelect, selectedSlots]);

  return {
    data: data?.availableBookingDays || [],
    bookableDays,
    isDisabledToBook,
    setSlot,
  };
}
