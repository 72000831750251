import { useCallback, useEffect } from 'react';
import {
  atom,
  selector,
  useRecoilState,
  DefaultValue,
  RecoilState,
  useSetRecoilState,
  useRecoilValue,
} from 'recoil';

import { PanChangeEvent } from '@views/shared/PanArea/interfaces';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { ZoomState } from './interfaces';
import { getAdjustedImageData, MOBILE_BROWSERS } from './utils';

const featureKey = 'FloorPlan/ZoomImage';

const zoomStateAtom = atom<ZoomState>({
  key: `${featureKey}/zoom-state`,
  default: {
    x: 0,
    y: 0,
    scale: 1,
    correctionX: 0,
    correctionY: 0,
    rotated: false,
  },
});

// Creates get / set selector for property of zoom state for easier interaction
function createPropertySelector<T extends keyof ZoomState>(
  prop: T,
): RecoilState<ZoomState[T]> {
  return selector<ZoomState[T]>({
    key: `${featureKey}/zoom-prop-${prop}`,
    get: ({ get }) => get(zoomStateAtom)[prop],
    set: ({ set, get }, val) => {
      const zoomState = get(zoomStateAtom);

      set(zoomStateAtom, {
        ...zoomState,
        [prop]: val instanceof DefaultValue ? 0 : val,
      });
    },
  });
}

export const xSelector = createPropertySelector('x');
export const ySelector = createPropertySelector('y');
export const scaleSelector = createPropertySelector('scale');
export const imageWidthSelector = createPropertySelector('imageWidth');
export const imageHeightSelector = createPropertySelector('imageHeight');
export const viewWidthSelector = createPropertySelector('viewWidth');
export const viewHeightSelector = createPropertySelector('viewHeight');
export const areaWidthSelector = createPropertySelector('areaWidth');
export const areaHeightSelector = createPropertySelector('areaHeight');
export const correctionXSelector = createPropertySelector('correctionX');
export const correctionYSelector = createPropertySelector('correctionY');
export const rotatedSelector = createPropertySelector('rotated');
export const imgUrlSelector = createPropertySelector('imgUrl');

// Responsible for initialization of image. Reads image dimensions and saves them for later use
export function useImageDim(imageUrl: string) {
  const [imageWidth, setImageWidth] = useRecoilState(imageWidthSelector);
  const [imageHeight, setImageHeight] = useRecoilState(imageHeightSelector);
  const [url, setUrl] = useRecoilState(imgUrlSelector);
  const [rotated, setRotated] = useRecoilState(rotatedSelector);
  const rotationEnabled = useHasFeature('floor_plan_rotation');

  useEffect(() => {
    getAdjustedImageData(imageUrl, rotationEnabled).then(
      ({ url, width, height, rotated }) => {
        setUrl(url);
        setImageWidth(width);
        setImageHeight(height);
        setRotated(rotated);
      },
    );
  }, [
    setImageWidth,
    setImageHeight,
    setUrl,
    setRotated,
    imageUrl,
    rotationEnabled,
  ]);

  return {
    imageWidth,
    imageHeight,
    url,
    rotated,
  };
}

export function usePan() {
  const [x, setX] = useRecoilState(xSelector);
  const [y, setY] = useRecoilState(ySelector);

  const onPan = useCallback(
    ({ x, y }: PanChangeEvent) => {
      setX(x);
      setY(y);
    },
    [setX, setY],
  );

  return {
    x,
    y,
    onPan,
  };
}

export function useReset() {
  const setX = useSetRecoilState(xSelector);
  const setY = useSetRecoilState(ySelector);
  const setScale = useSetRecoilState(scaleSelector);
  const setCorrectionX = useSetRecoilState(correctionXSelector);
  const setCorrectionY = useSetRecoilState(correctionYSelector);
  const viewWidth = useRecoilValue(viewWidthSelector) ?? 1;
  const viewHeight = useRecoilValue(viewHeightSelector) ?? 1;
  const imageWidth = useRecoilValue(imageWidthSelector) ?? 1;
  const imageHeight = useRecoilValue(imageHeightSelector) ?? 1;

  let isMobile = false;

  if (MOBILE_BROWSERS.test(navigator.userAgent)) {
    isMobile = true;
  }

  const correctionX = -imageWidth / 2 + viewWidth / (isMobile ? 2 : 4);
  const correctionY = -imageHeight / 2 + viewHeight / (isMobile ? 2.5 : 2);

  const reset = useCallback(() => {
    setX(0);
    setY(0);
    setCorrectionX(0);
    setCorrectionY(0);
    setScale(0.25);
  }, [
    setX,
    setY,
    setCorrectionX,
    setCorrectionY,
    setScale,
    correctionX,
    correctionY,
  ]);

  return reset;
}
