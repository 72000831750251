import { useEffect, useState } from 'react';

const getValue = (value, inMove) => value + inMove;

export function usePan(outsideX: number = 0, outsideY: number = 0) {
  const [x = 0, setX] = useState(outsideX);
  const [y = 0, setY] = useState(outsideY);
  const [inMoveX = 0, setInMoveX] = useState<number>(0);
  const [inMoveY = 0, setInMoveY] = useState<number>(0);

  useEffect(() => {
    setX(0);
    setY(0);
  }, []);

  return {
    x,
    y,
    inMoveX,
    inMoveY,
    setX,
    setY,
    setInMoveX,
    setInMoveY,
  };
}

interface getCoordinatesProp {
  scale: number;
  inMoveX: number;
  inMoveY: number;
  x: number;
  y: number;
}

export function getCoordinates({ inMoveX, inMoveY, x, y }: getCoordinatesProp) {
  const coordinateX = getValue(x, inMoveX);
  const coordinateY = getValue(y, inMoveY);

  return {
    coordinateX,
    coordinateY,
  };
}
