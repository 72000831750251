import { useState, useEffect } from 'react';

import { grey4 } from '@views/shared/colors';

export function useMaxWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.body.style.backgroundColor = grey4;

    const listener = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  if (width > 1400) {
    return '33%';
  }

  if (width > 1000) {
    return '50%';
  }

  if (width > 650) {
    return '70%';
  }

  return '100%';
}
