import { useCallback, useEffect, useState } from 'react';
import { isNil, isEmpty, pathOr } from 'ramda';
import { useNavigation } from '@react-navigation/native';

import { useErrorQuery } from '@providers/Errors';
import { getLocation } from '@views/shared/hooks/getLocation';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useMyOrganization } from '@providers/Organization';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { getLightSlots } from '@views/shared/BookingConfirmation';
import { getFullDaySlots } from '@views/shared/TimeSlots/helper';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { BlockedTimeSlot, TimeSlot } from '@views/shared/TimeSlots/interfaces';
import {
  BookingComponentBasicProps,
  CreateBookingGeneric,
  BookingDeskComponentProps,
  BookingParkingSpotComponentProps,
  BookingAreaComponentProps,
  BookingComponentProps,
  BookingConfirmationNavigationParams,
  QueryUnavailableTimePeriods,
} from '@views/shared/interfaces/booking';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VIRTUAL_DESK,
} from '@views/shared/consts';
import {
  getBookingUnavailableTimePeriodsForParkingSpots,
  getBookingUnavailableTimePeriodsForDesks,
  getBookingUnavailableTimePeriodsForHomeOffice,
  getBookingUnavailableTimePeriodsForFreeSeats,
  getBookingUnavailableTimePeriodsForMeetingRooms,
  getBookingUnavailableTimePeriodsForTravel,
} from '@views/Home/Book/BasicBook/Booking/unavailableTimePeriods';

export function useBookForHome(bookingInfos: BookingComponentProps) {
  return useBook(bookingInfos);
}

export function useBookForTravel(bookingInfos: BookingComponentProps) {
  return useBook(bookingInfos);
}

export function useBookForFreeSeats(bookingInfos: BookingAreaComponentProps) {
  return useBook(bookingInfos);
}

export function useBookForMeetingRooms(
  bookingInfos: BookingAreaComponentProps,
) {
  return useBook(bookingInfos);
}

export function useBookForParking(
  bookingInfos: BookingParkingSpotComponentProps,
) {
  return useBook(bookingInfos);
}

export function useBookForDesk(bookingInfos: BookingDeskComponentProps) {
  return useBook(bookingInfos);
}

export const queryUnavailableTimePeriodsMap = {
  [BOOKING_TYPE_HOME]: getBookingUnavailableTimePeriodsForHomeOffice,
  [BOOKING_TYPE_ROOM]: getBookingUnavailableTimePeriodsForDesks,
  [BOOKING_TYPE_MEETING_ROOM]: getBookingUnavailableTimePeriodsForMeetingRooms,
  [BOOKING_TYPE_FREE_SPACES_ROOM]: getBookingUnavailableTimePeriodsForFreeSeats,
  [BOOKING_TYPE_TRAVEL_DAY]: getBookingUnavailableTimePeriodsForTravel,
  [BOOKING_TYPE_PARKING_CHARGING]: getBookingUnavailableTimePeriodsForParkingSpots,
  [BOOKING_TYPE_PARKING_GENERAL]: getBookingUnavailableTimePeriodsForParkingSpots,
  [BOOKING_TYPE_PARKING_HANDICAPPED]: getBookingUnavailableTimePeriodsForParkingSpots,
  [BOOKING_TYPE_VIRTUAL_DESK]: getBookingUnavailableTimePeriodsForDesks,
};

const ERROR_AREA_LIMIT = 'Selected area reached the maximum number of bookings';
const ERROR_AREA_BLOCKED = 'Selected area is not bookable';
const ERROR_DESK_NOT_AVAILABLE = 'Selected desk is not available';
const ERROR_MEETING_ROOM_BLOCKED = 'Meeting room is locked for selected time';
const ERROR_MEETING_ROOM_NOT_AVAILABLE = 'Meeting room is not available';
const ERROR_HOME_OFFICE = 'One HomeOffice booking allowed at the same time';
const ERROR_TRAVEL_DAY = 'One TravelDay booking allowed at the same time';

export const getBookingErrorMessage = (message: string): string => {
  const errorMap = {
    [ERROR_AREA_LIMIT]: 'ErrorAreaLimit',
    [ERROR_AREA_BLOCKED]: 'ErrorAreaBlocked',
    [ERROR_HOME_OFFICE]: 'ErrorHomeOffice',
    [ERROR_MEETING_ROOM_BLOCKED]: 'ErrorMeetingRoom',
    [ERROR_MEETING_ROOM_NOT_AVAILABLE]: 'ErrorMeetingRoom',
    [ERROR_TRAVEL_DAY]: 'ErrorTravelDay',
    [ERROR_DESK_NOT_AVAILABLE]: 'ErrorDeskNotAvailable',
  };
  const errorMessage = errorMap[message] || 'GeneralError';
  return `Errors.Booking.${errorMessage}`;
};

function useBook({
  date,
  bookingMutation,
  params,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  t,
  type,
}: BookingComponentBasicProps) {
  // Internal State
  const [blockedTimeSlots, setBlockedTimeSlots] = useState<BlockedTimeSlot[]>(
    [],
  );
  const [selectedSlots, setSelectedSlots] = useState<TimeSlot[]>([]);
  const [errorBooking, setErrorBooking] = useState<string>();

  // Context Providers Dependencies
  const navigation = useNavigation();
  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const {
    refreshActiveBookings,
    getTimeSlotsActiveBookingByType,
  } = useMyActiveBookings();
  const {
    workingHoursStart,
    workingHoursEnd,
    midDayHour,
    meetingRoomHoursStart,
    meetingRoomHoursEnd,
  } = useMyOrganization();

  // Query & Mutation
  const [createBooking, { loading: loadingBooking }] = bookingMutation;

  const query = queryUnavailableTimePeriodsMap[type];

  const isFullDayBooking = !enabledHourlyBooking && !enabledHalfDayBooking;

  const {
    data,
    loading,
    refetch: refreshBookingTime,
    error,
  } = useErrorQuery<QueryUnavailableTimePeriods>(query, {
    fetchPolicy: 'no-cache',
    variables: {
      ...params,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: isFullDayBooking,
    finderError: {
      type: 'fatal',
      message: t('Home.Booking.TimeSlots.hooks.error'),
    },
  });

  const { dayShortFormat, timeFormatShort } = formats;

  useEffect(() => {
    if (isFullDayBooking) {
      setSelectedSlots([
        getFullDaySlots({ date, workingHoursStart, workingHoursEnd }),
      ]);
    }
  }, [date, isFullDayBooking, workingHoursEnd, workingHoursStart]);

  useEffect(() => {
    if (!isNil(data)) {
      const blockedSlots = getTimeSlotsActiveBookingByType(
        data.unavailableTimePeriods || [],
        type,
      );

      setBlockedTimeSlots(blockedSlots);
    }
  }, [data, getTimeSlotsActiveBookingByType, type]);

  const reset = useCallback(() => {
    setSelectedSlots([]);
    refreshActiveBookings();
    enabledHourlyBooking && refreshBookingTime(); //this is only for hourly booking
  }, [enabledHourlyBooking, refreshActiveBookings, refreshBookingTime]);

  const book = useCallback(() => {
    const slotsPromises = selectedSlots.map(({ startValue, endValue }) =>
      createBooking({
        variables: {
          ...params,
          start: startValue,
          end: endValue,
        },
      }),
    );

    if (!isEmpty(slotsPromises)) {
      Promise.all(slotsPromises)
        .then((bookings: CreateBookingGeneric[]) => {
          if (isNil(bookings) || isEmpty(bookings)) {
            navigation.navigate('index');
          }

          const booking = pathOr(
            undefined,
            [0, 'data', 'createBooking'],
            bookings,
          );

          const start = pathOr(undefined, ['start'], booking);
          const bookingId = pathOr(undefined, ['id'], booking);
          const time = getLightSlots(bookings, timeFormatShort);
          const location = booking
            ? getLocation({ booking, showDeskNumber, t })
            : '';

          const bookingOptions: BookingConfirmationNavigationParams = {
            ...params,
            bookingId,
            type,
            location,
            date: universalDateFormatter({
              date: start,
              format: dayShortFormat,
            }),
            time,
            enabledHalfDayBooking,
            enabledHourlyBooking,
            hasNextDaysBooking,
          };

          navigation.navigate('book-confirm', bookingOptions);
          reset();
        })
        .catch((e) => {
          reset();
          const error = enabledHourlyBooking
            ? 'Home.ActiveBooking.Booking.TimeSlots.failed'
            : getBookingErrorMessage(e.message);
          setErrorBooking(error);
          console.error('Book Error:', e);
        });
    }
  }, [
    createBooking,
    dayShortFormat,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    navigation,
    params,
    reset,
    selectedSlots,
    showDeskNumber,
    t,
    timeFormatShort,
    type,
  ]);

  const setSlots = useCallback((slots) => setSelectedSlots(slots), [
    setSelectedSlots,
  ]);

  const bookablePeriodDefault = {
    start: workingHoursStart,
    end: workingHoursEnd,
  };
  const bookablePeriodMeetingRoom = {
    start: meetingRoomHoursStart,
    end: meetingRoomHoursEnd,
  };

  return {
    bookablePeriod:
      type === BOOKING_TYPE_MEETING_ROOM
        ? bookablePeriodMeetingRoom
        : bookablePeriodDefault,
    blockedTimeSlots,
    book,
    error,
    errorBooking,
    loading,
    loadingBooking,
    midDayHour,
    selectedSlots,
    setSlots,
  };
}
