import React from 'react';
import { View, StyleSheet } from 'react-native';
import { StatusBar } from 'expo-status-bar';

import { TopBar } from '@views/TopBar';
import { Routes } from '@views/Routes';
import { Calendar } from '@views/Calendar';

import Errors from '@views/Errors';
import { useMyOrganization } from '@providers/Organization';

import { useMaxWidth } from './utils';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    margin: 'auto',
  },
});

export function Layout({ navigationRef }) {
  const maxWidth = useMaxWidth();
  const { backgroundColor } = useMyOrganization();

  return (
    <View style={[styles.container, { width: maxWidth, backgroundColor }]}>
      <StatusBar style="auto" />
      <TopBar />
      <Calendar navigationRef={navigationRef} />
      <Errors navigationRef={navigationRef} />
      <Routes />
    </View>
  );
}
