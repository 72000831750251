import React from 'react';

import BookWithArea from '@views/Home/Book/BookWithArea';

import useValues from './hooks';
import Booking from './Booking';

export default function BookMeetingRoom() {
  return <BookWithArea useValues={useValues} Component={Booking} />;
}
