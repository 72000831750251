import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, fonts, spacings } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { Empty } from '@views/Colleagues/Profile/Bookings/Day/Booking/empty';
import {
  getStartOfTheDay,
  getTodayAsDayJs,
  isAfter,
  isDayExpired,
  isToday,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { getBookingType } from '@views/shared/utils';

import {
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
} from '@views/shared/consts';

import Bookable from './Ticket/Bookable';
import Booking from './Ticket/Booking';

const bookableTypes = [
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
];
const { medium, small } = spacings;

export const styles = StyleSheet.create({
  container: {
    paddingVertical: medium,
  },
  date: {
    textAlign: 'left',
    marginBottom: small,
    fontFamily: 'mrt-semi-bold',
    fontSize: fonts.sizeDefault,
  },
  selected: {
    color: colors.aquaMarine,
  },
});

interface Props {
  bookings: BookingType[];
  date: Date;
  enabledHourlyBooking: boolean;
  refreshBookings: () => void;
}

export default function Day({
  bookings,
  date,
  enabledHourlyBooking,
  refreshBookings,
}: Props) {
  const {
    formats: { dayFormat },
  } = useIntl() as any;

  const bookingActive =
    isAfter(date, getStartOfTheDay(getTodayAsDayJs())) && bookings.length === 0;

  const [bookedTypes, setBookedTypes] = useState([]);

  useEffect(() => {
    if (bookings.length) {
      const types = bookings.map((booking) => getBookingType(booking));

      // @ts-ignore
      setBookedTypes(types);
    }
  }, [bookings]);

  const bookableTicket = (showBookMoreTitle = false) =>
    !isDayExpired(date) ? (
      <Bookable showBookMoreTitle={showBookMoreTitle} date={date} />
    ) : null;

  // 'book more' is available only for hourly bookings
  // and if not all these types: desk, home, travel are booked
  const canCreateMoreHourlyBookings =
    enabledHourlyBooking &&
    // @ts-ignore
    !bookableTypes.every((t) => bookedTypes.includes(t));

  const bookingsTickets = !bookings.length ? (
    <Empty />
  ) : (
    <View>
      {bookings.map((booking) => (
        <Booking
          key={booking.id}
          booking={booking}
          onCancel={refreshBookings}
        />
      ))}
      {canCreateMoreHourlyBookings && bookableTicket(true)}
    </View>
  );

  const selected = isToday(date);

  return (
    <View style={styles.container}>
      <Text style={[styles.date, selected && styles.selected]}>
        {universalDateFormatter({ date, format: dayFormat })}
      </Text>
      {bookingActive ? bookableTicket() : bookingsTickets}
    </View>
  );
}
