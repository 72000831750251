import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Home.ActiveBookings.Booking.floor_number': {
    id: 'Home.ActiveBookings.Booking.floor_number',
    defaultMessage: 'Floor {number}',
  },
  'Home.ActiveBookings.Booking.title.travelDay': {
    id: 'Home.ActiveBookings.Booking.title.travelDay',
    defaultMessage: 'Travel Day',
  },
  'Home.Book.bookFail': {
    id: 'Home.Book.bookFail',
    defaultMessage: 'Could not book',
  },
  'Home.Bookings.HomeOffice.title': {
    id: 'Home.Bookings.HomeOffice.title',
    defaultMessage: 'Home Office',
  },
  'Home.ActiveBookings.Booking.cancel': {
    id: 'Home.ActiveBookings.Booking.cancel',
    defaultMessage: 'Cancel',
  },
  'Home.ActiveBookings.Booking.check-in': {
    id: 'Home.ActiveBookings.Booking.check-in',
    defaultMessage: 'Check In',
  },
  'Home.ActiveBookings.Booking.check-out': {
    id: 'Home.ActiveBookings.Booking.check-out',
    defaultMessage: 'Check Out',
  },
  'Home.ActiveBookings.Booking.cancelFail': {
    id: 'Home.ActiveBookings.Booking.cancelFail',
    defaultMessage: 'Could not cancel booking',
  },
  'Home.ActiveBookings.Booking.checkInFail': {
    id: 'Home.ActiveBookings.Booking.checkInFail',
    defaultMessage: 'Could not check in booking',
  },
  'Home.ActiveBookings.Booking.checkOutFail': {
    id: 'Home.ActiveBookings.Booking.checkOutFail',
    defaultMessage: 'Could not check out booking',
  },
  'Home.ActiveBooking.Booking.TimeSlots.failed': {
    id: 'Home.ActiveBooking.Booking.TimeSlots.failed',
    defaultMessage: 'Not all selected slots are saved',
  },
  'Home.ActiveBooking.Booking.title': {
    id: 'Home.ActiveBooking.Booking.title',
    defaultMessage: 'Your booking was successful',
  },
  'Home.Book.book_again': {
    id: 'Home.Book.type.book_again',
    defaultMessage: 'Book again',
  },
  'Home.Book.Again': {
    id: 'Home.Book.Again',
    defaultMessage: 'Book again for the next few days?',
  },
  'Home.Book.ActiveBooking.Exists.Title': {
    id: 'Home.Book.ActiveBooking.Exists.Title',
    defaultMessage: 'The booking could not be made',
  },
  'Home.Book.ActiveBooking.Exists.Desk': {
    id: 'Home.Book.ActiveBooking.Exists.Desk',
    defaultMessage: 'Please note: only one desk can be booked per day',
  },
  'Home.Book.ActiveBooking.Exists.Area': {
    id: 'Home.Book.ActiveBooking.Exists.Area',
    defaultMessage: 'Please note: only one area can be booked per day',
  },
  'Home.Book.ActiveBooking.Exists.Parking': {
    id: 'Home.Book.ActiveBooking.Exists.Parking',
    defaultMessage: 'Please note: only one parking spot can be booked per day',
  },
  'Home.ActiveBooking.Booking.Confirm.title': {
    id: 'Home.ActiveBooking.Booking.Confirm.title',
    defaultMessage: 'Your booking for the next days was successful',
  },
  'views.Home.Bookings.Desks.hooks.desksDataFail': {
    id: 'views.Home.Bookings.Desks.hooks.desksDataFail',
    defaultMessage: 'Could not fetch desks data',
  },
  'views.Home.Bookings.MeetingRooms.total': {
    id: 'views.Home.Bookings.MeetingRooms.total',
    defaultMessage: '{total} rooms',
  },
  'views.Home.Bookings.MeetingRooms.title': {
    id: 'views.Home.Bookings.MeetingRooms.title',
    defaultMessage: 'Meeting rooms',
  },
  'views.Home.Bookings.Desks.hooks.meetingDataFail': {
    id: 'views.Home.Bookings.Desks.hooks.meetingDataFail',
    defaultMessage: 'Could not fetch meeting rooms data',
  },
  'views.Home.Bookings.Desks.free': {
    id: 'views.Home.Bookings.Desks.free',
    defaultMessage: 'Free seats',
  },
  'views.Home.Bookings.Areas.total': {
    id: 'views.Home.Bookings.Areas.total',
    defaultMessage: '{total} areas',
  },
  'views.Home.Bookings.Desks.total': {
    id: 'views.Home.Bookings.Desks.total',
    defaultMessage: '{totalDesks} seats',
  },
  'views.Home.Bookings.Desks.shared': {
    id: 'views.Home.Bookings.Desks.shared',
    defaultMessage: 'Shared Desks',
  },
  'Home.ActiveBooking.Verify.Booking.Title': {
    id: 'Home.ActiveBooking.Verify.Booking.Title',
    defaultMessage: 'Would you like to make this booking?',
  },
  'Home.ActiveBooking.Verify.FetchingFailed': {
    id: 'Home.ActiveBooking.Verify.FetchingFailed',
    defaultMessage: "Couldn't fetch the booking data",
  },
  'Home.ActiveBookings.Booking.Problem.title': {
    id: 'Home.ActiveBookings.Booking.Problem.title',
    defaultMessage: 'Report a problem',
  },
  'Home.ActiveBookings.Booking.Problem.located': {
    id: 'Home.ActiveBookings.Booking.Problem.located',
    defaultMessage: 'Where is the problem',
  },
  'Home.ActiveBookings.Booking.Problem.send': {
    id: 'Home.ActiveBookings.Booking.Problem.send',
    defaultMessage: 'Send',
  },
  'Home.ActiveBookings.Booking.Problem.details': {
    id: 'Home.ActiveBookings.Booking.Problem.details',
    defaultMessage: 'Please give us more details',
  },
  'Home.ActiveBookings.Booking.Problem.reportFail': {
    id: 'Home.ActiveBookings.Booking.Problem.reportFail',
    defaultMessage: 'Could not report a problem',
  },
  'Home.ActiveBookings.Booking.Problem.reportSend': {
    id: 'Home.ActiveBookings.Booking.Problem.reportSend',
    defaultMessage: 'The problem is reported!',
  },
  'Home.ActiveBookings.Booking.Problem.Subject': {
    id: 'Home.ActiveBookings.Booking.Problem.Subject',
    defaultMessage: 'What is the problem',
  },
  'Home.ActiveBookings.Booking.Problem.Subject.placeholder': {
    id: 'Home.ActiveBookings.Booking.Problem.Subject.placeholder',
    defaultMessage: 'Please select',
  },
  'Home.ActiveBookings.Booking.Problem.Subject.fetchFail': {
    id: 'Home.ActiveBookings.Booking.Problem.Subject.fetchFail',
    defaultMessage: 'Could not fetch problem subjects',
  },
  'Home.Book.ActiveBooking.Exists.Description': {
    id: 'Home.Book.ActiveBooking.Exists.Description',
    defaultMessage:
      'Please note: only one kind of this booking is allowed per day',
  },
  'Home.ActiveBookings.Booking.title.room': {
    id: 'Home.ActiveBookings.Booking.title.room',
    defaultMessage: 'My Shared Desk Booking',
  },
  'Home.ActiveBookings.Booking.title.meeting_room': {
    id: 'Home.ActiveBookings.Booking.title.meeting_room',
    defaultMessage: 'My Meeting Room Booking',
  },
  'Home.ActiveBookings.Booking.title.free_spaces_room': {
    id: 'Home.ActiveBookings.Booking.title.free_spaces_room',
    defaultMessage: 'My Free Seat Booking',
  },
  'Home.ActiveBookings.Booking.title.home': {
    id: 'Home.ActiveBookings.Booking.title.home',
    defaultMessage: 'My Home Booking',
  },
  'Home.ActiveBookings.Booking.title.parkingSpot': {
    id: 'Home.ActiveBookings.Booking.title.parkingSpot',
    defaultMessage: 'Parking spot',
  },
  'views.Bookings.title': {
    id: 'views.Bookings.title',
    defaultMessage: 'My Bookings',
  },
  'Bookings.Day.book': {
    id: 'Bookings.Day.book',
    defaultMessage: 'Book now!',
  },
  'Bookings.Day.book.more': {
    id: 'Bookings.Day.book.more',
    defaultMessage: 'Book more...',
  },
  'Bookings.hooks.getBookingsError': {
    id: 'Bookings.hooks.getBookingsError',
    defaultMessage: 'Failed to fetch bookings',
  },
  'Home.Book.Catering.Title': {
    id: 'Home.Book.Catering.Title',
    defaultMessage:
      'Would you like to order catering in addition to your booking?',
  },
  'Home.Book.Catering.Order.Notice': {
    id: 'Home.Book.Catering.Order.Notice',
    defaultMessage:
      'The catering order is saved. Please contact your manager for more information',
  },
  'Home.Book.Catering.Order.Title': {
    id: 'ome.Book.Catering.Order.Title',
    defaultMessage: 'Catering',
  },
  'Home.Book.Catering.Order.Error': {
    id: 'Home.Book.Catering.Order.Error',
    defaultMessage: 'An error happened. The catering is not ordered',
  },
  'Home.Book.Catering.Button.Book': {
    id: 'Home.Book.Catering.Button.Book',
    defaultMessage: 'Order catering',
  },
  'Home.Book.Catering.Order.Cancel.Error': {
    id: 'Home.Book.Catering.Order.Cancel.Error',
    defaultMessage: 'An error happened. The catering order is not canceled',
  },
  'Home.Book.Catering.Order.Cancel.Successful': {
    id: 'Home.Book.Catering.Order.Cancel.Successful',
    defaultMessage: 'The catering order has been successfully cancelled',
  },
  'Home.Book.GoHome': {
    id: 'Home.Book.GoHome',
    defaultMessage: 'Close and go Home',
  },
});

export const messages = dictionary;
