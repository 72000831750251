import { useCallback } from 'react';
import { gql } from '@apollo/client';
import { useIntl, defineMessages } from 'react-intl';
import { useNavigation } from '@react-navigation/native';

import { useErrorMutation } from '@providers/Errors';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_ROOM,
} from '@views/shared/consts';
import { useMyUser } from '@providers/User';

import { BadgeType, FavType } from './interface';

const i18nMessages = defineMessages({
  'Favourites.fetchError': {
    id: 'Favourites.fetchError',
    defaultMessage: 'Could not fetch favourites',
  },
  'Favourites.addError': {
    id: 'Favourites.addError',
    defaultMessage: 'Could not add favourite',
  },
  'Favourites.removeError': {
    id: 'Favourites.removeError',
    defaultMessage: 'Could not remove favourite',
  },
});

// TODO: Refactoring needed
export function useFavourites(type?: string) {
  const navigation = useNavigation();
  const {
    favouriteDesks,
    favouriteMeetingRooms,
    favouriteSeats,
    refetchFavourites,
  } = useMyUser();

  const goToFavourites = useCallback(() => {
    navigation.navigate('favourites', { type });
  }, [navigation, type]);

  const favCount =
    type === BOOKING_TYPE_ROOM
      ? favouriteDesks.length
      : type === BOOKING_TYPE_MEETING_ROOM
      ? favouriteMeetingRooms.length
      : favouriteSeats.length;

  return {
    favCount,
    favDesks: favouriteDesks,
    favSeats: favouriteSeats,
    favMeetingRooms: favouriteMeetingRooms,
    goToFavourites,
    refetch: refetchFavourites,
  };
}

const addFavouriteMutation = gql`
  mutation addFavourite($id: ID!, $type: FavouriteTypeEnum!) {
    addFavourite(favouriteId: $id, favouriteType: $type)
  }
`;

export function useAddFavourite(): (id: string, type: FavType) => Promise<any> {
  const intl = useIntl();
  const [add] = useErrorMutation<any, { id: string; type: FavType }>(
    addFavouriteMutation,
    {
      finderError: {
        type: 'minor',
        message: intl.formatMessage(i18nMessages['Favourites.addError']),
      },
    },
  );

  return useCallback(
    (id: string, type: FavType) => add({ variables: { id, type } }),
    [add],
  );
}

const removeFavouriteMutation = gql`
  mutation removeFavourite($id: ID!, $type: FavouriteTypeEnum!) {
    removeFavourite(favouriteId: $id, favouriteType: $type)
  }
`;

export function useRemoveFavourite(): (
  id: string,
  type: FavType,
) => Promise<any> {
  const intl = useIntl();
  const [remove] = useErrorMutation<any, { id: string; type: FavType }>(
    removeFavouriteMutation,
    {
      finderError: {
        type: 'minor',
        message: intl.formatMessage(i18nMessages['Favourites.removeError']),
      },
    },
  );

  return useCallback(
    (id: string, type: FavType) => remove({ variables: { id, type } }),
    [remove],
  );
}

export function useFavouriteBadge(id: string, type: BadgeType) {
  const removeFavourite = useRemoveFavourite();
  const addFavourite = useAddFavourite();
  const { favDesks, favMeetingRooms, favSeats, refetch } = useFavourites(type);

  const isSelected =
    type === BOOKING_TYPE_FREE_SPACES_ROOM
      ? favSeats.includes(id)
      : type === BOOKING_TYPE_MEETING_ROOM
      ? favMeetingRooms.includes(id)
      : favDesks.includes(id);

  const favType: FavType = type === 'room' ? 'desk' : 'area';
  const toggle = useCallback(async () => {
    if (isSelected) {
      await removeFavourite(id, favType);
    } else {
      await addFavourite(id, favType);
    }

    await refetch();
  }, [isSelected, removeFavourite, addFavourite, refetch, id, favType]);

  return {
    isSelected,
    toggle,
  };
}
