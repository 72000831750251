import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { SecondaryButton } from '@views/shared/Button';

import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Home/Book/MeetingRoom/Catering/styles';
import { OrderProps } from '@views/Home/Book/MeetingRoom/Catering/types';

import useCancelCatering from './hooks';

export default function Order({
  data,
  isExpired = false,
  showNotice = false,
}: OrderProps) {
  const { t } = useIntl();
  const { id: cateringId, items } = data;
  const { cancelCatering, isCanceled, isError, loading } = useCancelCatering();

  return (
    <View>
      {showNotice ? (
        <Text style={styles.title}>{t('Home.Book.Catering.Order.Notice')}</Text>
      ) : null}
      <View style={styles.headlineContainer}>
        <Text style={globalStyles.title}>
          {t('Home.Book.Catering.Order.Title')}
        </Text>
        {!isExpired ? (
          <SecondaryButton
            buttonStyle={[styles.cancelButton, globalStyles.cancelButton]}
            disabled={loading || isError || isCanceled}
            titleStyle={globalStyles.cancelButtonText}
            title={t('Home.ActiveBookings.Booking.cancel')}
            onPress={() => cancelCatering(cateringId)}
          />
        ) : null}
      </View>
      <View>
        {items.map(({ item, quantity }, index) => (
          <Text key={index} style={styles.orderItem}>
            {quantity} x {item.title}
          </Text>
        ))}
      </View>
      {isError ? (
        <Text style={[styles.error, styles.errorOrder]}>
          {t('Home.Book.Catering.Order.Cancel.Error')}
        </Text>
      ) : null}
      {isCanceled ? (
        <Text style={[styles.error, styles.errorOrder, styles.success]}>
          {t('Home.Book.Catering.Order.Cancel.Successful')}
        </Text>
      ) : null}
    </View>
  );
}
