import React from 'react';
import { View } from 'react-native';
import { compose, keys, pickBy, find } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';

import Slot from './Slot';
import useValues from './hooks';

import { AvailableDayBooking, WeekDaysProps } from '../interfaces';

// this can be expanded for other statuses of the booking
const BOOKING_STATUS = {
  FREE: 'FREE',
};

const getAvailableBookings = (day: string, bookings: any) =>
  compose(
    keys,
    pickBy((val) => val === BOOKING_STATUS.FREE),
    find(({ date }) => date === day),
  )(bookings) as AvailableDayBooking[];

export default function WeekDays({
  currentBookedType,
  onSelect,
  params = {},
}: WeekDaysProps) {
  const { data, bookableDays, isDisabledToBook, setSlot } = useValues({
    onSelect,
    params,
  });

  return (
    <View>
      {bookableDays.map((day, index) => {
        const date = universalDateFormatter({
          date: day,
          format: dateFormatShort,
        });
        const availableDayBookings = getAvailableBookings(date, data);

        return (
          <Slot
            availableDayBookings={availableDayBookings}
            currentBookedType={currentBookedType}
            date={date}
            disabledToBook={isDisabledToBook(date)}
            key={index}
            onSlotSelect={setSlot}
          />
        );
      })}
    </View>
  );
}
