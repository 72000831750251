import React, { ReactNode } from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';
import { BookingConfirmation } from '@views/shared/BookingConfirmation';
import { BookingType } from '@views/shared/interfaces/booking';

import { colors } from '@views/shared/styles';
import { styles } from '@views/shared/BookingConfirmation';

interface Props {
  type: BookingType;
  location: string;
  date: string;
  time?: string | ReactNode;
}

const XIcon = () => (
  <CustomIcon
    color={colors.warmPink}
    name="close"
    size={100}
    style={[styles.checkmark, { right: -25 }]}
  />
);

export default function ActiveBookingNotice({
  location,
  type,
  date,
  time,
}: Props) {
  const { t } = useIntl();

  return (
    <BookingConfirmation
      type={type}
      location={location}
      date={date}
      Icon={XIcon}
      time={time}
      title={t('Home.Book.ActiveBooking.Exists.Title')}
    >
      <View style={styles.card}>
        <Text style={styles.notice}>
          {t('Home.Book.ActiveBooking.Exists.Description')}
        </Text>
      </View>
    </BookingConfirmation>
  );
}
