import React from 'react';
import { IntlProvider } from 'react-intl';

const Provider = ({ locale, children, ...rest }: any) => {
  // wtf: import doesn't work for local env
  // TODO: check how to remove switch without crash the native app version
  switch (locale) {
    case 'de':
      require('./providers/de');
      break;
    case 'fr':
      require('./providers/fr');
      break;
    case 'it':
      require('./providers/it');
      break;
    case 'nl':
      require('./providers/nl');
      break;
    case 'ru':
      require('./providers/ru');
      break;
    default:
      require('./providers/en');
  }

  return (
    <IntlProvider locale={locale} {...rest}>
      {children}
    </IntlProvider>
  );
};

export default Provider;
