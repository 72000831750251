import React from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { useIntl, defineMessages } from 'react-intl';

import { MainButton, SecondaryButton } from '@views/shared/Button';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';

import { useHowWasYourDay } from './hooks';

const { aquaMarine, black } = colors;
const { medium, small } = spacings;

const i18nMessages = defineMessages({
  'Home.HowWasYourDay.title': {
    id: 'Home.HowWasYourDay.title',
    defaultMessage: 'How Was Your Office Day?',
  },
  'Home.ActiveBookings.Booking.cancel': {
    id: 'Home.ActiveBookings.Booking.cancel',
    defaultMessage: 'Cancel',
  },
  'Home.HowWasYourDay.vote': {
    id: 'Home.HowWasYourDay.vote',
    defaultMessage: 'Vote',
  },
  'Home.HowWasYourDay.info': {
    id: 'Home.HowWasYourDay.info',
    defaultMessage: 'Your data will be collected and processed anonymously.',
  },
});

const styles = StyleSheet.create({
  title: {
    fontSize: fonts.sizeBuilding,
    fontFamily: 'mrt-semi-bold',
    textAlign: 'center',
    marginBottom: small,
  },
  row: {
    flexDirection: 'row',
    marginVertical: small,
    justifyContent: 'center',
    alignContent: 'center',
  },
  info: {
    marginVertical: small,
    textAlign: 'center',
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeDefault,
  },
  icon: {
    marginHorizontal: small,
  },
  button: {
    marginRight: medium,
  },
});

function Smiley({ idx, selected, setValue }) {
  return (
    <CustomIcon
      style={styles.icon}
      name={`smiley-${idx}`}
      size={45}
      color={selected == idx ? aquaMarine : black}
      onPress={() => setValue(idx)}
    />
  );
}

export function HowWasYourDay() {
  const { setValue, value, vote, goBack } = useHowWasYourDay();
  const { formatMessage } = useIntl();

  return (
    <ScrollView style={globalStyles.container}>
      <Text style={styles.title}>
        {formatMessage(i18nMessages['Home.HowWasYourDay.title'])}
      </Text>
      <View style={styles.row}>
        {[1, 2, 3, 4, 5].map((idx) => (
          <Smiley idx={idx} key={idx} selected={value} setValue={setValue} />
        ))}
      </View>
      <Text style={styles.info}>
        {formatMessage(i18nMessages['Home.HowWasYourDay.info'])}
      </Text>
      <View style={styles.row}>
        <SecondaryButton
          title={formatMessage(
            i18nMessages['Home.ActiveBookings.Booking.cancel'],
          )}
          titleStyle={globalStyles.cancelButtonText}
          buttonStyle={[styles.button, globalStyles.cancelButton]}
          onPress={goBack}
        />
        <MainButton
          title={formatMessage(i18nMessages['Home.HowWasYourDay.vote'])}
          onPress={vote}
        />
      </View>
    </ScrollView>
  );
}
