import { atom, useRecoilState } from 'recoil';
import { gql } from '@apollo/client';
import { useIntl, defineMessages } from 'react-intl';

import {
  getWeekEnd,
  getWeekStart,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useCoreBookings } from '@views/Bookings/core.hooks';

const i18nMessages = defineMessages({
  'Colleagues.Content.Department.Collegue.Profile.Bookings.error': {
    id: 'Colleagues.Content.Department.Collegue.Profile.Bookings.error',
    defaultMessage: 'Could not fetch my collegue bookings',
  },
});

const featureKey = 'collegues/bookings';

const weekAtom = atom<Date | undefined>({
  key: `${featureKey}/week`,
  default: undefined,
});

const getBookings = gql`
  query getMyColleagueBookings($userId: ID!, $start: DateTime, $end: DateTime) {
    bookings: myColleagueBookings(userId: $userId, start: $start, end: $end) {
      id
      start
      end
      homeOffice
      travelDay
      status
      desk {
        label
        description
        id
        numberOnFloor
      }
      area {
        name
        areaType
        floor {
          floorNumber
          floorLabel
          floorType
          building {
            name
            address
          }
        }
      }
      parking {
        type: combinedType
        label
        numberOnFloor
      }
    }
  }
`;

interface Props {
  id: string;
}

export function useBookings({ id }: Props) {
  const intl = useIntl();
  const currentDate = useCurrentDate();
  const [_weekStart, setWeekStart] = useRecoilState(weekAtom);
  const weekStart = getWeekStart(currentDate, _weekStart);

  const {
    weekDays,
    mapping,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    onSwipe,
  } = useCoreBookings({
    query: getBookings,
    options: {
      variables: {
        start: universalDateFormatter({ date: weekStart }),
        end: universalDateFormatter({ date: getWeekEnd(weekStart) }),
        userId: id,
      },
      fetchPolicy: 'no-cache',
      finderError: {
        type: 'fatal',
        message: intl.formatMessage(
          i18nMessages[
            'Colleagues.Content.Department.Collegue.Profile.Bookings.error'
          ],
        ),
      },
    },
    weekStart,
    setWeekStart,
  });

  return {
    weekDays,
    mapping,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    onSwipe,
  };
}
