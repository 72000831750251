import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';
import { useMyBuildings } from '@providers/Buildings';
import { useCurrentDate } from '@views/Calendar/hooks';
import { getDeskSlots } from '@views/Home/Bookings/Desks/hooks';
import {
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_ROOM,
} from '@views/shared/consts';

import { BookCategory } from '@views/shared/interfaces/booking';
import { Floor } from '@views/shared/interfaces/buildingStructure';
import { ErrorQueryHookOptions } from '@providers/Errors/interfaces';
import { sortAreaByName } from '@views/shared/Booking';

import {
  DataForDesks,
  DataForFreeSeats,
  DataForMeetingRooms,
  DataForParkingSpots,
} from './interfaces';

const getStructureForDesks = gql`
  query getBuildingStructureForDesks($buildingId: ID!, $date: Date!) {
    building: buildingStructureForDesks(buildingId: $buildingId, date: $date) {
      id
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        desksAvailable
        freeSlots
        totalSlots
      }
      floors {
        id
        floorLabel
        floorNumber
        floorType
        numberOfAvailableDesksHourlyByAreaType {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
        }
      }
    }
  }
`;

const getStructureForMeetingRooms = gql`
  query getBuildingStructureForMeetingRooms($buildingId: ID!, $date: Date!) {
    building: buildingStructureForMeetingRooms(
      buildingId: $buildingId
      date: $date
    ) {
      id
      numberOfMeetingRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        meetingRoomTimeStats {
          booked
          total
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          meetingRoomTimeStats {
            booked
            total
          }
          equipment {
            category {
              name
            }
            name
          }
        }
      }
    }
  }
`;

const getStructureForFreeSeats = gql`
  query getBuildingStructureForFreeSeats($buildingId: ID!, $date: Date!) {
    building: buildingStructureForFreeSeats(
      buildingId: $buildingId
      date: $date
    ) {
      id
      numberOfFreeSpacesRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        numberOfAvailableDesksHourlyByAreaType {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
        }
      }
    }
  }
`;

const getStructureForParking = gql`
  query getBuildingStructureForParking($buildingId: ID!, $date: Date!) {
    building: buildingStructureForParkingSpots(
      buildingId: $buildingId
      date: $date
    ) {
      id
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas {
          areaType
          id
          image
          name
          isBookable
        }
      }
      numberOfAvailableParkings
      numberOfTotalParkings
    }
  }
`;

function processFloors(floors: Floor[] | undefined) {
  if (!floors) {
    return floors;
  }

  return floors
    .filter((floor) => floor.areas.length > 0)
    .map((floor) => ({ ...floor, areas: sortAreaByName(floor.areas) }))
    .sort((first, second) => first.floorNumber - second.floorNumber);
}

function useQueryOptions(buildingId): ErrorQueryHookOptions {
  const { t } = useIntl();
  const date = useCurrentDate();

  return {
    fetchPolicy: 'no-cache',
    variables: {
      buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: t('Home.Structure.hooks.error'),
    },
  };
}

function useStructureDataForDesks(queryResult) {
  const { data, ...rest } = queryResult;
  const floors = processFloors(data?.building?.floors);
  const count = getDeskSlots(data?.building, 'desksAvailable', 0);

  return {
    data,
    ...rest,
    floors,
    count,
  };
}

function useStructureDataForMeetingRooms(queryResult) {
  const { data, ...rest } = queryResult;
  const floors = processFloors(data?.building?.floors);
  const count = pathOr(0, ['building', 'numberOfMeetingRooms'], data);

  return {
    data,
    ...rest,
    floors,
    count,
  };
}

function useStructureDataForFreeSeats(queryResult) {
  const { data, ...rest } = queryResult;
  const floors = processFloors(data?.building?.floors);
  const count = pathOr(0, ['building', 'numberOfFreeSpacesRooms'], data);

  return {
    data,
    ...rest,
    floors,
    count,
  };
}

function useStructureDataForParkingSpots(queryResult) {
  const { data, ...rest } = queryResult;
  const floors = processFloors(data?.building?.floors);
  const count = pathOr(0, ['building', 'numberOfAvailableParkings'], data);

  return {
    data,
    ...rest,
    floors,
    count,
  };
}

function useStructureForDesks(buildingId) {
  const options = useQueryOptions(buildingId);
  const result = useErrorQuery<DataForDesks>(getStructureForDesks, options);

  return useStructureDataForDesks(result);
}

function useStructureForMeetingRooms(buildingId) {
  const options = useQueryOptions(buildingId);
  const result = useErrorQuery<DataForMeetingRooms>(
    getStructureForMeetingRooms,
    options,
  );

  return useStructureDataForMeetingRooms(result);
}

function useStructureForFreeSeats(buildingId) {
  const options = useQueryOptions(buildingId);
  const result = useErrorQuery<DataForFreeSeats>(
    getStructureForFreeSeats,
    options,
  );

  return useStructureDataForFreeSeats(result);
}

function useStructureForParking(buildingId) {
  const options = useQueryOptions(buildingId);
  const result = useErrorQuery<DataForParkingSpots>(
    getStructureForParking,
    options,
  );

  return useStructureDataForParkingSpots(result);
}

const useStructureByAreaType = {
  [BOOK_CATEGORY_ROOM]: useStructureForDesks,
  [BOOK_CATEGORY_MEETING_ROOM]: useStructureForMeetingRooms,
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: useStructureForFreeSeats,
  [BOOK_CATEGORY_PARKING]: useStructureForParking,
};

export function useStructure() {
  const navigation = useNavigation();
  const route = useRoute<any>();
  const type: BookCategory = route.params?.type ?? BOOK_CATEGORY_ROOM;
  const { selectedBuildingId: buildingId } = useMyBuildings();

  const { loading, error, floors, count } = useStructureByAreaType[type](
    buildingId,
  );

  useEffect(() => {
    if (!buildingId) {
      navigation.navigate('index');
    }
  }, [navigation, buildingId]);

  return {
    type,
    floors,
    loading,
    error,
    count,
  };
}
