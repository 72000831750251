import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useIntl, defineMessages } from 'react-intl';

import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';

const height = 'auto';
const { medium, extraSmall } = spacings;
const { blackAlpha } = colors;

const styles = StyleSheet.create({
  card: {
    marginBottom: medium,
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
  },
  row: {
    flexDirection: 'row',
  },
  right: {
    flex: 1,
    padding: medium,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
  },
});

const i18nMessages = defineMessages({
  'Colleagues.Day.no.book': {
    id: 'Colleagues.Day.no.book',
    defaultMessage: 'No Booking yet',
  },
});

export function Empty() {
  const intl = useIntl();

  return (
    <View style={[styles.card, globalStyles.shadow]}>
      <View style={[styles.row, { height }]}>
        <View style={styles.right}>
          <Text style={styles.text}>
            {intl.formatMessage(i18nMessages['Colleagues.Day.no.book'])}
          </Text>
        </View>
      </View>
    </View>
  );
}
