import React from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Loader } from '@views/shared/Loader';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { globalStyles, fonts, spacings } from '@views/shared/styles';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';

import Day from './Day';
import { useBookings } from './hooks';

const { small } = spacings;
const { sizeTitle } = fonts;

export const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: sizeTitle,
    flex: 1,
    fontFamily: 'mrt-regular',
  },
  header: {
    flexDirection: 'row',
    paddingVertical: small,
  },
});

export function Bookings() {
  const { t } = useIntl();
  const enabledHourlyBooking = useHasFeature('hourly_booking');

  const {
    mapping,
    weekDays,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    refreshBookings,
  } = useBookings();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ScrollView style={globalStyles.container}>
      <View style={styles.header}>
        <CustomIcon name="arrow-left" onPress={moveToPreviousWeek} />
        <Text style={styles.title}>{t('views.Bookings.title')}</Text>
        <CustomIcon name="arrow-right" onPress={moveToNextWeek} />
      </View>
      {weekDays.map((date) => {
        const key = universalDateFormatter({ date, format: dateFormatShort });
        const bookings = mapping.get(key) ?? [];

        return (
          <Day
            bookings={bookings}
            date={date}
            enabledHourlyBooking={enabledHourlyBooking}
            key={key}
            refreshBookings={refreshBookings}
          />
        );
      })}
    </ScrollView>
  );
}
