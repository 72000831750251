import React from 'react';
import { StyleSheet } from 'react-native';
import { useIntl, defineMessages } from 'react-intl';
import { Text } from 'react-native-elements';

import { spacings, colors } from '@views/shared/styles';
import { Card } from '@views/shared/Card';

const { blackAlpha, black } = colors;
const { extraSmall, medium, small } = spacings;

const i18nMessages = defineMessages({
  'Colleagues.error.no-data': {
    id: 'Colleagues.error.no-data',
    defaultMessage: 'No data available',
  },
});

const styles = StyleSheet.create({
  card: {
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
  },
  title_no_data: {
    flex: 1,
    alignContent: 'center',
    color: black,
    textAlign: 'center',
    padding: small,
    paddingVertical: medium,
  },
});

const NoInfoBox = ({ message }: { message: string }) => (
  <Card style={styles.card}>
    <Text style={styles.title_no_data}>{message}</Text>
  </Card>
);

export function NoStatusBox() {
  const { formatMessage } = useIntl();

  return (
    <NoInfoBox
      message={formatMessage(i18nMessages['Colleagues.error.no-data'])}
    />
  );
}
