import React from 'react';
import { View } from 'react-native';
import { isNil } from 'ramda';

import { Formats, useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';

import BasicBook from '@views/Home/Book/BasicBook';
import { BookAreaProps } from '@views/shared/interfaces/booking';
import { Equipment } from '@views/shared/Equipment';

export default function BookWithArea({
  useValues,
  Component,
}: {
  useValues: (formats: Formats, message: string) => any;
  Component: React.FC<BookAreaProps>;
}) {
  const { t, formats } = useIntl();
  const errorMessage = t('Home.ActiveBooking.Verify.FetchingFailed');

  const {
    areaId,
    currentDate,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    error,
    loading,
    time,
    data,
    type,
  } = useValues(formats, errorMessage);

  if (loading) {
    return <Loader />;
  }

  if (error || isNil(data)) {
    return <View>{errorMessage}</View>;
  }

  const { area } = data;

  function AreaPanel({ showAvailability }: { showAvailability? }) {
    return (
      <View>
        <Component
          type={type}
          areaId={areaId}
          date={currentDate}
          enabledHalfDayBooking={enabledHalfDayBooking}
          enabledHourlyBooking={enabledHourlyBooking}
          hasNextDaysBooking={hasNextDaysBooking}
          showAvailability={showAvailability}
        />
        {area?.equipment?.length ? (
          <Equipment equipment={area?.equipment} />
        ) : null}
      </View>
    );
  }

  return (
    <BasicBook
      booking={data}
      date={date}
      time={time}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
    >
      <AreaPanel />
    </BasicBook>
  );
}
