import React, { CSSProperties } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import ImageSlider from 'react-native-image-slider';

import { colors, spacings } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';

const { grey2, grey2Alpha } = colors;
const { extraSmall, small, smaller } = spacings;

interface SliderIem {
  image: string | { uri: string };
}

interface Props {
  hideDots?: boolean;
  items: SliderIem[];
  onChange?: (id: string) => void;
  style?: CSSProperties;
}

const styles = StyleSheet.create({
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    //iOS fix to prevent auto scale for static images
    width: undefined,
    height: undefined,
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: extraSmall,
  },
  icon: {
    marginHorizontal: smaller,
  },
});

const getHiddenStyles = (hide) =>
  StyleSheet.create({
    slider: { marginBottom: hide ? small : 0 },
    pagination: { display: hide ? 'none' : 'flex' },
  });

const IMAGE_ON_CHANGE_OFFSET = 24;

export const GallerySlider = ({ hideDots, items, onChange, style }: Props) => {
  const hiddenStyles = getHiddenStyles(hideDots);

  return (
    <ImageSlider
      style={[style, hiddenStyles.slider]}
      images={items}
      onPositionChanged={onChange}
      customSlide={({ index, item, style, width }) => {
        const rightOffset = index * IMAGE_ON_CHANGE_OFFSET;
        return (
          <View key={index} style={[style, { width, right: rightOffset }]}>
            <Image source={item.image} style={styles.image} />
          </View>
        );
      }}
      customButtons={(position, move) => (
        <View style={[styles.pagination, hiddenStyles.pagination]}>
          {items.map((item, index) => (
            <CustomIcon
              color={position === index ? grey2 : grey2Alpha}
              key={index}
              name="dot"
              onPress={() => move(index)}
              size={13}
              style={styles.icon}
            />
          ))}
        </View>
      )}
    />
  );
};
