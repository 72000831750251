import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableHighlight } from 'react-native';

import { universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { colors, fonts, spacings } from '@views/shared/styles';
import {
  TimeSlot,
  SlotUIStatus,
  Status,
} from '@views/shared/TimeSlots/interfaces';
import { STATUS, SLOT_STATUS } from '@views/shared/TimeSlots/helper';

interface Props {
  slot: TimeSlot;
  onSelectSlot: (slot: TimeSlot, selected: boolean) => void;
  selected: TimeSlot[];
  showAvailability: (slot?: TimeSlot) => any;
}

const { extraSmall } = spacings;
const {
  aquaMarine,
  black,
  darkBlue,
  grey2,
  grey2Alpha,
  grey5,
  paleOrange,
  warmPink,
  white,
  darkBlueHighDesaturated,
  paleOrangeHighDesaturated,
  darkBlueMediumDesaturated,
  paleOrangeMediumDesaturated,
  warmPinkHighDesaturated,
} = colors;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    alignItems: 'center',
    width: '47%',
    marginVertical: extraSmall,
    borderRadius: extraSmall,
  },
  text: {
    textAlign: 'center',
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
    padding: extraSmall,
  },
});

const mapBackgroundColor = {
  blocked: warmPinkHighDesaturated,
  booked: darkBlueHighDesaturated,
  bookedByColleague: paleOrangeHighDesaturated,
  free: white,
  expired: grey5,
  selected: aquaMarine,
};

const mapTextColor = {
  blocked: warmPink,
  booked: darkBlue,
  bookedByColleague: paleOrange,
  expired: grey2,
  free: black,
  selected: white,
};

const mapBorderColor = {
  blocked: warmPink,
  booked: darkBlueMediumDesaturated,
  bookedByColleague: paleOrangeMediumDesaturated,
  expired: grey5,
  free: grey2Alpha,
  selected: aquaMarine,
};

function mapSlotStatusToUI(status: Status, isSelected: boolean): SlotUIStatus {
  /**
   * const isFree = status === 'free';
   * const myStatus = isSelected && status !== 'booked' ? 'selected' : status;
   * TODO: This is the original condition, it doesn't make too much sense because
   *  if it's "selected" for sure it can not be booked due to we have enabled the click only for free status
   **/

  if (isSelected) {
    return SLOT_STATUS.SELECTED;
  }

  const mapStatus = {
    [STATUS.BLOCKED]: SLOT_STATUS.BLOCKED,
    [STATUS.BOOKED]: SLOT_STATUS.BOOKED,
    [STATUS.BOOKED_BY_COLLEAGUE]: SLOT_STATUS.BOOKED_BY_COLLEAGUE,
    [STATUS.EXPIRED]: SLOT_STATUS.EXPIRED,
    [STATUS.FREE]: SLOT_STATUS.FREE,
    [STATUS.OUTSIDE_BUILDING]: SLOT_STATUS.BOOKED,
  };

  return mapStatus[status];
}

export function Slot({
  slot,
  onSelectSlot,
  selected,
  showAvailability,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (selected.find((e) => slot.startIndex === e.startIndex)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selected, slot]);

  const { start, end, status } = slot;
  const {
    formats: { timeFormatShort },
  } = useIntl();

  const startTime = universalDateFormatter({
    date: start,
    format: timeFormatShort,
  });

  const endTime = universalDateFormatter({
    date: end,
    format: timeFormatShort,
  });

  const onPress = () => {
    setIsSelected((prevState) => {
      onSelectSlot(slot, prevState);
      return !prevState;
    });
  };

  const myStatus: SlotUIStatus = mapSlotStatusToUI(status, isSelected);
  const isDisabled = ![
    SLOT_STATUS.FREE,
    SLOT_STATUS.SELECTED,
    STATUS.BOOKED_BY_COLLEAGUE,
  ].includes(myStatus);

  const textStyle = { color: mapTextColor[myStatus] };
  const statusStyle = {
    backgroundColor: mapBackgroundColor[myStatus],
    borderColor: mapBorderColor[myStatus],
  };

  const onPressAction =
    myStatus === STATUS.BOOKED_BY_COLLEAGUE
      ? () => showAvailability(slot)
      : onPress;

  return (
    <View style={[styles.container, statusStyle]}>
      <TouchableHighlight
        disabled={isDisabled}
        onPress={onPressAction}
        style={{
          flex: 1,
        }}
        underlayColor="transparent"
      >
        <Text style={[styles.text, textStyle]}>
          {startTime} - {endTime}
        </Text>
      </TouchableHighlight>
    </View>
  );
}
