import { defineMessages } from 'react-intl';

import { messages as bookingMessages } from './Booking';
import { messages as structureMessages } from './Structure';

const dictionary = defineMessages({
  'Home.Booking.TimeSlots.hooks.error': {
    id: 'Home.Booking.TimeSlots.hooks.error',
    defaultMessage:
      'Could not fetch unavailable Time Periods for the selected resource',
  },
  'Home.MeetingRoom.book': {
    id: 'Home.MeetingRoom.book',
    defaultMessage: 'Book this room',
  },
});

export default {
  ...dictionary,
  ...bookingMessages,
  ...structureMessages,
};
