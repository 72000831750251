import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';

import { colors, fonts, spacings } from '@views/shared/styles';
import { ColleagueApiResponse } from '@views/Colleagues/Colleague/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';

const { white, grey4, darkBlue } = colors;
const { huge, medium, small } = spacings;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: medium,
    marginVertical: huge,
  },
  lightTitleStyle: {
    fontSize: fonts.sizeMedium,
    color: grey4,
  },
  viewSpacing: {
    marginHorizontal: small,
    backgroundColor: darkBlue,
    borderColor: darkBlue,
    borderWidth: 1,
    borderRadius: 50,
    padding: small,
  },
});

interface Props {
  id: string;
  colleague: ColleagueApiResponse;
}

export function Contacts({ colleague }: Props) {
  return (
    <View style={styles.container}>
      <View style={styles.viewSpacing}>
        <CustomIcon
          name="phone-inv"
          size={40}
          color={white}
          onPress={() => Linking.openURL('tel:' + colleague.phoneNumber)}
        />
      </View>
      <View style={styles.viewSpacing}>
        <CustomIcon
          name="mail-inv"
          size={40}
          color={white}
          onPress={() => Linking.openURL('mailto:' + colleague.email)}
        />
      </View>
    </View>
  );
}
