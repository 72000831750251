import { Image } from 'react-native';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_PARKING,
  AREA_TYPE_ROOM,
} from '@views/shared/consts';

import { AreaFloor } from '@views/shared/interfaces/floorplan';

export const MOBILE_BROWSERS = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const getBookablePoints = ({ isBookable, areaType }: AreaFloor) =>
  isBookable && [AREA_TYPE_ROOM, AREA_TYPE_PARKING].includes(areaType);

export const getBookableAreas = ({ isBookable, areaType }: AreaFloor) =>
  isBookable &&
  [AREA_TYPE_MEETING_ROOM, AREA_TYPE_FREE_SPACES_ROOM].includes(areaType);

async function rotateImage(imageUrl: string) {
  return imageUrl.replace('floor_plan', 'floor_plan_rotated');
}

function getImageDimensions(
  imageUrl: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    Image.getSize(
      imageUrl,
      (width, height) => {
        resolve({ width, height });
      },
      reject,
    );
  });
}

export async function getAdjustedImageData(
  imageUrl: string,
  rotationEnabled: boolean,
) {
  const { width, height } = await getImageDimensions(imageUrl);

  if (width > height && rotationEnabled) {
    const newUrl = await rotateImage(imageUrl);

    return {
      url: newUrl,
      width: height,
      height: width,
      rotated: true,
    };
  }

  return {
    url: imageUrl,
    width,
    height,
    rotated: false,
  };
}
