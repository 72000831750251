import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { useMyOrganization } from '@providers/Organization';
import {
  getBookableWorkingTime,
  universalDateFormatter,
} from '@utils/DateAndTime';
import {
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_FREE_SPACES_ROOM,
} from '@views/shared/consts';

import { bookTravelDayMutation } from '@views/Home/Book/Travel/Booking/hooks';
import { bookHomeOfficeMutation } from '@views/Home/Book/HomeOffice/Booking/hooks';
import { bookDeskMutation } from '@views/Home/Book/Desk/Booking/hooks';
import { bookFreeSpaceMutation } from '@views/Home/Book/FreeSpace/Booking/hooks';

import { getBookingErrorMessage } from '@views/Home/Book/BasicBook/Booking/hooks';

import { BookingParams } from './interfaces';

import {
  BasicBooking,
  BookingWithArea,
  BookingWithAreaAndDesk,
  CreateBookingAreaResponse,
  CreateBookingDeskResponse,
  CreateBookingResponse,
} from '@views/shared/interfaces/booking';

export default function useValues(params: BookingParams) {
  const navigation = useNavigation();
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [errorBooking, setErrorBooking] = useState<string>();
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const onSelect = useCallback((slots) => setSelectedSlots(slots), [
    setSelectedSlots,
  ]);

  const [createHomeOfficeBooking] = useMutation<
    { createBooking: CreateBookingResponse },
    BasicBooking
  >(bookHomeOfficeMutation);

  const [creatTravelDayBooking] = useMutation<
    { createBooking: CreateBookingResponse },
    BasicBooking
  >(bookTravelDayMutation);

  const [creatDeskBooking] = useMutation<
    { createBooking: CreateBookingDeskResponse },
    BookingWithAreaAndDesk
  >(bookDeskMutation);

  const [creatFreeSpaceBooking] = useMutation<
    { createBooking: CreateBookingAreaResponse },
    BookingWithArea
  >(bookFreeSpaceMutation);

  const mutationMap = {
    [BOOKING_TYPE_HOME]: createHomeOfficeBooking,
    [BOOKING_TYPE_TRAVEL_DAY]: creatTravelDayBooking,
    [BOOKING_TYPE_ROOM]: creatDeskBooking,
    [BOOKING_TYPE_FREE_SPACES_ROOM]: creatFreeSpaceBooking,
  };

  const goToConfirmPage = useCallback(() => {
    navigation.navigate('book-next-confirm');
  }, [navigation]);

  const book = useCallback(async () => {
    const slotsPromises = selectedSlots.map(({ date, type }) => {
      const { start, end } = getBookableWorkingTime(
        date,
        workingHoursStart,
        workingHoursEnd,
      );

      const variables = {
        start: universalDateFormatter({ date: start }),
        end: universalDateFormatter({ date: end }),
        ...params,
      };

      // @ts-ignore
      return mutationMap[type]({ variables });
    });

    if (slotsPromises.length) {
      try {
        await Promise.all(slotsPromises);
        goToConfirmPage();
      } catch (e) {
        const error = getBookingErrorMessage(e.message);
        setErrorBooking(error);
        console.error('bookAgain try catch', e);
      }
    }
  }, [
    goToConfirmPage,
    mutationMap,
    params,
    selectedSlots,
    workingHoursEnd,
    workingHoursStart,
  ]);

  return {
    book,
    errorBooking,
    onSelect,
    selectedSlots,
  };
}
