import React from 'react';
import { compose, head, groupBy, prop, sortBy, toLower } from 'ramda';
import { Text } from 'react-native-elements';
import { FlatList, ScrollView, View } from 'react-native';

import { Accordion } from '@views/shared/Accordion';
import { ColleagueListItem } from '@views/Colleagues/List/ColleagueListItem';
import {
  ColleagueApiResponse,
  GroupedColleaguesViewData,
  renderListItemProps,
} from '@views/Colleagues/Colleague/interfaces';
import { globalStyles } from '@views/shared/styles';
import { styles as accordionStyles } from '@views/Colleagues/List/Department';

interface Props {
  letter: string;
  colleagues: ColleagueApiResponse[];
  open: boolean;
}

const keyExtractor = (item, index) => index.toString();

const RenderDepartmentListItem = ({
  data: letter,
  open,
}: renderListItemProps) => {
  const { key, colleagues } = letter;

  return (
    <AlphabeticalListItem
      key={key}
      letter={key}
      colleagues={colleagues}
      open={open}
    />
  );
};

export function AlphabeticalView({
  colleagues,
  findMyColleagueEnabled,
}: GroupedColleaguesViewData) {
  const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));

  // @ts-ignore
  const groupByLetter = groupBy(compose(head, toLower, prop('name')));
  const alphabeticalSortedColleagues = sortByNameCaseInsensitive(colleagues);
  // @ts-ignore
  const groupedAlphabeticalSortedColleaguesSource = groupByLetter(
    alphabeticalSortedColleagues,
  );

  const decorateItem = (letter) => ({
    key: letter.toUpperCase(),
    colleagues: groupedAlphabeticalSortedColleaguesSource[letter],
  });

  const groupedAlphabeticalSortedColleagues = Object.keys(
    groupedAlphabeticalSortedColleaguesSource,
  ).map(decorateItem);

  const renderItem = ({ item: letter }) => (
    <RenderDepartmentListItem
      open={false}
      data={letter}
      findMyColleagueEnabled={findMyColleagueEnabled}
    />
  );

  return (
    <ScrollView style={globalStyles.container}>
      <FlatList
        keyExtractor={keyExtractor}
        data={groupedAlphabeticalSortedColleagues}
        renderItem={renderItem}
      />
    </ScrollView>
  );
}

function AlphabeticalListItem({ letter, colleagues, open }: Props) {
  const titleElm = (
    <View style={accordionStyles.titleContainer}>
      <Text style={accordionStyles.title}>{letter}</Text>
    </View>
  );

  return (
    <Accordion
      title={titleElm}
      open={open}
      containerStyle={accordionStyles.accordionContainer}
      headerStyle={accordionStyles.accordionHeader}
      childrenStyle={accordionStyles.accordionChildren}
    >
      {colleagues.map((colleague, index) => (
        <ColleagueListItem key={`${letter}-${index}`} colleague={colleague} />
      ))}
    </Accordion>
  );
}
