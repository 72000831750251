import React from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { Input as BaseInput } from 'react-native-elements';

import { useIntl } from '@utils/intl';
import { globalStyles, spacings } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';
import { Select } from '@views/shared/Select';
import { MainButton, SecondaryButton } from '@views/shared/Button';
import { ImageUpload } from '@views/shared/ImageUpload';

import { useEdit } from './hooks';
import { SelectedItem } from './SelectedItem';

const { medium, huge } = spacings;

export const styles = StyleSheet.create({
  actions: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: huge,
  },
  button: {
    paddingHorizontal: huge,
    marginHorizontal: medium,
  },

  avatarWrapper: {
    alignSelf: 'center',
  },
});

interface Props {
  label: string;
  onChange: (value: any) => void;
  value?: string;
}

function Input({ label, onChange, value }: Props) {
  const { t } = useIntl();
  return (
    <BaseInput
      label={t(label)}
      onChangeText={onChange}
      value={value}
      labelStyle={globalStyles.label}
      inputStyle={globalStyles.input}
      inputContainerStyle={{ borderBottomWidth: 0 }}
      containerStyle={{ paddingHorizontal: 0 }}
      errorStyle={{ margin: 0 }}
    />
  );
}

export function Edit() {
  const { t } = useIntl();
  const {
    me,
    loading,
    error,
    selectedDepartment,
    items,
    hasDepartmentDisabled,
    chooseDepartment,
    setName,
    setPhoneNumber,
    update,
    setAvatar,
    cancel,
    initials,
  } = useEdit();

  if (loading) {
    return <Loader />;
  }

  if (error || !me) {
    return <Text>GraphQL Error: edit profile</Text>;
  }

  return (
    <ScrollView style={globalStyles.container}>
      <View style={styles.avatarWrapper}>
        <ImageUpload
          title={initials}
          onError={() => setAvatar(undefined)}
          onFileChange={setAvatar}
          uri={me.avatar}
        />
      </View>
      <Input label={'Profile.Edit.name'} value={me.name} onChange={setName} />

      <Input
        label={'Profile.Edit.phone'}
        value={me.phoneNumber}
        onChange={setPhoneNumber}
      />
      {/*<Input
        label={'Profile.Edit.homeoffice'}
        value={me.userprofile?.homeofficeInfo}
        onChange={setHomeofficeInfo}
      />*/}
      {!hasDepartmentDisabled && (
        <Select
          value={selectedDepartment?.id ?? ''}
          items={items}
          onValueChange={chooseDepartment}
          placeholder="Select Department"
          selectedComponent={SelectedItem}
        />
      )}
      <View style={styles.actions}>
        <SecondaryButton
          title={t('Profile.Edit.cancel')}
          titleStyle={globalStyles.cancelButtonText}
          buttonStyle={[styles.button, globalStyles.cancelButton]}
          onPress={cancel}
        />
        <MainButton
          title={t('Profile.Edit.save')}
          buttonStyle={styles.button}
          onPress={update}
        />
      </View>
    </ScrollView>
  );
}
