import React, { useCallback } from 'react';

import { AreaWithStatus } from 'views/shared/interfaces/floorplan';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';
import { AvailabilityInfo } from 'views/shared/interfaces/floorplan';

import { Area } from './Area';
import { useArea } from './hooks';

interface ComponentProps {
  ids: string[];
  showAvailability: (info?: AvailabilityInfo) => any;
}

export default function Areas({ ids, showAvailability }: ComponentProps) {
  const makeABooking = useGoToBookingPage();
  const { areas, scale } = useArea(ids);

  const bookArea = useCallback(
    (area: AreaWithStatus) => {
      const { id: areaId, areaType: type } = area;

      makeABooking({ areaId, type });
    },
    [makeABooking],
  );

  return (
    <>
      {areas.map((area, index) => (
        <Area
          area={area}
          key={index}
          bookArea={bookArea}
          scale={scale}
          showAvailability={showAvailability}
        />
      ))}
    </>
  );
}
