import React, { ReactNode } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

import { spacings } from '@views/shared/styles';
const { huge, small } = spacings;

const styles = StyleSheet.create({
  card: {
    marginBottom: huge,
    marginTop: small,
  },
});

interface Props {
  children: ReactNode;
  style?: ViewStyle;
}

export function Card({ children, style }: Props) {
  return <View style={[styles.card, style]}>{children}</View>;
}
