import { mergeDeepRight, pickBy, omit, findIndex, propEq } from 'ramda';

import { FinderError } from '@providers/Errors/interfaces';

import {
  ActionErrorType,
  UIFinderError,
  mapErrorForFinderUIProps,
  uiErrorAdapterProps,
  errorNormalizerProps,
} from '@views/Errors/interfaces';

export const DEFAULT_TITLE = 'Errors.error_title';
export const DEFAULT_DESCRIPTION = 'Errors.description';

// Error message that comes from GraphQL server:
export const EXPIRED_TOKEN_MESSAGE = 'Signature has expired'; // When the token has been expired
export const LOGIN_REQUIRED = 'Login required'; // no reload of SPA but token expire
export const NO_PERMISSION_MESSAGE =
  'You do not have permission to see this content'; // no permission
export const NO_PERMISSION_ACTION_MESSAGE =
  'You do not have permission to perform this action'; // no permission
export const MAXIMUM_BOOKINGS_MESSAGE =
  'Selected area reached the maximum number of bookings';
export const DESK_NOT_AVAILABLE_MESSAGE = 'Selected desk is not available';
export const FIND_COLLEAGUE_DISABLED_MESSAGE =
  "Feature 'Find my colleague' is disabled";
export const USER_HAS_NO_PROFILE_MESSAGE = 'User has no userprofile.';
export const USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_MESSAGE =
  'have access to building object '; // partial on: "User (id: 115) doesn't have access to building object (id: 48) from this organization (id: 14)"

export const GENERIC_ERROR_KEY = 'generic';
export const EXPIRED_TOKEN_KEY = 'expiredToken';
export const NO_PERMISSION_KEY = 'noPermission';
export const MAXIMUM_BOOKINGS_KEY = 'maximumBookings';
export const DESK_NOT_AVAILABLE_KEY = 'deskNotAvailable';
export const FIND_COLLEAGUE_DISABLED_KEY = 'findColleagueDisabled';
export const USER_HAS_NO_PROFILE_KEY = 'noUserprofile';
export const USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY =
  'noAccessBuildingForOrganization';

// Actually the error tha comes from GraphQL just contains a message and sometimes should be not exactly how we expected
// so we check if the message contains one of those defined above
const getKey = (value: string) => {
  if (value.indexOf(EXPIRED_TOKEN_MESSAGE) > -1) {
    return EXPIRED_TOKEN_KEY;
  }

  if (value.indexOf(NO_PERMISSION_MESSAGE) > -1) {
    return NO_PERMISSION_KEY;
  }

  if (value.indexOf(MAXIMUM_BOOKINGS_MESSAGE) > -1) {
    return MAXIMUM_BOOKINGS_KEY;
  }

  if (value.indexOf(DESK_NOT_AVAILABLE_MESSAGE) > -1) {
    return DESK_NOT_AVAILABLE_KEY;
  }

  if (value.indexOf(LOGIN_REQUIRED) > -1) {
    return EXPIRED_TOKEN_KEY;
  }

  if (value.indexOf(USER_HAS_NO_PROFILE_MESSAGE) > -1) {
    return USER_HAS_NO_PROFILE_KEY;
  }

  if (value.indexOf(USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_MESSAGE) > -1) {
    return USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY;
  }

  return GENERIC_ERROR_KEY;
};

const genericError = {
  key: GENERIC_ERROR_KEY,
  dismissible: true,
  hasUserIteration: false,
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
};

const getGenericError = (action: ActionErrorType) => ({
  ...genericError,
  action,
  showed: false,
  index: 0,
});

const uiFinderErrors = {
  [GENERIC_ERROR_KEY]: getGenericError,
  [EXPIRED_TOKEN_KEY]: (action: ActionErrorType) => ({
    title: '',
    dismissible: false,
    hasUserIteration: false,
    key: EXPIRED_TOKEN_KEY,
    description: '',
    showed: false,
    action,
    index: 0,
  }),
  [NO_PERMISSION_KEY]: (action: ActionErrorType) => ({
    title: 'Errors.no.permission.title',
    dismissible: false,
    hasUserIteration: true,
    key: NO_PERMISSION_KEY,
    description: 'Errors.no.permission.description',
    showed: false,
    action,
    index: 0,
  }),
  [USER_HAS_NO_PROFILE_KEY]: (action: ActionErrorType) => ({
    title: 'Errors.no.userprofile.title',
    dismissible: false,
    hasUserIteration: true,
    key: USER_HAS_NO_PROFILE_KEY,
    description: 'Errors.no.userprofile.description',
    showed: false,
    action,
    index: 0,
  }),
  [MAXIMUM_BOOKINGS_KEY]: (action: ActionErrorType) => ({
    title: '',
    dismissible: true,
    hasUserIteration: false,
    key: MAXIMUM_BOOKINGS_KEY,
    description: MAXIMUM_BOOKINGS_MESSAGE,
    showed: false,
    action,
    index: 0,
  }),
  [DESK_NOT_AVAILABLE_KEY]: (action: ActionErrorType) => ({
    title: 'Errors.desk.title',
    dismissible: true,
    hasUserIteration: true,
    key: DESK_NOT_AVAILABLE_KEY,
    description: 'Errors.desk.description',
    showed: false,
    action,
    index: 0,
  }),
  [USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY]: (
    action: ActionErrorType,
  ) => ({
    title: 'Errors.no.orgBuildingAccess.title',
    dismissible: false,
    hasUserIteration: false,
    key: USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY,
    description: 'Errors.no.orgBuildingAccess.description',
    showed: false,
    action,
    index: 0,
  }),
};

export const uiErrorAdapter = ({ error, finderError }: uiErrorAdapterProps) => {
  if (finderError) {
    const description = error.message;
    const message = finderError.message;

    return {
      ...finderError,
      ...error,
      description,
      message,
    };
  }

  return {
    ...error,
  };
};

const errorPicker = (val: any, key: any) =>
  ['action'].includes(key) || (key === 'description' && val);

const errorNormalizer = ({
  errorKey,
  error,
  message,
  preserveMessage,
}: errorNormalizerProps) => {
  // This means that the error doesn't have any definition and to keep the data we put into the description
  if (errorKey === GENERIC_ERROR_KEY && !error.description) {
    return {
      ...error,
      description: message,
    };
  }

  if (!preserveMessage && errorKey !== GENERIC_ERROR_KEY) {
    return omit(['description'], error);
  }

  return error;
};

const getKeyFromMessage = getKey;
const getKeyFromDescription = getKey;

const getKeyFromDescriptionOrMessage = (error: FinderError) => {
  const { message, description = '' } = error;
  // try to use the description as first key detection due to the GraphQL errors will generate a FinderError which has the description as valuable key
  let key = getKeyFromDescription(description);
  let preserveMessage = false;
  if (key === GENERIC_ERROR_KEY) {
    key = getKeyFromMessage(message);
    preserveMessage = true;
  }

  return {
    key,
    preserveMessage,
  };
};

export const mapErrorForFinderUI = (
  errorToMap: mapErrorForFinderUIProps,
): UIFinderError => {
  if (!errorToMap || !errorToMap.actions) {
    throw new Error(
      '[mapErrorForFinderUI]: This function needs an error and the list of valid UI actions to handle errors',
    );
  }

  const { error, actions } = errorToMap;

  if (!error || !error.message) {
    return getGenericError(actions[GENERIC_ERROR_KEY]);
  }

  const { key: errorKey, preserveMessage } = getKeyFromDescriptionOrMessage(
    error,
  );
  const action = actions[errorKey] || actions[GENERIC_ERROR_KEY];
  const getBaseError = uiFinderErrors[errorKey] || getGenericError;
  const { message } = error;
  const baseError = getBaseError(action);
  const normalizedError = errorNormalizer({
    errorKey,
    error,
    message,
    preserveMessage,
  });
  const extendError = pickBy(errorPicker, normalizedError);

  // @ts-ignore
  return mergeDeepRight(baseError, extendError);
};

export const filterFatal = (error: UIFinderError) => !error.dismissible;

export const filterWithUserIteration = ({
  dismissible,
  hasUserIteration,
}: UIFinderError) => dismissible && hasUserIteration;

export const filterDismissible = ({
  dismissible,
  hasUserIteration,
}: UIFinderError) => dismissible && !hasUserIteration;

export const filterUnique = (
  { description }: UIFinderError,
  index: number,
  arr: any,
) => findIndex(propEq('description', description))(arr) === index;
