import React from 'react';
import { Alert, Platform, Text, View } from 'react-native';

import { ErrorAction, ActionErrorKey } from '@views/Errors/interfaces';

import {
  GENERIC_ERROR_KEY,
  EXPIRED_TOKEN_KEY,
  NO_PERMISSION_KEY,
  MAXIMUM_BOOKINGS_KEY,
  DESK_NOT_AVAILABLE_KEY,
  USER_HAS_NO_PROFILE_KEY,
  USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY,
} from './common';
import { Overlay } from '@views/shared/Overlay';
import { MainButton, SecondaryButton } from '@views/shared/Button';

const logoutAction = ({
  index,
  dismiss,
  navigationRef,
  logout,
}: ErrorAction) => {
  // if the result of the query is an error about the expired token it will be removed from the local storage and
  // the state cleared to enforce the app to trigger the login by oAuth0 provider
  (async () => {
    dismiss(index);
    await logout();
    navigationRef.current?.navigate('home');
  })();

  return null;
};

const fatalDefaultAction = ({
  index,
  t,
  dismiss,
  title = 'Errors.error_title',
  description = 'Errors.description',
  logout,
  navigationRef,
  modalStyle,
}: ErrorAction) => {
  if (Platform.OS === 'web') {
    return defaultNoDismissibleWebAction({
      index,
      dismiss,
      description,
      t,
      logout,
      navigationRef,
      modalStyle,
    });
  } else {
    Alert.alert(
      t(title),
      t(description),
      [
        {
          text: t('Errors.login.again'),
          onPress: () => {
            (async () => {
              dismiss(index);
              await logout();
              navigationRef.current?.navigate('home');
            })();
          },
        },
      ],
      { cancelable: false },
    );

    return null;
  }
};

const firstLoginAction = ({
  index,
  t,
  dismiss,
  title = 'Errors.error_title',
  description = 'Errors.description',
  navigationRef,
  modalStyle,
  logout,
}: ErrorAction) => {
  if (Platform.OS === 'web') {
    return defaultFirstLoginWebAction({
      index,
      dismiss,
      description,
      t,
      logout,
      navigationRef,
      modalStyle,
    });
  } else {
    Alert.alert(
      t(title),
      t(description),
      [
        {
          text: t('Errors.login.fresh'),
          onPress: () => {
            (async () => {
              dismiss(index);
              await logout();
              navigationRef.current?.navigate('home');
            })();
          },
        },
      ],
      { cancelable: false },
    );

    return null;
  }
};

const deskNotAvailable = ({
  index,
  t,
  dismiss,
  title = 'Errors.error_title',
  description = 'Errors.description',
  modalStyle,
}: ErrorAction) => {
  return (
    <Overlay
      animationType="fade"
      transparent={true}
      visible={true}
      isVisible={true}
      overlayStyle={modalStyle.modalView}
    >
      <>
        <Text style={modalStyle.title}>{t(title)}</Text>
        <View style={modalStyle.message} key={index}>
          <Text>{t(description)}</Text>
        </View>
        <MainButton
          title={t('Errors.desk.book.another')}
          onPress={() => dismiss(index)}
        />
      </>
    </Overlay>
  );
};

const defaultAction = ({
  index,
  t,
  dismiss,
  title,
  description,
  modalStyle,
}: ErrorAction) => {
  // TODO: remove when refactor with centralized translations is done
  const notTranslated = ['Errors.hook.defaultFatal', 'Errors.error_title'];

  return (
    <Overlay
      animationType="fade"
      transparent={true}
      visible={true}
      isVisible={true}
      overlayStyle={modalStyle.modalView}
    >
      <>
        <Text style={modalStyle.title}>
          {title && !notTranslated.includes(title)
            ? title
            : (title && t(title)) || t('Errors.error_title')}
        </Text>
        <View style={modalStyle.message} key={index}>
          <Text>{description}</Text>
        </View>
        <MainButton
          title={t('Errors.dismiss')}
          onPress={() => dismiss(index)}
        />
      </>
    </Overlay>
  );
};

const defaultNoDismissibleWebAction = ({
  index,
  t,
  dismiss,
  title,
  description = 'Errors.description',
  logout,
  modalStyle,
}: ErrorAction) => {
  return (
    <Overlay
      animationType="fade"
      transparent={true}
      visible={true}
      isVisible={true}
      overlayStyle={modalStyle.modalView}
    >
      <>
        <Text style={modalStyle.title}>
          {(title && t(title)) || t('Errors.error_title')}
        </Text>
        <View style={modalStyle.message} key={index}>
          <Text>{t(description)}</Text>
        </View>
        <View style={modalStyle.actions}>
          <MainButton
            style={modalStyle.buttonInline}
            title={t('Errors.login.again')}
            onPress={async () => {
              dismiss(index);
              await logout();
            }}
          />
          <SecondaryButton
            style={modalStyle.buttonInline}
            title={t('Errors.reload.page')}
            onPress={() => window.location.reload()}
          />
        </View>
      </>
    </Overlay>
  );
};

const defaultFirstLoginWebAction = ({
  index,
  t,
  dismiss,
  title,
  description = 'Errors.description',
  logout,
  modalStyle,
}: ErrorAction) => {
  return (
    <Overlay
      animationType="fade"
      transparent={true}
      visible={true}
      isVisible={true}
      overlayStyle={modalStyle.modalView}
    >
      <>
        <Text style={modalStyle.title}>
          {(title && t(title)) || t('Errors.error_title')}
        </Text>
        <View style={modalStyle.message} key={index}>
          <Text>{t(description)}</Text>
        </View>
        <MainButton
          title={t('Errors.login.fresh')}
          onPress={async () => {
            dismiss(index);
            await logout();
          }}
        />
      </>
    </Overlay>
  );
};

const definedErrorActions: ActionErrorKey = {
  [GENERIC_ERROR_KEY]: defaultAction,
  [EXPIRED_TOKEN_KEY]: logoutAction,
  [NO_PERMISSION_KEY]: fatalDefaultAction,
  [USER_HAS_NO_ORGANIZATION_BUILDING_ACCESS_KEY]: fatalDefaultAction,
  [MAXIMUM_BOOKINGS_KEY]: defaultAction,
  [DESK_NOT_AVAILABLE_KEY]: deskNotAvailable,
  [USER_HAS_NO_PROFILE_KEY]: firstLoginAction,
};

export default definedErrorActions;
