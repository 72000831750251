// Collection of common utilities
import Constants from 'expo-constants';

const { location } = window;
const {
  APP_ENV,
  GRAPHQL_URI,
  DEBUG_MOBILE_DEVICE_FROM_PROXY_MODE,
  GRAPHQL_UPLOAD_URI,
} = Constants.manifest.extra;

const graphQLURIByPlatform = {
  web: '/mitm/graphql',
  ios: GRAPHQL_URI,
  android: GRAPHQL_URI,
};

const graphQLUploadURIByPlatform = {
  web: '/graphql',
  ios: GRAPHQL_UPLOAD_URI,
  android: GRAPHQL_UPLOAD_URI,
};

export const isLocal = () =>
  location && location.host.indexOf('localhost') > -1;
export const isDevelop = () => APP_ENV === 'develop';

export const getGraphQLURI = (platform: string) =>
  isLocal() || DEBUG_MOBILE_DEVICE_FROM_PROXY_MODE
    ? GRAPHQL_URI
    : graphQLURIByPlatform[platform];

export const getGraphQLUploadURI = (platform: string) =>
  isLocal() || DEBUG_MOBILE_DEVICE_FROM_PROXY_MODE
    ? GRAPHQL_UPLOAD_URI
    : graphQLUploadURIByPlatform[platform];
