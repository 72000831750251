import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { loggedInAtom, accessTokenAtom } from './common';

export function useLoggedIn(): boolean {
  const loggedIn = useRecoilValue(loggedInAtom);

  return loggedIn;
}

export function useLogout() {
  const client = useApolloClient();
  const setLoggedIn = useSetRecoilState(loggedInAtom);
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const { logout: logoutFn } = useAuth0();

  const myAsyncOperations = async () => {
    return Promise.all([client.clearStore()]);
  };

  const logout = useCallback(() => {
    myAsyncOperations().then(() => {
      setAccessToken('');
      setLoggedIn(false);
      if (logoutFn) {
        logoutFn();
      }
    });
  }, [myAsyncOperations, setAccessToken, setLoggedIn, logoutFn]);

  return logout;
}
