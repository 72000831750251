import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useMyBuildings } from '@providers/Buildings';
import { useFavourites } from '@views/Favourites/hooks';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useGoToStructurePage } from '@views/shared/utils/useGoTo';
import { getDeskSlots } from '@views/Home/Bookings/Desks/hooks';
import { BookCategory } from '@views/shared/interfaces/booking';

const getTotalFreeSeats = gql`
  query getBuildingFreeSeats($id: ID!, $date: Date!) {
    building: buildingCountFreeSeats(buildingId: $id, date: $date) {
      numberOfFreeSpacesRooms
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        freeSlots
        totalSlots
      }
    }
  }
`;

interface DesksHourlyByType {
  areaType: string;
  freeSlots: number;
  totalSlots: number;
}

interface Data {
  building: {
    id: string;
    numberOfAvailableDesksHourlyByAreaType: DesksHourlyByType[];
    numberOfFreeSpacesRooms: number;
  };
}

export function useFreeSeats(type: BookCategory, errorMessage) {
  const date = useCurrentDate();
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const goToStructure = useGoToStructurePage();

  const { data, loading, error } = useErrorQuery<Data>(getTotalFreeSeats, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: errorMessage,
    },
  });

  const { favCount, goToFavourites } = useFavourites(type);

  const free = getDeskSlots(data?.building, 'freeSlots', 0, type);
  const total = getDeskSlots(data?.building, 'totalSlots', 1, type);

  const numberOfFreeSpacesRooms = pathOr(
    0,
    ['building', 'numberOfFreeSpacesRooms'],
    data,
  );

  return {
    numberOfFreeSpacesRooms,
    loading: !buildingId || loading,
    error,
    favCount,
    goToFavourites,
    goToStructure: () => goToStructure(type),
    worklightStatus: getWorklightStatus(free, total),
  };
}
