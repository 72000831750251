import React from 'react';
import { ScrollView } from 'react-native';

import { globalStyles } from '@views/shared/styles';
import { ColleagueLight } from '@views/Colleagues/Colleague/interfaces';
import { StatusBox } from '@views/Colleagues/Profile/Overview/StatusBox';
import { Contacts } from '@views/Colleagues/Profile/Overview/Contacts';
import { PersonalInfoBox } from '@views/Colleagues/Profile/Overview/PersonalInfoBox';
import { NoStatusBox } from './NoStatusBox';

interface Props {
  id: string;
  colleague: ColleagueLight;
  findMyColleagueEnabled: boolean;
}

export function Overview({ id, colleague, findMyColleagueEnabled }: Props) {
  return (
    <ScrollView style={globalStyles.container}>
      <PersonalInfoBox id={id} colleague={colleague} />
      {findMyColleagueEnabled ? (
        <StatusBox
          id={id}
          colleague={colleague}
          findMyColleagueEnabled={findMyColleagueEnabled}
        />
      ) : (
        <NoStatusBox />
      )}

      <Contacts id={id} colleague={colleague} />
    </ScrollView>
  );
}
