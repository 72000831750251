import React from 'react';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/BookingIcon';
import { BOOK_CATEGORY_PARKING, getBookingIcon } from '@views/shared/consts';
import { Loader } from '@views/shared/Loader';
import { Panel } from '@views/Home/Bookings/Panel';

import useParkingSpots from './hooks';

export default function ParkingsSpots() {
  const { t } = useIntl();
  const errorMessage = t('Home.Structure.Parkings.DataFailed');
  const {
    count,
    loading,
    error,
    goToStructure,
    worklightStatus,
  } = useParkingSpots(BOOK_CATEGORY_PARKING, errorMessage);

  if (error || count === 0) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Panel
      icon={
        <BookingIcon
          name={getBookingIcon(BOOK_CATEGORY_PARKING)}
          worklight={worklightStatus}
        />
      }
      name={t('Home.Structure.Parkings.Title')}
      onAction={goToStructure}
      text={t('Home.Structure.Parkings.Total', {
        total: count,
      })}
    />
  );
}
