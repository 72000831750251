import React, { ReactNode } from 'react';
import { PanGestureHandler } from 'react-native-gesture-handler';

import { useSwipeable } from './hooks';

interface Props {
  onSwipe: (direction: 'left' | 'right') => void;
  children: ReactNode;
}

export function Swipeable({ onSwipe, children }: Props) {
  const onGesture = useSwipeable(onSwipe);

  return (
    <PanGestureHandler minDist={50} onGestureEvent={onGesture}>
      {children}
    </PanGestureHandler>
  );
}
