import { useState, useCallback, UIEvent, useRef, useEffect } from 'react';

import { PanChangeListener } from '../interfaces';
import { usePan, getCoordinates } from './common';

interface Props {
  scale: number;
  listener?: PanChangeListener;
  outsideX?: number;
  outsideY?: number;
}

export function useWebPan({ scale, listener, outsideX, outsideY }: Props) {
  const isMountedVal = useRef(0);
  const { x, y, inMoveX, inMoveY, setX, setY, setInMoveX, setInMoveY } = usePan(
    outsideX,
    outsideY,
  );
  const [moveStartX = 0, setMoveStartX] = useState<number>(0);
  const [moveStartY = 0, setMoveStartY] = useState<number>(0);
  const [dragActive, setDragActive] = useState(false);

  const onTouchStart = useCallback(
    (event: UIEvent<HTMLDivElement, TouchEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.stopPropagation();

      const touches = event.nativeEvent.touches;

      if (touches.length > 1) {
        // Not drag so can be skipped or disabled
        return;
      }

      setDragActive(true);
      setMoveStartX(touches[0].clientX);
      setMoveStartY(touches[0].clientY);
    },
    [setMoveStartX, setMoveStartY, setDragActive],
  );

  const onMouseDown = useCallback(
    (event: UIEvent<HTMLDivElement, MouseEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.stopPropagation();

      setMoveStartX(event.nativeEvent.clientX);
      setMoveStartY(event.nativeEvent.clientY);
      setDragActive(true);
    },
    [setMoveStartX, setMoveStartY, setDragActive],
  );

  const onMove = useCallback(
    (clientX: number, clientY: number) => {
      const moveX = (clientX - moveStartX) / scale;
      const moveY = (clientY - moveStartY) / scale;

      setInMoveX(moveX);
      setInMoveY(moveY);

      listener?.({
        x: x + moveX,
        y: y + moveY,
      });
    },
    [scale, moveStartX, moveStartY, x, y, setInMoveX, setInMoveY],
  );

  const onTouchMove = useCallback(
    (event: UIEvent<HTMLDivElement, TouchEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.stopPropagation();

      const touches = event.nativeEvent.touches;

      if (touches.length > 1) {
        // Not drag so can be skipped
        return;
      }

      onMove(touches[0].clientX, touches[0].clientY);
    },
    [setInMoveX, setInMoveY, onMove],
  );

  const onMouseMove = useCallback(
    (event: UIEvent<HTMLDivElement, MouseEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.stopPropagation();

      if (!dragActive) {
        return;
      }

      onMove(event.nativeEvent.clientX, event.nativeEvent.clientY);
    },
    [dragActive, setInMoveX, setInMoveY, onMove],
  );

  const onEnd = useCallback(
    (event) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();
      event.stopPropagation();

      if (!dragActive) {
        return;
      }

      const { coordinateX, coordinateY } = getCoordinates({
        scale,
        x,
        y,
        inMoveX,
        inMoveY,
      });

      setX(coordinateX);
      setY(coordinateY);
      setInMoveX(0);
      setInMoveY(0);
      setDragActive(false);

      listener?.({
        x: coordinateX,
        y: coordinateY,
      });
    },
    [
      x,
      y,
      inMoveX,
      inMoveY,
      dragActive,
      setX,
      setY,
      setDragActive,
      setInMoveX,
      setInMoveY,
    ],
  );

  const resetPan = () => {
    setX(0);
    setY(0);
    setInMoveX(0);
    setInMoveY(0);
    setDragActive(false);

    listener?.({
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    isMountedVal.current = 1;
    // Pan Area loading and reset state...
    resetPan();

    return () => {
      // Pan Area Unmounting no reset state changes...
      isMountedVal.current = 0;
    };
  }, [isMountedVal]);

  return {
    onTouchStart,
    onTouchMove,
    onMouseDown,
    onMouseMove,
    onEnd,
  };
}
