import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';

import { useIntl, Translator } from '@utils/intl';
import { getFloorName, getDeskOrParkingLabel } from '@utils/Building';
import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { MainButton } from '@views/shared/Button';
import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Home/Favourites/styles';
import { AREA_TYPE_ROOM } from '@views/shared/consts';
import { Equipment, Touchable } from '@views/shared/Equipment';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';

import { FavouriteItem } from '@views/shared/interfaces/favourites';

interface Props {
  item: FavouriteItem;
  last: boolean;
}

const getLocationAndLabelByType = ({
  item,
  showDeskNumber,
  t,
}: {
  item: FavouriteItem;
  showDeskNumber: boolean;
  t: Translator;
}) => {
  const { floor, label: itemLabel, areaName, type } = item;
  let location = getFloorName({ ...floor, t });
  let label = itemLabel;

  if (type === AREA_TYPE_ROOM) {
    label = getDeskOrParkingLabel({
      point: item,
      t,
      showDeskNumber,
      showDeskTranslation: true,
    });

    location += `, ${areaName}`;
  }

  return { label, location };
};

export default function Item({ item, last }: Props) {
  const { t } = useIntl();
  const bookFavouriteItem = useGoToBookingPage();
  const showDeskNumber = useHasFeature('desk_number_on_floor');

  const [showEquipment, setShowEquipment] = useState(false);
  const toggleEquipment = useCallback(
    () => setShowEquipment((prevState) => !prevState),
    [setShowEquipment],
  );
  const book = useCallback(
    ({ areaId, id, type }) => {
      const params = { areaId, type };
      if (type === AREA_TYPE_ROOM) {
        params['deskId'] = id;
      }
      bookFavouriteItem(params);
    },
    [bookFavouriteItem],
  );

  const { location, label } = getLocationAndLabelByType({
    item,
    showDeskNumber,
    t,
  });

  const { available, equipment, id, type } = item;

  const buttonText = available
    ? 'Home.Favourites.book'
    : 'Home.Favourites.disabled';

  return (
    <View style={[styles.itemContainer, { borderBottomWidth: last ? 0 : 1 }]}>
      <View style={styles.item}>
        <View style={styles.left}>
          <View style={styles.badge}>
            <FavouriteBadge type={type} id={id} />
          </View>
          <View style={styles.flexContainer}>
            <Text style={globalStyles.title}>{label}</Text>
            <Text style={globalStyles.description}>{location}</Text>
          </View>
        </View>
        <MainButton
          title={t(buttonText)}
          onPress={() => book(item)}
          disabled={!available}
        />
        <Touchable
          hasEquipment={!!equipment.length}
          onPress={toggleEquipment}
          show={showEquipment}
        />
      </View>
      {showEquipment ? <Equipment equipment={equipment} /> : null}
    </View>
  );
}
