import React, { CSSProperties } from 'react';
import { Field } from 'formik';

import { useIntl } from '@utils/intl';
import { Select } from '@views/shared/Form';

import { useSubject } from './hooks';
export * from './interfaces';

interface Props {
  errorStyle?: CSSProperties;
}

export function Subject({ errorStyle }: Props) {
  const { t } = useIntl();
  const { items } = useSubject();

  return (
    <Field
      name="subject"
      label={t('Home.ActiveBookings.Booking.Problem.Subject')}
      component={Select}
      selectProps={{
        items,
        placeholder: t(
          'Home.ActiveBookings.Booking.Problem.Subject.placeholder',
        ),
        errorStyle,
      }}
    />
  );
}
