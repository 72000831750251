import React from 'react';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/BookingIcon';
import { BOOK_CATEGORY_TRAVEL, getBookingIcon } from '@views/shared/consts';
import { Panel } from '@views/Home/Bookings/Panel';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';

export default function TravelDay() {
  const { t } = useIntl();

  const makeABooking = useGoToBookingPage();

  return (
    <Panel
      icon={<BookingIcon name={getBookingIcon(BOOK_CATEGORY_TRAVEL)} />}
      name={t('Home.ActiveBookings.Booking.title.travelDay')}
      onAction={() => makeABooking({ type: BOOK_CATEGORY_TRAVEL })}
    />
  );
}
