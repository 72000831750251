import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Avatar, Text } from 'react-native-elements';

import {
  colors,
  spacings,
  avatarWithBadgeStyle,
  fonts,
} from '@views/shared/styles';
import { Card } from '@views/shared/Card';
import { ColleagueLight } from '@views/Colleagues/Colleague/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';

const { sizeDefault, sizeMedium } = fonts;
const { darkBlue, blackAlpha } = colors;
const { medium, small, extraSmall, oversize } = spacings;

export const styles = StyleSheet.create({
  card: {
    padding: small,
    borderColor: blackAlpha,
    borderWidth: 1,
    paddingVertical: medium,
    paddingHorizontal: medium,
    borderRadius: extraSmall,
    flexDirection: 'row',
    flex: 1,
  },
  userDataContainer: {
    flex: 1,
    marginLeft: oversize,
  },
  top: {
    flex: 1,
  },
  bottom: {},
  name: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeMedium,
  },
  department: {
    fontFamily: 'pts-regular',
    fontSize: sizeDefault,
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: extraSmall,
  },
  longtext: {
    // just a horrible fix to wrap the text
    flexGrow: 1,
    width: 0,
  },
  text: {
    fontFamily: 'pts-regular',
    fontSize: sizeDefault,
  },
  icon: {
    marginRight: extraSmall,
  },
});

interface Props {
  id: string;
  colleague: ColleagueLight;
}

export function PersonalInfoBox({ id, colleague }: Props) {
  const { initials, name, email, phoneNumber, department } = colleague;

  return (
    <Card style={styles.card} key={id}>
      {colleague.avatar && colleague.avatar.trim().length > 0 ? (
        <Avatar
          rounded
          size={100}
          source={{ uri: colleague.avatar }}
          title={initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
        />
      ) : (
        <Avatar
          rounded
          size={100}
          title={initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
          containerStyle={avatarWithBadgeStyle.avatarContainer}
        />
      )}
      <View style={styles.userDataContainer}>
        <View style={styles.top}>
          <Text style={styles.name}>{name}</Text>
          <Text style={styles.department}>{department?.name}</Text>
        </View>
        <View style={styles.bottom}>
          <View style={styles.info}>
            <CustomIcon
              name="mail"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <View style={styles.longtext}>
              <Text style={styles.text}>{email}</Text>
            </View>
          </View>
          <View style={styles.info}>
            <CustomIcon
              name="phone"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <Text style={styles.text}>{phoneNumber}</Text>
          </View>
        </View>
      </View>
    </Card>
  );
}
