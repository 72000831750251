import { curry, pathOr } from 'ramda';

import { Booking, BookingType } from '@views/shared/interfaces/booking';
import { AreaType } from '@views/shared/interfaces/buildingStructure';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_PARKING,
  AREA_TYPE_ROOM,
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VIRTUAL_DESK,
} from '@views/shared/consts';
import { BlockedTimeSlot } from '@views/shared/TimeSlots/interfaces';
import { createBlockedTimeSlot } from '@views/shared/TimeSlots/helper';
import { getBookingType } from '@views/shared/utils';
/**********************************************************************
 *            FULL DAY BOOKING TYPES COMBINATIONS CHECKS              *
 **********************************************************************/

const checkHome = ({ homeOffice }: Booking) => homeOffice;
const checkTravel = ({ travelDay }: Booking) => travelDay;
const checkByAreaType = (type: AreaType, { area }: Booking) =>
  type === area?.areaType;

const forbidDoubleAwayOrHome = (booking: Booking) =>
  checkHome(booking) || checkTravel(booking);

const forbidOutsideBuilding = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_MEETING_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_FREE_SPACES_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_PARKING, booking) ||
  forbidDoubleAwayOrHome(booking);

const forbidDoubleDesk = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_FREE_SPACES_ROOM, booking) ||
  forbidDoubleAwayOrHome(booking);

const forbidMeetingOutsideBuilding = (booking: Booking) =>
  forbidDoubleAwayOrHome(booking);

const forbidDoubleParking = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_PARKING, booking) ||
  forbidDoubleAwayOrHome(booking);

// This function is to curried so is possible pass one argument per time ( map, reduce, filter )
export const checkFullDayActiveBookings = curry(
  (type: BookingType, booking: Booking) => {
    const fullDayOverlap = {
      [BOOKING_TYPE_HOME]: () => forbidOutsideBuilding(booking),
      [BOOKING_TYPE_ROOM]: () => forbidDoubleDesk(booking),
      [BOOKING_TYPE_MEETING_ROOM]: () => forbidMeetingOutsideBuilding(booking),
      [BOOKING_TYPE_FREE_SPACES_ROOM]: () => forbidDoubleDesk(booking),
      [BOOKING_TYPE_TRAVEL_DAY]: () => forbidOutsideBuilding(booking),
      [BOOKING_TYPE_PARKING_CHARGING]: () => forbidDoubleParking(booking),
      [BOOKING_TYPE_PARKING_GENERAL]: () => forbidDoubleParking(booking),
      [BOOKING_TYPE_PARKING_HANDICAPPED]: () => forbidDoubleParking(booking),
      [BOOKING_TYPE_VIRTUAL_DESK]: () => forbidDoubleDesk(booking),
    };

    return fullDayOverlap[type]();
  },
);

/**********************************************************************
 *              HOURLY BOOKING TYPES COMBINATIONS CHECKS              *
 **********************************************************************/

const whitelistOr = (
  whitelist: AreaType[],
  areaType: AreaType | null,
  booking: Booking,
) =>
  areaType && whitelist.includes(areaType) && !forbidDoubleAwayOrHome(booking)
    ? null
    : booking;

const excludeComboForMeetingRooms = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_PARKING, AREA_TYPE_ROOM, AREA_TYPE_FREE_SPACES_ROOM],
    areaType,
    booking,
  );
};

const excludeComboForDesks = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_MEETING_ROOM, AREA_TYPE_PARKING],
    areaType,
    booking,
  );
};

const excludeComboForParkingSpot = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_MEETING_ROOM, AREA_TYPE_ROOM, AREA_TYPE_FREE_SPACES_ROOM],
    areaType,
    booking,
  );
};

const forbidBookingTypesCombo = (
  unavailableTimePeriods: BlockedTimeSlot[],
  booking: Booking,
): BlockedTimeSlot[] => {
  return [...unavailableTimePeriods, createBlockedTimeSlot(booking)];
};

const forbidOutsideBuildingSlot = curry(forbidBookingTypesCombo);

const forbidBookingTypesComboOrWhitelist = curry(
  (
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking | null,
  ): BlockedTimeSlot[] =>
    booking
      ? forbidBookingTypesCombo(unavailableTimePeriods, booking)
      : unavailableTimePeriods,
);

export const getActiveBookingsTimePeriods = curry(
  (
    type: BookingType,
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking,
  ): BlockedTimeSlot[] => {
    const getActiveBookingTimeSlots = forbidBookingTypesComboOrWhitelist(
      unavailableTimePeriods,
    );

    const getOutsideBuildingActiveBookingTimeSlots = forbidOutsideBuildingSlot(
      unavailableTimePeriods,
    );

    const fullDayOverlap = {
      [BOOKING_TYPE_HOME]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
      [BOOKING_TYPE_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
      [BOOKING_TYPE_MEETING_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForMeetingRooms(booking)),
      [BOOKING_TYPE_FREE_SPACES_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
      [BOOKING_TYPE_TRAVEL_DAY]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
      [BOOKING_TYPE_PARKING_CHARGING]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_PARKING_GENERAL]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_PARKING_HANDICAPPED]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_VIRTUAL_DESK]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
    };

    return fullDayOverlap[type]();
  },
);
