import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { defineMessages, useIntl } from 'react-intl';

import { Overlay } from '@views/shared/Overlay';
import { EventsList } from '@views/shared/Events';
import { spacings } from '@views/shared/styles';
import { useModalWidth } from '@views/shared/Overlay/hooks';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Loader } from '@views/shared/Loader';

import { ColleagueEventsProps } from './interfaces';
import { useOpenColleagueEvents, useColleagueEvents } from './hooks';

const { extraSmall, medium, smallMedium } = spacings;

const styles = StyleSheet.create({
  overlay: {
    borderRadius: extraSmall,
    paddingHorizontal: 0,
    paddingVertical: smallMedium,
  },
  no_event: {
    textAlign: 'center',
  },
  loader: {
    height: 70,
  },
  button: {
    position: 'absolute',
    right: medium,
  },
});

const i18nMessages = defineMessages({
  'Colleagues.Events.privacy_placeholder': {
    id: 'Colleagues.Events.privacy_placeholder',
    defaultMessage: 'Meeting',
  },
  'Colleagues.Events.no_events_today': {
    id: 'Colleagues.Events.no_events_today',
    defaultMessage: 'No events today',
  },
});

export function ColleagueEvents({ id }: ColleagueEventsProps) {
  const intl = useIntl();
  const modalWidth = useModalWidth();

  const { events, loading, error } = useColleagueEvents({
    id,
  });
  const { isOpen, close } = useOpenColleagueEvents();

  if (loading) {
    return <Loader containerStyle={styles.loader} />;
  }

  if (error) {
    return null;
  }

  return isOpen ? (
    <Overlay
      key={id}
      isVisible={isOpen}
      onBackdropPress={close}
      overlayStyle={[styles.overlay, { width: modalWidth }]}
    >
      <View>
        {events.length === 0 ? (
          <Text style={styles.no_event}>
            {intl.formatMessage(
              i18nMessages['Colleagues.Events.no_events_today'],
            )}
          </Text>
        ) : (
          <EventsList events={events} />
        )}
        <CustomIcon
          name="close"
          onPress={close}
          size={20}
          style={styles.button}
        />
      </View>
    </Overlay>
  ) : null;
}
