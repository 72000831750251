import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';
import { styles } from '@views/shared/Structure';

import Items from './Items';
import useParkingSpots from './hooks';
import { AreaStructureForParkingSpots } from '@views/shared/interfaces/buildingStructure';

interface Props {
  area: AreaStructureForParkingSpots;
}

export default function ParkingSpots({ area }: Props) {
  const { t } = useIntl();
  const { items, error, loading } = useParkingSpots(area);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Text>{t('Home.Structure.Floor.Area.Parkings.error')}</Text>;
  }

  return (
    <View style={styles.container}>
      <View>
        {!items.length ? (
          <Text style={styles.noDesks}>
            {t('Home.Structure.Floor.Area.Parkings.NoParkingSpots')}
          </Text>
        ) : (
          <Items area={area} items={items} />
        )}
      </View>
    </View>
  );
}
