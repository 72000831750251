import { useCallback } from 'react';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useMyOrganization } from '@providers/Organization';
import {
  getNextDays,
  getNextDayFromThis,
  getTodayAsDayJs,
  getWorkingDaysOfWeek,
  isAfter,
} from '@utils/DateAndTime';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
} from '@views/shared/consts';
import { useGoToBookingPage } from '@views/shared/utils/useGoTo';

import { useSetCurrentDate } from '@views/Calendar/hooks';
import { BookingProps } from '@views/Home/Book/NextDaysBooking/FullDayBooking/interfaces';

const homeAndTravelTypes = [BOOKING_TYPE_HOME, BOOKING_TYPE_TRAVEL_DAY];

const bookableTypes = {
  [BOOKING_TYPE_ROOM]: [BOOKING_TYPE_ROOM, ...homeAndTravelTypes],
  [BOOKING_TYPE_FREE_SPACES_ROOM]: [BOOKING_TYPE_FREE_SPACES_ROOM],
  [BOOKING_TYPE_HOME]: homeAndTravelTypes,
  [BOOKING_TYPE_TRAVEL_DAY]: homeAndTravelTypes,
  [BOOKING_TYPE_PARKING_CHARGING]: [
    BOOKING_TYPE_ROOM,
    BOOKING_TYPE_PARKING_CHARGING,
  ],
  [BOOKING_TYPE_PARKING_GENERAL]: [
    BOOKING_TYPE_ROOM,
    BOOKING_TYPE_PARKING_GENERAL,
  ],
  [BOOKING_TYPE_PARKING_HANDICAPPED]: [
    BOOKING_TYPE_ROOM,
    BOOKING_TYPE_PARKING_HANDICAPPED,
  ],
};

export default function useValues({ currentBookedType, params }: BookingProps) {
  const date = useCurrentDate();
  const setCurrentDate = useSetCurrentDate();
  const {
    workingDaysStart,
    workingDaysEnd,
    bookingRange,
  } = useMyOrganization();
  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const bookableDays = getNextDays({
    start: date,
    enabledWorkingDays,
    includeStartDate: true,
  });
  const goToBookingPage = useGoToBookingPage();

  const goToPage = useCallback(
    (date, type) => {
      setCurrentDate(date);

      goToBookingPage({
        type,
        ...params,
      });
    },
    [goToBookingPage, setCurrentDate, params],
  );

  const maxAvailableDate = getNextDayFromThis(getTodayAsDayJs(), bookingRange);
  const isDisabledToBook = useCallback(
    (date) => bookingRange > 0 && isAfter(date, maxAvailableDate),
    [bookingRange, maxAvailableDate],
  );

  return {
    goToPage,
    bookableDays,
    isDisabledToBook,
    types: bookableTypes[currentBookedType],
  };
}
