import React from 'react';

import { useIntl } from '@utils/intl';
import BasicBook from '@views/Home/Book/BasicBook';

import useValues from './hooks';
import Booking from './Booking';

export default function BookTravelDay() {
  const { formats } = useIntl();

  const {
    booking,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    time,
    currentDate,
    type,
  } = useValues(formats);

  return (
    <BasicBook
      booking={booking}
      date={date}
      time={time}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
    >
      <Booking
        type={type}
        date={currentDate}
        enabledHalfDayBooking={enabledHalfDayBooking}
        enabledHourlyBooking={enabledHourlyBooking}
        hasNextDaysBooking={hasNextDaysBooking}
      />
    </BasicBook>
  );
}
