import { colors, ThemeProvider } from 'react-native-elements';
import { fonts, colors as appColorsPalette } from '@views/shared/styles';
import React from 'react';

const { sizeDefault } = fonts;
const { black, paleOrange, warmPink, aquaMarine } = appColorsPalette;

const statusColors = {
  success: aquaMarine,
  error: warmPink,
  warning: paleOrange,
};

const statusColorsIos = {
  ios: {
    ...statusColors,
  },
  android: {
    ...statusColors,
  },
  web: {
    ...colors.platform.android, // this line is to prevent the undefined on colors definitions fot platform "web" and avoid the error un the build
    ...statusColors,
  },
};

const theme = {
  colors: {
    ...colors,
    ...statusColors,
    platform: {
      ...colors.platform,
      ...statusColorsIos,
    },
  },
  Text: {
    style: {
      fontFamily: 'pts-regular',
      fontSize: sizeDefault,
      color: black,
    },
  },
};

interface Prop {
  children: JSX.Element;
}

export default function Theme({ children }: Prop) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
