import { formDataAppendFile } from 'apollo-upload-client';

import {universalDateFormatter} from "@utils/DateAndTime";
import { b64toBlob } from './base64ToBlob';

function mapTypeToExtension(type: string) {
  return type.split('/')[1];
}

export function formAppendFile(formData: FormData, fieldName: string, file: any) {
  const date = universalDateFormatter({ format: 'YYYYMMDDHHmm' });

  if (file.uri && file.uri.includes('base64')) {
    const { blob, type } = b64toBlob(file.uri);
    const extension = mapTypeToExtension(type);

    return formData.append(fieldName, blob, `${date}.${extension}`);
  }

  if (file instanceof Blob) {
    return formData.append(fieldName, file, `${date}.jpg`);
  }

  return formDataAppendFile(formData, fieldName, file);
}
