import { uiErrorContextAdapterProps } from './interfaces';

export const finderErrorContextAdapter = ({
  error,
  finderError,
}: uiErrorContextAdapterProps) => {
  let adaptedError = {
    ...error,
  };

  if (finderError) {
    const description = error.message;
    const message = finderError.message;

    adaptedError = {
      ...finderError,
      ...error,
      description,
      message,
    };
  }

  return adaptedError;
};
