import React from 'react';
import { View } from 'react-native';

import { useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';
import { BookingIcon } from '@views/shared/BookingIcon';
import { Panel } from '@views/Home/Bookings/Panel';
import {
  BOOK_CATEGORY_MEETING_ROOM,
  getBookingIcon,
} from '@views/shared/consts';

import { useMeetingRooms } from './hooks';

export default function MeetingRooms() {
  const { t } = useIntl();
  const errorMessage = t('views.Home.Bookings.Desks.hooks.meetingDataFail');

  const {
    numberOfMeetingRooms,
    loading,
    error,
    favCount,
    goToFavourites,
    goToStructure,
    worklight,
  } = useMeetingRooms(BOOK_CATEGORY_MEETING_ROOM, errorMessage);

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <View>
      <Panel
        text={t('views.Home.Bookings.MeetingRooms.total', {
          total: numberOfMeetingRooms,
        })}
        favs={favCount}
        icon={
          <BookingIcon
            name={getBookingIcon(BOOK_CATEGORY_MEETING_ROOM)}
            worklight={worklight}
          />
        }
        name={t('views.Home.Bookings.MeetingRooms.title')}
        onFavsPress={goToFavourites}
        onAction={goToStructure}
      />
    </View>
  );
}
