/************* USE THIS FOR RUNNING TESTS ***************/
// import * as dayjs from 'dayjs';
// import * as utc from 'dayjs/plugin/utc';
// import * as localeData from 'dayjs/plugin/localeData';
/**************************-*****************************/
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  compose,
  difference,
  partial,
  partialRight,
  range,
  toLower,
  until,
} from 'ramda';

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

export interface TimeRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

export type EnabledWorkingDays = number[];

type Time = 'inherit' | 'startOfDay' | 'endOfDay';

export type MixDate = string | Date | dayjs.Dayjs;

interface TimeTransform {
  INHERIT: Time;
  START_OF_DAY: Time;
  END_OF_DAY: Time;
}

interface GetNextDaysProps {
  start: Date;
  enabledWorkingDays: EnabledWorkingDays;
  nextDays?: number;
  includeStartDate?: boolean;
}

export const TIME_TRANSFORM: TimeTransform = {
  INHERIT: 'inherit',
  START_OF_DAY: 'startOfDay',
  END_OF_DAY: 'endOfDay',
};

export const dateFormat = 'YYYY-MM-DDTHH:mm';
export const dateFormatAPI = 'YYYY-MM-DDTHH:mm:ssZ';
export const dateFormatShort = 'YYYY-MM-DD';

// To work in the scope of this file without acces the main object every time
const { INHERIT, START_OF_DAY, END_OF_DAY } = TIME_TRANSFORM;

const setTime = (
  date: dayjs.Dayjs,
  hours = 0,
  minutes = 0,
  seconds = 0,
): dayjs.Dayjs =>
  date.set('hours', hours).set('minutes', minutes).set('seconds', seconds);

const setWeekDay = (date: dayjs.Dayjs, days = 1): dayjs.Dayjs => date.day(days);

const setTimeByTransformationType = (date: dayjs.Dayjs, time: Time) => {
  const timeTransformations = {
    [INHERIT]: (date: dayjs.Dayjs): dayjs.Dayjs => date,
    [START_OF_DAY]: (date: dayjs.Dayjs): dayjs.Dayjs => setTime(date),
    [END_OF_DAY]: (date: dayjs.Dayjs): dayjs.Dayjs => setTime(date, 23, 59, 59),
  };

  return timeTransformations[time](date);
};

const weekDays = (dayjs.weekdays() || []).map(toLower);
const getToday = () => dayjs.utc();

// This function get the DayJs instance of the specified date. If no date is specified today will be returned.
const transformOr = (date?: MixDate): dayjs.Dayjs => {
  // avoid to reparse and do the UTC parsing if the date is a valid dayjs instance
  if (dayjs.isDayjs(date)) {
    return date;
  }

  // Check for the calendar date format YYYY-MM-DD and parse it with it
  if (
    typeof date === 'string' &&
    /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/gim.test(date)
  ) {
    return dayjs.utc(date, 'YYYY-MM-DD');
  }

  // All other string formats and JS Date will be parsed with UTC
  return dayjs.utc(date);
};

const toDate = (date: dayjs.Dayjs): Date => date.toDate();

export const getEventsOverZoneFormat = (date, format) => {
  return dayjs(date, 'YYYY-MM-DDTHH:mm').format(format);
};

// Extracts day as number from the GraphQL API string format
export const getNumberFromHours = (hours: string): number => {
  const regex = /([0-9])?([0-9]):[0-9]+:[0-9]+/gi;
  const groups = regex.exec(hours);

  if (groups) {
    const firstDigit = Number(groups[1]);
    const secondDigit = Number(groups[2]);

    return secondDigit > 0
      ? firstDigit === 0
        ? secondDigit
        : Number(`${firstDigit}${secondDigit}`)
      : Number(`${firstDigit}${secondDigit}`);
  }

  return -1;
};

export const getAllDay = (date: MixDate): TimeRange => {
  const localDate = transformOr(date);

  return {
    start: setTimeByTransformationType(localDate, START_OF_DAY),
    end: setTimeByTransformationType(localDate, END_OF_DAY),
  };
};

export const getBookableWorkingTime = (
  date: MixDate,
  start: string,
  end: string,
): TimeRange => {
  const localDate = transformOr(date);
  const startValue = getNumberFromHours(start);
  const endValue = getNumberFromHours(end);

  return {
    start: setTime(localDate, startValue, 0, 0),
    end: setTime(localDate, endValue, 0, 0),
  };
};

export const getTimeSlot = (
  date: MixDate,
  time: number,
  slotSize = 1, // by default 1 hour
): TimeRange => {
  const localDate = transformOr(date);

  return {
    start: setTime(localDate, time, 0, 0),
    end: setTime(localDate, time + slotSize, 0, 0),
  };
};

export const universalDateFormatter = ({
  date,
  format,
}: {
  date?: MixDate;
  format?: string;
}): string => transformOr(date).format(format || dateFormatAPI);

export const sortByDate = (a: MixDate, b: MixDate) =>
  transformOr(a).unix() - transformOr(b).unix();

export const getHour = (date?: MixDate): number => transformOr(date).hour();
export const getDay = (date?: MixDate): number => transformOr(date).day();
export const isSameDay = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isSame(transformOr(secondDate), 'day');
// This function returns true if firstDate is after secondDate
export const isAfter = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isAfter(transformOr(secondDate));
// This function returns true if firstDate is before secondDate
export const isBefore = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isBefore(transformOr(secondDate));

export const isToday = (date: MixDate): boolean =>
  getToday().isSame(transformOr(date), 'day');

export const isDayExpired = (date: MixDate): boolean =>
  isToday(date) ? false : getToday().isAfter(transformOr(date));

export const getTodayAsDate = (): Date => toDate(getToday());
export const getTodayAsDayJs = (): dayjs.Dayjs => getToday();

// Transforms the specified date into the Date object format
export const transformToDate = (date: MixDate, time = INHERIT): Date => {
  const transformByTime = partialRight(setTimeByTransformationType, [time]);

  return compose(toDate, transformByTime, (date) => transformOr(date))(date);
};

export const getNextDayFromThis = (date: MixDate, daysToAdd = 1) =>
  transformOr(date).add(daysToAdd, 'days');

export const getPreviousWeekFromThis = (date: MixDate, weeks = 1) =>
  transformOr(date).subtract(weeks, 'days');

export const getPreviousDayFromToday = (
  date: dayjs.Dayjs,
  daysToSubtract = 1,
) => date.subtract(daysToSubtract, 'days');

export const getWorkingDaysOfWeek = (
  workingDaysStart,
  workingDaysEnd,
): EnabledWorkingDays => {
  const days = weekDays.reduce(
    (acc, day, index) => ({
      ...acc,
      // TODO: add plugin https://day.js.org/docs/en/get-set/weekday to avoid this
      [day]: day === 'sunday' ? 7 : index,
    }),
    {},
  );

  // range generate list of number where from is inclusive and to is exclusive so our week range is 0-7
  const allWeek = range(0, 7);
  const workingDays = range(days[workingDaysStart], days[workingDaysEnd] + 1)
    // Normalize Sunday for range TODO: add plugin https://day.js.org/docs/en/get-set/weekday to avoid this
    .map((day) => (day === 7 ? 0 : day));

  const excludedDays = difference(allWeek, workingDays);

  return allWeek.filter((day) => !excludedDays.includes(day));
};

export const setFirstWeekDay = partialRight(setWeekDay, [0]);
export const setLastWeekDay = partialRight(setWeekDay, [6]);
export const getStartOfTheDay = partialRight(setTimeByTransformationType, [
  START_OF_DAY,
]);

export const getEndOfTheDay = partialRight(setTimeByTransformationType, [
  END_OF_DAY,
]);

export function getWeekStart(currentDate: Date, weekStart?: Date): Date {
  if (weekStart) {
    return weekStart;
  }

  return compose(toDate, getStartOfTheDay, setFirstWeekDay, (date) =>
    transformOr(date),
  )(currentDate);
}

export function getWeekEnd(weekStart: Date): Date {
  return compose(toDate, getEndOfTheDay, setLastWeekDay, (date) =>
    transformOr(date),
  )(weekStart);
}

// Returns the list of working week days based on the provided date
export const getWeekDays = (
  dayOfTheWeek: Date,
  enabledWorkingDays: EnabledWorkingDays,
): Date[] =>
  enabledWorkingDays.map((day: number) =>
    toDate(setWeekDay(transformOr(dayOfTheWeek), day)),
  );

export function* testRange(start, end, tick = 1) {
  for (let i = 1; i <= end; i += tick) {
    yield i;
  }
}

export const isWorkingDay = (
  enabledWorkingDays: EnabledWorkingDays,
  date: MixDate,
) => enabledWorkingDays.includes(getDay(date));

// Returns the list of next working week days based on the provided date and the number of desired days
export function getNextDays({
  start,
  enabledWorkingDays,
  nextDays = 5,
  includeStartDate = false,
}: GetNextDaysProps): Date[] {
  let current;
  const isEnabledWorkingDays = partial(isWorkingDay, [enabledWorkingDays]);
  const startValue = includeStartDate ? [start] : [];

  return until(
    (val: Date[]) => val.length === nextDays,
    (val: Date[]) => {
      current = getNextDayFromThis(current || start);

      if (isEnabledWorkingDays(current)) {
        return [...val, toDate(current)];
      }

      return val;
    },
  )(startValue);
}

// returns time with provided hours
export const timeFromHours = (hours: number) =>
  hours < 10 ? `0${hours}:00:00` : `${hours}:00:00`;
