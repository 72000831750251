import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { dateFormatShort } from '@utils/DateAndTime';
import { useFindMyColleague } from '@views/Colleagues/Profile/Overview/FindMyColleague/hooks';
import {
  ColleagueLight,
  UserStatus,
} from '@views/Colleagues/Colleague/interfaces';
import { Event } from '@views/shared/Events/interfaces';
import { useMyOrganization } from '@providers/Organization';

interface Props {
  id: string;
  colleague: ColleagueLight;
  findMyColleagueEnabled: boolean;
}

interface TemplateProps {
  timeFormat: string;
  currentEvent?: Event;
  previousEvent?: Event;
  status: UserStatus;
  organizationWorkingHours?: OrganizationWorkingHours;
}

interface OrganizationWorkingHours {
  workingHoursStart: Date | string;
  workingHoursEnd: Date | string;
}

const busyPresenceLabel = {
  ABSENT: 'Colleagues.Content.Find.status.absent',
  DISALLOWED: 'Colleagues.error.no-data', // TODO: there is a specific message to show in this case?
};

const busyPresenceLabelTpl = {
  ABSENT: {},
  DISALLOWED: {},
};

const busyStatusLabel = {
  error: 'Colleagues.Content.Find.status.busy',
  success: 'Colleagues.Content.Find.status.free',
  //'success': "Colleagues.Content.Find.status.free.all",
  warning: 'Colleagues.error.no-data',
};

// TODO find a way to replays dayjs, current usage of dayjs is using the local time.
// The dayjs in utils/DateAndTime is using the utc time and shows here a wrong date
const getTplData = ({
  timeFormat,
  currentEvent,
  previousEvent,
  status,
  organizationWorkingHours,
}: TemplateProps) => {
  if (currentEvent && status === 'OCCUPIED') {
    return {
      start: dayjs(currentEvent.start).format(timeFormat),
      end: dayjs(currentEvent.end).format(timeFormat),
    };
  }

  return {
    start: previousEvent
      ? dayjs(previousEvent.end).format(timeFormat)
      : dayjs().startOf('hour').format(timeFormat),
    end: currentEvent
      ? dayjs(currentEvent.start).format(timeFormat)
      : dayjs(
          `${dayjs().format(dateFormatShort)} ${
            organizationWorkingHours?.workingHoursEnd
          }`,
        ).format(timeFormat),
  };
};

export function useStatusBox({ id, colleague }: Props) {
  const intl = useIntl();
  const { formats } = intl;
  const { timeFormat } = formats as any;

  const {
    data,
    loading: loadingFindMyColleague,
    error: errorFindMyColleague,
  } = useFindMyColleague({ id });

  const { workingHoursStart = '', workingHoursEnd = '' } = useMyOrganization();
  const {
    icon,
    presenceStatus,
    currentEvent,
    previousEvent,
    badgeColor,
    status,
  } = colleague;

  const error = errorFindMyColleague;
  const loading = loadingFindMyColleague;

  const statusLabel =
    error || loading
      ? busyStatusLabel['DISALLOWED']
      : busyPresenceLabel[presenceStatus] || busyStatusLabel[badgeColor];

  const statusTmplValues =
    error || loading
      ? busyPresenceLabelTpl['DISALLOWED']
      : busyPresenceLabelTpl[presenceStatus] ||
        getTplData({
          timeFormat,
          currentEvent,
          status,
          previousEvent,
          organizationWorkingHours: {
            workingHoursStart,
            workingHoursEnd,
          },
        });

  const navigation = useNavigation();
  const bookSameRoom = useCallback(() => {
    navigation.navigate('home', {
      screen: 'structure',
      params: {
        type: 'room',
        areaId: data?.findMyColleague.area.id,
      },
    });
  }, [navigation, data]);

  return {
    loading,
    bookSameRoom,
    icon,
    status: badgeColor,
    error,
    data,
    presenceStatus,
    statusLabel,
    statusTmplValues,
  };
}
