import { gql } from '@apollo/client';
import { defineMessages, useIntl } from 'react-intl';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import { Data } from '@views/Colleagues/Colleague/interfaces';

const i18nMessages = defineMessages({
  'Colleagues.List.no_department': {
    id: 'Colleagues.List.no_department',
    defaultMessage: 'No Department assigned',
  },
  'Colleagues.List.error': {
    id: 'Colleagues.List.error',
    defaultMessage: 'Could not fetch collegues',
  },
});

const getColleagues = gql`
  query getColleagues($forDate: Date!) {
    colleagues(forDate: $forDate) {
      id
      email
      name
      initials
      avatar
      phoneNumber
      department {
        id
        name
      }
      visibleForColleagues
      visibleCalendar
      visibleHomeoffice
      presenceStatus
      homeofficeInfo
      status
      currentEvent {
        subject
        description
        start
        end
      }
      previousEvent {
        subject
        description
        start
        end
      }
    }
  }
`;

export function useColleagues() {
  const intl = useIntl();
  const date = useCurrentDate();

  const { data, loading, error } = useErrorQuery<Data>(getColleagues, {
    variables: {
      forDate: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: intl.formatMessage(i18nMessages['Colleagues.List.error']),
    },
  });

  return {
    data,
    loading,
    error,
  };
}
