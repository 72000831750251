import React, { ReactNode } from 'react';
import { View, StyleSheet, Text, TouchableHighlight } from 'react-native';

import { colors, spacings } from '@views/shared/styles';
import { FavouriteCount } from './FavouriteCount';
import { globalStyles } from '@views/shared/styles';

const { extraSmall, medium, small } = spacings;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: small,
    borderRadius: extraSmall,
    backgroundColor: colors.grey1,
    paddingHorizontal: medium,
    paddingVertical: small,
    alignItems: 'center',
  },
  middle: {
    flex: 1,
    alignSelf: 'center',
  },
  iconWrapper: {
    alignSelf: 'center',
    marginRight: small,
  },
});

interface Props {
  icon: ReactNode;
  name: string | ReactNode;
  text?: string;
  favs?: number;
  onFavsPress?: () => void;
  onAction?: () => void;
}

export function Panel({
  icon,
  name,
  text = '',
  favs,
  onFavsPress,
  onAction,
}: Props) {
  return (
    <View style={styles.container}>
      <View style={styles.iconWrapper}>{icon}</View>
      <View style={styles.middle}>
        <TouchableHighlight onPress={onAction} underlayColor="transparent">
          <View>
            <Text style={globalStyles.title}>{name}</Text>
            {text.length ? (
              <Text style={globalStyles.description}>{text}</Text>
            ) : null}
          </View>
        </TouchableHighlight>
      </View>
      {favs !== undefined && (
        <FavouriteCount count={favs} onPress={onFavsPress} />
      )}
    </View>
  );
}
