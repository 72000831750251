import React from 'react';
import { Icon } from 'react-native-elements';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { StackActions } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useIntl } from '@utils/intl';
import { Home } from '@views/Home';
import { Bookings } from '@views/Bookings';
import { FloorPlan } from '@views/Floorplan';
import { Colleagues } from '@views/Colleagues';
import { Login, useLoggedIn } from '@views/Login';
import { ProfileTab } from '@views/Profile';
import { colors, fonts } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Examples } from '@views/Examples';
import { isLocal } from '@app/client/common';

const Tab = createBottomTabNavigator();

const { aquaMarine, grey2 } = colors;

const getNaviIcon = ({ color, focused, name, size }) => {
  const icon = `navi-${name}${focused ? '-inv' : ''}`;
  return <CustomIcon color={color} name={icon} size={size} />;
};

export const resetStackOnTabPress = ({ navigation, route }) => ({
  tabPress: () =>
    route?.state?.key &&
    navigation.dispatch({
      ...StackActions.popToTop(),
      target: route?.state.key,
    }),
});

export function Routes() {
  const { t } = useIntl();
  const loggedIn = useLoggedIn();
  const insets = useSafeAreaInsets();

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: aquaMarine,
        inactiveTintColor: grey2,
        labelPosition: 'below-icon',
        style: {
          height: 65 + insets.bottom,
          borderTopColor: grey2,
        },
        labelStyle: {
          position: 'relative',
          top: -10,
          fontSize: fonts.sizeSmall,
        },
      }}
    >
      {loggedIn ? (
        <>
          <Tab.Screen
            name="home"
            component={Home}
            options={({ route: { name } }) => ({
              tabBarIcon: (props) => getNaviIcon({ ...props, name }),
              title: t('Routes.tabs.home'),
            })}
            listeners={resetStackOnTabPress}
          />
          <Tab.Screen
            name="bookings"
            component={Bookings}
            options={({ route: { name } }) => ({
              tabBarIcon: (props) => getNaviIcon({ ...props, name }),
              title: t('Routes.tabs.bookings'),
            })}
            listeners={resetStackOnTabPress}
          />
          <Tab.Screen
            name="floorplan"
            component={FloorPlan}
            options={({ route: { name } }) => ({
              tabBarIcon: (props) => getNaviIcon({ ...props, name }),
              title: t('Routes.tabs.floorplan'),
            })}
            listeners={resetStackOnTabPress}
          />
          <Tab.Screen
            name="colleagues"
            component={Colleagues}
            options={({ route: { name } }) => ({
              tabBarIcon: (props) => getNaviIcon({ ...props, name }),
              title: t('Routes.tabs.colleagues'),
            })}
            listeners={resetStackOnTabPress}
          />
          <Tab.Screen
            name="profile"
            component={ProfileTab}
            options={({ route: { name } }) => ({
              tabBarIcon: (props) => getNaviIcon({ ...props, name }),
              title: t('Routes.tabs.profile'),
            })}
            listeners={resetStackOnTabPress}
          />
          {isLocal() && (
            <Tab.Screen
              name="examples"
              component={Examples}
              options={() => ({
                tabBarIcon: ({ color, size }) => (
                  <Icon name="code" type="feather" size={size} color={color} />
                ),
                title: 'Style Guide',
              })}
            />
          )}
        </>
      ) : (
        <>
          <Tab.Screen
            name="login"
            component={Login}
            options={{
              tabBarVisible: false,
              title: t('Routes.tabs.login'),
            }}
          />
        </>
      )}
    </Tab.Navigator>
  );
}
