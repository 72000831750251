import { useState, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Booking } from '@views/shared/interfaces/booking';
import { useSubject } from './Subject/hooks';

const reportProblemMutation = gql`
  mutation reportProblem(
    $bookingId: ID!
    $subjectId: ID!
    $subjectText: String!
    $body: String!
  ) {
    addUserProblem(
      problem: {
        bookingId: $bookingId
        subjectId: $subjectId
        subjectText: $subjectText
        body: $body
      }
    ) {
      id
    }
  }
`;

const defaultSendState = { send: false, error: false };

export function useProblem(booking: Booking) {
  const { items } = useSubject();

  const [isOpen, setOpen] = useState(false);
  const [sendReport, setSendReport] = useState(defaultSendState);

  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => {
    setOpen(false);
    setSendReport(defaultSendState);
  }, [setOpen, setSendReport]);

  const [reportProblem, { loading }] = useMutation(reportProblemMutation);

  const submit = useCallback(
    async (values, resetForm) => {
      try {
        const item = items.find(({ value }) => value === values.subject);

        const { data } = await reportProblem({
          variables: {
            bookingId: booking.id,
            subjectId: item?.value,
            subjectText: item?.label,
            body: values.body,
          },
        });

        if (data) {
          resetForm({ values: { body: '' } });
          setSendReport({ send: true, error: false });
          setTimeout(() => setOpen(false), 2000);
        }
      } catch (e) {
        setSendReport({ send: false, error: true });
      }
    },
    [reportProblem, items, booking.id, setSendReport],
  );

  return {
    close,
    isOpen,
    loading,
    open,
    sendReport,
    submit,
  };
}
