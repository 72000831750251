import React from 'react';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { createUploadLink } from 'apollo-upload-client';
import { useAuth0 } from '@auth0/auth0-react';
import { useRecoilState } from 'recoil';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { loggedInAtom } from '@views/Login';

// @ts-ignore
import { getGraphQLURI, getGraphQLUploadURI } from './common';
import { formAppendFile } from './formAppendFile';

interface Prop {
  children: JSX.Element;
}

const {
  AUTH0_SCOPE,
  AUTH0_AUDIENCE,
  DEBUG_WITHOUT_OAUTH_LOGIN,
} = Constants.manifest.extra;

const uri = getGraphQLURI(Platform.OS);
const uriUpload = getGraphQLUploadURI(Platform.OS);

const httpLink = createHttpLink({
  uri,
  useGETForQueries: false,
});

const uploadLink: any = createUploadLink({
  uri,
  formDataAppendFile: formAppendFile,
} as any);

const httpLinkBackend = createHttpLink({
  uri: uriUpload,
  useGETForQueries: false,
});

const uploadLinkBackend: any = createUploadLink({
  uri: uriUpload,
  formDataAppendFile: formAppendFile,
} as any);

export const UploadClient = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    let token = DEBUG_WITHOUT_OAUTH_LOGIN;

    if (isAuthenticated && !DEBUG_WITHOUT_OAUTH_LOGIN) {
      token = await getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scopes: AUTH0_SCOPE.split(' '),
      });
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: from([authLink, uploadLinkBackend, httpLinkBackend]),
    cache: new InMemoryCache(),
  });
};

function ApolloWrapper({ children }: Prop): JSX.Element {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInAtom);

  const authLink = setContext(async (_, { headers }) => {
    let token = DEBUG_WITHOUT_OAUTH_LOGIN;

    if (isAuthenticated && !DEBUG_WITHOUT_OAUTH_LOGIN) {
      token = await getAccessTokenSilently({
        audience: AUTH0_AUDIENCE,
        scopes: AUTH0_SCOPE.split(' '),
      });
    }

    if (token && !loggedIn) {
      setLoggedIn(true);
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: from([authLink, uploadLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloWrapper;
