import React from 'react';
import { Text, StyleSheet, ScrollView, Platform } from 'react-native';
import { ListItem } from 'react-native-elements';

import { Overlay } from '@views/shared/Overlay';

import { useSelect } from './hooks';
import { SelectProps, ItemComponentProps } from './interfaces';
import { fonts } from '@views/shared/styles';
import { spacings } from '@views/shared/styles';

export * from './interfaces';

const styles = StyleSheet.create({
  item: {
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeDefault,
  },
  content: {
    width: '100%',
    height: '100%',
  },
  overlayStyle: {
    width: Platform.OS === 'web' ? 350 : '80%',
  },
  list: {
    padding: spacings.small,
  },
});

function DefaultItem({ item, onSelected, last }: ItemComponentProps) {
  if (!item) {
    return null;
  }

  return (
    <ListItem
      onPress={() => onSelected(item.value)}
      bottomDivider={!last}
      containerStyle={styles.list}
    >
      <ListItem.Content>
        <ListItem.Title style={styles.item}>{item.label}</ListItem.Title>
      </ListItem.Content>
    </ListItem>
  );
}

function DefaultSelected({ item, show, placeholder }: ItemComponentProps) {
  return (
    <Text style={styles.item} onPress={show}>
      {item?.label ?? placeholder}
    </Text>
  );
}

export function Select({
  items,
  value,
  onValueChange,
  itemComponent,
  selectedComponent,
  placeholder,
  errorStyle,
}: SelectProps) {
  const { shown, selectedItem, ...controls } = useSelect(items, value);
  const ItemComponent = itemComponent ?? DefaultItem;
  const SelectedComponent = selectedComponent ?? DefaultSelected;
  const { show, toggleShow } = controls;
  const itemHeight = 39;
  const height =
    items.length * itemHeight < 400 ? 20 + items.length * itemHeight : '70%';
  const overlayStyle =
    Platform.OS === 'web'
      ? styles.overlayStyle
      : [styles.overlayStyle, { height }];

  return (
    <>
      <SelectedComponent
        errorStyle={errorStyle}
        onSelected={show}
        item={selectedItem}
        placeholder={placeholder}
        {...controls}
      />
      <Overlay
        isVisible={shown}
        onBackdropPress={toggleShow}
        overlayStyle={overlayStyle}
      >
        <ScrollView style={styles.content}>
          {items.map((item, index) => (
            <ItemComponent
              key={item.value}
              item={item}
              last={index === items.length - 1}
              {...controls}
              onSelected={(value) => {
                toggleShow();
                onValueChange(value);
              }}
            />
          ))}
        </ScrollView>
      </Overlay>
    </>
  );
}
