import React, { useCallback, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { isEmpty, isNil } from 'ramda';

import { useMyOrganization } from '@providers/Organization';
import { useIntl } from '@utils/intl';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { SecondaryButton } from '@views/shared/Button';
import { CustomIcon } from '@views/shared/CustomIcon';
import { getLocation } from '@views/shared/hooks/getLocation';
import { getBookingIcon } from '@views/shared/consts';
import { default as CateringOrder } from '@views/Home/Book/MeetingRoom/Catering/Order';

import { colors, spacings, globalStyles } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';

import { useBooking } from './hooks';

const height = 'auto';

const { extraSmall, medium, small } = spacings;
const { blackAlpha, warmPink } = colors;

export const styles = StyleSheet.create({
  card: {
    marginBottom: medium,
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: medium,
  },
  row: {
    flexDirection: 'row',
  },
  center: {
    flex: 1,
    paddingLeft: medium,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  between: {
    justifyContent: 'space-between',
  },
  left: {
    justifyContent: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: medium,
  },
  button: {
    borderColor: warmPink,
    paddingHorizontal: 0,
    paddingVertical: 0,
    padding: 0,
  },
  buttonText: {
    color: warmPink,
  },
  cateringIcon: {
    marginTop: -4,
    paddingLeft: medium,
    display: 'flex',
    alignSelf: 'center',
  },
  cateringContainer: {
    marginTop: small,
    paddingTop: medium,
    borderColor: blackAlpha,
    borderTopWidth: 1,
  },
});

interface Props {
  booking: BookingType;
  onCancel: any;
}

export default function Booking({ booking, onCancel }: Props) {
  const [showCatering, setShowCatering] = useState(false);

  const toggleCatering = useCallback(
    () => setShowCatering((prevState) => !prevState),
    [setShowCatering],
  );

  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const { workingHoursStart, workingHoursEnd } = useMyOrganization();
  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const { cancel, end, isCancelable, isExpired, start, type } = useBooking(
    booking,
    onCancel,
  );

  const location = getLocation({
    booking,
    t,
    showDeskNumber,
  });

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  const { catering } = booking;

  const hasCatering = !isNil(catering) && !isEmpty(catering);

  return (
    <View style={[styles.card, globalStyles.shadow]}>
      <View style={[styles.row, { height }]}>
        <View style={[styles.left, styles.between, styles.icon]}>
          <CustomIcon name={`${getBookingIcon(type)}-no`} size={30} />
        </View>
        <View style={[styles.center, styles.between]}>
          <View>
            <Text style={globalStyles.title}>{location}</Text>
            <Text style={globalStyles.description}>{time}</Text>
          </View>
        </View>
        {hasCatering ? (
          <CustomIcon
            style={styles.cateringIcon}
            name="coffee"
            onPress={toggleCatering}
            size={22}
          />
        ) : null}
        {isCancelable && !isExpired ? (
          <View style={[styles.row, styles.buttonContainer]}>
            <SecondaryButton
              buttonStyle={[styles.button, globalStyles.cancelButton]}
              titleStyle={globalStyles.cancelButtonText}
              title={t('Home.ActiveBookings.Booking.cancel')}
              onPress={cancel}
            />
          </View>
        ) : null}
      </View>
      {showCatering && hasCatering
        ? catering.map((data, index) => (
            <View style={styles.cateringContainer} key={index}>
              <CateringOrder data={data} />
            </View>
          ))
        : null}
    </View>
  );
}
