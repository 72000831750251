import { createContext, useContext } from 'react';
import { identity } from 'ramda';

interface IntlContext {
  setAppLanguage: (lang: string) => void;
  lang: string;
}

export const defaultLang = 'en';

const DEFAULT_STATE = {
  setAppLanguage: identity,
  lang: defaultLang,
};

export const IntlContext = createContext<IntlContext>(DEFAULT_STATE);

export const useIntlContext = () => useContext(IntlContext);
