import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, ButtonProps } from 'react-native-elements';

import { colors, fonts, spacings } from '@views/shared/styles';
const { aquaMarine, blackAlpha2, white } = colors;

export const styles = StyleSheet.create({
  btnStyle: {
    borderColor: aquaMarine,
    backgroundColor: aquaMarine,
    borderWidth: 1,
    borderRadius: 25,
    paddingVertical: 2,
    paddingHorizontal: 2,
  },
  titleStyle: {
    color: white,
    fontSize: fonts.sizeDefault,
    fontFamily: 'pts-regular',
    paddingHorizontal: spacings.smallMedium,
  },
  disabled: {
    backgroundColor: 'transparent',
    color: blackAlpha2,
    borderColor: blackAlpha2,
  },
});

export function MainButton({ buttonStyle, titleStyle, ...props }: ButtonProps) {
  const { disabled } = props;

  return (
    <Button
      buttonStyle={[styles.btnStyle, buttonStyle, disabled && styles.disabled]}
      titleStyle={[styles.titleStyle, titleStyle]}
      disabledStyle={styles.disabled}
      disabledTitleStyle={styles.disabled}
      type="outline"
      {...props}
    />
  );
}
