import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { BookingIcon } from '@views/shared/BookingIcon';
import { getBookingIcon } from '@views/shared/consts';
import {
  WorklightStatus,
  AreaStructureForParkingSpots,
  AreaStructureForDesks,
  AreaStructureForMeetingRooms,
  AreaStructureForFreeSeats,
} from '@views/shared/interfaces/buildingStructure';

const { extraSmall, huge, medium, oversize, small, smaller } = spacings;
const { grey2Alpha } = colors;

export const styles = StyleSheet.create({
  container: {
    borderColor: grey2Alpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    marginTop: small,
  },
  headerContainer: {
    borderRadius: extraSmall,
    marginHorizontal: medium,
    paddingHorizontal: medium,
    paddingVertical: small,
  },
  headerContainerArea: {
    borderRadius: 0,
    marginHorizontal: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerArea: {
    paddingHorizontal: medium,
    paddingVertical: small,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: oversize,
  },
  title: {
    paddingLeft: small,
  },
  image: {
    width: '100%',
    height: 200,
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
  },
  badgeWrapper: {
    justifyContent: 'center',
  },
  noFree: {
    textAlign: 'center',
    fontFamily: 'pts-regular',
  },
  central: {
    flexDirection: 'row',
    padding: small,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noImage: {
    borderBottomColor: 'blue',
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  more: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: small,
    paddingHorizontal: medium,
  },
  moreText: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
  },
  toggle: {
    alignSelf: 'center',
    top: 1,
    paddingLeft: smaller,
  },
  toggleEquipment: {
    marginLeft: medium,
  },
  noDesks: {
    paddingVertical: small,
    textAlign: 'center',
    fontFamily: 'pts-regular',
  },
  open: {
    borderBottomColor: grey2Alpha,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  equipmentContainer: {
    paddingTop: huge,
  },
  equipmentTitle: {
    fontFamily: 'mrt-semi-bold',
  },
  equipmentStuff: {
    fontFamily: 'mrt-regular',
  },
  equipment: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  equipmentBlock: {
    paddingTop: small,
    width: '49%',
  },
  noEquipment: {
    width: 19,
    marginLeft: medium,
  },
  buttonIcon: {
    marginLeft: 2,
    marginRight: -7,
  },
  equipmentBlockFullWidth: {
    width: 'auto',
  },
});

interface Props {
  area:
    | AreaStructureForParkingSpots
    | AreaStructureForDesks
    | AreaStructureForMeetingRooms
    | AreaStructureForFreeSeats;
  worklight: WorklightStatus;
}

export function AreaHeader({ area, worklight }: Props) {
  const { areaType, name, image } = area;

  return (
    <View style={[styles.headerContainer, styles.headerContainerArea]}>
      <View style={[styles.header, styles.headerArea]}>
        <View style={styles.titleContainer}>
          <BookingIcon
            name={`${getBookingIcon(areaType)}-no`}
            worklight={worklight}
          />
          <Text style={[styles.title, globalStyles.title]}>{name}</Text>
        </View>
        <View style={styles.badgeWrapper}>
          <FavouriteBadge type={areaType} id={area.id} />
        </View>
      </View>
      {image && image.trim().length > 0 ? (
        <Image
          style={styles.image}
          resizeMode="contain"
          source={{ uri: image }}
        />
      ) : (
        <View style={styles.open} />
      )}
    </View>
  );
}
