import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from 'react-native-elements';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { Loader } from '@views/shared/Loader';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { Card } from '@views/shared/Card';
import { CustomIcon } from '@views/shared/CustomIcon';
import { ColleagueLight } from '@views/Colleagues/Colleague/interfaces';
import { STATUS } from '@views/Colleagues/Colleague';
import { useStatusBox } from '@views/Colleagues/Profile/Overview/StatusBox/hooks';
import { ColleagueEvents } from '@views/Colleagues/Profile/Events';
import { useOpenColleagueEvents } from '@views/Colleagues/Profile/Events/hooks';
import { getLocation } from '@views/shared/hooks/getLocation';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

const { warmPink, aquaMarine, white, blackAlpha, black, paleOrange } = colors;
const { extraSmall, medium, small } = spacings;
const { sizeMedium } = fonts;

const styles = StyleSheet.create({
  card: {
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
  },
  title: {
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexDirection: 'row',
    padding: small,
    paddingVertical: extraSmall,
    flex: 1,
    backgroundColor: 'transparent',
    alignItems: 'center',
  },
  title_no_data: {
    flex: 1,
    alignContent: 'center',
    color: black,
    textAlign: 'center',
    padding: small,
    paddingVertical: medium,
  },
  title_label: {
    flex: 1,
    alignContent: 'center',
    color: white,
    textAlign: 'center',
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeMedium,
  },
  title_icon: {
    alignContent: 'flex-end',
  },
  status_icon: {
    alignContent: 'flex-start',
  },
  container: {
    padding: small,
    paddingVertical: medium,
  },
  content: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: small,
    alignItems: 'center',
  },
});

interface Props {
  id: string;
  colleague: ColleagueLight;
  findMyColleagueEnabled: boolean;
}

interface CardBoxTitleProps {
  id: string;
  icon: string;
  status: string | undefined;
  message: string;
  openColleagueEvents: () => void;
  children?: JSX.Element;
  presenceStatus: string;
}

const busyStatus = {
  error: warmPink,
  success: aquaMarine,
  warning: paleOrange,
};

interface IconProps {
  color: string;
  icon: string;
  status: string;
}

export const PresenceIcon = ({ color, icon, status }: IconProps) =>
  !['ABSENT', 'DISALLOWED'].includes(status) ? (
    <CustomIcon
      style={styles.status_icon}
      name={icon}
      size={30}
      color={color}
    />
  ) : null;

const CardBox = ({
  id,
  icon,
  message,
  openColleagueEvents,
  status = STATUS.WARNING,
  children,
  presenceStatus,
}: CardBoxTitleProps) => (
  <>
    <ColleagueEvents id={id} />
    <Card style={styles.card}>
      <View style={[styles.title, { backgroundColor: busyStatus[status] }]}>
        <PresenceIcon color={white} icon={icon} status={presenceStatus} />
        <Text style={styles.title_label}>{message}</Text>
        <CustomIcon
          style={styles.title_icon}
          name="calendar"
          size={30}
          color={white}
          onPress={openColleagueEvents}
        />
      </View>
      {children}
    </Card>
  </>
);

export function StatusBox({ id, colleague, findMyColleagueEnabled }: Props) {
  const { t } = useIntl();
  const showDeskNumber = useHasFeature('desk_number_on_floor');

  const {
    icon,
    status,
    loading,
    bookSameRoom,
    data,
    error,
    presenceStatus,
    statusLabel,
    statusTmplValues,
  } = useStatusBox({ id, colleague, findMyColleagueEnabled });

  const { openColleagueEvents } = useOpenColleagueEvents();

  if (loading) {
    return <Loader />;
  }

  if (error || !data || (!data.findMyColleague && status !== 'error')) {
    return (
      <CardBox
        id={id}
        status={'warning'}
        message={t('Colleagues.error.no-data')}
        icon={icon}
        openColleagueEvents={openColleagueEvents}
        presenceStatus={presenceStatus}
      />
    );
  }

  const location = getLocation({
    booking: data.findMyColleague ?? {},
    t,
    showDeskNumber,
  });

  return (
    <CardBox
      id={id}
      status={status}
      message={t(statusLabel, statusTmplValues)}
      icon={icon}
      openColleagueEvents={openColleagueEvents}
      presenceStatus={presenceStatus}
    >
      {data.findMyColleague ? (
        <View style={styles.container}>
          <Text style={globalStyles.title}>{location}</Text>
          {!data.findMyColleague.homeOffice &&
          !data.findMyColleague.travelDay ? (
            <View style={styles.content}>
              <Text style={globalStyles.description}>
                {t('Colleagues.Content.Find.available_desks')}:{' '}
                {data.findMyColleague.area.numberOfAvailableDesks}
              </Text>
              {presenceStatus === 'OFFICE' ? (
                <MainButton
                  title={t('Colleagues.Content.Book.text')}
                  onPress={bookSameRoom}
                  disabled={
                    data?.findMyColleague?.area?.numberOfAvailableDesks === 0
                  }
                />
              ) : null}
            </View>
          ) : null}
        </View>
      ) : undefined}
    </CardBox>
  );
}
