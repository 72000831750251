import React, { forwardRef } from 'react';

import { PanAreaProps } from './interfaces';
import { useWebPan } from './hooks/web';

export const PanArea = forwardRef<any, PanAreaProps>(
  ({ children, x, y, onChange, scale = 1 }: PanAreaProps, ref) => {
    const {
      onTouchStart,
      onTouchMove,
      onEnd,
      onMouseDown,
      onMouseMove,
    } = useWebPan({
      scale,
      listener: onChange,
      outsideX: x,
      outsideY: y,
    });

    return (
      <div
        ref={ref}
        onMouseDownCapture={onMouseDown}
        onMouseMoveCapture={onMouseMove}
        onMouseUpCapture={onEnd}
        onTouchStartCapture={onTouchStart}
        onTouchMoveCapture={onTouchMove}
        onTouchEndCapture={onEnd}
      >
        {children}
      </div>
    );
  },
);
