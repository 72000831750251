import React from 'react';
import { Text, View } from 'react-native';
import { useIntl } from 'react-intl';

import { universalDateFormatter, isToday } from '@utils/DateAndTime';
import { Booking } from '@views/shared/interfaces/booking';
import { styles } from '@views/Bookings/Day';

import { Booking as BookingComponent } from './Booking';
import { Empty } from './Booking/empty';

interface Props {
  bookings: Booking[];
  day: Date;
}

export function Day({ day, bookings }: Props) {
  const {
    formats: { dayFormat },
  } = useIntl() as any;

  let noBooking;

  if (bookings.length === 0) {
    noBooking = <Empty />;
  }

  const list = bookings.map((booking) => (
    <BookingComponent key={booking.id} booking={booking} />
  ));

  const date = universalDateFormatter({ date: day, format: dayFormat });

  return (
    <View style={styles.container}>
      <Text style={[styles.date, isToday(date) ? styles.selected : {}]}>
        {date}
      </Text>
      {list}
      {noBooking}
    </View>
  );
}
