import { useCallback } from 'react';

import { useMyOrganization } from '@providers/Organization';
import {
  getNextDayFromThis,
  getTodayAsDayJs,
  isAfter,
} from '@utils/DateAndTime';

import { useSetCurrentDate } from '@views/Calendar/hooks';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import {
  useGoToBookingPage,
  useGoToStructurePage,
} from '@views/shared/utils/useGoTo';
import { BOOK_CATEGORY_ROOM } from '@views/shared/consts';

export function useBookable(date: Date) {
  const isHomeOfficeEnabled = useHasFeature('home_office');
  const isTravelDayEnabled = useHasFeature('travel_day');
  const setCurrentDate = useSetCurrentDate();

  const goToBookingPage = useGoToBookingPage();
  const goToStructurePage = useGoToStructurePage();

  const { bookingRange } = useMyOrganization();
  const maxAvailableDate = getNextDayFromThis(getTodayAsDayJs(), bookingRange);
  const isDisabledToBook = bookingRange > 0 && isAfter(date, maxAvailableDate);

  const goToPage = useCallback(
    (type) => {
      setCurrentDate(date);
      type === BOOK_CATEGORY_ROOM
        ? goToStructurePage(type)
        : goToBookingPage({ type });
    },
    [date, goToBookingPage, goToStructurePage, setCurrentDate],
  );

  return {
    goToPage,
    isHomeOfficeEnabled,
    isDisabledToBook,
    isTravelDayEnabled,
  };
}
