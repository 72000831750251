import { defineMessages, useIntl as useIntlHook } from 'react-intl';

import calendarMessages from './Calendar';
import colleaguesMessages from './Colleagues';
import commonMessages from './common';
import homeMessages from './Home';
import favouritesMessages from './Favourites';
import floorplanMessages from './Floorplan';
import profileMessages from './Profile';
import routesMessages from './Routes';
import timeSlotsMessages from './TimeSlots';
import availabilityInfosMessages from './AvailabilityInfos';

const definedMessages = defineMessages({
  ...calendarMessages,
  ...colleaguesMessages,
  ...commonMessages,
  ...homeMessages,
  ...favouritesMessages,
  ...floorplanMessages,
  ...profileMessages,
  ...routesMessages,
  ...timeSlotsMessages,
  ...availabilityInfosMessages,
});

export interface Formats {
  dateFormat: string;
  dayFormat: string;
  dayShortFormat: string;
  dayFullFormat: string;
  timeFormat: string;
  timeFormatShort: string;
}

export type Translator = (key: string, vars?: any) => string;

export interface Intl {
  locale: string;
  formats: Formats;
  t: Translator;
}

export function useIntl(): Intl {
  const { formatMessage, formats, locale } = useIntlHook();

  return {
    /**
     * Function returns the translation of the specified key based on the current locale settings
     * @param {string} key key that represents the id of the translation
     * @param {object} vars template variables for translations vars
     * @returns {string} string with the translated key
     */
    t: (key, vars = {}) => formatMessage(definedMessages[key], vars),
    /**
     * Object contains the custom formats defined into the i18n provider ( CustomFormat and Formats are not compatible for ts )
     */
    // @ts-ignore
    formats,
    locale,
  };
}
