import React from 'react';

import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import {
  getPointFullLabel,
  getFloorInfo,
  getBuildingInfo,
} from '@utils/Building';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useCurrentDate } from '@views/Calendar/hooks';
import AvailabilityInfos from '@views/shared/AvailabilityInfos';
import { BuildingProps } from '@views/Floorplan/ZoomImage/interfaces';
import {
  FloorPlan,
  AvailabilityInfo,
  AreaWithStatus,
  Point,
} from '@views/shared/interfaces/floorplan';

import { useInfos } from './hooks';

export default function Infos({
  info,
  building,
  floor,
  dismiss,
}: {
  building: BuildingProps;
  floor: FloorPlan;
  info: AvailabilityInfo;
  dismiss: (info?: AvailabilityInfo) => any;
}) {
  const {
    t,
    formats: { dayFormat },
  } = useIntl();

  const {
    error,
    findActiveBooking,
    loading,
    status,
    infoType,
    unavailableTimePeriods,
    type,
  } = useInfos({
    info,
  });

  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const currentDate = useCurrentDate();
  const date = universalDateFormatter({
    date: currentDate,
    format: dayFormat,
  });

  const getLocationByType = {
    area: ({ element }: AvailabilityInfo) => {
      const area = element as AreaWithStatus;
      return getPointFullLabel({
        ...getFloorInfo(floor),
        ...getBuildingInfo(building),
        areaName: area.name,
        areaType: area.areaType,
        t,
      });
    },
    point: ({ element }: AvailabilityInfo) => {
      const point = element as Point;

      return getPointFullLabel({
        ...getFloorInfo(floor),
        ...getBuildingInfo(building),
        areaName: point.areaName,
        areaType: point.areaType,
        point: {
          label: point.label,
          type: point.type,
          numberOnFloor: point.deskCaption,
        },
        showDeskNumber,
        showDeskTranslation: true,
        t,
      });
    },
  };

  const fullCaption = getLocationByType[infoType](info);

  return (
    <AvailabilityInfos
      findActiveBooking={findActiveBooking}
      dismiss={dismiss}
      error={error}
      loading={loading}
      fullCaption={fullCaption}
      date={date}
      status={status}
      unavailableTimeWithColleague={unavailableTimePeriods}
      type={type}
    />
  );
}
