import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { Accordion } from '@views/shared/Accordion';
import { BookingIcon } from '@views/shared/BookingIcon';

import {
  AreaType,
  FloorStructureForDesks,
  FloorStructureForMeetingRooms,
  FloorStructureForFreeSeats,
  FloorStructureForParkingSpots,
  WorklightStatus,
  AreaStructure,
} from '@views/shared/interfaces/buildingStructure';

import { BookingIcon as BookingIconType } from '@views/shared/interfaces/booking';

import { Area } from './Area';
import {
  useFloorWithDesks,
  useFloorWithFreeSeats,
  useFloorWithMeetingRooms,
  useFloorWithParkingSpots,
} from './hooks';
import { colors, spacings, globalStyles } from '@views/shared/styles';

interface Props {
  floor: any;
  type: AreaType;
  bookingIcon: BookingIconType;
  open: boolean;
  worklight: WorklightStatus;
  title: string;
}

const { grey1 } = colors;
const { medium, small } = spacings;

const styles = StyleSheet.create({
  container: {
    marginBottom: medium,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    paddingLeft: small,
  },
  accordionChildren: {
    marginBottom: medium,
    marginTop: small,
  },
});

export const withDesks = () => (WrappedComponent) => {
  const WithDesks = ({ floor }: { floor: FloorStructureForDesks }) => (
    <WrappedComponent {...useFloorWithDesks(floor)} />
  );

  return WithDesks;
};

export const withFreeSeats = () => (WrappedComponent) => {
  const WithFreeSeats = ({ floor }: { floor: FloorStructureForFreeSeats }) => (
    <WrappedComponent {...useFloorWithFreeSeats(floor)} />
  );

  return WithFreeSeats;
};

export const withMeetingRooms = () => (WrappedComponent) => {
  const WithMeetingRooms = ({
    floor,
  }: {
    floor: FloorStructureForMeetingRooms;
  }) => <WrappedComponent {...useFloorWithMeetingRooms(floor)} />;

  return WithMeetingRooms;
};

export const withParkingSpots = () => (WrappedComponent) => {
  const WithParkingSpots = ({
    floor,
  }: {
    floor: FloorStructureForParkingSpots;
  }) => <WrappedComponent {...useFloorWithParkingSpots(floor)} />;

  return WithParkingSpots;
};

export default function Floor({
  floor,
  type,
  bookingIcon,
  open,
  worklight,
  title,
}: Props) {
  const iconName = `${bookingIcon}-no`;

  const titleElm = (
    <View style={styles.titleContainer}>
      <BookingIcon name={iconName} worklight={worklight} />
      <Text style={[styles.title, globalStyles.title]}>{title}</Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <Accordion
        title={titleElm}
        open={open}
        headerStyle={{
          backgroundColor: grey1,
        }}
        childrenStyle={styles.accordionChildren}
      >
        {floor.areas
          // @ts-ignore
          .filter(
            ({ isBookable, areaType }: AreaStructure) =>
              isBookable && areaType === type,
          )
          .map((area) => (
            <Area key={area.id} area={area} type={type} />
          ))}
      </Accordion>
    </View>
  );
}
