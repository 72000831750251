import React from 'react';
import { Platform, Modal } from 'react-native';
import {
  Overlay as ElementsOverlay,
  OverlayProps,
} from 'react-native-elements';
import WebModal from 'modal-react-native-web';

const ModalComponent = Platform.OS === 'web' ? WebModal : Modal;

// ariaHideApp needs to be set to false to avoid React Warning
// For more info look here: https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

export function Overlay(props: OverlayProps) {
  return (
    <ElementsOverlay
      // @ts-ignore
      ariaHideApp={false}
      ModalComponent={ModalComponent}
      {...props}
    />
  );
}
