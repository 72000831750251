import React from 'react';
import { Text, TouchableHighlight, View, ViewStyle } from 'react-native';

import { useIntl } from '@utils/intl';
import { styles } from '@views/shared/Structure';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Equipment as EquipmentType } from 'views/shared/interfaces/buildingStructure';

interface EquipmentStuff {
  category: string;
  stuff: string[];
}

const transformEquipment = (equipment: EquipmentType[]): EquipmentStuff[] =>
  equipment.reduce((acc: EquipmentStuff[], curr) => {
    const category = acc.find((el) => el.category === curr.category.name);
    if (category) {
      return acc.map((cat) => {
        if (cat.category === curr.category.name) {
          return { ...cat, stuff: [...cat.stuff, curr.name] };
        }
        return cat;
      });
    }
    return [...acc, { category: curr.category.name, stuff: [curr.name] }];
  }, []);

export function Equipment({ equipment }: { equipment: EquipmentType[] }) {
  const { t } = useIntl();
  const transformedData = transformEquipment(equipment);

  return (
    <View style={styles.equipmentContainer}>
      <Text style={styles.equipmentTitle}>
        {t('Home.Structure.Floor.Area.Equipment')}
      </Text>
      <View style={styles.equipment}>
        {transformedData.map(({ category, stuff }, i) => (
          <View
            key={i}
            style={[
              styles.equipmentBlock,
              transformedData.length === 1 && styles.equipmentBlockFullWidth,
            ]}
          >
            <Text style={styles.equipmentTitle}>{category}</Text>
            {stuff.map((name, idx) => (
              <Text key={idx}>{name}</Text>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}

interface Props {
  hasEquipment: boolean;
  onPress: () => void;
  show: boolean;
  style?: ViewStyle;
}

export function Touchable({ hasEquipment, onPress, show, style }: Props) {
  return hasEquipment ? (
    <TouchableHighlight underlayColor="transparent" onPress={onPress}>
      <View style={[styles.toggle, styles.toggleEquipment, style]}>
        <CustomIcon name={show ? 'arrow-up' : 'arrow-down'} size={15} />
      </View>
    </TouchableHighlight>
  ) : (
    <View style={styles.noEquipment} />
  );
}
