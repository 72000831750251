import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { any, all } from 'ramda';

import { useCurrentDate } from '@views/Calendar/hooks';
import { spacings } from '@views/shared/styles';
import { getNumberFromHours } from '@utils/DateAndTime';

import { Slot } from './Slot';
import Selection from './Selection';

import {
  calculateTimeSlots,
  mergeTimeSlots,
  STATUS,
  SELECTION_OPTIONS,
} from './helper';

import {
  BlockedTimeSlot,
  PeriodProps,
  TimeSlot,
  SelectionType,
} from './interfaces';

interface Props {
  bookablePeriod: PeriodProps;
  blockedTimeSlots: BlockedTimeSlot[];
  onSelect: (slots: TimeSlot[]) => void;
  midDayHour: string;
  showAvailability: (slot?: TimeSlot) => any;
  showSlotsSelection?: boolean;
  showShortCutSlotsSelection?: boolean;
}

const DEFAULT_CONTAINER_WIDTH = 350;
const DEFAULT_SELECTION_WIDTH = 110;
const DEFAULT_SELECTION_SPACE = 20;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: spacings.medium,
  },
  shortCutSelectionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: 35,
    alignSelf: 'center',
  },
});

const isFree = (slot: TimeSlot) => slot.status === STATUS.FREE;
const isBeforeLunchFree = (midDayHour: string) => (slot: TimeSlot) =>
  slot.status === STATUS.FREE &&
  slot.startIndex < getNumberFromHours(midDayHour);
const isAfterLunchFree = (midDayHour: string) => (slot: TimeSlot) =>
  slot.status === STATUS.FREE &&
  slot.startIndex >= getNumberFromHours(midDayHour);

const isAfterLunchSelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.AFTER_LUNCH;
const isBeforeLunchSelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.BEFORE_LUNCH;
const isFullDaySelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.FULL_DAY;

export default function TimeSlots({
  blockedTimeSlots,
  bookablePeriod,
  showSlotsSelection = true,
  showShortCutSlotsSelection = true,
  onSelect,
  midDayHour,
  showAvailability,
}: Props) {
  const [selectedSlots, setSelectedSlots] = useState<TimeSlot[]>([]);
  const [selectedShortCut, setSelectedShortCut] = useState<SelectionType>(
    SELECTION_OPTIONS.NONE,
  );
  const [containerWidth, setContainerWidth] = useState(DEFAULT_CONTAINER_WIDTH);
  const [selectionWidth, setSelectionWidth] = useState(DEFAULT_SELECTION_WIDTH);

  const date = useCurrentDate();
  const slots = calculateTimeSlots({ blockedTimeSlots, bookablePeriod, date });

  const setSlot = useCallback(
    (slot, remove) => {
      let data;
      if (remove) {
        data = selectedSlots.filter((e) => slot.startIndex !== e.startIndex);
      } else {
        data = selectedSlots.concat(slot);
      }
      setSelectedSlots(data);
      setSelectedShortCut(SELECTION_OPTIONS.NONE);
    },
    [selectedSlots, setSelectedSlots],
  );

  const setFullDay = useCallback(() => {
    const selected = slots.filter(isFree);
    setSelectedSlots(selected);
    setSelectedShortCut(SELECTION_OPTIONS.FULL_DAY);
  }, [slots]);

  const setBeforeLunch = useCallback(() => {
    const selected = slots.filter(isBeforeLunchFree(midDayHour));
    setSelectedSlots([]);
    setSelectedSlots(selected);
    setSelectedShortCut(SELECTION_OPTIONS.BEFORE_LUNCH);
  }, [midDayHour, slots]);

  const setAfterLunch = useCallback(() => {
    const selected = slots.filter(isAfterLunchFree(midDayHour));
    setSelectedSlots([]);
    setSelectedSlots(selected);
    setSelectedShortCut(SELECTION_OPTIONS.AFTER_LUNCH);
  }, [midDayHour, slots]);

  useEffect(() => onSelect(mergeTimeSlots(selectedSlots)), [
    onSelect,
    selectedSlots,
  ]);

  const onPageLayout = useCallback(
    (event) => {
      const { width } = event.nativeEvent.layout;

      const newWidth =
        width > DEFAULT_CONTAINER_WIDTH ? DEFAULT_CONTAINER_WIDTH : width;
      const itemWidth = Math.round((newWidth - DEFAULT_SELECTION_SPACE) / 3);
      setContainerWidth(Math.round(newWidth));
      setSelectionWidth(Math.round(itemWidth));
    },
    [setContainerWidth, setSelectionWidth],
  );

  return (
    <View onLayout={onPageLayout}>
      {showShortCutSlotsSelection && (
        <View
          style={[styles.shortCutSelectionContainer, { width: containerWidth }]}
        >
          <Selection
            from={bookablePeriod.start}
            isDisabled={!any(isBeforeLunchFree(midDayHour))(slots)}
            isSelected={isBeforeLunchSelected(selectedShortCut)}
            onPress={setBeforeLunch}
            selection={SELECTION_OPTIONS.BEFORE_LUNCH}
            to={midDayHour}
            width={selectionWidth}
          />
          <Selection
            from={bookablePeriod.start}
            isDisabled={!all(isFree)(slots)}
            isSelected={isFullDaySelected(selectedShortCut)}
            onPress={setFullDay}
            selection={SELECTION_OPTIONS.FULL_DAY}
            to={bookablePeriod.end}
            width={selectionWidth}
          />
          <Selection
            from={midDayHour}
            isDisabled={!any(isAfterLunchFree(midDayHour))(slots)}
            isSelected={isAfterLunchSelected(selectedShortCut)}
            onPress={setAfterLunch}
            selection={SELECTION_OPTIONS.AFTER_LUNCH}
            to={bookablePeriod.end}
            width={selectionWidth}
          />
        </View>
      )}

      {showSlotsSelection ? (
        <View style={styles.container}>
          {slots.map((slot, index) => (
            <Slot
              key={index}
              slot={slot}
              onSelectSlot={setSlot}
              selected={selectedSlots}
              showAvailability={showAvailability}
            />
          ))}
        </View>
      ) : null}
    </View>
  );
}
