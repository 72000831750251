import { useCallback } from 'react';
import { gql } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { useIntlContext } from '@i18n/context';
import { appName } from '@views/shared/consts';
import { SelectItem } from '@views/shared/Select/interfaces';
import { useErrorMutation } from '@providers/Errors';

const languages: SelectItem[] = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'fr',
    label: 'Française',
  },
  {
    value: 'it',
    label: 'Italiano',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'ru',
    label: 'Русский',
  },
];

const changeLangMutation = gql`
  mutation updateMeLanguage(
    $language: LanguageEnumType!
    $appName: Application!
  ) {
    updateMe(
      user: { userprofile: { language: $language } }
      appName: $appName
    ) {
      id
      userprofile {
        language
      }
    }
  }
`;

export function useLanguage() {
  const { t } = useIntl();
  const { lang, setAppLanguage } = useIntlContext();
  const [changeLang] = useErrorMutation(changeLangMutation, {
    finderError: {
      type: 'fatal',
      message: t('Profile.Content.Language.changeError'),
    },
  });
  const onLanguageChange = useCallback(
    async (lang: string) => {
      await changeLang({
        variables: {
          appName,
          language: lang,
        },
      });

      setAppLanguage(lang);
    },
    [setAppLanguage, changeLang],
  );

  return {
    lang,
    languages,
    onLanguageChange,
  };
}
