import React from 'react';
import { StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';
import { MainButton } from '@views/shared/Button';
import { colors, spacings } from '@views/shared/styles';

import { useGoogle } from './hooks';

const { white, googleRed, black } = colors;
const { small } = spacings;

export const styles = StyleSheet.create({
  button: {
    backgroundColor: white,
    borderColor: googleRed,
    marginVertical: small,
    minHeight: 50,
    borderRadius: 50,
  },
  title: {
    color: black,
    flex: 1,
  },
  icon: {
    marginRight: 5,
    marginLeft: 15,
    color: googleRed,
  },
});

export function Google() {
  const { t } = useIntl();
  const { toggle, checkedIn } = useGoogle();

  return (
    <MainButton
      buttonStyle={styles.button}
      titleStyle={styles.title}
      title={t(
        checkedIn
          ? 'Profile.Content.Google.out'
          : 'Profile.Content.Google.button',
      )}
      onPress={toggle}
      icon={<CustomIcon name="google" style={styles.icon} />}
    />
  );
}
