import { useMyBuildings } from '@providers/Buildings';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const placeholderImage = require('@assets/images/placeholder-building.jpg');

export function useBuildings() {
  const {
    buildings,
    loading,
    error,
    selectedBuilding,
    selectBuildingById,
  } = useMyBuildings();

  return {
    items: buildings.map((building) => {
      const image = building.image ? { uri: building.image } : placeholderImage;
      return {
        label: building.name,
        value: building.id,
        image,
      };
    }),
    error,
    loading,
    selectedBuilding,
    selectBuildingById,
  };
}
