import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { colors, spacings } from '@views/shared/styles';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getDeskOrParkingLabel } from '@utils/Building';
import { styles } from '@views/shared/Structure';
import { DeskStructure } from '@views/shared/interfaces/buildingStructure';

import { Equipment, Touchable } from '@views/shared/Equipment';

interface Props {
  desk: DeskStructure;
  last: boolean;
  onBook: (deskId: string) => void;
}

const styleDesk = (last) => ({
  borderBottomWidth: last ? 0 : 1,
  borderColor: colors.grey2Alpha,
  borderRadius: 0,
  paddingHorizontal: spacings.medium,
});

export function Desk({ desk, last, onBook }: Props) {
  const { t } = useIntl();
  const [showEquipment, setShowEquipment] = useState(false);
  const toggleEquipment = useCallback(
    () => setShowEquipment((prevState) => !prevState),
    [setShowEquipment],
  );

  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const { equipment, id } = desk;

  const buttonText = getDeskOrParkingLabel({
    point: desk,
    t,
    showDeskNumber,
    showDeskTranslation: true,
  });

  // @ts-ignore
  return (
    <View style={[styles.headerContainer, styleDesk(last)]}>
      <View style={styles.header}>
        <View style={styles.buttonContainer}>
          <MainButton onPress={() => onBook(id)} title={buttonText} />
        </View>
        <View style={styles.badgeWrapper}>
          <FavouriteBadge type="room" id={id} />
        </View>
        <Touchable
          hasEquipment={!!equipment.length}
          onPress={toggleEquipment}
          show={showEquipment}
        />
      </View>
      {showEquipment ? <Equipment equipment={equipment} /> : null}
    </View>
  );
}
