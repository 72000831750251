import { useCallback, UIEvent, useState } from 'react';

import { containValue } from '@views/shared/utils';

import { useScale } from './common';
import { ScaleInput, Position, PinchPositions } from './interfaces';
import { getDistance } from './utils';

function getPositionsForTouches(touches: TouchList): PinchPositions {
  const first = touches[0];
  const second = touches[1];

  return [
    {
      x: first.clientX,
      y: first.clientY,
    },
    {
      x: second.clientX,
      y: second.clientY,
    },
  ];
}

// Returns touch points closes to start touch points in proper order
function getMatchedPositions(
  [startFrist, startSecond]: PinchPositions,
  touches: TouchList,
): PinchPositions {
  type DistanceEntry = {
    distance: number;
    startPos: Position;
    currentPos: Position;
  };

  const positions = getPositionsForTouches(touches);
  let distances = positions
    .reduce((distances: DistanceEntry[], position: Position) => {
      const firstEntry: DistanceEntry = {
        currentPos: position,
        startPos: startFrist,
        distance: getDistance(startFrist, position),
      };
      const secondEntry: DistanceEntry = {
        currentPos: position,
        startPos: startSecond,
        distance: getDistance(startSecond, position),
      };

      return distances.concat([firstEntry, secondEntry]);
    }, [])
    .sort((first, second) => first.distance - second.distance);

  const first = distances[0];

  distances = distances.filter((entry) => entry.startPos !== first.startPos);

  return first.startPos === startFrist
    ? [first.currentPos, distances[0].currentPos]
    : [distances[0].currentPos, first.currentPos];
}

export function useWebScale(input: ScaleInput) {
  const { scale, setScale, pinch, setPinch } = useScale(input);
  const [startPositions, setStartPositions] = useState<PinchPositions>();

  const onWheel = useCallback(
    (event: UIEvent<HTMLDivElement, WheelEvent>) => {
      const newScale = containValue(
        scale - event.nativeEvent.deltaY / 1000,
        0.1,
        4,
      );

      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();

      setScale(newScale);
    },
    [setScale, scale],
  );

  const onTouchStart = useCallback(
    (event: UIEvent<HTMLDivElement, TouchEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();

      if (event.nativeEvent.touches.length !== 2) {
        return;
      }

      setStartPositions(getPositionsForTouches(event.nativeEvent.touches));
    },
    [setStartPositions],
  );

  const onTouchMove = useCallback(
    (event: UIEvent<HTMLDivElement, TouchEvent>) => {
      event.nativeEvent.stopImmediatePropagation();
      event.nativeEvent.stopPropagation();

      if (event.nativeEvent.touches.length !== 2 || !startPositions) {
        return;
      }

      const [currentFirst, currentSecond] = getMatchedPositions(
        startPositions,
        event.nativeEvent.touches,
      );
      const [startFirst, startSecond] = startPositions;

      const startDistance = getDistance(startFirst, startSecond);
      const currentDistance = getDistance(currentFirst, currentSecond);
      const pinch = currentDistance / startDistance;

      setPinch(pinch);
    },
    [scale, startPositions, setPinch],
  );

  const onTouchEnd = useCallback(() => {
    if (!startPositions) {
      return;
    }

    setStartPositions(undefined);
    setScale(scale * pinch);
    setPinch(1);
  }, [startPositions, scale, pinch, setScale, setPinch, setStartPositions]);

  return {
    onWheel,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
}
