import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isNil } from 'ramda';

import { useMyActiveBookings } from '@providers/ActiveBookings';

const cancelCateringMutation = gql`
  mutation cancelCatering($cateringId: ID!) {
    cancelCatering(cateringId: $cateringId) {
      status
    }
  }
`;

export default function useCancelCatering() {
  const { refreshActiveBookings } = useMyActiveBookings();
  const [cancel, { data, error, loading }] = useMutation(
    cancelCateringMutation,
  );

  const cancelCatering = useCallback(
    async (cateringId) => {
      await cancel({
        variables: {
          cateringId,
        },
      });
      setTimeout(() => refreshActiveBookings(), 2000);
    },
    [cancel, refreshActiveBookings],
  );

  return {
    cancelCatering,
    isCanceled: data?.cancelCatering.status === 'CANCELED_BY_USER',
    isError: !isNil(error),
    loading,
  };
}
