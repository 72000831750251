import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { Loader } from '@views/shared/Loader';
import { GallerySlider } from '@views/shared/GallerySlider';
import { colors, fonts, spacings } from '@views/shared/styles';

import { useBuildings } from './hooks';

const { grey3Alpha, white } = colors;
const { sizeBuilding } = fonts;
const { extraSmall, huge, medium, small, smaller, verySmall } = spacings;

const styles = StyleSheet.create({
  card: {
    marginTop: verySmall,
    marginBottom: medium,
    height: 200,
    position: 'relative',
  },
  building: {
    zIndex: 20,
    backgroundColor: grey3Alpha,
    position: 'absolute',
    left: 0,
    bottom: huge,
    right: 0,
    paddingVertical: small,
    borderBottomLeftRadius: extraSmall,
    borderBottomRightRadius: extraSmall,
  },
  label: {
    fontSize: sizeBuilding,
    fontWeight: 'bold',
    color: white,
    textAlign: 'center',
  },
  slider: {
    borderRadius: extraSmall,
  },
});

export function Buildings() {
  const {
    items,
    selectedBuilding,
    selectBuildingById,
    error,
    loading,
  } = useBuildings();

  if (loading) {
    return <Loader />;
  }

  if (!items || !selectedBuilding || error) {
    return <UnexpectedNoDataOrError />;
  }
  const { address, name } = selectedBuilding;
  const hideGalleryDots = items.length < 2;

  return (
    <View style={[styles.card, hideGalleryDots && { marginBottom: smaller }]}>
      <View style={styles.building}>
        <Text style={styles.label}>{name}</Text>
        <Text style={styles.label}>{address}</Text>
      </View>

      <GallerySlider
        hideDots={hideGalleryDots}
        items={items}
        onChange={selectBuildingById}
        style={styles.slider}
      />
    </View>
  );
}
