import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { compose, pathOr } from 'ramda';

import { useCurrentDate } from '@views/Calendar/hooks';
import {
  sortParkingByTypeAndLabel,
  getAvailableParkingSpots,
} from '@views/shared/Booking';
import { universalDateFormatter } from '@utils/DateAndTime';
import {
  AreaParkingSpots,
  AreaStructureForParkingSpots,
  ParkingStructure,
} from '@views/shared/interfaces/buildingStructure';

interface Data {
  areasParkings: AreaParkingSpots[];
}

// TODO remove not bookable parking spots and add the same logic to the desks
const getParkingSpots = gql`
  query getParkingSpots($areaId: ID!, $date: DateTime) {
    areasParkings(areaIds: [$areaId], date: $date) {
      areaId
      parkings {
        blocked
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        id
        label
        numberOnFloor
        type: combinedType
      }
    }
  }
`;

export default function useParkingSpots({ id }: AreaStructureForParkingSpots) {
  const [items, setItems] = useState<ParkingStructure[]>([]);
  const date = useCurrentDate();

  const { data, loading, error } = useQuery<Data>(getParkingSpots, {
    variables: {
      areaId: id,
      date: universalDateFormatter({ date }),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.areasParkings) {
      const items = compose(
        sortParkingByTypeAndLabel,
        getAvailableParkingSpots,
      )(pathOr([], ['areasParkings', 0, 'parkings'], data));

      setItems(items);
    }
  }, [data]);

  return {
    items,
    error,
    loading,
  };
}
