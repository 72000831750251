import { Formats } from '@utils/intl';
import { useBaseValues } from '@views/Home/Book/BasicBook/hooks';
import { BOOKING_TYPE_TRAVEL_DAY } from '@views/shared/consts';

export default function useValues(formats: Formats) {
  const result = useBaseValues(formats);

  return {
    ...result,
    booking: { travelDay: true },
    type: BOOKING_TYPE_TRAVEL_DAY,
    hasNextDaysBooking: true,
  };
}
