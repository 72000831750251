import { StyleSheet } from 'react-native';

import {
  aquaMarine,
  aquaMarineLight,
  aquaMarineMedium,
  aquaMarineTransparent,
  black,
  blackAlpha,
  darkBlue,
  grey3Alpha,
  grey2Alpha,
  msBlue,
  paleOrange,
  paleOrangeTransparent,
  warmPink,
  warmPinkTransparent,
  white,
  whiteOverlay,
  googleRed,
  darkBlueHighDesaturated,
  paleOrangeHighDesaturated,
  darkBlueMediumDesaturated,
  paleOrangeMediumDesaturated,
  warmPinkHighDesaturated,
} from './colors';

export const colors = {
  grey1: '#e3e3e3', // TODO: why these 3 are re-defined views/shared/colors.ts?
  grey2: '#6f6f6e',
  grey3: '#707070',
  grey4: '#36333e',
  grey5: '#f3f4f5',
  aquaMarine,
  aquaMarineLight,
  aquaMarineMedium,
  aquaMarineTransparent,
  black,
  blackAlpha,
  blackAlpha2: 'rgba(0, 0, 0, .3)',
  darkBlue,
  googleRed,
  grey2Alpha,
  grey3Alpha,
  msBlue,
  paleOrange,
  paleOrangeTransparent,
  warmPink,
  warmPinkTransparent,
  white,
  whiteOverlay,
  darkBlueHighDesaturated,
  paleOrangeHighDesaturated,
  darkBlueMediumDesaturated,
  paleOrangeMediumDesaturated,
  warmPinkHighDesaturated,
};

export const fonts = {
  sizeTiny: 9,
  sizeSmaller: 10,
  sizeSmall: 11,
  sizeSmallPlus: 12,
  sizeDefault: 14,
  sizeMedium: 15,
  sizeTitle: 16,
  sizeBuilding: 20,
};

export const spacings = {
  tiny: 3,
  smaller: 4,
  extraSmall: 6,
  verySmall: 8,
  small: 10,
  smallMedium: 12,
  medium: 14,
  large: 16,
  big: 18,
  huge: 20,
  oversize: 25,
};

export const globalStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    paddingHorizontal: spacings.smallMedium,
    paddingTop: spacings.smallMedium,
    paddingBottom: spacings.oversize,
    backgroundColor: colors.white,
  },
  badge: {
    position: 'absolute',
    top: -1,
    right: 0,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  label: {
    color: colors.black,
    fontFamily: 'mrt-semi-bold',
    fontWeight: 'normal',
    fontSize: fonts.sizeDefault,
    paddingBottom: spacings.small,
  },
  input: {
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeDefault,
    borderColor: colors.grey2,
    borderRadius: spacings.smaller,
    borderWidth: 1,
    paddingHorizontal: spacings.smallMedium,
    paddingVertical: spacings.verySmall,
    marginBottom: spacings.large,
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.16,
    shadowRadius: 6,
  },
  title: {
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
  },
  description: {
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-regular',
  },
  favCount: {
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-semi-bold',
    color: colors.white,
  },
  cancelButton: {
    borderColor: colors.warmPink,
  },
  cancelButtonText: {
    color: colors.warmPink,
  },
  shortCutSelectionButton: {
    borderColor: colors.darkBlue,
    borderRadius: spacings.extraSmall,
  },
  shortCutSelectionButtonText: {
    color: colors.darkBlue,
  },
  shortCutSelectionButtonOn: {
    backgroundColor: colors.darkBlue,
  },
  shortCutSelectionButtonOnText: {
    color: colors.white,
  },
  shortCutSelectionTitle: {
    paddingVertical: spacings.extraSmall,
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
    color: colors.darkBlue,
  },
});

export const topBarNavigatorStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: white,
  },
  sceneContainer: {
    backgroundColor: white,
  },
  navigator: {
    borderWidth: 0,
    shadowRadius: 0,
    shadowColor: 'transparent',
  },
  tabLabel: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-regular',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
});

export const avatarWithBadgeStyle = StyleSheet.create({
  title: {
    fontFamily: 'mrt-semi-bold',
    color: darkBlue,
    fontSize: fonts.sizeTitle,
  },
  avatarOverlayContainer: {
    backgroundColor: white,
  },
  placeholder: {
    backgroundColor: white,
  },
  avatarContainer: {
    borderWidth: 1,
    borderColor: darkBlue,
  },
});
