import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Time.Slots.selection.button.fullDay': {
    id: 'Time.Slots.selection.button.fullDay',
    defaultMessage: 'Full Day',
  },
  'Time.Slots.selection.button.beforeLunch': {
    id: 'Time.Slots.selection.button.beforeLunch',
    defaultMessage: 'Before Lunch',
  },
  'Time.Slots.selection.button.AfterLunch': {
    id: 'Time.Slots.selection.button.AfterLunch',
    defaultMessage: 'After Lunch',
  },
  'Time.Slots.selection.button.All': {
    id: 'Time.Slots.selection.button.All',
    defaultMessage: 'All available slots',
  },
});

export default {
  ...dictionary,
};
