import React from 'react';
import { View } from 'react-native';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { Loader } from '@views/shared/Loader';

import { Booking } from './Booking';

export function ActiveBookings() {
  const { bookings, loading } = useMyActiveBookings();

  if (loading) {
    return <Loader />;
  }

  return (
    <View>
      {bookings.map((booking) => (
        <Booking key={booking.id} booking={booking} />
      ))}
    </View>
  );
}
