import { gql } from '@apollo/client';

const UNAVAILABLE_TIME_PERIODS_QUERY = gql`
  fragment BookingUnavailableTimePeriods on UnavailableTimePeriodsType {
    start
    end
    unavailableType
    userId
  }
`;

export const getBookingUnavailableTimePeriodsForDesks = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsDesks($deskId: ID, $date: Date!) {
    unavailableTimePeriods(date: $date, deskId: $deskId) {
      ...BookingUnavailableTimePeriods
    }
  }
`;

export const getBookingUnavailableTimePeriodsForFreeSeats = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsFreeSeats($areaId: ID!, $date: Date!) {
    unavailableTimePeriods(date: $date, areaId: $areaId) {
      ...BookingUnavailableTimePeriods
    }
  }
`;

export const getBookingUnavailableTimePeriodsForParkingSpots = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsParkingSpots($parkingId: ID, $date: Date!) {
    unavailableTimePeriods(date: $date, parkingId: $parkingId) {
      ...BookingUnavailableTimePeriods
    }
  }
`;

export const getBookingUnavailableTimePeriodsForMeetingRooms = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsMeetingRooms($areaId: ID, $date: Date!) {
    unavailableTimePeriods(date: $date, areaId: $areaId) {
      ...BookingUnavailableTimePeriods
    }
  }
`;

export const getBookingUnavailableTimePeriodsForHomeOffice = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsHomeOffice($date: Date!) {
    unavailableTimePeriods(date: $date, homeOffice: true) {
      ...BookingUnavailableTimePeriods
    }
  }
`;

export const getBookingUnavailableTimePeriodsForTravel = gql`
  ${UNAVAILABLE_TIME_PERIODS_QUERY}
  query unavailableTimePeriodsTravel($date: Date!) {
    unavailableTimePeriods(date: $date, travelDay: true) {
      ...BookingUnavailableTimePeriods
    }
  }
`;
