import Constants from 'expo-constants';
import { Alert, Linking } from 'react-native';

import { Translator } from '@utils/intl';

const { APP_ENV } = Constants.manifest.extra;

export const webAppURL = {
  production: 'https://finder.onltx.com/',
  develop: 'https://finder.develop.onltx.com/',
  demo: 'https://finder.demo.onltx.com/',
};

export const nativeToggle = (t: Translator) => {
  Alert.alert(
    t('Profile.Content.Google.hooks.native.redirect.title'),
    t('Profile.Content.Google.hooks.native.redirect.description'),
    [
      {
        text: t('Profile.Content.Google.hooks.native.redirect.action'),
        onPress: () => {
          (async () => {
            await Linking.openURL(webAppURL[APP_ENV]);
          })();
        },
      },
    ],
    { cancelable: false },
  );
};
