import { WorklightStatus } from '@views/shared/interfaces/buildingStructure';

export default function getWorklightStatus(
  free: number,
  total: number,
): WorklightStatus {
  const ratio = free / total;

  if (!isFinite(ratio)) {
    return 'none';
  }

  return ratio > 0.5 ? 'low' : ratio > 0 ? 'medium' : 'high';
}
