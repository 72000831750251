import { useMemo } from 'react';
import { gql } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { SelectItem } from '@views/shared/Select';
import { useErrorQuery } from '@providers/Errors';

import { Subject, Data } from './interfaces';

const getSubjectsQuery = gql`
  query getSubjects {
    userProblemSubjects {
      id
      en
      de
    }
  }
`;

function transformSubjectsToItems(
  subjects: Subject[],
  locale: string,
): SelectItem[] {
  if (locale.includes('de')) {
    return subjects.map(({ id, de }) => ({ label: de, value: id }));
  }

  return subjects.map(({ id, en }) => ({ label: en, value: id }));
}

export function useSubject() {
  const { t, locale } = useIntl();
  const { data } = useErrorQuery<Data>(getSubjectsQuery, {
    finderError: {
      type: 'fatal',
      message: t('Home.ActiveBookings.Booking.Problem.Subject.fetchFail'),
    },
  });
  const subjects = data?.userProblemSubjects ?? [];
  const items = useMemo(
    () => transformSubjectsToItems(data?.userProblemSubjects ?? [], locale),
    [subjects, locale],
  );

  return {
    items,
  };
}
