import React from 'react';
import { View, ScrollView } from 'react-native';
import { globalStyles, spacings } from '@views/shared/styles';

import { Buildings } from './Buildings';
import { Bookings } from './Bookings';
import { ActiveBookings } from './ActiveBookings';

export function Main() {
  return (
    <ScrollView style={globalStyles.container}>
      <View style={{ marginBottom: spacings.huge }}>
        <ActiveBookings />
        <Buildings />
        <Bookings />
      </View>
    </ScrollView>
  );
}
