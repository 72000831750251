import React, { useCallback, useContext, useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import {
  DocumentNode,
  TypedDocumentNode,
  useMutation,
  useQuery,
} from '@apollo/client';

import { loggedInAtom } from '@views/Login';

import { finderErrorContextAdapter } from './common';

import {
  ContextFinderError,
  ErrorsProps,
  ErrorsStateContext,
  FinderError,
  ErrorMutationHookOptions,
  ErrorQueryHookOptions,
} from './interfaces';

const DEFAULT_CONTEXT_STATE = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  forceClearAllErrors: () => {},
};

const errorsAtom = atom<ContextFinderError[]>({
  key: `errors`,
  default: [],
});

// Error is the GraphQL response, finderError is the basic setup for that error type ( internal app type )
export function useObserveError(error: any, finderError?: FinderError) {
  const { addError } = useErrors();
  const displayError = finderError ?? {
    type: 'fatal',
    message: 'Errors.hook.defaultFatal',
  };

  useEffect(() => {
    if (error) {
      addError(error, displayError);
    }
  }, [addError, error, displayError]);
}

export function useErrorQuery<Data = any, Vars = Record<string, any>>(
  query: DocumentNode | TypedDocumentNode<Data, Vars>,
  options?: ErrorQueryHookOptions<Data, Vars>,
) {
  const result = useQuery(query, options);

  useObserveError(result.error, options?.finderError);

  return result;
}

export function useErrorMutation<Data = any, Vars = Record<string, any>>(
  mutation: DocumentNode | TypedDocumentNode<Data, Vars>,
  options?: ErrorMutationHookOptions<Data, Vars>,
) {
  const result = useMutation(mutation, options);

  useObserveError(result[1].error, options?.finderError);

  return result;
}

export function useSessionErrors() {
  return useRecoilValue(errorsAtom);
}

export const ErrorsContext = React.createContext<ErrorsStateContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useErrors = () => useContext(ErrorsContext);

export function ErrorsProvider({ children }: ErrorsProps) {
  const loggedIn = useRecoilValue(loggedInAtom);

  const [errors, setErrors] = useRecoilState<ContextFinderError[]>(errorsAtom);

  const forceClearAllErrors = useCallback(() => setErrors([]), [setErrors]);

  // This method can be used internally to react on GraphQL error response but
  // also as API to call directly from your business logic
  const addError = useCallback(
    (error: FinderError | any, finderError?: FinderError) => {
      if (!loggedIn) {
        // Ignores errors before login.
        // Auth0 has own validation and request failures can happen if user is yet not logged in.
        return;
      }

      const newError = finderErrorContextAdapter({ error, finderError });

      setErrors((prevErrors) => {
        const exists = prevErrors.find(
          (error) =>
            error.type === newError.type &&
            (error.message === newError.message ||
              error.description === newError.description),
        );

        return exists ? errors : errors.concat([newError]);
      });
    },
    [errors, loggedIn, setErrors],
  );

  return (
    <ErrorsContext.Provider
      value={{
        addError,
        forceClearAllErrors,
      }}
    >
      {children}
    </ErrorsContext.Provider>
  );
}
