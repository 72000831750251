import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useMyBuildings } from '@providers/Buildings';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useGoToStructurePage } from '@views/shared/utils/useGoTo';
import { BookCategory } from '@views/shared/interfaces/booking';

const query = gql`
  query getBuildingParking($id: ID!, $date: Date!) {
    building: buildingCountParking(buildingId: $id, date: $date) {
      numberOfAvailableParkings
      numberOfTotalParkings
    }
  }
`;

interface Data {
  building: {
    numberOfAvailableParkings: number;
    numberOfTotalParkings: number;
  };
}

export default function useParkingSpots(type: BookCategory, message: any) {
  const date = useCurrentDate();
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const goToStructure = useGoToStructurePage();

  const { data, loading, error } = useErrorQuery<Data>(query, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message,
    },
  });

  const free = pathOr(0, ['building', 'numberOfAvailableParkings'], data);
  const total = pathOr(1, ['building', 'numberOfTotalParkings'], data);

  return {
    count: free,
    loading: !buildingId || loading,
    error,
    goToStructure: () => goToStructure(type),
    worklightStatus: getWorklightStatus(free, total),
  };
}
