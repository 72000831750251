import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { Accordion } from '@views/shared/Accordion';
import { Switch } from '@views/shared/Switch';
import { Loader } from '@views/shared/Loader';

import { useIntl } from '@utils/intl';
import { spacings, colors, fonts } from '@views/shared/styles';

import { useToggles } from './hooks';

const { grey1, white, grey2, black } = colors;
const { extraSmall, medium, small } = spacings;
const { sizeDefault, sizeSmallPlus } = fonts;

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
  },
  accordionChildren: {
    paddingHorizontal: small,
  },
  accordionHeader: {
    backgroundColor: grey1,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  accordionContainer: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: grey1,
    borderRadius: extraSmall,
  },
  toggleWrapper: {
    borderColor: grey1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    paddingVertical: medium,
  },
  lastToggleWrapper: {
    borderBottomWidth: 0,
    marginBottom: 0,
  },
  labelToggle: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeDefault,
    color: black,
  },
  secondLabelToggle: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSmallPlus,
    color: grey2,
    paddingRight: small,
  },
});

export function Toggles() {
  const { t } = useIntl();
  const {
    colleagues,
    toggleColleagues,
    calendar,
    toggleCalendar,
    homeOffice,
    toggleHomeOffice,
    privateMode,
    togglePrivateMode,
    loading,
    error,
  } = useToggles();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  const title = (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>{t('Profile.Content.Toggles.title')}</Text>
    </View>
  );

  return (
    <Accordion
      open={true}
      title={title}
      containerStyle={styles.accordionContainer}
      headerStyle={styles.accordionHeader}
      childrenStyle={styles.accordionChildren}
    >
      <View style={styles.toggleWrapper}>
        <Switch
          labelStyle={styles.labelToggle}
          label={t('Profile.Content.Toggles.colleague')}
          secondLabelStyle={styles.secondLabelToggle}
          secondLabel={t('Profile.Content.Toggles.colleague.description')}
          isActive={colleagues}
          onToggle={toggleColleagues}
        />
      </View>

      <View style={styles.toggleWrapper}>
        <Switch
          labelStyle={styles.labelToggle}
          label={t('Profile.Content.Toggles.calendar')}
          secondLabelStyle={styles.secondLabelToggle}
          secondLabel={t('Profile.Content.Toggles.calendar.description')}
          isActive={calendar}
          onToggle={toggleCalendar}
        />
      </View>

      {calendar ? (
        <View style={styles.toggleWrapper}>
          <Switch
            labelStyle={styles.labelToggle}
            label={t('Profile.Content.Toggles.privateMode')}
            secondLabelStyle={styles.secondLabelToggle}
            secondLabel={t('Profile.Content.Toggles.privateMode.description')}
            isActive={!privateMode}
            onToggle={togglePrivateMode}
          />
        </View>
      ) : null}

      <View style={[styles.toggleWrapper, styles.lastToggleWrapper]}>
        <Switch
          labelStyle={styles.labelToggle}
          label={t('Profile.Content.Toggles.homeoffice')}
          secondLabelStyle={styles.secondLabelToggle}
          secondLabel={t('Profile.Content.Toggles.homeoffice.description')}
          isActive={homeOffice}
          onToggle={toggleHomeOffice}
        />
      </View>
    </Accordion>
  );
}
