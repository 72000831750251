import { useCallback } from 'react';
import { PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import debounce from 'lodash/debounce';

export function useSwipeable(onSwipe: (direction: 'left' | 'right') => void) {
  const reactToEvent = debounce(
    useCallback(
      (translationX: number) => {
        const direction = translationX < 0 ? 'right' : 'left';

        if (Math.abs(translationX) > 50) {
          onSwipe(direction);
        }
      },
      [onSwipe]
    ),
    150
  );

  const onPanGesture = useCallback(
    (event: PanGestureHandlerGestureEvent) => {
      reactToEvent(event?.nativeEvent?.translationX);
    },
    [reactToEvent]
  );

  return onPanGesture;
}
