import { useMyOrganization } from '@providers/Organization';

export type Feature =
  | 'book_any_desk_for_room_type_area'
  | 'booking_catering'
  | 'booking_clean_desk'
  | 'booking_find_space'
  | 'booking_report_problem'
  | 'booking_sensors_data'
  | 'check_in_out_booking'
  | 'department_forbidden_parkings'
  | 'desk_number_on_floor'
  | 'find_my_colleague'
  | 'floor_plan_rotation'
  | 'halfday_booking'
  | 'home_office'
  | 'hourly_booking'
  | 'management_booking_page'
  | 'my_organization_setup'
  | 'parkings_enabled'
  | 'social_distance'
  | 'travel_day';

export function useHasFeature(...features: Feature[]): boolean {
  const { enabledFeatures = [] } = useMyOrganization();

  return features.every((feature) => enabledFeatures.includes(feature));
}
