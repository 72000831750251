import React from 'react';

import BookingWithArea from '@views/Home/Book/BookWithArea/Booking';
import { BookAreaProps } from '@views/shared/interfaces/booking';

import { useBook } from './hooks';

export default function Booking({
  areaId,
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  type,
  showAvailability,
}: BookAreaProps) {
  return (
    <BookingWithArea
      areaId={areaId}
      date={date}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
      hasNextDaysBooking={hasNextDaysBooking}
      type={type}
      useBook={useBook}
      showAvailability={showAvailability}
      showShortCutSlotsSelection={false}
    />
  );
}
