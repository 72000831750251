import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { fonts, spacings } from '@views/shared/styles';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_ROOM,
} from '@views/shared/consts';
import { universalDateFormatter } from '@utils/DateAndTime';

import Checkable from './Checkable';
import useSlot from './hooks';

import { BookingType } from '@views/shared/interfaces/booking';
import { AvailableDayBooking, SlotProps } from '../../interfaces';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: spacings.small,
  },
  date: {
    width: '50%',
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
  },
  icons: {
    flexDirection: 'row',
  },
  gap: { marginLeft: spacings.huge },
});

const mapBookingType = (type: BookingType): AvailableDayBooking => {
  const typeMap = {
    [BOOKING_TYPE_ROOM]: 'desk',
    [BOOKING_TYPE_FREE_SPACES_ROOM]: 'area',
    [BOOKING_TYPE_HOME]: 'homeOffice',
    [BOOKING_TYPE_TRAVEL_DAY]: 'travelDay',
  };

  return typeMap[type] as AvailableDayBooking;
};

export default function Slot({
  availableDayBookings,
  currentBookedType,
  date,
  disabledToBook,
  onSlotSelect,
}: SlotProps) {
  const { formats } = useIntl();
  const { dayFullFormat } = formats as any;
  const { homeOfficeEnabled, selectedType, travelDayEnabled, toggle } = useSlot(
    date,
    onSlotSelect,
  );

  const getCheckableComponent = (type, style = {}) => {
    const isBooked = !availableDayBookings.includes(mapBookingType(type));
    const disabled = disabledToBook || isBooked;

    return (
      <Checkable
        disabled={disabled}
        onToggle={toggle}
        selected={selectedType}
        style={style}
        type={type}
      />
    );
  };

  const DeskIcon = () => getCheckableComponent(BOOKING_TYPE_ROOM);

  const HomeOfficeIcon = () =>
    homeOfficeEnabled
      ? getCheckableComponent(BOOKING_TYPE_HOME, styles.gap)
      : null;

  const Seat = () => getCheckableComponent(BOOKING_TYPE_FREE_SPACES_ROOM);

  const TravelDayIcon = () =>
    travelDayEnabled
      ? getCheckableComponent(BOOKING_TYPE_TRAVEL_DAY, styles.gap)
      : null;

  return (
    <View style={styles.container}>
      <Text style={styles.date}>
        {universalDateFormatter({ date, format: dayFullFormat })}
      </Text>
      <View style={styles.icons}>
        {currentBookedType === BOOKING_TYPE_ROOM ? (
          <>
            <DeskIcon />
            <HomeOfficeIcon />
            <TravelDayIcon />
          </>
        ) : currentBookedType === BOOKING_TYPE_FREE_SPACES_ROOM ? (
          <Seat />
        ) : (
          <>
            <HomeOfficeIcon />
            <TravelDayIcon />
          </>
        )}
      </View>
    </View>
  );
}
