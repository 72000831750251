import React, { useContext, useEffect, useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import { loggedInAtom } from '@views/Login';
import { useErrorQuery } from '@providers/Errors';
import { isEmpty, isNil } from 'ramda';

interface MyActiveBookingsProviderProps {
  children: React.ReactElement;
}

export interface Building {
  id: string;
  name: string;
  image: string;
  address: string;
}

export interface MyBookingsData {
  buildingsInfo: Building[];
}

interface MyBuildingsContext {
  buildings: Building[];
  selectedBuilding: Building | undefined;
  selectedBuildingId: string | undefined;
  error: any;
  loading: boolean;
  selectBuildingById: (id: string) => any;
}

const getBuildings = gql`
  query getBuildings {
    buildingsInfo {
      id
      name
      image
      address
    }
  }
`;

const DEFAULT_CONTEXT_STATE = {
  buildings: [],
  selectedBuilding: undefined,
  selectedBuildingId: undefined,
  error: undefined,
  loading: false,
  selectBuildingById: () => null,
};

export const MyBuildingsContext = React.createContext<MyBuildingsContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyBuildings = () => useContext(MyBuildingsContext);

export const MyBuildingsProvider = ({
  children,
}: MyActiveBookingsProviderProps) => {
  const loggedIn = useRecoilValue(loggedInAtom);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<Building>();

  const { data, error, loading } = useErrorQuery<MyBookingsData>(getBuildings, {
    skip: !loggedIn || !isEmpty(buildings) || !isNil(selectedBuilding),
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: 'Common.Buildings.fetchFail',
    },
  });

  const selectBuildingById = useCallback(
    (id: string) => {
      const building = buildings[id];
      setSelectedBuilding(building);
    },
    [buildings, setSelectedBuilding],
  );

  useEffect(() => {
    if (data) {
      const { buildingsInfo = [] } = data;

      setBuildings(buildingsInfo);

      if (!selectedBuilding) {
        setSelectedBuilding(buildingsInfo[0]);
      }
    }
  }, [data, selectedBuilding]);

  return (
    <MyBuildingsContext.Provider
      value={{
        error,
        loading,
        buildings,
        selectBuildingById,
        selectedBuilding,
        selectedBuildingId: selectedBuilding?.id,
      }}
    >
      {children}
    </MyBuildingsContext.Provider>
  );
};
