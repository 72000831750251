import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import {
  BOOKING_TYPE_ROOM,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_TRAVEL,
  BOOK_CATEGORY_PARKING,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
} from '@views/shared/consts';
import { BookCategory } from '@views/shared/interfaces/booking';

const BOOKING_PAGE_DESK = 'book-desk';
const BOOKING_PAGE_MEETING = 'book-meeting-room';
const BOOKING_PAGE_HOMEOFFICE = 'book-home';
const BOOKING_PAGE_TRAVELDAY = 'book-travel';
const BOOKING_PAGE_PARKING = 'book-parking';
const BOOKING_PAGE_FREE_SPACE = 'book-free-space';

const bookingScreenMap = {
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: BOOKING_PAGE_FREE_SPACE,
  [BOOK_CATEGORY_HOME]: BOOKING_PAGE_HOMEOFFICE,
  [BOOK_CATEGORY_MEETING_ROOM]: BOOKING_PAGE_MEETING,
  [BOOKING_TYPE_ROOM]: BOOKING_PAGE_DESK,
  [BOOK_CATEGORY_PARKING]: BOOKING_PAGE_PARKING,
  [BOOK_CATEGORY_TRAVEL]: BOOKING_PAGE_TRAVELDAY,
  [BOOKING_TYPE_PARKING_CHARGING]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_PARKING_GENERAL]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_PARKING_HANDICAPPED]: BOOKING_PAGE_PARKING,
};

//will be replaced in future
export function useGoToBookPage() {
  const navigation = useNavigation();
  return useCallback(
    (type: string) => {
      navigation.navigate('home', { screen: 'book', params: { type } });
    },
    [navigation],
  );
}

export function useGoToStructurePage() {
  const navigation = useNavigation();
  return useCallback(
    (type: BookCategory) => {
      navigation.navigate('home', {
        screen: 'structure',
        params: {
          type,
        },
      });
    },
    [navigation],
  );
}

interface Props {
  areaId?: string;
  deskId?: string;
  parkingId?: string;
  type?: BookCategory;
}

export function useGoToBookingPage() {
  const navigation = useNavigation();

  return useCallback(
    ({ areaId, deskId, parkingId, type = BOOK_CATEGORY_ROOM }: Props) => {
      const screen = bookingScreenMap[type];

      navigation.navigate('home', {
        screen,
        params: {
          areaId,
          deskId,
          parkingId,
          type,
        },
      });
    },
    [navigation],
  );
}
