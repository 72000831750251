import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { AreaHeader, styles } from '@views/shared/Structure';

import {
  WorklightStatus,
  AreaStructureForParkingSpots,
} from '@views/shared/interfaces/buildingStructure';

import { useBookable } from './hooks';

interface Props {
  area: AreaStructureForParkingSpots;
  notAvailable?: boolean;
  worklight: WorklightStatus;
}

export function FreeSpaceRoom({ area, notAvailable, worklight }: Props) {
  const { t } = useIntl();
  const { book } = useBookable(area);

  return (
    <View style={styles.container}>
      <AreaHeader area={area} worklight={worklight} />

      <View style={styles.central}>
        <View style={styles.buttonContainer}>
          {notAvailable ? (
            <Text style={styles.noFree}>
              {t('Home.Structure.Seats.nofree')}
            </Text>
          ) : (
            <MainButton title={t('Home.Structure.book')} onPress={book} />
          )}
        </View>
      </View>
    </View>
  );
}
