import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.Content.Language.changeError': {
    id: 'Profile.Content.Language.changeError',
    defaultMessage: 'Could not change language',
  },
});

export const messages = dictionary;
