import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { Calendar as CalendarPicker } from 'react-native-calendars';
import Day from 'react-native-calendars/src/calendar/day/basic';

import {
  dateFormatShort,
  isAfter,
  isDayExpired,
  isToday,
  isSameDay,
  getDay,
  universalDateFormatter,
  getTodayAsDate,
} from '@utils/DateAndTime';
import { Overlay } from '@views/shared/Overlay';
import { CustomIcon } from '@views/shared/CustomIcon';
import { useModalWidth } from '@views/shared/Overlay/hooks';
import { colors, fonts, spacings } from '@views/shared/styles';

import { useCalendar, Props } from './hooks';
import { Events } from './Events';
import { LoadCalenderConfig } from './LoadCalenderConfig';

const { large, small, extraSmall, verySmall } = spacings;
const { sizeDefault } = fonts;

const styles = StyleSheet.create({
  overlay: {
    borderRadius: extraSmall,
    padding: 0,
    paddingBottom: verySmall,
  },
  button: {
    position: 'absolute',
    right: large,
    top: large,
  },
  calendar: {
    marginTop: large,
  },
});

const theme = {
  textMonthFontFamily: 'mrt-semi-bold',
  textDayFontFamily: 'mrt-semi-bold',
  textDayFontSize: sizeDefault,
  textDayHeaderFontSize: sizeDefault,
  selectedDayBackgroundColor: colors.aquaMarine,
  'stylesheet.calendar.header': {
    header: {
      marginTop: small,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};

export function Calendar({ navigationRef: { current: navigation } }: Props) {
  const {
    enabledWorkingDays,
    isOpen,
    currentDate,
    onDayChange,
    close,
    maxAvailableDate,
  } = useCalendar(navigation);

  const modalWidth = useModalWidth();

  LoadCalenderConfig(); //localize calendar names for months and days

  const dayComponent = (props) => {
    const {
      date: { dateString },
    } = props;

    const disabled = isDayExpired(dateString);
    const selectedColor = isSameDay(dateString, currentDate)
      ? colors.aquaMarine
      : '';

    const today = isToday(dateString) ? 'today' : '';
    const isWorkingDay = enabledWorkingDays.includes(getDay(dateString));

    const textStyles = {
      fontFamily: 'mrt-semi-bold',
      fontSize: sizeDefault,
      textDecorationLine: !isWorkingDay ? 'line-through' : 'unset',
    };

    const disabledMaxRange =
      maxAvailableDate && isAfter(dateString, maxAvailableDate);

    return (
      <Day
        {...props}
        state={today}
        marking={{
          customStyles: {
            text: textStyles,
          },
          disabled: disabled || disabledMaxRange,
          disableTouchEvent: disabled || !isWorkingDay || disabledMaxRange,
          selectedColor,
          selected: selectedColor.length > 0,
        }}
        markingType="custom"
        onPress={onDayChange}
      />
    );
  };

  return isOpen ? (
    <Overlay
      isVisible={isOpen}
      onBackdropPress={close}
      overlayStyle={[styles.overlay, { width: modalWidth }]}
    >
      <ScrollView>
        {/* @ts-ignore */}
        <CalendarPicker
          theme={theme}
          style={styles.calendar}
          current={universalDateFormatter({
            date: currentDate,
            format: dateFormatShort,
          })}
          markedDates={{
            [universalDateFormatter({
              date: currentDate,
              format: dateFormatShort,
            })]: {
              selected: true,
            },
          }}
          minDate={getTodayAsDate()}
          maxDate={maxAvailableDate}
          onDayPress={onDayChange}
          renderArrow={(direction) => (
            <CustomIcon name={`arrow-${direction}`} size={18} />
          )}
          firstDay={1}
          dayComponent={dayComponent}
        />
        <CustomIcon
          name="close"
          onPress={close}
          size={20}
          style={styles.button}
        />
        {isOpen && <Events />}
      </ScrollView>
    </Overlay>
  ) : null;
}
