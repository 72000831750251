import React from 'react';
import { View } from 'react-native';
import { isEmpty } from 'ramda';

import Items from './Items';
import Order from './Order';
import useCatering from './hooks';
import { styles } from './styles';
import { MainButton } from '@views/shared/Button';
import { useIntl } from '@utils/intl';

export default function Catering({ bookingId }: { bookingId: string }) {
  const { t } = useIntl();
  const {
    bookCatering,
    data,
    goHome,
    isError,
    isSaving,
    loading,
    onItemUpdate,
    orderCateringData,
  } = useCatering(bookingId);

  // im case the catering items aren't created by admin don't display anything
  if (isEmpty(data) || loading) {
    return null;
  }

  // show the ordered catering
  if (!isEmpty(orderCateringData)) {
    return (
      <View style={styles.container}>
        <Order data={orderCateringData} showNotice />
        <View style={styles.buttonContainer}>
          <MainButton onPress={goHome} title={t('Home.Book.GoHome')} />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Items
        bookCatering={bookCatering}
        isSaving={isSaving}
        data={data}
        isError={isError}
        onUpdate={onItemUpdate}
      />
    </View>
  );
}
