import React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, spacings, fonts } from '@views/shared/styles';

import { usePolicy } from './hooks';

const { aquaMarine } = colors;
const { small } = spacings;
const { sizeSmallPlus } = fonts;

const styles = StyleSheet.create({
  link: {
    fontSize: sizeSmallPlus,
    color: aquaMarine,
    marginVertical: small,
    textAlign: 'right',
  },
});

export function PrivacyPolicy() {
  const { t } = useIntl();
  const { open } = usePolicy();

  if (Platform.OS !== 'web') {
    return null;
  }

  return (
    <Text style={styles.link} onPress={open}>
      {t('Profile.Content.PrivacyPolicy.link')}
    </Text>
  );
}
