import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useMyUser } from '@providers/User';

export function useContent() {
  const navigation = useNavigation();
  const {
    email,
    avatar,
    name,
    initials,
    phoneNumber,
    department,
    loading,
    permissions,
    homeofficeInfo,
    error,
  } = useMyUser();
  const edit = useCallback(() => {
    navigation.navigate('edit');
  }, [navigation]);

  return {
    me: {
      email,
      name,
      initials,
      permissions,
      userprofile: {
        phoneNumber,
        avatar,
        homeofficeInfo,
        department,
      },
    },
    loading,
    edit,
    error,
  };
}
