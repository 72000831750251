import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { BookingIcon } from '@views/shared/BookingIcon';
import {
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
} from '@views/shared/consts';
import { getBookingIcon } from '@views/shared/consts';
import { useBookable } from './hooks';

const height = 'auto';
const { extraSmall, medium } = spacings;
const { blackAlpha, blackAlpha2 } = colors;

const styles = StyleSheet.create({
  card: {
    marginBottom: medium,
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
  },
  title: {
    fontSize: fonts.sizeDefault,
  },
  location: {
    fontSize: fonts.sizeSmall,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    flex: 1,
    padding: medium,
    textAlign: 'center',
  },
  actions: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 10,
    marginHorizontal: 20,
  },
  iconWrapper: {
    marginLeft: 10,
    alignItems: 'center',
  },
  text: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeDefault,
  },
});

interface Props {
  date: Date;
  showBookMoreTitle: boolean;
}

export default function Bookable({ date, showBookMoreTitle }: Props) {
  const { t } = useIntl();
  const {
    goToPage,
    isHomeOfficeEnabled,
    isDisabledToBook,
    isTravelDayEnabled,
  } = useBookable(date);

  const style = isDisabledToBook ? { color: blackAlpha2 } : {};

  const getIconComponent = (type) => (
    <View style={styles.iconWrapper}>
      <BookingIcon
        name={getBookingIcon(type)}
        onPress={!isDisabledToBook ? () => goToPage(type) : undefined}
        style={style}
      />
    </View>
  );

  const title = showBookMoreTitle
    ? 'Bookings.Day.book.more'
    : 'Bookings.Day.book';

  return (
    <View style={[styles.card, globalStyles.shadow]}>
      <View style={[styles.row, { height }]}>
        <View style={styles.right}>
          <Text style={styles.text}>{t(title)}</Text>
        </View>
        <View style={styles.actions}>
          {getIconComponent(BOOK_CATEGORY_ROOM)}
          {isHomeOfficeEnabled && getIconComponent(BOOK_CATEGORY_HOME)}
          {isTravelDayEnabled && getIconComponent(BOOK_CATEGORY_TRAVEL)}
        </View>
      </View>
    </View>
  );
}
