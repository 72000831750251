import { Image } from 'react-native';
import { Asset } from 'expo-asset';
import { loadAsync } from 'expo-font';

function cacheImages(images) {
  return images.map((image) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts) {
  return fonts.map((font) => loadAsync(font));
}

export const loadAssets = () => {
  const imageAssets = cacheImages([
    // 'https://some.domain.com/images/my-image.png'
    require('@assets/images/LIZ-splash.png'),
  ]);

  const fontAssets = cacheFonts([
    { 'mrt-regular': require('@assets/fonts/Montserrat-Regular.ttf') },
    { 'mrt-semi-bold': require('@assets/fonts/Montserrat-SemiBold.ttf') },
    { 'pts-regular': require('@assets/fonts/PTSans-Regular.ttf') },
    {
      'liz-native-finder-icomoon': require('@assets/fonts/icons/liz-native-finder-icomoon.ttf'),
    },
  ]);

  return Promise.all([...imageAssets, ...fontAssets]);
};

export const preloadImages = ({ resources }: { resources: string[] }) => {
  const resourcesToLoad = cacheImages(resources);

  return Promise.all([...resourcesToLoad]);
};
