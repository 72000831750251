import React from 'react';

import { useHasFeature } from '@views/shared/hooks/hasFeature';

import Desks from './Desks';
import FreeSeats from './FreeSeats';
import MeetingRooms from './MeetingRooms';
import HomeOffice from './HomeOffice';
import TravelDay from './TravelDay';
import ParkingSpots from './ParkingSpots';
import { useBookings } from './hooks';
import { Loader } from '@views/shared/Loader';

export function Bookings() {
  const {
    error,
    loading,
    numberOfFreeSpacesRooms,
    numberOfMeetingRooms,
    numberOfDesks,
    numberOfTotalParking,
  } = useBookings();

  const homeOfficeEnabled = useHasFeature('home_office');
  const travelDayEnabled = useHasFeature('travel_day');
  const hasParkingFeature = useHasFeature('parkings_enabled');

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {numberOfDesks > 0 && <Desks />}
      {numberOfMeetingRooms > 0 && <MeetingRooms />}
      {numberOfFreeSpacesRooms > 0 && <FreeSeats />}
      {hasParkingFeature && numberOfTotalParking > 0 && <ParkingSpots />}
      {homeOfficeEnabled && <HomeOffice />}
      {travelDayEnabled && <TravelDay />}
    </>
  );
}
