import { gql, useMutation } from '@apollo/client';

import { useBookForFreeSeats } from '@views/Home/Book/BasicBook/Booking/hooks';

import {
  BookingWithArea,
  BookingHooksBasicProps,
  CreateBookingAreaResponse,
} from '@views/shared/interfaces/booking';

export const bookFreeSpaceMutation = gql`
  mutation bookFreeSpace($areaId: ID!, $start: DateTime!, $end: DateTime!) {
    createBooking(booking: { areaId: $areaId, start: $start, end: $end }) {
      start
      end
      area {
        areaType
        name
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  params: { areaId },
  t,
  type,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingAreaResponse },
    BookingWithArea
  >(bookFreeSpaceMutation, {});

  return useBookForFreeSeats({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {
      areaId,
    },
    formats,
    bookingMutation: mutation,
  });
}
