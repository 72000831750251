import {
  BookingType,
  BookCategory,
  BookingIcon,
} from '@views/shared/interfaces/booking';
import {
  FloorTypes,
  ParkingSpotType,
  AreaType,
} from '@views/shared/interfaces/buildingStructure';

export const appName = 'FINDER';
export const MAX_COUNT_DESKS = 3;

export const BOOKING_TYPE_FREE_SPACES_ROOM: BookingType = 'free_spaces_room';
export const BOOKING_TYPE_MEETING_ROOM: BookingType = 'meeting_room';
export const BOOKING_TYPE_HOME: BookingType = 'home';

export const BOOKING_TYPE_PARKING_CHARGING: BookingType =
  'VirtualParkingCHARGING';
export const BOOKING_TYPE_PARKING_GENERAL: BookingType =
  'VirtualParkingGENERAL';
export const BOOKING_TYPE_PARKING_HANDICAPPED: BookingType =
  'VirtualParkingHANDICAPPED';
export const BOOKING_TYPE_ROOM: BookingType = 'room';
export const BOOKING_TYPE_TRAVEL_DAY: BookingType = 'travelDay';
export const BOOKING_TYPE_VIRTUAL_DESK: BookingType = 'VirtualDesk';

export const FLOOR_TYPE_MIXED: FloorTypes = 'mixed';
export const FLOOR_TYPE_PARKING: FloorTypes = 'parking';

export const PARKING_SPOT_TYPE_CHARGING: ParkingSpotType =
  'VirtualParkingCHARGING';
export const PARKING_SPOT_TYPE_GENERAL: ParkingSpotType =
  'VirtualParkingGENERAL';
export const PARKING_SPOT_TYPE_HANDICAPPED: ParkingSpotType =
  'VirtualParkingHANDICAPPED';

export const AREA_TYPE_FREE_SPACES_ROOM: AreaType = 'free_spaces_room';
export const AREA_TYPE_PARKING: AreaType = 'parking';
export const AREA_TYPE_ROOM: AreaType = 'room';
export const AREA_TYPE_MEETING_ROOM: AreaType = 'meeting_room';

export const BOOK_CATEGORY_FREE_SPACES_ROOM: BookCategory = 'free_spaces_room';
export const BOOK_CATEGORY_HOME: BookCategory = 'home';
export const BOOK_CATEGORY_MEETING_ROOM: BookCategory = 'meeting_room';
export const BOOK_CATEGORY_PARKING: BookCategory = 'parking';
export const BOOK_CATEGORY_ROOM: BookCategory = 'room';
export const BOOK_CATEGORY_TRAVEL: BookCategory = 'travelDay';

const MAP_BOOKING_BY_AREA_TYPE = {
  [AREA_TYPE_FREE_SPACES_ROOM]: BOOKING_TYPE_FREE_SPACES_ROOM,
  [AREA_TYPE_PARKING]: PARKING_SPOT_TYPE_GENERAL,
  [AREA_TYPE_ROOM]: BOOKING_TYPE_ROOM,
  [AREA_TYPE_MEETING_ROOM]: BOOKING_TYPE_MEETING_ROOM,
};

const MAP_AREA_BY_BOOK_CATEGORY = {
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: AREA_TYPE_FREE_SPACES_ROOM,
  [BOOK_CATEGORY_PARKING]: AREA_TYPE_PARKING,
  [BOOK_CATEGORY_ROOM]: AREA_TYPE_ROOM,
  [BOOK_CATEGORY_MEETING_ROOM]: AREA_TYPE_MEETING_ROOM,
};

const MAP_BOOK_CATEGORY_BY_AREA = {
  [AREA_TYPE_FREE_SPACES_ROOM]: BOOK_CATEGORY_FREE_SPACES_ROOM,
  [AREA_TYPE_PARKING]: BOOK_CATEGORY_PARKING,
  [AREA_TYPE_ROOM]: BOOK_CATEGORY_ROOM,
  [AREA_TYPE_MEETING_ROOM]: BOOK_CATEGORY_MEETING_ROOM,
};

const ICON_DESK: BookingIcon = 'shared';
const ICON_HOME_OFFICE: BookingIcon = 'home-office';
const ICON_MEETING: BookingIcon = 'meeting';
const ICON_PARKING_AREA: BookingIcon = 'parking-area';
const ICON_PARKING_CHARGING: BookingIcon = 'parking-charging';
const ICON_PARKING_HANDICAPPED: BookingIcon = 'parking-handicapped';
const ICON_SEAT: BookingIcon = 'seat';
const ICON_TRAVEL: BookingIcon = 'travel';

const MAP_TYPE_ICON = {
  // BookingType
  [BOOKING_TYPE_HOME]: ICON_HOME_OFFICE,
  [BOOKING_TYPE_ROOM]: ICON_DESK,
  [BOOKING_TYPE_MEETING_ROOM]: ICON_MEETING,
  [BOOKING_TYPE_FREE_SPACES_ROOM]: ICON_SEAT,
  [BOOKING_TYPE_TRAVEL_DAY]: ICON_TRAVEL,
  [BOOKING_TYPE_PARKING_CHARGING]: ICON_PARKING_CHARGING,
  [BOOKING_TYPE_PARKING_GENERAL]: ICON_PARKING_AREA,
  [BOOKING_TYPE_PARKING_HANDICAPPED]: ICON_PARKING_HANDICAPPED,
  [BOOKING_TYPE_VIRTUAL_DESK]: ICON_DESK,
  // BookCategory
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: ICON_SEAT,
  [BOOK_CATEGORY_PARKING]: ICON_PARKING_AREA,
  [BOOK_CATEGORY_ROOM]: ICON_DESK,
  [BOOK_CATEGORY_MEETING_ROOM]: ICON_MEETING,
  [BOOK_CATEGORY_HOME]: ICON_HOME_OFFICE,
  [BOOK_CATEGORY_TRAVEL]: ICON_TRAVEL,
};

export const getBookingIcon = (
  type: BookCategory | BookingType,
): BookingIcon => {
  return MAP_TYPE_ICON[type];
};

export const getAreaTypeByBookCategory = (type: BookCategory): AreaType => {
  // @ts-ignore
  return MAP_AREA_BY_BOOK_CATEGORY[type] || AREA_TYPE_ROOM;
};

export const getBookCategoryByAreaType = (type: AreaType): BookCategory => {
  // @ts-ignore
  return MAP_BOOK_CATEGORY_BY_AREA[type] || BOOK_CATEGORY_ROOM;
};

export const getBookingTypeByAreaType = (type: AreaType): BookingType => {
  // @ts-ignore
  return MAP_BOOKING_BY_AREA_TYPE[type] || BOOKING_TYPE_ROOM;
};
