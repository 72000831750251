import { defineMessages } from 'react-intl';
import { messages as overviewMessages } from './Overview';
import { messages as editMessages } from './Edit';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.title': {
    id: 'Profile.title',
    defaultMessage: 'Profile',
  },
  'Profile.Content.Logout.text': {
    id: 'Profile.Content.Logout.text',
    defaultMessage: 'Logout',
  },
  'Profile.Content.PrivacyPolicy.link': {
    id: 'Profile.Content.PrivacyPolicy.link',
    defaultMessage: 'Privacy Policy',
  },
});

export default {
  ...dictionary,
  ...overviewMessages,
  ...editMessages,
};
