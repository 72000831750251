import { gql, useMutation } from '@apollo/client';

import { useBookForHome } from '@views/Home/Book/BasicBook/Booking/hooks';
import { BasicBooking } from '@views/shared/interfaces/booking';
import {
  BookingHooksBasicProps,
  CreateBookingResponse,
} from '@views/shared/interfaces/booking';

export const bookHomeOfficeMutation = gql`
  mutation bookHomeOffice($start: DateTime!, $end: DateTime!) {
    createBooking: createHomeOfficeBooking(
      booking: { start: $start, end: $end }
    ) {
      homeOffice
      start
      end
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  t,
  type,
  formats,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingResponse },
    BasicBooking
  >(bookHomeOfficeMutation);

  return useBookForHome({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {},
    formats,
    bookingMutation: mutation,
  });
}
