import { useState, useCallback, useEffect } from 'react';

export function useAccordion(open?: boolean, onToggle?: (isOpen: boolean) => void) {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => {
    const newIsOpen = !isOpen;

    setOpen(newIsOpen);
    onToggle && onToggle(isOpen);
  }, [setOpen, isOpen]);

  useEffect(() => {
    if (open !== undefined) {
      setOpen(!!open);
    }
  }, [open, setOpen]);

  return {
    isOpen,
    toggle,
  };
}
