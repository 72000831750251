import { gql, useMutation } from '@apollo/client';

import { useBookForParking } from '@views/Home/Book/BasicBook/Booking/hooks';

import {
  BookingWithAreaAndParking,
  BookingHooksBasicProps,
  CreateBookingParkingSpotResponse,
} from '@views/shared/interfaces/booking';

const bookParkingSpotMutation = gql`
  mutation bookParking(
    $areaId: ID!
    $start: DateTime!
    $end: DateTime!
    $parkingId: ID!
  ) {
    createBooking: createParkingBooking(
      booking: {
        areaId: $areaId
        start: $start
        end: $end
        parkingId: $parkingId
      }
    ) {
      start
      end
      area {
        areaType
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
          }
        }
        name
      }
      parking {
        type: combinedType
        label
        numberOnFloor
      }
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  params: { areaId, parkingId },
  t,
  type,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingParkingSpotResponse },
    BookingWithAreaAndParking
  >(bookParkingSpotMutation);

  return useBookForParking({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {
      areaId,
      parkingId,
    },
    formats,
    bookingMutation: mutation,
  });
}
