import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { defineMessages, useIntl } from 'react-intl';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { topBarNavigatorStyles } from '@views/shared/styles';
import { Overview } from '@views/Colleagues/Profile/Overview';
import { Bookings } from '@views/Colleagues/Profile/Bookings';
import { useProfile } from '@views/Colleagues/Profile/hooks';
import { MainButton } from '@views/shared/Button';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  labelStyle: topBarNavigatorStyles.tabLabel,
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
};

const styles = StyleSheet.create({
  containerNoProfile: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15,
    marginVertical: 20,
  },
});

const i18nMessages = defineMessages({
  'Colleagues.reload_failed': {
    id: 'Colleagues.reload_failed',
    defaultMessage: 'Go back to list of colleagues',
  },
  'Colleague.Screen.overview': {
    id: 'Colleague.Screen.overview',
    defaultMessage: 'Overview',
  },
  'Colleague.Screen.bookings': {
    id: 'Colleague.Screen.bookings',
    defaultMessage: 'Bookings',
  },
});

export function Profile() {
  const { formatMessage } = useIntl();
  const { id, colleague, goToList, findMyColleagueEnabled } = useProfile();

  if (!id) {
    return (
      <View style={styles.containerNoProfile}>
        <MainButton
          title={formatMessage(i18nMessages['Colleagues.reload_failed'])}
          onPress={goToList}
        />
      </View>
    );
  }

  const overviewView = () => (
    <Overview
      id={id}
      colleague={colleague}
      findMyColleagueEnabled={findMyColleagueEnabled}
    />
  );
  const bookingsView = () => <Bookings id={id} colleague={colleague} />;

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        initialRouteName="overview"
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy={true}
        initialLayout={{ width: Dimensions.get('window').width }}
      >
        <Tab.Screen
          component={overviewView}
          name={formatMessage(i18nMessages['Colleague.Screen.overview'])}
        />
        {findMyColleagueEnabled && (
          <Tab.Screen
            component={bookingsView}
            name={formatMessage(i18nMessages['Colleague.Screen.bookings'])}
          />
        )}
      </Tab.Navigator>
    </View>
  );
}
