import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.Content.Google.hooks.native.redirect.title': {
    id: 'Profile.Content.Google.hooks.native.redirect.title',
    defaultMessage: 'Calendar Sync',
  },
  'Profile.Content.Google.hooks.native.redirect.description': {
    id: 'Profile.Content.Google.hooks.native.redirect.description',
    defaultMessage: 'Please use the web app to connect your calendar',
  },
  'Profile.Content.Google.hooks.native.redirect.action': {
    id: 'Profile.Content.Google.hooks.native.redirect.action',
    defaultMessage: 'Open web app',
  },
  'Profile.Content.Google.hooks.statusError': {
    id: 'Profile.Content.Google.hooks.statusError',
    defaultMessage: 'Could not check auth status',
  },
  'Profile.Content.Google.hooks.urlError': {
    id: 'Profile.Content.Google.hooks.urlError',
    defaultMessage: 'Could not generate auth url',
  },
  'Profile.Content.Microsoft.button': {
    id: 'Profile.Content.Microsoft.button',
    defaultMessage: 'Sign in with Microsoft',
  },
  'Profile.Content.Microsoft.out': {
    id: 'Profile.Content.Microsoft.out',
    defaultMessage: 'Sign out from Microsoft',
  },
  'Profile.Content.Google.button': {
    id: 'Profile.Content.Google.button',
    defaultMessage: 'Sign in with Google',
  },
  'Profile.Content.Google.out': {
    id: 'Profile.Content.Google.out',
    defaultMessage: 'Sign out from Google',
  },
});

export const messages = dictionary;
