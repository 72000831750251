import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useMyBuildings } from '@providers/Buildings';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import { getDeskSlots } from '@views/Home/Bookings/Desks/hooks';

const getTotalDesks = gql`
  query getCurrentBuildingInfo($id: ID!, $date: Date!) {
    building: buildingInfo(buildingId: $id, date: $date) {
      numberOfMeetingRooms
      numberOfFreeSpacesRooms
      numberOfTotalParkings
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        desksAvailable
        freeSlots
        totalSlots
      }
    }
  }
`;

interface DesksHourlyByType {
  areaType: string;
  desksAvailable: number;
  freeSlots: number;
  totalSlots: number;
}

interface Data {
  building: {
    id: string;
    numberOfAvailableDesksHourlyByAreaType: DesksHourlyByType[];
    numberOfMeetingRooms: number;
    numberOfFreeSpacesRooms: number;
    numberOfTotalParkings: number;
  };
}

export function useBookings() {
  const { t } = useIntl();
  const date = useCurrentDate();
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const { data, loading, error } = useErrorQuery<Data>(getTotalDesks, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: t('views.Home.Bookings.Desks.hooks.desksDataFail'),
    },
  });

  const numberOfDesks = getDeskSlots(data?.building, 'desksAvailable', 0);
  const numberOfFreeSpacesRooms = pathOr(
    0,
    ['building', 'numberOfFreeSpacesRooms'],
    data,
  );
  const numberOfMeetingRooms = pathOr(
    0,
    ['building', 'numberOfMeetingRooms'],
    data,
  );
  const numberOfTotalParking = pathOr(
    0,
    ['building', 'numberOfTotalParkings'],
    data,
  );

  return {
    numberOfDesks,
    numberOfMeetingRooms,
    numberOfFreeSpacesRooms,
    numberOfTotalParking,
    loading: !buildingId || loading,
    error,
  };
}
