export const white = '#FFFFFF';
export const black = '#000000';
export const grey1 = '#f3f4f5';
export const grey2 = '#a3a4a5';
export const grey3 = '#86939e';
export const grey4 = '#36333e';
export const aquaMarine = '#5ae0b9';
export const aquaMarineTransparent = '#5ae0b970';
export const aquaMarineLight = '#eefbf8';
export const aquaMarineMedium = '#5ae0b940';
export const whiteOverlay = '#ffffff99';
export const paleOrange = '#ffb65c';
export const paleOrangeTransparent = '#ffb65c70';
export const warmPink = '#ff4387';
export const warmPinkTransparent = '#ff438770';
export const msBlue = '#00acef';
export const darkBlue = '#061A52';
export const darkBlueTransparent = '#061A5270';
export const blackAlpha = '#00000029';
export const grey3Alpha = '#70707080';
export const grey2Alpha = '#6f6f6ea7';
export const googleRed = '#dd4b39';

export const warmPinkHighDesaturated = '#ffecf3'; // --> https://www.color-hex.com/color/ff4387
export const darkBlueHighDesaturated = '#f2f8fa'; // --> https://www.color-hex.com/color/83bcd4 ( Sky Blue )
export const paleOrangeHighDesaturated = '#fefbf5'; // --> https://www.color-hex.com/color/fbeed1 ( Papaya Whip )
export const darkBlueMediumDesaturated = '#d9eaf2'; // --> https://www.color-hex.com/color/83bcd4 ( Sky Blue )
export const paleOrangeMediumDesaturated = '#fbeed1'; // --> https://www.color-hex.com/color/fbeed1 ( Papaya Whip )
