import React from 'react';
import { View, StyleSheet, TouchableHighlight } from 'react-native';
import { Text } from 'react-native-elements';

import { Select } from '@views/shared/Select';
import { ItemComponentProps } from '@views/shared/Select/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';
import { spacings, fonts } from '@views/shared/styles';

import { useLanguage } from './hooks';

export { useLanguage } from './hooks';

const { sizeTitle } = fonts;
const { small, tiny } = spacings;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: small,
  },
  lang: {
    fontSize: sizeTitle,
    fontFamily: 'pts-regular',
    textAlign: 'right',
    marginRight: small,
  },
  icon: {
    marginTop: tiny,
  },
});

function SelectedItem({ show, item, placeholder }: ItemComponentProps) {
  return (
    <TouchableHighlight onPress={show} underlayColor="transparent">
      <View style={styles.item}>
        <Text style={styles.lang}>{item?.label ?? placeholder}</Text>
        <CustomIcon name="arrow-down" size={sizeTitle} style={styles.icon} />
      </View>
    </TouchableHighlight>
  );
}

export function Language() {
  const { languages, lang, onLanguageChange } = useLanguage();

  return (
    <Select
      value={lang}
      items={languages}
      onValueChange={onLanguageChange}
      selectedComponent={SelectedItem}
    />
  );
}
