import { useCallback } from 'react';
import { gql } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { Booking as MyBooking } from '@views/shared/interfaces/booking';
import { useErrorMutation } from '@providers/Errors';
import {
  getTodayAsDayJs,
  isDayExpired,
  isAfter,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { getBookingType } from '@views/shared/utils';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { BOOKING_TYPE_MEETING_ROOM } from '@views/shared/consts';

const cancelMutation = gql`
  mutation cancelBooking($id: ID!) {
    cancelBookings(bookingIds: [$id]) {
      id
    }
  }
`;

export function useBooking(booking: MyBooking, onCancel: () => void) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const { end, id, start, status } = booking;

  const [cancel] = useErrorMutation(cancelMutation, {
    variables: {
      id,
    },
    finderError: {
      type: 'minor',
      message: t('Home.ActiveBookings.Booking.cancelFail'),
    },
  });

  const type = getBookingType(booking);
  const { refreshActiveBookings: refreshList } = useMyActiveBookings();

  const cancelBooking = useCallback(async () => {
    await cancel();
    onCancel();

    //find a better way to refresh the active bookings. now the list will be updated for every cancelation
    await refreshList();
  }, [cancel, onCancel, refreshList]);

  const isExpired =
    type === BOOKING_TYPE_MEETING_ROOM
      ? isAfter(getTodayAsDayJs(), end)
      : isDayExpired(end);

  // @ts-ignore
  const isCancelable = !['CHECKED_IN', 'CHECKED_OUT'].includes(status);

  return {
    cancel: cancelBooking,
    end: universalDateFormatter({ date: end, format: timeFormatShort }),
    isCancelable,
    isExpired,
    start: universalDateFormatter({
      date: start,
      format: timeFormatShort,
    }),
    type,
  };
}
