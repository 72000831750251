import React from 'react';
import { ScrollView, View } from 'react-native';

import { colors } from '@views/shared/styles';
import { globalStyles } from "@views/shared/styles";

import { TextExamples } from './text';
import { IconExamples } from './icon';
import { GalleryExamples } from './gallery';
import {Divider} from "react-native-elements";

export function Examples() {
  return (
    <ScrollView>
      <View style={globalStyles.container}>
        <TextExamples />
        <Divider style={{ backgroundColor: colors.blackAlpha }} />
        <IconExamples />
        <Divider style={{ backgroundColor: colors.blackAlpha }} />
        <GalleryExamples />
      </View>
    </ScrollView>
  );
}
