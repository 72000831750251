import React, { ReactNode, memo } from 'react';

import { IntlContext } from './context';

import useLocale from './useLocale';
import IntlProvider from './Provider';

interface Props {
  children: ReactNode;
}
interface ProviderProps {
  children: ReactNode;
  formats: { time: string; date: string };
  messages: { [key: string]: string }[];
  customMessages: { [key: string]: string }[];
  lang: string;
  setLang: any;
}

// eslint-disable-next-line react/display-name
const Providers = memo(
  ({ children, formats, messages, lang, setLang }: ProviderProps) => {
    return (
      <IntlProvider
        formats={formats}
        key={lang}
        locale={lang}
        messages={messages}
      >
        <>
          <IntlContext.Provider
            value={{
              setAppLanguage: setLang,
              lang,
            }}
          >
            {children}
          </IntlContext.Provider>
        </>
      </IntlProvider>
    );
  },
  (prevs, nexts) =>
    prevs &&
    nexts &&
    prevs.lang === nexts.lang &&
    prevs.customMessages === nexts.customMessages,
);

export const LanguageContext = ({ children }: Props) => {
  // @ts-ignore
  return <Providers {...useLocale()}>{children}</Providers>;
};
