import { isNil, pathOr } from 'ramda';

import {
  Booking,
  BookingType,
  MinimalBookingInfo,
} from '@views/shared/interfaces/booking';
import {
  BOOKING_TYPE_HOME,
  AREA_TYPE_PARKING,
  BOOKING_TYPE_TRAVEL_DAY,
  PARKING_SPOT_TYPE_GENERAL,
  AREA_TYPE_ROOM,
  getBookingTypeByAreaType,
} from '@views/shared/consts';

export function getBookingType(
  booking: Booking | MinimalBookingInfo,
): BookingType {
  const isTravelDay = pathOr(false, ['travelDay'], booking);
  const homeOffice = pathOr(false, ['homeOffice'], booking);
  const parkingType = pathOr(
    PARKING_SPOT_TYPE_GENERAL,
    ['parking', 'type'],
    booking,
  );
  const area = pathOr(null, ['area'], booking);
  const areaType = pathOr(AREA_TYPE_ROOM, ['area', 'areaType'], booking);

  if (homeOffice || (isNil(area) && !isTravelDay)) {
    return BOOKING_TYPE_HOME;
  }

  if (isTravelDay) {
    return BOOKING_TYPE_TRAVEL_DAY;
  }

  if (areaType === AREA_TYPE_PARKING && parkingType.length) {
    return parkingType;
  }

  return getBookingTypeByAreaType(areaType);
}
