import { useWindowDimensions, Platform } from 'react-native';

export function useModalWidth() {
  const dims = useWindowDimensions();

  if (Platform.OS !== 'web') {
    return '90%';
  }

  if (dims.width < 360) {
    return 250;
  }

  if (dims.width < 600) {
    return 350;
  }

  return 500;
}
