import React from 'react';
import { Divider, Text } from 'react-native-elements';
import { StyleSheet, View } from 'react-native';
import { colors, spacings } from '@views/shared/styles';

const { aquaMarine, black, warmPink, blackAlpha } = colors;
const { huge, medium, tiny } = spacings;

const styles = StyleSheet.create({
  container: {
    marginVertical: huge,
  },
  section: {
    marginVertical: medium,
    borderTopWidth: 1,
    borderTopColor: blackAlpha,
    paddingTop: medium,
  },
  text: {
    marginVertical: medium,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  withFontMrtRegular: {
    marginTop: 10,
    fontFamily: 'mrt-regular',
    fontSize: 20,
    color: warmPink,
  },
  withFontMrtSemiBold: {
    marginTop: 10,
    fontFamily: 'mrt-semi-bold',
    fontSize: 20,
    color: aquaMarine,
  },
  withFontPTSRegular: {
    marginTop: 10,
    fontFamily: 'pts-regular',
    fontSize: 20,
    color: black,
  },
});

export function TextExamples() {
  return (
    <View style={styles.container}>
      <Text h3>Typography</Text>
      <Divider style={{ backgroundColor: blackAlpha, marginTop: tiny }} />
      <View style={styles.text}>
        <View>
          <Text h1>Default Title h1</Text>
          <Text h2>Default Title h2</Text>
          <Text h3>Default Title h3</Text>
          <Text h4>Default Title h4</Text>
        </View>
        <View style={styles.section}>
          <Text>Text without Style </Text>
          <Text style={styles.withFontMrtRegular}>
            Text with Font-Family Montserrat-Regular
          </Text>
          <Text style={styles.withFontMrtSemiBold}>
            Text with Font-Family Montserrat-SemiBold
          </Text>
          <Text style={styles.withFontPTSRegular}>
            Text with Font-Family PT Sans Regular
          </Text>
        </View>
      </View>
    </View>
  );
}
