import { useCallback } from 'react';
import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { Booking as MyBooking } from '@views/shared/interfaces/booking';
import { getBookingType } from '@views/shared/utils';
import { useErrorMutation } from '@providers/Errors';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_VIRTUAL_DESK,
} from '@views/shared/consts';
import { useMyActiveBookings } from '@providers/ActiveBookings';

const cancelMutation = gql`
  mutation cancelBooking($id: ID!) {
    cancelBookings(bookingIds: [$id]) {
      id
    }
  }
`;

const checkInMutation = gql`
  mutation checkIn($id: ID!) {
    bookingCheckIn(bookingId: $id) {
      id
    }
  }
`;

const checkoutMutation = gql`
  mutation checkOut($id: ID!) {
    bookingCheckOut(bookingId: $id) {
      id
    }
  }
`;

const reportEnabledBookings = [
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_ROOM,
];

export function useBooking(booking: MyBooking) {
  const {
    t,
    formats: { dayShortFormat, timeFormatShort },
  } = useIntl() as any;

  const navigation = useNavigation();
  const checkInFeatureEnabled = useHasFeature('check_in_out_booking');
  const reportFeatureEnabled = useHasFeature('booking_report_problem');
  const { refreshActiveBookings: refreshList } = useMyActiveBookings();

  const { checkedIn, desk, id, start, end, status } = booking;

  const [cancel] = useErrorMutation(cancelMutation, {
    variables: {
      id,
    },
    finderError: {
      type: 'minor',
      message: t('Home.ActiveBookings.Booking.cancelFail'),
    },
  });
  const [checkout] = useErrorMutation(checkoutMutation, {
    variables: {
      id,
    },
    finderError: {
      type: 'minor',
      message: t('Home.ActiveBookings.Booking.checkOutFail'),
    },
  });
  const [checkin] = useErrorMutation(checkInMutation, {
    variables: {
      id,
    },
    finderError: {
      type: 'minor',
      message: t('Home.ActiveBookings.Booking.checkInFail'),
    },
  });
  const type = getBookingType(booking);

  const cancelBooking = useCallback(async () => {
    await cancel();
    refreshList();
  }, [cancel, refreshList]);
  const changeCheckStatus = useCallback(() => {
    (async () => {
      if (checkedIn) {
        await checkout();
        navigation.navigate('howwasyourday', {
          bookingId: id,
        });
      } else {
        await checkin();
      }

      refreshList();
    })();
  }, [refreshList, checkin, checkout, checkedIn, navigation, id]);

  const date = universalDateFormatter({ date: start, format: dayShortFormat });
  const currentDate = universalDateFormatter({ format: dayShortFormat });

  const checkinEnabled =
    checkInFeatureEnabled &&
    desk?.type === BOOKING_TYPE_VIRTUAL_DESK &&
    date === currentDate;

  return {
    cancel: cancelBooking,
    changeCheckStatus,
    checkinEnabled,
    date,
    end: universalDateFormatter({ date: end, format: timeFormatShort }),
    isCancelable: status === 'ACTIVE',
    isReportEnabled:
      reportFeatureEnabled && reportEnabledBookings.includes(type),
    start: universalDateFormatter({ date: start, format: timeFormatShort }),
    type,
  };
}
