import { atom } from 'recoil';
import Constants from 'expo-constants';
import * as SecureStore from 'expo-secure-store';

const { DEBUG_WITHOUT_OAUTH_LOGIN } = Constants.manifest.extra;

export const loggedInAtom = atom({
  key: 'logged-in',
  default: false,
});

export const accessTokenAtom = atom({
  key: 'access-token',
  default: '',
});

// Token name
export const tokenKey = 'token';

export const getClientToken = async () =>
  DEBUG_WITHOUT_OAUTH_LOGIN || SecureStore.getItemAsync(tokenKey);
export const setClientToken = async (token: string) =>
  SecureStore.setItemAsync(tokenKey, token);
export const removeClientToken = async () =>
  SecureStore.deleteItemAsync(tokenKey);

// Refresh Token name
export const tokenRefreshKey = 'refresh_token';

export const getRefreshToken = async () =>
  SecureStore.getItemAsync(tokenRefreshKey);
export const setRefreshToken = async (token: string) =>
  SecureStore.setItemAsync(tokenRefreshKey, token);
export const removeRefreshToken = async () =>
  SecureStore.deleteItemAsync(tokenRefreshKey);
