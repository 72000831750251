import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { Loader } from '@views/shared/Loader';
import TimeSlots from '@views/shared/TimeSlots';

import { globalStyles } from '@views/shared/styles';
import { loaderSize, styles } from '@views/shared/Booking';
import { BaseBookingProps } from '@views/shared/interfaces/booking';

export default function BaseBooking({
  loading,
  blockedTimeSlots,
  bookablePeriod,
  setSlots,
  error,
  errorBooking,
  selectedSlots,
  book,
  loadingBooking,
  midDayHour,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  showAvailability,
  showShortCutSlotsSelection = true,
}: BaseBookingProps) {
  const { t } = useIntl();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  return (
    <View>
      {(enabledHourlyBooking || enabledHalfDayBooking) && !loadingBooking ? (
        <TimeSlots
          blockedTimeSlots={blockedTimeSlots}
          bookablePeriod={bookablePeriod}
          midDayHour={midDayHour}
          onSelect={setSlots}
          showAvailability={showAvailability}
          showSlotsSelection={enabledHourlyBooking}
          showShortCutSlotsSelection={showShortCutSlotsSelection}
        />
      ) : null}
      {errorBooking ? (
        <Text style={[globalStyles.title, styles.error]}>
          {t(errorBooking)}
        </Text>
      ) : null}
      <View style={styles.buttonContainer}>
        <MainButton
          disabled={!selectedSlots.length}
          onPress={book}
          title={t('Home.Structure.book')}
        />
        {loadingBooking ? (
          <Loader
            iconSize={loaderSize}
            animationStyle={styles.loaderAnimation}
            containerStyle={styles.loaderContainer}
          />
        ) : null}
      </View>
    </View>
  );
}
