import { useCallback } from 'react';

import { useGoToBookingPage } from '@views/shared/utils/useGoTo';
import { AreaStructureForMeetingRooms } from '@views/shared/interfaces/buildingStructure';

export function useBookable(area: AreaStructureForMeetingRooms) {
  const makeABooking = useGoToBookingPage();

  const { id: areaId, areaType } = area;
  const onBook = useCallback(() => makeABooking({ areaId, type: areaType }), [
    areaId,
    areaType,
    makeABooking,
  ]);

  return {
    onBook,
  };
}
