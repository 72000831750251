import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Formik, Field } from 'formik';

import { useIntl } from '@utils/intl';
import { Overlay } from '@views/shared/Overlay';
import { Input } from '@views/shared/Form';
import { SecondaryButton } from '@views/shared/Button';
import { Booking } from '@views/shared/interfaces/booking';
import { useModalWidth } from '@views/shared/Overlay/hooks';
import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';

import { useProblem } from './hooks';
import { Subject } from './Subject';

const { large, oversize, small, extraSmall } = spacings;
const { aquaMarine, grey1, warmPink, white } = colors;

const styles = StyleSheet.create({
  overlay: {
    //wtf: overwrite default style
    borderRadius: 0,
    shadowOpacity: 0,
    padding: 0,
    backgroundColor: '#ffffff00',
  },
  header: {
    backgroundColor: grey1,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    padding: small,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeTitle,
    textAlign: 'center',
    flex: 1,
  },
  content: {
    backgroundColor: white,
    borderBottomLeftRadius: extraSmall,
    borderBottomRightRadius: extraSmall,
    padding: oversize,
  },
  button: {
    alignSelf: 'flex-end',
  },
  error: {
    borderColor: warmPink,
  },
  success: {
    color: aquaMarine,
    textAlign: 'center',
    fontFamily: 'mrt-semi-bold',
    paddingBottom: large,
  },
  failed: { color: warmPink },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loader: {
    width: 20,
    height: 20,
    marginRight: small,
  },
});

interface Props {
  booking: Booking;
  location: string;
}

const validate = (values: any) => {
  const errors: { subject?: boolean; body?: boolean } = {};

  if (!values.subject) {
    errors.subject = true;
  }

  if (!values.body || values.body.length < 3) {
    errors.body = true;
  }

  return errors;
};

export function Problem({ booking, location }: Props) {
  const { t } = useIntl();
  const { close, isOpen, loading, open, sendReport, submit } = useProblem(
    booking,
  );
  const modalWidth = useModalWidth();

  return (
    <>
      <CustomIcon name="warning" onPress={open} />
      {isOpen ? (
        <Overlay
          isVisible
          onBackdropPress={close}
          overlayStyle={[styles.overlay, { width: modalWidth }]}
        >
          <View>
            <View style={styles.header}>
              <Text style={styles.title}>
                {t('Home.ActiveBookings.Booking.Problem.title')}
              </Text>
              <CustomIcon name="close" onPress={close} size={20} />
            </View>
            <View style={styles.content}>
              {sendReport.send && (
                <Text style={styles.success}>
                  {t('Home.ActiveBookings.Booking.Problem.reportSend')}
                </Text>
              )}
              {sendReport.error && (
                <Text style={[styles.success, styles.failed]}>
                  {t('Home.ActiveBookings.Booking.Problem.reportFail')}
                </Text>
              )}
              <View>
                <Text style={globalStyles.label}>
                  {t('Home.ActiveBookings.Booking.Problem.located')}
                </Text>
                <Text style={globalStyles.input}>{location}</Text>
              </View>
              <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                  submit(values, actions.resetForm);
                }}
                validate={validate}
              >
                {/* TODO: please check this  */}
                {/* @ts-ignore */}
                {({ errors: { body, subject }, handleSubmit }) => {
                  return (
                    <>
                      <Subject errorStyle={subject && styles.error} />
                      <Field
                        component={Input}
                        name="body"
                        label={t('Home.ActiveBookings.Booking.Problem.details')}
                        multiline
                        numberOfLines={4}
                        errorStyle={body && styles.error}
                      />
                      <View style={styles.footer}>
                        {loading && (
                          <Loader
                            iconSize={20}
                            animationStyle={styles.loader}
                          />
                        )}
                        <SecondaryButton
                          buttonStyle={styles.button}
                          onPress={() => handleSubmit()}
                          title={t('Home.ActiveBookings.Booking.Problem.send')}
                        />
                      </View>
                    </>
                  );
                }}
              </Formik>
            </View>
          </View>
        </Overlay>
      ) : null}
    </>
  );
}
