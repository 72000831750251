import React from 'react';
import { Text, View } from 'react-native';
import { Avatar } from 'react-native-elements';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { colors, avatarWithBadgeStyle } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';
import { Card } from '@views/shared/Card';
import { CustomIcon } from '@views/shared/CustomIcon';
import { styles } from '@views/Colleagues/Profile/Overview/PersonalInfoBox';

import { useContent } from './hooks';

const { darkBlue } = colors;

export function UserData() {
  const { me, loading, edit } = useContent();

  if (loading) {
    return <Loader />;
  }

  if (!me) {
    return <UnexpectedNoDataOrError />;
  }

  return (
    <Card style={styles.card}>
      {me.userprofile.avatar && me.userprofile.avatar.trim().length > 0 ? (
        <Avatar
          rounded
          size={100}
          source={{ uri: me.userprofile.avatar }}
          title={me.initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
        />
      ) : (
        <Avatar
          rounded
          size={100}
          title={me.initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
          containerStyle={avatarWithBadgeStyle.avatarContainer}
        />
      )}
      <View style={styles.userDataContainer}>
        <View style={styles.top}>
          <Text style={styles.name}>{me.name}</Text>
          <Text style={styles.department}>
            {me.userprofile.department?.name}
          </Text>
        </View>
        <View style={styles.bottom}>
          <View style={styles.info}>
            <CustomIcon
              name="mail"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <View style={styles.longtext}>
              <Text style={styles.text}>{me.email}</Text>
            </View>
          </View>
          <View style={styles.info}>
            <CustomIcon
              name="phone"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <Text style={styles.text}>{me.userprofile.phoneNumber}</Text>
          </View>
        </View>
      </View>
      <View>
        <CustomIcon name="edit" size={25} onPress={edit} />
      </View>
    </Card>
  );
}
