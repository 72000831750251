import React from 'react';
import { Text, View } from 'react-native';

import { MainButton } from '@views/shared/Button';
import { useIntl } from '@utils/intl';

import { styles } from '@views/Home/Book/MeetingRoom/Catering/styles';
import { ItemsProps } from '@views/Home/Book/MeetingRoom/Catering/types';

import Item from './item';

export default function Items({
  bookCatering,
  isError,
  isSaving,
  data,
  onUpdate,
}: ItemsProps) {
  const { t } = useIntl();
  return (
    <View>
      <Text style={styles.title}>{t('Home.Book.Catering.Title')}</Text>

      {data.map((item, index) => (
        <Item data={item} key={index} onUpdate={onUpdate} />
      ))}

      {isError ? (
        <Text style={styles.error}>{t('Home.Book.Catering.Order.Error')}</Text>
      ) : null}

      <View style={styles.buttonContainer}>
        <MainButton
          disabled={isError || isSaving}
          onPress={bookCatering}
          title={t('Home.Book.Catering.Button.Book')}
        />
      </View>
    </View>
  );
}
