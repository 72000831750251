import React from 'react';
import { StyleSheet, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { BookingType } from '@views/shared/interfaces/booking';
import { fonts } from '@views/shared/styles';
import {
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VIRTUAL_DESK,
} from '@views/shared/consts';

const styles = StyleSheet.create({
  title: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
});

export default function TitleByType({ type }: { type: BookingType }) {
  const { t } = useIntl();
  const defaultTitle = 'Home.ActiveBookings.Booking.title.free_spaces_room';

  const titles = {
    [BOOKING_TYPE_HOME]: 'Home.ActiveBookings.Booking.title.home',
    [BOOKING_TYPE_MEETING_ROOM]:
      'Home.ActiveBookings.Booking.title.meeting_room',
    [BOOKING_TYPE_TRAVEL_DAY]: 'Home.ActiveBookings.Booking.title.travelDay',
    [BOOKING_TYPE_PARKING_CHARGING]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_PARKING_GENERAL]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_PARKING_HANDICAPPED]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_ROOM]: 'Home.ActiveBookings.Booking.title.room',
    [BOOKING_TYPE_VIRTUAL_DESK]: 'Home.ActiveBookings.Booking.title.room',
  };

  return <Text style={styles.title}>{t(titles[type] || defaultTitle)}</Text>;
}
