import React from 'react';

import { BookingType } from '@views/shared/interfaces/booking';

import FullDayBooking from './FullDayBooking';
import HourlyBooking from './HourlyBooking';

interface Props {
  currentBookedType: BookingType;
  enabledHalfDayBooking: boolean;
  enabledHourlyBooking: boolean;
  params?: {
    areaId?: string;
    deskId?: string;
    parkingId?: string;
  };
}

export default function NextDaysBooking({
  currentBookedType,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  params,
}: Props) {
  return !enabledHourlyBooking && !enabledHalfDayBooking ? (
    <FullDayBooking currentBookedType={currentBookedType} params={params} />
  ) : (
    <HourlyBooking currentBookedType={currentBookedType} params={params} />
  );
}
