import React from 'react';
import { Text, View, ScrollView } from 'react-native';

import { useIntl } from '@utils/intl';
import { useFavourites } from '@views/Favourites/hooks';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_ROOM,
} from '@views/shared/consts';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';

import { globalStyles } from '@views/shared/styles';

import { styles } from './styles';
import List from './List';

export default function Favourites() {
  const { favDesks, favMeetingRooms, favSeats } = useFavourites();
  const { t } = useIntl();

  if (!favDesks.length && !favMeetingRooms.length && !favSeats.length) {
    return <UnexpectedNoDataOrError message={t('Home.Favourites.no-favs')} />;
  }

  return (
    <ScrollView style={globalStyles.container}>
      <View style={styles.pageHeader}>
        <Text style={globalStyles.title}>
          {t('Home.Favourites.MyFavourites')}
        </Text>
      </View>
      {favDesks.length ? <List ids={favDesks} type={AREA_TYPE_ROOM} /> : null}
      {favMeetingRooms.length ? (
        <List ids={favMeetingRooms} type={AREA_TYPE_MEETING_ROOM} />
      ) : null}
      {favSeats.length ? (
        <List ids={favSeats} type={AREA_TYPE_FREE_SPACES_ROOM} />
      ) : null}
    </ScrollView>
  );
}
