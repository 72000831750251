import { Platform, StyleSheet } from 'react-native';
import { colors, fonts, spacings } from '@views/shared/styles';

const { extraSmall, huge, oversize, smaller, verySmall } = spacings;
const { aquaMarine, black, blackAlpha, warmPink, white } = colors;
const { sizeDefault, sizeSmall, sizeSmallPlus } = fonts;
const isWeb = Platform.OS === 'web';

export const styles = StyleSheet.create({
  container: {
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: oversize,
    marginTop: oversize,
  },
  title: {
    fontSize: sizeDefault,
    fontFamily: 'mrt-semi-bold',
    marginBottom: verySmall,
  },
  item: {
    fontSize: sizeSmallPlus,
    fontFamily: 'mrt-regular',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: huge,
  },
  itemButton: {
    borderColor: blackAlpha,
    borderRadius: smaller,
    borderWidth: 1,
    backgroundColor: white,
    height: 30,
    width: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButtonTitle: {
    fontSize: 40,
    paddingBottom: verySmall,
    lineHeight: 30,
    color: black,
    fontFamily: 'mrt-regular',
    marginLeft: isWeb ? 0 : -7,
    marginTop: isWeb ? 0 : 1,
  },
  itemButtonTitlePlus: {
    fontSize: 30,
    paddingBottom: 0,
    marginTop: -1,
    marginLeft: isWeb ? 0 : -8,
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: smaller,
  },
  quantityContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantityText: {
    width: 36,
    textAlign: 'center',
    fontSize: sizeDefault,
    fontFamily: 'mrt-semi-bold',
  },
  error: {
    color: warmPink,
    fontFamily: 'mrt-semi-bold',
    marginTop: verySmall,
    fontSize: sizeSmallPlus,
  },
  errorOrder: {
    marginTop: verySmall,
  },
  success: {
    color: aquaMarine,
  },
  headlineContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    padding: 0,
  },
  orderItem: {
    fontSize: sizeSmall,
    fontFamily: 'mrt-regular',
  },
  itemText: { flex: 1 },
});
