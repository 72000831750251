import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useMyBuildings } from '@providers/Buildings';
import { useFavourites } from '@views/Favourites/hooks';
import { useCurrentDate } from '@views/Calendar/hooks';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useErrorQuery } from '@providers/Errors';
import { useGoToStructurePage } from '@views/shared/utils/useGoTo';
import { AREA_TYPE_ROOM } from '@views/shared/consts';
import { BookCategory } from '@views/shared/interfaces/booking';

const getTotalDesks = gql`
  query getBuildingDesks($id: ID!, $date: Date!) {
    building: buildingCountDesks(buildingId: $id, date: $date) {
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        desksAvailable
        freeSlots
        totalSlots
      }
    }
  }
`;

interface DesksHourlyByType {
  areaType: string;
  desksAvailable: number;
  freeSlots: number;
  totalSlots: number;
}

interface Data {
  building: { DesksData };
}
interface DesksData {
  numberOfAvailableDesksHourlyByAreaType: DesksHourlyByType[];
}

export const getDeskSlots = (
  data: DesksData | any,
  field: string,
  defaultValue: number,
  areaType = AREA_TYPE_ROOM,
) => {
  const desksByTypes = pathOr(
    [],
    ['numberOfAvailableDesksHourlyByAreaType'],
    data,
  );
  const slotsOfDesks = desksByTypes.find(
    (entry: DesksHourlyByType) => entry.areaType === areaType,
  );

  return pathOr(defaultValue, [field], slotsOfDesks);
};

export function useDesks(type: BookCategory, errorMessage: any) {
  const date = useCurrentDate();
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const goToStructure = useGoToStructurePage();

  const { data, loading, error } = useErrorQuery<Data>(getTotalDesks, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: errorMessage,
    },
  });

  const { favCount, goToFavourites } = useFavourites(type);
  const free = getDeskSlots(data?.building, 'freeSlots', 0);
  const total = getDeskSlots(data?.building, 'totalSlots', 1);
  const numberOfDesks = getDeskSlots(data?.building, 'desksAvailable', 0);

  return {
    numberOfDesks,
    loading: !buildingId || loading,
    error,
    favCount,
    goToFavourites,
    goToStructure: () => goToStructure(type),
    worklightStatus: getWorklightStatus(free, total),
  };
}
