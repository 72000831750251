import 'intl';
import { Platform } from 'react-native';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/de';

if (Platform.OS === 'android') {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}
