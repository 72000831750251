import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useIntl } from '@utils/intl';
import { Confirm as BookConfirm } from '@views/Home/Book/BasicBook/Confirm';
import { Confirm as ConfirmNextDaysBooking } from '@views/Home/Book/NextDaysBooking/FullDayBooking/Confirm';

import BookDesk from './Book/Desk';
import BookFreeSpace from './Book/FreeSpace';
import BookTravelDay from './Book/Travel';
import BookParkingSpot from './Book/ParkingSpot';
import BookMeetingRoom from './Book/MeetingRoom';
import BookHomeOffice from './Book/HomeOffice';
import Structure from './Structure';

import Favourites from './Favourites';
import { HowWasYourDay } from './HowWasYourDay';
import { Main } from './Main';

const Stack = createStackNavigator();

export function Home() {
  const { t } = useIntl();

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="index"
        component={Main}
        options={{ headerShown: false, title: t('Routes.tabs.home') }}
      />
      <Stack.Screen
        name="structure"
        component={Structure}
        options={{
          headerShown: false,
          title: t('Routes.Home.Structure'),
        }}
      />
      <Stack.Screen
        name="book-desk"
        component={BookDesk}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.BookDesk'),
        }}
      />
      <Stack.Screen
        name="book-free-space"
        component={BookFreeSpace}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.FreeSpace'),
        }}
      />
      <Stack.Screen
        name="book-meeting-room"
        component={BookMeetingRoom}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.MeetingRoom'),
        }}
      />
      <Stack.Screen
        name="book-travel"
        component={BookTravelDay}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.TravelDay'),
        }}
      />
      <Stack.Screen
        name="book-home"
        component={BookHomeOffice}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.HomeOffice'),
        }}
      />
      <Stack.Screen
        name="book-parking"
        component={BookParkingSpot}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.ParkingSpot'),
        }}
      />
      <Stack.Screen
        name="book-confirm"
        component={BookConfirm}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.Confirmation'),
        }}
      />
      <Stack.Screen
        name="book-next-confirm"
        component={ConfirmNextDaysBooking}
        options={{
          headerShown: false,
          title: t('Routes.Home.Book.Confirmation'),
        }}
      />
      <Stack.Screen
        name="favourites"
        component={Favourites}
        options={{ headerShown: false, title: t('Routes.Home.Favourites') }}
      />
      <Stack.Screen
        name="howwasyourday"
        component={HowWasYourDay}
        options={{ headerShown: false, title: t('Routes.Home.HowWasYourDay') }}
      />
    </Stack.Navigator>
  );
}
