import { gql } from '@apollo/client';
import { isNil, pathOr } from 'ramda';
import { useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';

import { Formats } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import { useErrorQuery } from '@providers/Errors';
import { useMyOrganization } from '@providers/Organization';
import { useCurrentDate } from '@views/Calendar/hooks';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { BookingType } from '@views/shared/interfaces/booking';
import { Area } from '@views/shared/interfaces/buildingStructure';

import { QueryAreaData } from './interfaces';

export const AREAS_BY_IDS_QUERY = gql`
  fragment BookingArea on AreaType {
    areaType
    floor {
      floorLabel
      floorNumber
      floorType
      building {
        name
      }
    }
    name
    equipment {
      category {
        name
      }
      name
    }
  }
`;

export const getAreaQuery = gql`
  ${AREAS_BY_IDS_QUERY}
  query getDesk($areaId: ID!) {
    areasByIds(areasIds: [$areaId]) {
      ...BookingArea
    }
  }
`;

export function useBaseValues({ dayShortFormat, timeFormatShort }: Formats) {
  const enabledHalfDayBooking = useHasFeature('halfday_booking');
  const enabledHourlyBooking = useHasFeature('hourly_booking');
  const {
    workingHoursStart,
    workingHoursEnd,
    midDayHour,
  } = useMyOrganization();
  const currentDate = useCurrentDate();
  const date = universalDateFormatter({
    date: currentDate,
    format: dayShortFormat,
  });

  const time =
    enabledHalfDayBooking || enabledHourlyBooking
      ? undefined
      : getTimeByType({
          timeFormatShort,
          workingHoursStart,
          workingHoursEnd,
        });

  return {
    currentDate,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    time,
    midDayHour,
  };
}

interface State {
  area?: Area;
}

interface Props {
  formats: Formats;
  message: string;
  type: BookingType;
}

export function useBaseValuesWithArea({ formats, message, type }: Props) {
  const {
    params: { areaId },
  } = useRoute<any>();

  const { currentDate, ...rest } = useBaseValues(formats);
  const [areaData, setAreaData] = useState<State>({});

  const { data, error, loading } = useErrorQuery<QueryAreaData>(getAreaQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      areaId,
      date: universalDateFormatter({ date: currentDate }),
    },
    finderError: {
      type: 'fatal',
      message,
    },
  });

  useEffect(() => {
    if (!isNil(data)) {
      const area = pathOr(null, ['areasByIds', 0], data);
      const newState = {
        ...(area ? { area } : {}),
      };

      setAreaData(newState);
    }
  }, [data]);

  return {
    ...rest,
    areaId,
    currentDate,
    data: areaData,
    error,
    loading,
    type,
  };
}
