import { useCallback } from 'react';

import { useGoToBookingPage } from '@views/shared/utils/useGoTo';
import { AreaStructureForParkingSpots } from '@views/shared/interfaces/buildingStructure';

export function useBookable(area: AreaStructureForParkingSpots) {
  const makeABooking = useGoToBookingPage();

  const { id: areaId, areaType: type } = area;
  const book = useCallback(() => makeABooking({ areaId, type }), [
    areaId,
    type,
    makeABooking,
  ]);

  return {
    book,
  };
}
