import React, { useCallback, useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { isEmpty, pathOr } from 'ramda';

import { useErrorQuery } from '@providers/Errors';
import { Department } from '@views/Profile/Edit/interfaces';
import { loggedInAtom } from '@views/Login';
import * as Localization from 'expo-localization';
import { defaultLang } from '@i18n/context';
import { useRecoilValue } from 'recoil';

interface MyUserProps {
  children: React.ReactElement;
}

interface MyUserContext {
  hasGoogleAccount: boolean;
  hasMicrosoftAccount: boolean;
  loading: boolean;
  loaded: boolean;
  department: Department;
  favouriteDesks: string[];
  favouriteSeats: string[];
  favouriteMeetingRooms: string[];
  hasPermission: (permission: string) => boolean;
  permissions: string[];
  refetchFavourites: () => any;
  error: any;
  homeofficeInfo: string;
  language: 'de' | 'en' | 'fr' | 'it' | string;
  id: string;
  avatar?: string;
  name: string;
  initials: string;
  email: string;
  phoneNumber: string;
  getGoogleAuthState: () => any;
  getMicrosoftAuthState: () => any;
  refetch: () => any;
}

interface FavData {
  me: {
    userprofile: {
      favouriteDesks: string[];
      favouriteSeats: string[];
      favouriteMeetingRooms: string[];
    };
  };
}

interface Data {
  hasGoogleAccount: boolean;
  hasMicrosoftAccount: boolean;
  department: Department;
  favouriteDesks: string[];
  favouriteSeats: string[];
  favouriteMeetingRooms: string[];
  permissions: string[];
  homeofficeInfo: string;
  language: 'de' | 'en' | 'fr' | 'it' | string;
  id: string;
  avatar?: string;
  name: string;
  initials: string;
  email: string;
  phoneNumber: string;
}

const googleGetAuthState = gql`
  query getGoogleAuthState {
    me {
      hasGoogleAccount
    }
  }
`;

const microsoftGetAuthState = gql`
  query getMicrosoftAuthState {
    me {
      hasMicrosoftAccount
    }
  }
`;

const getFavs = gql`
  query getFavs {
    me {
      id
      userprofile {
        favouriteDesks
        favouriteSeats
        favouriteMeetingRooms
      }
    }
  }
`;

const getMeQuery = gql`
  query getMe {
    me {
      id
      name
      email
      initials
      permissions
      hasMicrosoftAccount
      hasGoogleAccount
      userprofile {
        language
        avatar
        phoneNumber
        favouriteDesks
        favouriteSeats
        favouriteMeetingRooms
        department {
          id
          name
        }
        homeofficeInfo
      }
    }
  }
`;

const DEFAULT_CONTEXT_STATE = {
  id: '',
  avatar: undefined,
  name: '',
  initials: '',
  email: '',
  phoneNumber: '',
  department: {
    id: 'none',
    name: 'zzzzzz',
  },
  language: defaultLang,
  hasGoogleAccount: false,
  hasMicrosoftAccount: false,
  favouriteDesks: [],
  favouriteSeats: [],
  favouriteMeetingRooms: [],
  permissions: [],
  hasPermission: () => false,
  refetch: () => {},
  refetchFavourites: () => {},
  getGoogleAuthState: () => {},
  getMicrosoftAuthState: () => {},
  homeofficeInfo: '',
  loading: false,
  loaded: false,
  error: {},
};

export const MyUserContext = React.createContext<MyUserContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyUser = () => useContext(MyUserContext);

export function MyUserProvider({ children }: MyUserProps) {
  const loggedIn = useRecoilValue(loggedInAtom);

  const [id, setId] = useState(DEFAULT_CONTEXT_STATE.id);
  const [avatar, setAvatar] = useState(DEFAULT_CONTEXT_STATE.avatar);
  const [name, setName] = useState(DEFAULT_CONTEXT_STATE.name);
  const [initials, setInitials] = useState(DEFAULT_CONTEXT_STATE.initials);
  const [email, setEmail] = useState(DEFAULT_CONTEXT_STATE.email);
  const [language, setLanguage] = useState(DEFAULT_CONTEXT_STATE.language);
  const [homeofficeInfo, setHomeofficeInfo] = useState(
    DEFAULT_CONTEXT_STATE.homeofficeInfo,
  );
  const [phoneNumber, setPhoneNumber] = useState(
    DEFAULT_CONTEXT_STATE.phoneNumber,
  );
  const [department, setDepartment] = useState<Department>(
    DEFAULT_CONTEXT_STATE.department,
  );
  const [permissions, setPermissions] = useState<string[]>(
    DEFAULT_CONTEXT_STATE.permissions,
  );
  const [hasGoogleAccount, setHasGoogleAccount] = useState<boolean>(
    DEFAULT_CONTEXT_STATE.hasGoogleAccount,
  );
  const [hasMicrosoftAccount, setHasMicrosoftAccount] = useState<boolean>(
    DEFAULT_CONTEXT_STATE.hasMicrosoftAccount,
  );
  const [favouriteDesks, setFavouriteDesks] = useState(
    DEFAULT_CONTEXT_STATE.favouriteDesks,
  );
  const [favouriteSeats, setFavouriteSeats] = useState(
    DEFAULT_CONTEXT_STATE.favouriteSeats,
  );
  const [favouriteMeetingRooms, setFavouriteMeetingRooms] = useState(
    DEFAULT_CONTEXT_STATE.favouriteMeetingRooms,
  );

  const { data, loading, error, refetch } = useErrorQuery<Data>(getMeQuery, {
    skip: !loggedIn,
    finderError: {
      type: 'fatal',
      message: 'Common.Errors.Fetch.User',
    },
  });

  const [getFavourites, { data: dataFavourites }] = useLazyQuery<FavData>(
    getFavs,
    { fetchPolicy: 'no-cache' },
  );

  const [
    getGoogleAuthState,
    { data: dataGoogleAuthState },
  ] = useLazyQuery(googleGetAuthState, { fetchPolicy: 'no-cache' });

  const [
    getMicrosoftAuthState,
    { data: dataMicrosoftAuthState },
  ] = useLazyQuery(microsoftGetAuthState, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (dataFavourites) {
      const myFavouriteDesks = pathOr(
        [],
        ['me', 'userprofile', 'favouriteDesks'],
        dataFavourites,
      );
      const myFavouriteSeats = pathOr(
        [],
        ['me', 'userprofile', 'favouriteSeats'],
        dataFavourites,
      );
      const myFavouriteMeetingRooms = pathOr(
        [],
        ['me', 'userprofile', 'favouriteMeetingRooms'],
        dataFavourites,
      );

      setFavouriteDesks(myFavouriteDesks);
      setFavouriteSeats(myFavouriteSeats);
      setFavouriteMeetingRooms(myFavouriteMeetingRooms);
    }
  }, [dataFavourites]);

  useEffect(() => {
    if (dataGoogleAuthState) {
      const myHasGoogleAccount = pathOr(
        false,
        ['me', 'hasGoogleAccount'],
        dataGoogleAuthState,
      );
      setHasGoogleAccount(myHasGoogleAccount);
    }
  }, [dataGoogleAuthState]);

  useEffect(() => {
    if (dataMicrosoftAuthState) {
      const myHasMicrosoftAccount = pathOr(
        false,
        ['me', 'hasMicrosoftAccount'],
        dataMicrosoftAuthState,
      );

      setHasMicrosoftAccount(myHasMicrosoftAccount);
    }
  }, [dataMicrosoftAuthState]);

  useEffect(() => {
    if (data) {
      const myId = pathOr('', ['me', 'id'], data);
      const myLanguage = pathOr(
        Localization.locale.split('-')[0],
        ['me', 'userprofile', 'language'],
        data,
      );

      const myAvatar = pathOr(undefined, ['me', 'userprofile', 'avatar'], data);
      const myPhoneNumber = pathOr(
        '',
        ['me', 'userprofile', 'phoneNumber'],
        data,
      );
      const myName = pathOr('', ['me', 'name'], data);
      const myInitials = pathOr('', ['me', 'initials'], data);
      const myEmail = pathOr('', ['me', 'email'], data);
      const myPermissions = pathOr([], ['me', 'permissions'], data);
      const myHasGoogleAccount = pathOr(
        false,
        ['me', 'hasGoogleAccount'],
        data,
      );
      const myHasMicrosoftAccount = pathOr(
        false,
        ['me', 'hasMicrosoftAccount'],
        data,
      );
      const myDepartment = pathOr(
        DEFAULT_CONTEXT_STATE.department,
        ['me', 'userprofile', 'department'],
        data,
      );
      const myHomeofficeInfo = pathOr(
        '',
        ['me', 'userprofile', 'homeofficeInfo'],
        data,
      );
      const myFavouriteDesks = pathOr(
        [],
        ['me', 'userprofile', 'favouriteDesks'],
        data,
      );
      const myFavouriteSeats = pathOr(
        [],
        ['me', 'userprofile', 'favouriteSeats'],
        data,
      );
      const myFavouriteMeetingRooms = pathOr(
        [],
        ['me', 'userprofile', 'favouriteMeetingRooms'],
        data,
      );

      setId(myId);
      setAvatar(myAvatar);
      setLanguage(myLanguage);
      setName(myName);
      setEmail(myEmail);
      setPhoneNumber(myPhoneNumber);
      setInitials(myInitials);
      setPermissions(myPermissions);
      setHasGoogleAccount(myHasGoogleAccount);
      setHasMicrosoftAccount(myHasMicrosoftAccount);
      setDepartment(myDepartment);
      setHomeofficeInfo(myHomeofficeInfo);
      setFavouriteDesks(myFavouriteDesks);
      setFavouriteSeats(myFavouriteSeats);
      setFavouriteMeetingRooms(myFavouriteMeetingRooms);
    }
  }, [data]);

  const hasPermission = useCallback(
    (permission: string) => permissions.includes(permission),
    [permissions],
  );

  return (
    <MyUserContext.Provider
      value={{
        id,
        avatar,
        name,
        initials,
        email,
        phoneNumber,
        department,
        language,
        hasGoogleAccount,
        hasMicrosoftAccount,
        favouriteDesks,
        favouriteSeats,
        favouriteMeetingRooms,
        homeofficeInfo,
        hasPermission,
        permissions,
        loading,
        loaded: !isEmpty(id),
        error,
        getGoogleAuthState,
        getMicrosoftAuthState,
        refetchFavourites: getFavourites,
        refetch,
      }}
    >
      {children}
    </MyUserContext.Provider>
  );
}
