import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';

import { MainButton } from '@views/shared/Button';
import { globalStyles } from '@views/shared/styles';

import { styles } from '@views/Home/Book/MeetingRoom/Catering/styles';
import { ItemProps } from '@views/Home/Book/MeetingRoom/Catering/types';

export default function Item({ data, onUpdate }: ItemProps) {
  const [quantity, setQuantity] = useState<number>(0);
  const { description, itemId, title } = data;

  const increaseQuantity = useCallback(() => {
    setQuantity((prevState: number) => {
      const newQuantity = prevState + 1;
      onUpdate(itemId, newQuantity);
      return newQuantity;
    });
  }, [itemId, onUpdate]);

  const decreaseQuantity = useCallback(() => {
    setQuantity((prevState: number) => {
      const newQuantity = prevState - 1;
      onUpdate(itemId, newQuantity);
      return newQuantity;
    });
  }, [itemId, onUpdate]);

  return (
    <View style={styles.itemRow}>
      <View style={styles.itemText}>
        <Text style={globalStyles.title}>{title}</Text>
        {description ? (
          <Text style={globalStyles.description}>{description}</Text>
        ) : null}
      </View>
      <View style={styles.quantityContainer}>
        <MainButton
          buttonStyle={styles.itemButton}
          disabled={quantity === 0}
          onPress={decreaseQuantity}
          title="-"
          titleStyle={styles.itemButtonTitle}
        />
        <Text style={styles.quantityText}>{quantity}</Text>
        <MainButton
          buttonStyle={styles.itemButton}
          onPress={increaseQuantity}
          title="+"
          titleStyle={[styles.itemButtonTitle, styles.itemButtonTitlePlus]}
        />
      </View>
    </View>
  );
}
