import { useCallback } from 'react';
import { Linking, Platform } from 'react-native';
import { gql, useApolloClient } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { useMyUser } from '@providers/User';
import { useErrors } from '@providers/Errors';
import { nativeToggle } from '@views/Profile/Overview/CalendarAuth';

const getAuthUrl = gql`
  query {
    googleAuthorizationUrl
  }
`;

const revokeMutation = gql`
  mutation {
    revokeGoogleAuthorization
  }
`;

export function useGoogle() {
  const { t } = useIntl();
  const { hasGoogleAccount, getGoogleAuthState } = useMyUser();
  const client = useApolloClient();
  const checkedIn = hasGoogleAccount;
  const { addError } = useErrors();
  const toggle = useCallback(async () => {
    try {
      if (checkedIn) {
        await client.mutate({ mutation: revokeMutation });

        return getGoogleAuthState();
      }

      const { data } = await client.query<{ googleAuthorizationUrl: string }>({
        query: getAuthUrl,
      });

      if (!data) {
        return;
      }

      await Linking.openURL(data.googleAuthorizationUrl);
    } catch (error) {
      addError({
        type: 'minor',
        message: t('Profile.Content.Google.hooks.urlError'),
      });
    }
  }, [checkedIn, client, getGoogleAuthState, addError, t]);

  const handleNative = () => nativeToggle(t);

  return {
    toggle: Platform.OS === 'web' ? toggle : handleNative,
    checkedIn,
  };
}
