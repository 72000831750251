import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Avatar, ListItem, withBadge, Text } from 'react-native-elements';

import {
  colors,
  spacings,
  globalStyles,
  fonts,
  avatarWithBadgeStyle,
} from '@views/shared/styles';

import { ColleagueApiResponse } from '@views/Colleagues/Colleague/interfaces';
import { useLightColleague } from '@views/Colleagues/Colleague';
import { PresenceIcon } from '@views/Colleagues/Profile/Overview/StatusBox';

interface Props {
  colleague: ColleagueApiResponse;
}

interface StatusLabel {
  formatMessage: any;
}

const SHOW_AVATAR_BADGE = false;

const { sizeMedium, sizeSmall } = fonts;
const { darkBlue, aquaMarine, white, warmPink } = colors;
const { smaller, verySmall } = spacings;

const styles = StyleSheet.create({
  container: {
    paddingVertical: verySmall,
    paddingHorizontal: verySmall,
  },
  text: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeMedium,
  },
  badgeWrapper: {
    position: 'absolute',
    right: 40,
  },
  badgeFree: {
    backgroundColor: aquaMarine,
  },
  badgeBusy: {
    paddingVertical: smaller,
    alignSelf: 'center',
    width: 90,
    backgroundColor: warmPink,
    borderRadius: 25,
  },
  badgeText: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeSmall,
    color: white,
    textAlign: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    right: 0,
  },
});

const i18nMessages = defineMessages({
  'Colleagues.List.Screen.status.free': {
    id: 'Colleagues.List.Screen.status.free',
    defaultMessage: 'Free',
  },
  'Colleagues.List.Screen.status.busy': {
    id: 'Colleagues.List.Screen.status.busy',
    defaultMessage: 'Busy',
  },
});

const badgeStatusLabel = {
  UNKNOWN: ({ formatMessage }: StatusLabel) => null,
  // eslint-disable-next-line react/display-name
  AVAILABLE: ({ formatMessage }: StatusLabel) => (
    <View style={styles.badgeWrapper}>
      <View style={[styles.badgeBusy, styles.badgeFree]}>
        <Text style={styles.badgeText}>
          {formatMessage(i18nMessages['Colleagues.List.Screen.status.free'])}
        </Text>
      </View>
    </View>
  ),
  // eslint-disable-next-line react/display-name
  OCCUPIED: ({ formatMessage }: StatusLabel) => (
    <View style={styles.badgeWrapper}>
      <View style={styles.badgeBusy}>
        <Text style={styles.badgeText}>
          {formatMessage(i18nMessages['Colleagues.List.Screen.status.busy'])}
        </Text>
      </View>
    </View>
  ),
};

export function ColleagueListItem({ colleague }: Props) {
  const { formatMessage } = useIntl();
  const {
    goToProfile,
    name,
    initials,
    icon,
    status,
    badgeColor,
  } = useLightColleague({
    colleague,
  });

  // @ts-ignore
  const BadgedAvatar =
    status && SHOW_AVATAR_BADGE
      ? withBadge(null, { status: badgeColor, badgeStyle: globalStyles.badge })(
          Avatar,
        )
      : Avatar;

  return (
    <ListItem
      containerStyle={styles.container}
      onPress={goToProfile}
      underlayColor={aquaMarine}
    >
      {colleague.avatar && colleague.avatar.trim().length > 0 ? (
        <BadgedAvatar
          rounded
          size="small"
          source={{ uri: colleague.avatar }}
          title={initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
        />
      ) : (
        <BadgedAvatar
          rounded
          size="small"
          title={initials}
          titleStyle={avatarWithBadgeStyle.title}
          placeholderStyle={avatarWithBadgeStyle.placeholder}
          overlayContainerStyle={avatarWithBadgeStyle.avatarOverlayContainer}
          containerStyle={avatarWithBadgeStyle.avatarContainer}
        />
      )}
      <ListItem.Content>
        <ListItem.Title style={styles.text}>{name}</ListItem.Title>
        {badgeStatusLabel[status]({ formatMessage })}
        <View style={styles.iconWrapper}>
          <PresenceIcon
            color={darkBlue}
            icon={icon}
            status={colleague.presenceStatus}
          />
        </View>
      </ListItem.Content>
    </ListItem>
  );
}
