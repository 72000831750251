import React from 'react';
import { TouchableWithoutFeedback, StyleSheet, View, Text } from 'react-native';
import { FieldProps } from 'formik';
import { CustomIcon } from '@views/shared/CustomIcon';

import {
  Select as BaseSelect,
  SelectProps,
  ItemComponentProps,
} from '@views/shared/Select';

import { fonts, globalStyles } from '@views/shared/styles';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    fontFamily: 'pts-regular',
    fontSize: fonts.sizeDefault,
  },
});

interface Props extends FieldProps {
  label?: string;
  selectProps: Omit<SelectProps, 'onValueChange' | 'value'>;
}

function FormSelected({
  item,
  show,
  placeholder,
  errorStyle,
}: ItemComponentProps) {
  return (
    <TouchableWithoutFeedback onPress={show}>
      <View style={[globalStyles.input, styles.container, errorStyle]}>
        <Text style={styles.item}>{item?.label ?? placeholder}</Text>
        <CustomIcon name="arrow-down" size={13} />
      </View>
    </TouchableWithoutFeedback>
  );
}

export function Select({ field, label, form, selectProps }: Props) {
  const name = field.name;
  return (
    <View>
      {label ? <Text style={globalStyles.label}>{label}</Text> : null}
      <BaseSelect
        {...selectProps}
        onValueChange={form.handleChange(name)}
        value={field.value}
        selectedComponent={FormSelected}
      />
    </View>
  );
}
