import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.Content.Toggles.fetchError': {
    id: 'Profile.Content.Toggles.fetchError',
    defaultMessage: 'Could not fetch feature toggles state',
  },
  'Profile.Content.Toggles.changeError': {
    id: 'Profile.Content.Toggles.changeError',
    defaultMessage: 'Could not change feature toggle',
  },
  'Profile.Content.Toggles.title': {
    id: 'Profile.Content.Toggles.title',
    defaultMessage: 'Privacy Settings',
  },
  'Profile.Content.Toggles.colleague': {
    id: 'Profile.Content.Toggles.colleague',
    defaultMessage: 'Find my colleague',
  },
  'Profile.Content.Toggles.colleague.description': {
    id: 'Profile.Content.Toggles.colleague..description',
    defaultMessage:
      'With the activation of this feature, your colleagues can see what workplace you have booked.',
  },
  'Profile.Content.Toggles.calendar': {
    id: 'Profile.Content.Toggles.calendar',
    defaultMessage: 'Others can see my calendar',
  },
  'Profile.Content.Toggles.calendar.description': {
    id: 'Profile.Content.Toggles.calendar.description',
    defaultMessage:
      'With the activation of this feature, your colleagues can view their calendar. However, you can not see an appointment details.',
  },
  'Profile.Content.Toggles.homeoffice': {
    id: 'Profile.Content.Toggles.homeoffice',
    defaultMessage: 'Show Office / Home-Office',
  },
  'Profile.Content.Toggles.homeoffice.description': {
    id: 'Profile.Content.Toggles.homeoffice.description',
    defaultMessage:
      'Let your colleagues know if you are in the Office or HomeOffice.',
  },
  'Profile.Content.Toggles.privateMode': {
    id: 'Profile.Content.Toggles.privateMode',
    defaultMessage: 'Calendar visible in private mode',
  },
  'Profile.Content.Toggles.privateMode.description': {
    id: 'Profile.Content.Toggles.privateMode.description',
    defaultMessage:
      'If this function is switched off, your colleagues can only see that they have an appointment but not the topic of the appointment.',
  },
});

export const messages = dictionary;
