import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { compose, groupBy, prop, sortBy, toLower } from 'ramda';
import { FlatList, ScrollView, StyleSheet, View } from 'react-native';
import { Text } from 'react-native-elements';

import { Accordion } from '@views/shared/Accordion';
import { ColleagueListItem } from '@views/Colleagues/List/ColleagueListItem';
import {
  ColleagueApiResponse,
  GroupedColleaguesViewData,
  renderListItemProps,
} from '@views/Colleagues/Colleague/interfaces';
import { spacings, colors, fonts, globalStyles } from '@views/shared/styles';

const { grey1, white } = colors;
const { extraSmall, huge, small } = spacings;

const i18nMessages = defineMessages({
  'Colleagues.List.no_department': {
    id: 'Colleagues.List.no_department',
    defaultMessage: 'No Department assigned',
  },
});

export const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  title: {
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
  },
  accordionChildren: {
    marginBottom: extraSmall,
    marginTop: small,
  },
  accordionHeader: {
    backgroundColor: grey1,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  accordionContainer: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: grey1,
    borderRadius: extraSmall,
    marginBottom: huge,
  },
});

interface Props {
  name: string;
  colleagues: ColleagueApiResponse[];
  open: boolean;
}

const keyExtractor = (item, index) => index.toString();

const RenderDepartmentListItem = ({
  data: department,
  open,
}: renderListItemProps) => {
  const { name, colleagues } = department;

  return (
    <DepartmentListItem
      key={name}
      name={name}
      colleagues={colleagues}
      open={open}
    />
  );
};

export function DepartmentsView({ colleagues }: GroupedColleaguesViewData) {
  const intl = useIntl();
  const transNoDep = intl.formatMessage(
    i18nMessages['Colleagues.List.no_department'],
  );

  // @ts-ignore
  const groupByDepartment = groupBy(compose(toLower, prop('departmentGroup')));
  const mapColleaguesDepartment = (colleague) => {
    return {
      ...colleague,
      departmentGroup:
        colleague.department?.name || colleague.department?.id || 'zzzzzz', // put to the end of the list
    };
  };
  const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));

  const alphabeticalSortedColleagues = sortByNameCaseInsensitive(colleagues);
  const alphabeticalSortedColleaguesMapped = alphabeticalSortedColleagues.map(
    mapColleaguesDepartment,
  );

  // @ts-ignore
  const groupedDepartmentsWithAlphabeticalSortedColleaguesSource = groupByDepartment(
    alphabeticalSortedColleaguesMapped,
  );

  const decorateItem = (departmentGroup) => {
    const departmentData =
      groupedDepartmentsWithAlphabeticalSortedColleaguesSource[departmentGroup];

    return {
      // @ts-ignore
      name: departmentData[0]?.department?.name || transNoDep,
      colleagues: departmentData,
    };
  };
  const groupedDepartmentsWithAlphabeticalSortedColleagues = Object.keys(
    groupedDepartmentsWithAlphabeticalSortedColleaguesSource,
  )
    .sort()
    .map(decorateItem);

  const renderItem = ({ item: department}) => (
    <RenderDepartmentListItem open={false} data={department} />
  );

  return (
    <ScrollView style={globalStyles.container}>
      <FlatList
        keyExtractor={keyExtractor}
        data={groupedDepartmentsWithAlphabeticalSortedColleagues}
        renderItem={renderItem}
      />
    </ScrollView>
  );
}

function DepartmentListItem({ colleagues, name, open }: Props) {
  const titleElm = (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>{name}</Text>
    </View>
  );

  return (
    <Accordion
      title={titleElm}
      open={open}
      containerStyle={styles.accordionContainer}
      headerStyle={styles.accordionHeader}
      childrenStyle={styles.accordionChildren}
    >
      {colleagues.map((colleague, index) => (
        <ColleagueListItem key={`${name}-${index}`} colleague={colleague} />
      ))}
    </Accordion>
  );
}
