import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.Edit.hooks.depsError': {
    id: 'Profile.Edit.hooks.depsError',
    defaultMessage: 'Could not fetch departments',
  },
  'Profile.Edit.hooks.updateError': {
    id: 'Profile.Edit.hooks.updateError',
    defaultMessage: 'Could not update user profile',
  },
  'Profile.Edit.name': {
    id: 'Profile.Edit.name',
    defaultMessage: 'Name',
  },
  'Profile.Edit.email': {
    id: 'Profile.Edit.email',
    defaultMessage: 'Email',
  },
  'Profile.Edit.phone': {
    id: 'Profile.Edit.phone',
    defaultMessage: 'Phone Number',
  },
  'Profile.Edit.save': {
    id: 'Profile.Edit.save',
    defaultMessage: 'Save',
  },
  'Profile.Edit.cancel': {
    id: 'Profile.Edit.cancel',
    defaultMessage: 'Cancel',
  },
  'Profile.Edit.homeoffice': {
    id: 'Profile.Edit.homeoffice',
    defaultMessage: 'Home Office Info',
  },
});

export const messages = dictionary;
