import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Home.Favourites.book': {
    id: 'Home.Favourites.book',
    defaultMessage: 'Book',
  },
  'Home.Favourites.disabled': {
    id: 'Home.Favourites.disabled',
    defaultMessage: 'Not available',
  },
  'Home.Favourites.no-favs': {
    id: 'Home.Favourites.no-favs',
    defaultMessage: "You don't have any favourite desks yet",
  },
  'Home.Favourites.MyFavourites': {
    id: 'Home.Favourites.MyFavourites',
    defaultMessage: 'My Favourites',
  },
  'Home.Favourites.hooks.desks.desksFetchFail': {
    id: 'Home.Favourites.hooks.desks.desksFetchFail',
    defaultMessage: 'Could not fetch list of favourite desks',
  },
  'Home.Favourites.hooks.area.fetchFail': {
    id: 'Home.Favourites.hooks.area.fetchFail',
    defaultMessage: 'Could not fetch list of favourite areas',
  },
  'Home.Favourites.Error.NoFreeSpaceAreas': {
    id: 'Home.Favourites.Error.NoFreeSpaceAreas',
    defaultMessage: 'No favorite areas found',
  },
  'Home.Favourites.Error.NoMeetingRooms': {
    id: 'Home.Favourites.Error.NoMeetingRooms',
    defaultMessage: 'No favorite meeting rooms found',
  },
  'Home.Favourites.Error.NoDesks': {
    id: 'Home.Favourites.Error.NoDesks',
    defaultMessage: 'No favorite desks found',
  },
});

export default {
  ...dictionary,
};
