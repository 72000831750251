import { LocaleConfig } from 'react-native-calendars';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useIntl } from 'react-intl';

dayjs.extend(localeData);

export function LoadCalenderConfig() {
  const { locale } = useIntl();

  LocaleConfig.locales[locale] = {
    monthNames: dayjs().localeData().months(),
    monthNamesShort: dayjs().localeData().monthsShort(),
    dayNames: dayjs().localeData().weekdays(),
    dayNamesShort: dayjs().localeData().weekdaysShort(),
  };
  LocaleConfig.defaultLocale = locale;
}
