import React from 'react';
import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import { colors } from '@views/shared/styles';

const Icon = createIconSetFromIcoMoon(
  require('@assets/fonts/icons/selection.json'),
  'liz-native-finder-icomoon',
  'liz-native-finder-icomoon',
);

interface Props {
  color?: string;
  name: string;
  onPress?: () => void;
  size?: number;
  style?: any;
}

export function CustomIcon({
  color = colors.darkBlue,
  name,
  onPress,
  size = 20,
  style,
}: Props) {
  return (
    <Icon
      color={color}
      name={name}
      onPress={onPress}
      size={size}
      style={style}
    />
  );
}
