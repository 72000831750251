import React from 'react';
import { StyleSheet } from 'react-native';

import { Loader } from '@views/shared/Loader';
import { EventsList } from '@views/shared/Events';

import { useEvents } from './hooks';

const styles = StyleSheet.create({
  loader: {
    height: 70,
  },
});

export function Events() {
  const { events, loading, error } = useEvents();

  if (loading) {
    return <Loader containerStyle={styles.loader} />;
  }

  if (error || !events.length) {
    return null;
  }

  return <EventsList events={events} />;
}
