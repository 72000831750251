import { useEffect, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { pathOr, mergeDeepRight } from 'ramda';

import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';

import translations, { supportedLanguages } from './translations/prod';
import { localeFormatDefault, localeFormats } from './formats';
import { defaultLang } from './context';

export default () => {
  const { language, refetch } = useMyUser();
  const { translations: customMessages } = useMyOrganization();
  const lang =
    language && supportedLanguages.includes(language) ? language : defaultLang;

  const setLang = useCallback(() => refetch(), [refetch]);

  const trs: { [index: string]: any } = translations;
  const messages = useMemo(() => mergeDeepRight(trs[lang], customMessages), [
    lang,
    trs,
    customMessages,
  ]);

  const formats = pathOr(localeFormatDefault, [lang], localeFormats);

  useEffect(() => {
    dayjs.locale(lang);
  }, [lang]);

  return {
    //allMessages: trs,
    formats,
    lang,
    messages,
    customMessages,
    setLang,
  };
};
