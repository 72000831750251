import React from 'react';
import { View, StyleSheet, TouchableHighlight, Platform } from 'react-native';
import { Text } from 'react-native-elements';

import { STATUS, SLOT_STATUS } from '@views/shared/TimeSlots/helper';
import { colors, fonts } from '@views/shared/styles';
import { SlotUIStatus, Status } from '@views/shared/TimeSlots/interfaces';

import { usePoints, ComponentProps } from './hooks';

/**
 * This Function map the Status of a Point into the UI status type
 **/
function mapPointStatusToUI(status: Status): SlotUIStatus {
  const mapStatus = {
    [STATUS.BLOCKED]: SLOT_STATUS.BLOCKED,
    [STATUS.BOOKED]: SLOT_STATUS.BOOKED,
    [STATUS.BOOKED_BY_COLLEAGUE]: SLOT_STATUS.BOOKED_BY_COLLEAGUE,
    [STATUS.FREE]: SLOT_STATUS.FREE,
    // EXPIRED At the moment is not used
    // OUTSIDE_BUILDING is not a Status that is not showed via Floor Plan
  };

  return mapStatus[status];
}

const { black, white, aquaMarine, darkBlue, paleOrange, warmPink } = colors;
const { sizeDefault, sizeSmaller } = fonts;

const styles = StyleSheet.create({
  point: {
    zIndex: 50,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    color: black,
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: sizeDefault,
  },
});

const mapBackgroundColor = {
  blocked: warmPink,
  booked: darkBlue,
  bookedByColleague: paleOrange,
  free: aquaMarine,
};

const mapTextColor = {
  blocked: white,
  booked: white,
  bookedByColleague: black,
  free: black,
};

export function Points({ ids, floorType, showAvailability }: ComponentProps) {
  const { book, points, scale, showNumber } = usePoints({
    ids,
    floorType,
  });

  const isAndroid = Platform.OS === 'android';
  const pointSize = (isAndroid ? 15 : 20) / scale;
  const fontSize = (isAndroid ? sizeSmaller : sizeDefault) / scale;
  const pointBaseStyle = {
    width: pointSize,
    height: pointSize,
    borderRadius: 10 / scale,
  };

  return (
    <>
      {points.map((point, index) => {
        const { deskCaption, id, isAvailableToBook, status, x, y } = point;
        const myStatus: SlotUIStatus = mapPointStatusToUI(status);

        const textColor = mapTextColor[myStatus];
        const pointStyle = {
          ...pointBaseStyle,
          backgroundColor: mapBackgroundColor[myStatus],
        };

        return (
          <TouchableHighlight
            onPress={() =>
              isAvailableToBook
                ? book(point)
                : showAvailability({ type: 'point', element: point })
            }
            key={index}
            style={[
              styles.point,
              pointStyle,
              {
                transform: [
                  {
                    translateX: x - 10 / scale,
                  },
                  {
                    translateY: y - 10 / scale,
                  },
                ],
              },
            ]}
          >
            <View style={[styles.point, pointStyle]}>
              {
                // desk/ parking id for debugging
                Platform.OS === 'web' && (
                  <Text style={{ display: 'none' }}>{`PointID: ${id}`}</Text>
                )
              }
              {showNumber && (
                <Text style={[styles.caption, { fontSize, color: textColor }]}>
                  {deskCaption}
                </Text>
              )}
            </View>
          </TouchableHighlight>
        );
      })}
    </>
  );
}
