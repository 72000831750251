import { atob as polyAtob } from './atob';

const decode = 'atob' in globalThis ? atob : polyAtob;

function getMimeType(dataURI: string): string {
  const matched = dataURI.match(/^data:(.*);base64/);

  return matched?.[1] ?? 'image/jpeg';
}

export function b64toBlob(dataURI: string) {
  const byteString = decode(dataURI.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const bytes = new Uint8Array(arrayBuffer);
  const type = getMimeType(dataURI);

  for (let i = 0; i < byteString.length; i++) {
    bytes[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type });

  return {
    blob,
    type,
  };
}
