import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

export default function CloseConfirmBooking() {
  const navigation = useNavigation();
  const goHome = useCallback(() => {
    navigation.navigate('index');
  }, [navigation]);

  return (
    <CustomIcon name="close" onPress={goHome} size={20} style={styles.close} />
  );
}
