import { useCallback } from 'react';
import { Linking, Platform } from 'react-native';
import { useIntl } from 'react-intl';

export function usePolicy() {
  const intl = useIntl();
  const link = intl.locale === 'de' ? 'https://liz.solutions/daten/' : 'https://liz.solutions/en/daten/';
  const open = useCallback(async () => {

    // fallback for web cause linking package does not support new tab
    if(Platform.OS == 'web'){
      window.open(link, '_blank');
    } else {
      Linking.openURL(link);
    }

  }, [link]);

  return {
    open,
  };
}
