import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useErrors } from '@providers/Errors';
import { useSetRecoilState } from 'recoil';
import { loggedInAtom } from '../Login';

export function Auth0Redirect() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const [ok, setOk] = useState(false);
  const { forceClearAllErrors } = useErrors();
  const setLoggedIn = useSetRecoilState(loggedInAtom);

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    setTimeout(() => {
      if (isMounted) setOk(true);
    }, 300);

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [setOk]);

  useEffect(() => {
    if (isAuthenticated) {
      forceClearAllErrors();
      return setLoggedIn(true);
    }

    if (isLoading || !ok) {
      return;
    }

    (async function () {
      await loginWithRedirect();
    })();
  }, [
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    ok,
    forceClearAllErrors,
    setLoggedIn,
  ]);

  return null;
}
