import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isNil, pathOr } from 'ramda';
import { useNavigation } from '@react-navigation/native';
import { gql } from '@apollo/client';

import { STATUS } from '@views/shared/TimeSlots/helper';
import {
  imageHeightSelector,
  rotatedSelector,
  scaleSelector,
} from '@views/Floorplan/ZoomImage/state';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
} from '@views/shared/consts';

import { getDeskSlots } from '@views/Home/Bookings/Desks/hooks';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useErrorQuery } from '@providers/Errors';
import { universalDateFormatter, dateFormatShort } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useCurrentDate } from '@views/Calendar/hooks';

import {
  AreaType,
  WorklightStatus,
} from '@views/shared/interfaces/buildingStructure';
import {
  AreasData,
  AreaFloor,
  AreaCoordinates,
  AreaWithStatus,
} from '@views/shared/interfaces/floorplan';
import { Status } from '@views/shared/TimeSlots/interfaces';
import { Booking } from '@views/shared/interfaces/booking';

const getAreasQuery = gql`
  query getAreas($ids: [ID]!, $date: Date) {
    floorplanAreas(areasIds: $ids, date: $date) {
      id
      areaType
      name
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        freeSlots
        totalSlots
      }
      meetingRoomTimeStats {
        booked
        total
      }
      coordinates {
        x
        y
        height
        width
      }
    }
  }
`;

const getStatusByType = (area: AreaFloor, isBooked: boolean): Status => {
  const areasTypes = {
    [AREA_TYPE_FREE_SPACES_ROOM]: () => {
      const free = getDeskSlots(
        area,
        'freeSlots',
        0,
        AREA_TYPE_FREE_SPACES_ROOM,
      );
      const total = getDeskSlots(
        area,
        'totalSlots',
        1,
        AREA_TYPE_FREE_SPACES_ROOM,
      );

      const worklight: WorklightStatus = getWorklightStatus(free, total);

      return getAreaStatus(worklight, isBooked);
    },
    [AREA_TYPE_MEETING_ROOM]: () => {
      const { meetingRoomTimeStats } = area;
      const bookedTime = pathOr(0, ['booked'], meetingRoomTimeStats);
      const totalTime = pathOr(1, ['total'], meetingRoomTimeStats);
      const free = totalTime - bookedTime;

      const worklight: WorklightStatus = getWorklightStatus(free, totalTime);

      return getAreaStatus(worklight, isBooked);
    },
  };

  return areasTypes[area.areaType]();
};

const getAreaStatus = (worklight: WorklightStatus, isBooked): Status => {
  const worklights = {
    low: STATUS.FREE,
    medium: STATUS.BOOKED_BY_COLLEAGUE,
    high: STATUS.BLOCKED,
    none: STATUS.BLOCKED,
  };

  if (isBooked) {
    return STATUS.BOOKED;
  }

  return worklights[worklight];
};

export const checkActiveAreaBooking = (areaType: AreaType, id: string) => ({
  area,
}: Booking) => area?.id === id;

function decorateArea({
  area,
  rotated,
  imageHeight,
  findActiveBooking,
  checkIsAvailableToBook,
}: {
  area: AreaFloor;
  rotated: boolean;
  imageHeight: number;
  findActiveBooking: (id: string, areaType: AreaType) => boolean;
  checkIsAvailableToBook: (status: Status, areaType: AreaType) => boolean;
}): AreaWithStatus {
  const isBooked = findActiveBooking(area.id, area.areaType);
  const status: Status = getStatusByType(area, isBooked);

  // Always defined because we are specifically checking for it in filter
  const { x, y, width, height } = area.coordinates as AreaCoordinates;
  // Distance from top for new top left corner.
  // Distance from right is just y, because after rotating -90 deg y is new x.
  const y2 = imageHeight - width - x;

  return {
    ...area,
    status,
    isAvailableToBook: checkIsAvailableToBook(status, area.areaType),
    coordinates: {
      // If rotated flip dimensions and use new top left corner
      x: rotated ? y : x,
      y: rotated ? y2 : y,
      width: rotated ? height : width,
      height: rotated ? width : height,
    },
  };
}

const isOwnDailyBookFreeSeats = ({
  status,
  areaType,
  enabledHourlyBooking,
}: {
  status: Status;
  areaType: AreaType;
  enabledHourlyBooking: boolean;
}) =>
  !enabledHourlyBooking &&
  areaType === AREA_TYPE_FREE_SPACES_ROOM &&
  status === STATUS.BOOKED;

export function useArea(ids: string[]) {
  const rotated = useRecoilValue(rotatedSelector);
  const height = useRecoilValue(imageHeightSelector) ?? 1;
  const scale = useRecoilValue(scaleSelector);
  const date = useCurrentDate();
  const { t } = useIntl();
  const navigation = useNavigation();
  const { bookings } = useMyActiveBookings();
  const enabledHourlyBooking = useHasFeature('hourly_booking');
  const [areas, setAreas] = useState<AreaWithStatus[]>([]);

  const findActiveBooking = useCallback(
    (id: string, areaType: AreaType): boolean =>
      !isNil(bookings.find(checkActiveAreaBooking(areaType, id))),
    [bookings],
  );

  const checkIsAvailableToBook = useCallback(
    (status: Status, areaType: AreaType) => {
      return (
        status === STATUS.FREE ||
        (status !== STATUS.BLOCKED &&
          !isOwnDailyBookFreeSeats({ enabledHourlyBooking, status, areaType }))
      );
    },
    [enabledHourlyBooking],
  );

  const { data, refetch } = useErrorQuery<AreasData>(getAreasQuery, {
    variables: {
      ids,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
    skip: !ids,
    finderError: {
      type: 'fatal',
      message: t('Floorplan.ZoomImage.Areas.fetch'),
    },
  });

  useEffect(() => {
    navigation.addListener('focus', () => {
      refetch();
    });
  }, [navigation, refetch]);

  useEffect(() => {
    if (data?.floorplanAreas) {
      const transformedData = data.floorplanAreas
        .filter(({ coordinates }) => !isNil(coordinates))
        .map((area) =>
          decorateArea({
            area,
            rotated,
            imageHeight: height,
            findActiveBooking,
            checkIsAvailableToBook,
          }),
        );
      setAreas(transformedData);
    }
  }, [data, rotated, height, findActiveBooking, checkIsAvailableToBook]);

  return {
    scale,
    areas,
  };
}
